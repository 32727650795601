import { api } from './configs/axiosConfigs';

export const ProductAPI = {
  getProducts: async function () {
    const response = await api.request({
      url: '/products/',
      method: 'GET',
    });

    return response.data;
  },
  getProduct: async function (productId) {
    const response = await api.request({
      url: `/products/${productId}`,
      method: 'GET',
    });

    return response.data;
  },
  getPrice: async function (productId, period) {
    const response = await api.request({
      url: `/products/${productId}/prices?period=${period}`,
      method: 'GET',
    });

    return response.data;
  },
};
