import { useContext, useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { TitleLabel } from '../../components/Label';
import { UserContext } from '../../UserContext';
import { AccountAPI } from '../../apis/AccountAPI';
import EvePaper from '../../components/EvePaper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { SvgIcon, ImageIcon } from '../../components/Icon';
import LinearProgressBar from '../../components/LinearProgressBar';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AssetPriceNumberFormat } from '../../utils/CoinNumberFormat';
import Decimal from 'decimal.js';
import useWebSocket from 'react-use-websocket';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';
import { ProductAPI } from '../../apis/ProductAPI';
import IconButton from '@mui/material/IconButton';
import NavigationBar from '../../components/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { UserAPI } from '../../apis/UserAPI';
import {
  PriceNumberFormat,
  priceNumberRoundedToInt,
} from '../../utils/CoinNumberFormat';

const eveFunctions = [
  { name: '儲值', iconSrc: '/icons/deposit.svg', path: '/deposit/bank' },
  { name: '提領', iconSrc: '/icons/withdraw.svg', path: '/withdraw/bank' },
  { name: '買賣', iconSrc: '/icons/trade.svg', path: '/market/BTC-TWD' },
  {
    name: '帳戶紀錄',
    iconSrc: '/icons/history.svg',
    path: '/assetHistory/order',
  },
];

export default function AssetPage() {
  const { user, setLoading } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [limitQuota, setLimitQuota] = useState(0);
  const navigate = useNavigate();

  let digitalAssetSum = 0;
  let ntdAssetSum = 0;
  for (let i = 0; i < accounts.length; i++) {
    if (accounts[i].currencyId === 'TWD') {
      ntdAssetSum = accounts[i].availableBalance;
    } else {
      digitalAssetSum += priceNumberRoundedToInt(accounts[i].twdValue);
    }
  }
  const estimatedTotalAssets = digitalAssetSum + ntdAssetSum;

  const [realtimePrices, setRealtimePrices] = useState(null);
  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => false,
    queryParams: {
      token: getWebsocketToken(),
    },
  });
  const initData = useRef(false);

  // TODO: 底下四個 useEffect 應該要做成一個hook，且應該要用 promise.all 去處理
  useEffect(() => {
    async function fetchAccountsData() {
      if (user) {
        setLoading(true);
        const response = await AccountAPI.getAccounts();
        setAccounts(response);
        setLoading(false);
      }
    }
    fetchAccountsData();
  }, [user, setLoading, setAccounts]);

  useEffect(() => {
    async function fetchProductData() {
      setLoading(true);
      const products = await ProductAPI.getProducts();
      setProducts(products);
      setLoading(false);
    }

    if (initData.current === false) {
      initData.current = true;
      fetchProductData();
    }

    if (lastMessage) {
      const data = JSON.parse(lastMessage?.data);
      if (data['id'] && data['status']) {
        return;
      }

      const prices = data;
      setRealtimePrices(prices);
    }
  }, [products, lastMessage, setLoading]);

  useEffect(() => {
    async function fetchLimitQuota() {
      if (user) {
        setLoading(true);
        const limitQuota = await UserAPI.getLimitQuota();
        setLimitQuota(limitQuota);
        setLoading(false);
      }
    }
    fetchLimitQuota();
  }, [user, setLoading, setLimitQuota]);

  return (
    <>
      <NavigationBar title="我的帳戶" leftButtonHandler={() => navigate('/')}>
        <IconButton onClick={() => navigate('/member')}>
          <ImageIcon
            src="/icons/wallet.svg"
            width={28}
            height={28}
            alt="wallet"
          />
        </IconButton>
      </NavigationBar>

      <Box padding="72px 16px">
        {/* 會員資訊 */}
        <Box marginBottom="16px">
          <MemberInfo
            phoneNumber={user?.phoneNumber}
            id={user?.id}
            level={user?.level}
          />
        </Box>
        <Stack spacing={3}>
          {/* 總資產估值和功能資訊 */}
          <EveFunctionPanel
            estimatedTotalAssets={estimatedTotalAssets}
            bankAccountStatus={user?.bankAccount?.status}
            accounts={accounts}
            navigate={navigate}
          />
          {/* 帳戶額度 */}
          <QuotaProgressBar limitQuota={limitQuota} navigate={navigate} />
          {/* 帳戶資訊 */}
          {accounts.length > 0 && (
            <AccountInfo accounts={accounts} realtimePrices={realtimePrices} />
          )}
        </Stack>
      </Box>
    </>
  );
}

function MemberInfo({ phoneNumber, id, level }) {
  return (
    <>
      <Typography fontSize={18} fontWeight={400} letterSpacing={-0.4}>
        {phoneNumber}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Typography fontSize="12px" color="text.gray">
          UID: {id}
        </Typography>
        <Typography fontSize="12px" color="text.primary">
          {/* (EVE 會員分級) */}
          {`(${level})`}
        </Typography>
      </Stack>
    </>
  );
}

function EveFunctionPanel({
  estimatedTotalAssets,
  bankAccountStatus,
  accounts,
  navigate,
}) {
  const [showAsset, setShowAsset] = useState(false);

  const initProfitLoss = 0;
  const profitLossTotal = accounts?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.profitLoss,
    initProfitLoss,
  );
  function profitLossSymbol() {
    if (profitLossTotal > 0) {
      return '+';
    } else if (profitLossTotal < 0) {
      return '-';
    } else {
      return '';
    }
  }

  const handleWithdraw = () => {
    if (bankAccountStatus === 'succeed') {
      navigate('/withdraw/bank', { state: { accounts } });
    } else if (
      bankAccountStatus === 'failed' ||
      bankAccountStatus === 'verifing'
    ) {
      navigate('/bankStatus', {
        state: { from: '/asset' },
      });
    } else {
      navigate('/bankAccount', { state: { from: '/asset' } });
    }
  };

  return (
    <EvePaper>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography fontSize={15} color="text.primary">
          總資產估值 (TWD)
        </Typography>
        <IconButton
          onClick={() => setShowAsset(!showAsset)}
          aria-label="show total asset">
          {showAsset ? (
            <SvgIcon src={'/icons/eye_open.svg'} width={20} height={20} />
          ) : (
            <SvgIcon src={'/icons/eye_off.svg'} width={20} height={20} />
          )}
        </IconButton>
      </Stack>
      <Stack direction="row">
        {showAsset ? (
          <Typography
            fontWeight={590}
            fontSize={36}
            letterSpacing={-0.4}
            lineHeight="38px">
            <PriceNumberFormat value={estimatedTotalAssets} prefix="$" />
          </Typography>
        ) : (
          <Typography
            fontWeight={480}
            fontSize={36}
            letterSpacing={-0.4}
            lineHeight="38px">
            ＊＊＊＊＊＊
          </Typography>
        )}
      </Stack>
      <Stack direction="row">
        <Typography fontSize={15} color="#FFA48E">
          {profitLossSymbol()}$
          {profitLossTotal?.toString()?.split('.')[0] >= 0
            ? profitLossTotal?.toString()?.split('.')[0]
            : profitLossTotal?.toString()?.split('.')[0]?.slice(1)}
          {profitLossTotal?.toString()?.split('.')[1] && '.'}
        </Typography>
        <Typography fontSize={15} color="text.gray">
          {profitLossTotal?.toString()?.split('.')[1]?.slice(0, 2)}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} paddingTop={2}>
        {eveFunctions.map((eveFunction) => (
          <Stack
            key={eveFunction.iconSrc}
            alignItems={'center'}
            width="100%"
            spacing={0.5}
            onClick={() => {
              if (eveFunction.name === '提領') {
                handleWithdraw();
              } else {
                navigate(eveFunction.path, { state: { originFrom: '/asset' } });
              }
            }}
            sx={{ cursor: 'pointer' }}>
            <SvgIcon src={eveFunction.iconSrc} width={42} height={45} />
            <Typography noWrap>{eveFunction.name}</Typography>
          </Stack>
        ))}
      </Stack>
    </EvePaper>
  );
}

function QuotaProgressBar({ limitQuota, navigate }) {
  const apiResponse = [
    {
      name: '每日提領',
      usageAmount: limitQuota?.dailyWithdrawQuota?.amount,
      quota: limitQuota?.dailyWithdrawQuota?.maxLimitAmount,
      percentage: Math.round(
        (limitQuota?.dailyWithdrawQuota?.amount /
          limitQuota?.dailyWithdrawQuota?.maxLimitAmount) *
          100,
      ),
    },
    {
      name: '每月提領',
      usageAmount: limitQuota?.monthlyWithdrawQuota?.amount,
      quota: limitQuota?.monthlyWithdrawQuota?.maxLimitAmount,
      percentage: Math.round(
        (limitQuota?.monthlyWithdrawQuota?.amount /
          limitQuota?.monthlyWithdrawQuota?.maxLimitAmount) *
          100,
      ),
    },
    {
      name: '每日儲值',
      usageAmount: limitQuota?.dailyDepositQuota?.amount,
      quota: limitQuota?.dailyDepositQuota?.maxLimitAmount,
      percentage: Math.round(
        (limitQuota?.dailyDepositQuota?.amount /
          limitQuota?.dailyDepositQuota?.maxLimitAmount) *
          100,
      ),
    },
    {
      name: '每月儲值',
      usageAmount: limitQuota?.monthlyDepositQuota?.amount,
      quota: limitQuota?.monthlyDepositQuota?.maxLimitAmount,
      percentage: Math.round(
        (limitQuota?.monthlyDepositQuota?.amount /
          limitQuota?.monthlyDepositQuota?.maxLimitAmount) *
          100,
      ),
    },
  ];

  const colors = ['#4429E8', '#503FEC', '#624BEE', '#794CD9', '#9A4EC4'];

  return (
    <Stack spacing={1.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontSize={18} fontWeight={590} letterSpacing={-0.4}>
          帳戶額度
        </Typography>
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          color="#FFD66E">
          <Typography
            fontSize={14}
            fontWeight={400}
            letterSpacing={-0.4}
            onClick={() => navigate('/bankLimited')}>
            銀行轉出相關限額
          </Typography>
          <ArrowForwardIosSharpIcon sx={{ fontSize: 16 }} />
        </Stack>
      </Stack>

      <Stack spacing={1.5}>
        {apiResponse.map((item, index) => (
          <LinearProgressBar
            key={item.name}
            label={item.name}
            value={item.percentage}
            usageAmount={item.usageAmount}
            quota={item.quota}
            barcolor={colors[index]}
            warningColor="#F96C47"
          />
        ))}
      </Stack>
    </Stack>
  );
}

function AccountInfo({ accounts, realtimePrices }) {
  return (
    <Stack spacing={4}>
      <Stack spacing={1.5}>
        <TitleLabel fontSize="18px" fontWeight="medium">
          餘額帳戶 (TWD)
        </TitleLabel>
        <Stack spacing={1.3}>
          {[accounts.find((account) => account?.currency?.type === 'fiat')].map(
            (account) => {
              return (
                <Stack
                  key={account.currencyId}
                  spacing={1.3}
                  sx={{ cursor: 'pointer' }}>
                  <CurrencyInfo accountInfo={account} accounts={accounts} />
                </Stack>
              );
            },
          )}
        </Stack>
      </Stack>

      <Stack spacing={1.5}>
        <TitleLabel fontSize="18px" fontWeight="medium">
          虛擬貨幣帳戶 (TWD)
        </TitleLabel>
        <Stack spacing={1.3}>
          {accounts
            .filter((account) => account?.currency?.type === 'crypto')
            .map((account) => {
              return (
                <Stack key={account.currencyId} spacing={1.3}>
                  <CurrencyInfo accountInfo={account} accounts={accounts} />
                </Stack>
              );
            })}
        </Stack>
      </Stack>
    </Stack>
  );
}

function CurrencyInfo({ accountInfo, accounts }) {
  const roi = accountInfo?.roi;
  const navigate = useNavigate();

  return (
    <Stack
      onClick={() =>
        navigate(`/accountAsset/${accountInfo.currencyId}`, {
          state: {
            currencyId: accountInfo?.currencyId,
            chineseName: accountInfo?.currency?.chineseName,
            twdValue: accountInfo?.twdValue,
            availableBalance: accountInfo?.availableBalance,
            accounts,
          },
        })
      }
      bgcolor="background.secondary"
      padding={2}
      borderRadius={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ cursor: 'pointer' }}>
      <Stack direction="row" alignItems="center" spacing={1.2}>
        <SvgIcon
          src={`/icons/${accountInfo?.currencyId}.svg`}
          width={34}
          height={34}
          alt={`${accountInfo?.currency?.chineseName} icon`}
        />
        <Box>
          <Typography fontSize={17} fontWeight={510} letterSpacing={-0.4}>
            {accountInfo?.currency?.chineseName}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Typography
              {...(accountInfo?.currencyId !== 'TWD' && { width: 40 })}
              fontSize={12}
              fontWeight={400}
              letterSpacing={-0.4}
              sx={{ opacity: 0.6, marginRight: '10px' }}>
              {accountInfo?.currency?.name}
            </Typography>
            {accountInfo?.currencyId !== 'TWD' && (
              <Typography
                fontSize={12}
                color={roi === 0 ? '#FFFFFF' : roi < 0 ? '#22DDB9' : '#FBA28B'}
                fontWeight={400}
                letterSpacing={-0.4}>
                {!!roi && (roi < 0 ? '-' : '+')}
                {roi}%
              </Typography>
            )}
          </Stack>
        </Box>
      </Stack>

      <Stack alignItems="end">
        <Typography fontSize={15} fontWeight={400} letterSpacing={-0.4}>
          <AssetPriceNumberFormat
            value={
              accountInfo.currencyId === 'TWD'
                ? accountInfo?.availableBalance
                : accountInfo?.twdValue
            }
            prefix="$"
            suffix=""
          />
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={400}
          letterSpacing={-0.4}
          sx={{ opacity: 0.6 }}>
          {accountInfo?.currencyId === 'TWD'
            ? accountInfo?.balance
            : Number(Decimal(accountInfo?.balance)).toFixed(8)}
        </Typography>
      </Stack>
    </Stack>
  );
}

// ========== 以下為舊的儲值程式碼，暫時留著參考用 ==========
//=======================================================
// <Dialog
// showDialog={showDialog}
// setShowDialog={setShowDialog}
// title="台幣儲值"
// message="請選擇想要的台幣儲值方式"
// actionLabel="台灣大哥大門市"
// secondaryActionLabel="銀行轉帳"
// actionHandler={() => {
//   if (user?.bankAccount?.status === 'succeed') {
//     navigate('/deposit/store');
//   } else if (
//     user?.bankAccount?.status === 'failed' ||
//     user?.bankAccount?.status === 'verifing'
//   ) {
//     navigate('/bankStatus', {
//       state: { from: '/asset' },
//     });
//   } else {
//     navigate('/bankAccount', { state: { from: '/asset' } });
//   }
// }}
// secondaryActionHandler={() => {
//   if (user?.bankAccount?.status === 'succeed') {
//     navigate('/deposit/bank');
//   } else if (
//     user?.bankAccount?.status === 'failed' ||
//     user?.bankAccount?.status === 'verifing'
//   ) {
//     navigate('/bankStatus', {
//       state: { from: '/asset' },
//     });
//   } else {
//     navigate('/bankAccount', { state: { from: '/asset' } });
//   }
// }}
// />
