import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { TitleLabel } from './Label';
import ButtonContainer from './ButtonContainer';

const Modal = ({ title, children, enabled, buttons, buttonDirection }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={enabled}>
      <Box
        sx={{
          borderRadius: '10px',
          backgroundColor: '#231a54',
          display: 'flex',
          width: '345px',
          flexDirection: 'column',
          overflow: 'hidden',
          color: (theme) => theme.palette.common.white,
          whiteSpace: 'normal',
          justifyContent: 'flex-start',
          padding: '16px',
          font: '500 16px PingFang TC, sans-serif',
        }}>
        <Box
          sx={{
            borderRadius: '0px 0px 0px 0px',
            width: '100%',
            fontSize: '18px',
            textAlign: 'center',
          }}>
          <TitleLabel>{title}</TitleLabel>
        </Box>
        <Box
          sx={{
            marginY: '16px',
            display: 'flex',
            minHeight: '50px',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          {children}
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            letterSpacing: '-0.4px',
            justifyContent: 'flex-start',
          }}>
          {buttons && (
            <ButtonContainer buttons={buttons} direction={buttonDirection} />
          )}
        </Box>
      </Box>
    </Backdrop>
  );
};

export default Modal;
