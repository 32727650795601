import PropTypes from 'prop-types';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme, barcolor }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.background.secondary,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: barcolor || '#1a90ff',
    // transitionDuration: '600ms',
  },
}));

export default function LinearProgressBar({
  label,
  value,
  usageAmount,
  quota,
  barcolor,
  warningColor,
}) {
  const formatNumber = (num) => {
    return num?.toLocaleString() || '0';
  };

  // 判斷是否達到配額上限
  const isReachQuota = usageAmount === quota;
  const currentBarColor = isReachQuota ? warningColor : barcolor;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ paddingRight: 0.75 }}>
        <Typography
          variant="body2"
          width={48}
          fontWeight={400}
          fontSize={12}
          noWrap>
          {label}
        </Typography>
      </Box>

      <Box sx={{ width: '100%', position: 'relative' }}>
        <BorderLinearProgress
          variant="determinate"
          value={value}
          barcolor={currentBarColor}
        />
        <Typography
          variant="body2"
          fontSize={12}
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            textShadow: '0px 0px 2px rgba(0,0,0,0.6)', // 添加文字陰影以增加可讀性
          }}>
          {formatNumber(usageAmount)}/{formatNumber(quota)}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressBar.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
