import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { navigationBarHeight } from '../../components/Layout';
import Icon from '../../components/Icon';
import { UserContext } from '../../UserContext';
import Typography from '@mui/material/Typography';
import CustomButton from '../../components/CustomButton';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

export default function FreezeRejectPage() {
  const { setTabIndex, user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleBack = () => {
    setTabIndex(0);
    if (user.freezeStatus === 'c') {
      navigate('/landing');
    } else {
      navigate('/');
    }
  };

  const kycDoneDesc = () => {
    return (
      <Stack
        sx={{ mt: '80px' }}
        spacing={2}
        direction={'column'}
        alignItems="center">
        <Box>
          <Icon src="icons/account_freeze.svg" width={175} height={175} />
        </Box>
        <Box>
          <Typography fontSize="26px">帳戶部分功能停用</Typography>
        </Box>
        <Box width={'100%'} marginTop="10px" marginBottom="37px">
          <Typography
            sx={{
              fontSize: '16px',
              textAlign: 'left',
              letterSpacing: '-0.4px',
            }}>
            您的帳戶目前不支援此功能，如有疑問請聯繫客服。
          </Typography>
        </Box>
      </Stack>
    );
  };

  const bottomPanel = () => {
    return (
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: '80px',
          padding: '16px',
          zIndex: '1200',
          backgroundColor: 'transparent',
          width: '100%',
        }}>
        <Box display="flex" height="100%">
          <CustomButton
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => {
              handleBack();
            }}>
            返回
          </CustomButton>
        </Box>
      </Paper>
    );
  };
  return (
    <Box sx={sxContainer}>
      <Box padding="30px">{kycDoneDesc()}</Box>
      {bottomPanel() /* 進行身分驗證按鈕 */}
    </Box>
  );
}
