import { PriceNumberFormat } from '../../../utils/CoinNumberFormat';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { SvgIcon } from '../../Icon';

export default function Content({
  amount,
  status,
  records,
  txSize,
  txSide,
  productImage,
  type,
}) {
  return (
    <Box padding={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <Typography
            color="text.secondary"
            fontWeight={400}
            fontSize={18}
            letterSpacing={-0.4}
            textAlign="start">
            金額 (TWD)
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={40}
            letterSpacing={-0.4}
            textAlign="start">
            <PriceNumberFormat value={amount} prefix="$" suffix="" />
          </Typography>
        </Stack>
        <Box
          fontWeight={400}
          fontSize={18}
          sx={{ color: typeof status === 'object' ? status.color : 'inherit' }}>
          {typeof status === 'object' ? status.text : status}
        </Box>
      </Stack>

      {type === 'order' && (
        <Stack marginTop={4} marginBottom={4}>
          <Typography fontSize={16} color="text.secondary">
            買賣數量
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1.3}>
            <Typography fontSize={30} fontWeight={400}>
              {txSide === 'buy' ? '+' : '-'}
              {txSize}
            </Typography>
            <SvgIcon src={productImage} width={30} height={30} />
          </Stack>
          <Typography fontSize={14} fontWeight={400}>
            1 BTC ≈ 3,000,000 TWD
          </Typography>
        </Stack>
      )}

      <Stack spacing={3} marginTop={4}>
        {records?.map((row) => (
          <Stack
            key={row?.title}
            direction="row"
            justifyContent="space-between"
            alignItems="start">
            <Typography
              component="div"
              fontSize={16}
              color="text.secondary"
              width="120px">
              {row?.title}
            </Typography>
            <Typography component="div" fontSize={16} textAlign="right">
              {row?.content}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
