const privacy = {
  title: 'OP加密資產存摺 隱私權政策',
  description: [
    '「OP加密資產存摺」係由富昇數位股份有限公司(下稱本公司)所維運、提供之服務， 本公司十分重視用戶的隱私權，當您點選同意或使用本網站服務後，即表示您同意並遵守以下條款 ，為保障您的權益， 請您詳閱下列隱私權保護政策內容：',
  ],
  sections: [
    {
      title: '一、隱私權保護政策適用範圍',
      description: ['隱私權保護政策內容，包括本公司如何處理下列資料：'],
      sectionList: {
        items: [
          {
            item: '您使用本公司網站（下稱本網站）、註冊或加入本公司OP加密資產存摺服務(下稱本服務)會員、使用本服務、進行交易、於本網站上查詢/異動資訊、參加活動等，所蒐集到的個人資料及其他資訊。',
          },
          {
            item: '本公司合作伙伴(包括但不限於商業組織、政府機關等)、關係企業與本公司合作時分享的任何個人資料及其他資訊。',
          },
        ],
        orderBy: 'ordinalNumber',
      },
      additionInfo: '',
    },
    {
      title: '二、資料之蒐集',
      description: [
        '此資料的蒐集目的係為確認在本網站、本服務或合作伙伴取得資訊的使用者身份及提供各項服務之用，其範圍如下：',
      ],
      sectionList: {
        items: [
          {
            item: '用戶提供之資訊。 本公司在您使用本網站進行註冊的過程及啟用OP加密資產存摺帳戶的程序中，您需要提供個人資料並上傳身分證件與自拍照，包括但不限於您的姓名、手機號碼、電子郵件地址、出生日期、通訊地址、身分證號碼/居留證統一證號/護照號碼、職業、性別、國籍、財務與銀行帳戶資訊 。以及其他因客戶服務、強化交易安全，以及防制洗錢與打擊資恐等所需進行相關人別確認，並提供審查所需資料，包括但不限於如資金流向或交易往來證明等。',
          },
          {
            item: '自動蒐集資訊。您透過網路進入或使用本網站，本公司會使用某些技術，包含但不限於cookies與類似技術，蒐集您的電腦、手機、或其他通訊設備傳送給我們的資訊，該資訊包含但不限於（i）您的IP位置、設備資訊，例如識別碼、設備名稱及類型、作業系統、位置、網路資訊；（ii）有關您與本網站互動的方式及偏好的資訊，例如您的瀏覽器類型、往返本網站的方式、您點選的網路連結、您瀏覽的網頁、以及您進入與離開本網站的時間；及（iii）您的用戶名稱及密碼',
          },
        ],
        orderBy: 'alphabetically',
      },
      additionInfo: [
        '本公司會保留及處理您所提供的上述資料，並依據本隱私權政策所揭露之範圍進行利用。其他內容請見下列個資告知事項。',
      ],
    },
    {
      title: '三、個資告知事項',
      description: ['為確保您的權益，請詳閱以下個人資料保護法告知事項：'],
      sectionList: {
        items: [
          {
            item: '非公務機關名稱：富昇數位股份有限公司。',
          },
          {
            item: '蒐集之目的：036/存款與匯款、 040/行銷、063/非公務機關依法定義務所進行個人資料之蒐集處理及利用、069/契約、類似契約或其他法律關係事務、090/消費者、客戶管理與服務、094（財產管理）、104/帳務管理及債權交易業務、129/會計與相關服務、135/資(通)訊服務、136/資(通)訊與資料庫管理、137/資通安全與管理、148/網路購物及其他電子商務服務、152/廣告或商業行為管理、154/徵信、157/調查、統計與研究分析、181/其他經營合於營業登記項目或組織章程所定之業務、182/其他諮詢與顧問服務及其他依主管機關公告之特定目的。',
          },
          {
            item: '個人資料之類別： C001/辨識個人者、C002/辨識財務者、C003/政府資料中之辨識者、C011/個人描述、C031/住家及設施、C032/財產、C083/信用評等、C102/約定或契約。',
          },
          {
            item: '個人資料利用之期間、地區、對象及方式：',
            detail: {
              detailItems: [
                '(1) 期間：資料蒐集目的之存續期間、本公司因執行業務所需的保存期間、或依相關法令規範所訂之保存年限。',
                '(2) 地區：中華民國境內、境外（主管機關禁止者不在此限）。',
                '(3) 對象：本公司、受本公司委託或與本公司有合作或業務往來之本公司關係企業及合作廠商。',
                '(4) 方式：符合法令規定範圍之利用。',
              ],
              orderBy: 'ordinalNumber',
            },
          },
          {
            item: '您可以行使個人資料保護法第三條規定之請求查詢、製給複製本、補充或更正、停止蒐集、處理及利用、刪除等權利，其行使方式及收費方式依法令及本公司相關規定。本公司得依個人資料保護法第10條、第11條規定，執行業務所必須及法定保存期間等考量，決定是否接受申請。',
          },
          {
            item: '您可以自由選擇提供資料（但依法令規定者不在此限），若不提供將影響服務申辦或其完整性。',
          },
        ],
        orderBy: 'ordinalNumber',
      },
      additionInfo: '',
    },
    {
      title: '四、資料之保護',
      description: [
        '本公司會將您的個人資料完整儲存於我們的資料儲存系統中，並以嚴密的保護措施防止未經授權人員之接觸。本公司的人員均接受過完整之資訊保密教育，充分瞭解用戶資料之保密為基本責任，如有違反保密義務者，將受相關法律及公司內部規定之處分。 為了保護您個人資料之完整及安全，保存您個人資料之資料處理系統均已受妥善的維護，並符合相關主管機關嚴格之要求，以保障您的個人資料不會被不當取得或破壞。 如因業務需要有必要委託第三者提供服務時，本公司亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。',
      ],
      sectionList: '',
      additionInfo: '',
    },
    {
      title: '五、資料之使用',
      description: [
        '為了提供您更多服務或優惠，如需要求您將個人資料提供予本公司之優良商業夥伴或合作廠商時，我們會在活動時提供充分說明，您可以自由選擇是否接受這項特定服務或優惠。 本公司對於您個人資料之使用，僅在蒐集之特定目的及相關法令規定之範圍內為之。 除非經由您的同意或其他法令之特別規定，本網站絕不會將您的個人資料揭露於第三人或使用於蒐集目的以外之其他用途。',
        '司法單位/政府機關因公眾安全依法要求本公司提供特定個人資料時，本公司將依司法單位/政府機關合法正式的程序，以及對本公司所有使用者安全考量下做可能必要的配合。',
        '若您不希望接受行銷資訊，提供您表示選擇拒絕接受行銷之方式(電話撥打客服專線(02)6635-1968)，即設定停止行銷利用。',
      ],
      sectionList: '',
      additionInfo: '',
    },
    {
      title: '六、連結網頁之使用',
      description: [
        '本網站的網頁可能提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但本網站並不保護您於該連結網站中的隱私權。',
      ],
      sectionList: '',
      additionInfo: '',
    },
    {
      title: '七、個人資料之修正',
      description: [
        '當您發現個人資料須修改時，經過身分確認無誤後，您可透過本網站提供之服務管道進行修改、更正以確保自身權益。修正作業流程如下。',
      ],
      sectionList: {
        items: [
          {
            item: '確認身分。',
          },
          {
            item: '更正個人資料，本公司於三十日內完成審查與回覆；若需延長則不得逾三十日，並以書面通知原因。',
          },
          {
            item: '拒絕請求時，本公司確認原因後通知說明。',
          },
          {
            item: '設置客服專線，提供您處理個人資料相關服務、申訴或諮詢。',
          },
        ],
        orderBy: 'ordinalNumber',
      },
      additionInfo: '',
    },
    {
      title: '八、Cookie之使用',
      description: [
        '為提供您更完善的個人化服務，本網站可能會使用Cookie以紀錄及分析使用者行為，此系統能夠辨識使用者，例如依您偏好的特定種類資料執行不同動作。',
      ],
      sectionList: '',
      additionInfo: '',
    },
    {
      title: '九、隱私權保護政策之修正',
      description: [
        '本公司隱私權保護政策將因應需求隨時進行修正，以落實保障使用者隱私權之立意。修正後的條款將刊登於本網站上。',
      ],
      sectionList: '',
      additionInfo: '',
    },
  ],
};

export default privacy;
