import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { UserContext } from '../../UserContext';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigationBar from '../../components/NavigationBar';
import Modal from '../../components/Modal';
import { useBillInfo } from '../../hooks/useBillInfo';
import Icon from '../../components/Icon';

// const iconSize = '28px';
// const userStatus = {
//   level: {
//     level0: 'LEVEL 0',
//     level1: 'LEVEL 1',
//     level2: 'LEVEL 2',
//   },
//   bankAccount: {
//     undefined: '未綁定',
//     unverified: '未綁定',
//     verifing: '綁定審核中',
//     succeed: '綁定完成',
//     failed: '綁定失敗',
//   },
// };

// const userStatus = {
//   kycStatus: {
//     unverified: '未綁定',
//     verifing: '驗證中',
//     pendingReview: '待人工審核',
//     submmitted: '待營管主管審核',
//     succeed: '驗證完成',
//   },
//   emailVerifyStatus: {
//     notSet: '未綁定',
//     unverified: '未驗證',
//     verifing: '驗證中',
//     succeed: '驗證完成',
//   },
//   phoneVerifyStatus: {
//     intraUnverified: '網內未驗證',
//     intraVerifing: '網內驗證中',
//     intraVerified: '網內驗證完畢',
//     interUnverified: '網外未驗證',
//     interVerifing: '網外驗證中',
//     interVerified: '網外驗證完畢',
//   },
// };

const emailVerifyStatus = {
  notSet: '尚未認證',
  unverified: '待驗證',
  verifing: '待驗證',
  succeed: '驗證成功',
};

// 還面有 null，需要給值是'尚未綁定'
const bankAccountStatus = {
  unverified: '尚未綁定',
  verifing: '驗證中',
  succeed: '綁定完成',
  failed: '綁定失敗 ',
};

const invoiceStatus = {
  A: '會員載具',
  B: '手機條碼載具',
  C: '自然人憑證條碼載具',
  P: '紙本',
};

// const kycStatus = {
//   unverified: '未綁定',
//   verifing: '驗證中',
//   pendingReview: '待人工審核',
//   submmitted: '待營管主管審核',
//   succeed: '驗證完成',
// };

export default function MemberPage() {
  const { user, setUser, setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const { billInfo } = useBillInfo(user, setLoading);

  const personalInfo = [
    { label: '姓名', value: user?.chineseName, isLink: false },
    { label: '身分證字號', value: user?.identityNumber, isLink: false },
    {
      label: '手機號碼',
      value: user?.phoneNumber,
      // 等電子郵件功能開發完再打開
      // isLink: true,
      // linkAction: handleChangePhoneNumber,
    },
    {
      label: '電子郵件',
      prefixIcon: user.emailVerifyStatus !== 'succeed' && (
        <Icon
          src="/icons/ic_alert_sm.svg"
          alt=""
          sx={{
            width: '18px',
            mr: '4px',
          }}
        />
      ),
      value: emailVerifyStatus[user.emailVerifyStatus],
      isLink: false,
      // 等電子郵件功能開發完再打開
      // isLink: true,
      // linkAction: () => navigate('/email'),
    },
  ];

  const bankAccountInfo = [
    {
      label: '銀行帳戶',
      value: user?.bankAccount?.status
        ? bankAccountStatus[user?.bankAccount?.status]
        : '尚未綁定',
      isLink: true,
      linkAction: handleMenuBankAccount,
    },
    {
      label: '電子發票',
      value: billInfo?.isDonee ? '捐贈 ' : invoiceStatus[billInfo?.carrierType],
      isLink: true,
      linkAction: handleChangeUserInvoice,
    },
    {
      label: '身分驗證',
      value: getIdentityStatus(user?.kycStatus, user?.amlStatus),
      isLink: false,
    },
  ];

  const productInfo = [
    {
      label: '幫助中心',
      value: '',
      isLink: true,
      linkAction: () => navigate('/faq'),
    },
    {
      label: '使用者條款',
      value: '',
      isLink: true,
      linkAction: () => navigate('/terms'),
    },
    {
      label: '隱私權政策',
      value: '',
      isLink: true,
      linkAction: () => navigate('/privacy'),
    },
    {
      label: '官方網站',
      value: '',
      isLink: true,
      linkAction: () => {},
      url: 'https://staging.opcryptoex.com??ext_browser=1',
    },
    {
      label: '登出',
      value: '',
      isLink: true,
      linkAction: () => setShowLogoutDialog(true),
    },
  ];

  function handleLogout() {
    setUser(null);
    navigate('/landing');
  }

  // function handleChangeAccountInfo() {
  //   navigate('/changeAccountInfo');
  // }

  function handleChangePhoneNumber() {
    navigate('/phoneNumber');
  }

  function handleMenuBankAccount() {
    if (
      !user?.bankAccount ||
      user?.bankAccount?.status === null ||
      user?.bankAccount?.status === 'unverified' ||
      user?.bankAccount?.status === 'undefined'
    ) {
      navigate('/bankAccount', { state: { from: '/member' } });
    } else {
      navigate('/bankStatus', { state: { from: '/member' } });
    }
  }

  function handleChangeUserInvoice() {
    navigate('/changeInvoice', {
      state: {
        navTitle: '發票載具設定',
      },
    });
  }
  const confirmModalButtons = [
    {
      variant: 'outlined',
      label: '繼續使用',
      onClick: () => setShowLogoutDialog(false),
    },
    {
      variant: 'contained',
      label: '離開',
      onClick: handleLogout,
    },
  ];

  // function handleCustomerService() {
  //   window.location.href = `mailto:${process.env.REACT_APP_CS_EMAIL}`;
  // }

  return (
    <>
      <NavigationBar
        title="帳戶設定"
        leftButtonHandler={() => navigate('/asset')}
      />

      <Stack padding="72px 16px" alignItems="center" sx={{ width: '100%' }}>
        <Stack spacing={3} sx={{ width: '100%', maxWidth: '800px' }}>
          <Stack direction="column" spacing={0.5}>
            <PanelTitle text="個人資料" />
            <PanelBlock>
              <InfoList data={personalInfo} />
            </PanelBlock>
          </Stack>

          <Stack direction="column" spacing={0.5}>
            <PanelTitle text="帳戶設定" />
            <PanelBlock>
              <InfoList data={bankAccountInfo} />
            </PanelBlock>
          </Stack>

          <Stack direction="column" spacing={0.5}>
            <PanelTitle text="關於富昇數位" />
            <PanelBlock>
              <InfoList data={productInfo} />
            </PanelBlock>
          </Stack>
        </Stack>
      </Stack>
      <Box display="flex" justifyContent="center" alignItems="center">
        v{user?.csappVersion}
      </Box>

      <Modal
        title="登出OP加密資產存摺"
        enabled={showLogoutDialog}
        buttons={confirmModalButtons}
        buttonDirection="row">
        <Typography
          fontWeight={400}
          fontSize={16}
          textAlign="center"
          color="text.secondary">
          請確認是否要離開OP加密資產存摺?
        </Typography>
      </Modal>
      {/* <MemberMenu menuItems={menuItems} /> */}
    </>
  );
}

function PanelTitle({ text }) {
  return (
    <Box marginLeft={2}>
      <Typography
        fontWeight={500}
        fontSize={12}
        letterSpacing={0.41}
        color="text.secondary">
        {text}
      </Typography>
    </Box>
  );
}

const PanelBlock = styled(Paper)(({ theme }) => ({
  width: '100%', // Changed from fixed 343px to 100%

  borderRadius: 10,
  borderWidth: 1,
  padding: '0px 15px',
  backgroundColor: theme.palette.background.secondary,
  textAlign: 'center',
}));

function InfoList({ data }) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.secondary' }}>
      {data.map((row) => {
        // TODO: 這個 hidden attribute 之後要拿掉，這裡只用來暫時隱藏還會開發完成的頁面
        return row?.hidden ? null : (
          <Link
            href={row?.url}
            target={'_blank'}
            sx={{
              color: '#fff',
              textDecoration: 'none',
              width: '100%',
              '&hover': {
                textDecoration: 'none',
              },
            }}>
            <ListItem
              onClick={row.linkAction}
              disableGutters
              key={row.label}
              secondaryAction={
                <Stack direction="row" spacing={0.3} alignItems="center">
                  {row.prefixIcon && row.prefixIcon}
                  <Typography>{row.value}</Typography>
                  {row.isLink ? (
                    <ChevronRightIcon sx={{ color: 'text.primary' }} />
                  ) : (
                    <Box width={4}></Box> // TODO: 為了對齊有 Icon 的 row，所以加了一個 empty space，之後再想想怎麼處理更好
                  )}
                </Stack>
              }>
              <ListItemText primary={row.label} />
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
}

// function MemberMenu({ menuItems }) {
//   return (
//     <>
//       {menuItems.map((item, index) => (
//         <Box key={index}>
//           <MemberCenterCell item={item} />
//         </Box>
//       ))}
//     </>
//   );
// }

function getIdentityStatus(kycStatus, amlStatus) {
  let identityStatus;
  if (kycStatus === 'succeed' && amlStatus === 'succeed') {
    identityStatus = '驗證成功';
  } else {
    identityStatus = '尚未驗證 ';
  }

  return identityStatus;
}
