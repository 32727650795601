import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import formatUnixTimestamp from '../../utils/formatUnixTimestamp';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { UserContext } from '../../UserContext';
import Content from '../../components/trade/detailPage/Content';
import { CopyButton } from '../../components/trade/detailPage/CopyButton';
import Hero from '../../components/trade/detailPage/Hero';
import { useSnackbar } from '../../hooks/useSnackbar';

export default function DepositDonePage() {
  const { state } = useLocation();
  const { depositRecord, originFrom } = state;
  const createdTime = depositRecord?.createdTime;
  const amount = depositRecord?.amount;
  const txId = depositRecord?.txId;
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { showSnackbar, EveSnackbar } = useSnackbar('儲值編號已複製');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = async () => {
    navigate(-1, { state: { originFrom: originFrom } });
  };

  const handleClickAction = async () => {
    await navigator.clipboard.writeText(txId);
    showSnackbar((prev) => !prev);
  };

  const records = [
    {
      title: '交易時間',
      content: formatUnixTimestamp(createdTime),
    },
    {
      title: '儲值編號',
      content: (
        <Stack alignItems="end" spacing={0.7}>
          <Typography
            fontSize="15px"
            align="right"
            sx={{
              maxWidth: '200px',
              wordBreak: 'break-all',
            }}>
            {txId}
          </Typography>
          <CopyButton variant="outlined" onClick={handleClickAction}>
            複製
          </CopyButton>
        </Stack>
      ),
    },
    {
      title: '儲值方式',
      content: '**' + user?.bankAccount?.accountNumber.slice(-5),
    },
  ];

  const depositStatus = {
    done: '儲值成功',
    succeed: '提領成功',
    open: '處理中',
    processing: '處理中',
    rejected: '提領失敗',
    fbo_confirmed: '處理中',
    txt_done: '處理中',
    refunding: '處理中',
  };

  return (
    <>
      <Box padding="60px 16px">
        <NavigationBar leftButtonHandler={handleBack} title="明細" />
        <Hero
          imageSrc={'/icons/transfer.svg'}
          imageDest={'/icons/bc_ntd.svg'}
          content="儲值"
        />
        <Box marginTop={1}>
          <Content
            amount={amount}
            status={depositStatus[depositRecord.status]}
            records={records}
            type="deposit"
          />
        </Box>
      </Box>
      <EveSnackbar />
    </>
  );
}
