import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { OrderAPI } from '../../apis/OrderAPI';
import { UserContext } from '../../UserContext';
import Hero from '../../components/trade/detailPage/Hero';
import Content from '../../components/trade/detailPage/Content';
import Stack from '@mui/material/Stack';
import { CopyButton } from '../../components/trade/detailPage/CopyButton';
import formatUnixTimestamp from '../../utils/formatUnixTimestamp';
import { Typography } from '@mui/material';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import { invoiceType, invoiceDetail } from '../../utils/invoiceInfo';
import { useSnackbar } from '../../hooks/useSnackbar';

const productDict = {
  'BTC-TWD': { src: '/icons/BTC.svg', name: '比特幣' },
  'ETH-TWD': { src: '/icons/ETH.svg', name: '以太幣' },
};

const orderStatus = {
  success: { text: '交易成功', color: '#FFFFFF' },
  pending: { text: '處理中', color: '#FFDD86' },
  cancel: { text: '交易取消', color: '#969696' },
  rejected: { text: '交易失敗', color: '#FBA28B' },
};

export default function OrderInfoPage() {
  const { user } = useContext(UserContext);
  const { setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [order, setOrder] = useState(state.order);
  const { showSnackbar, EveSnackbar } = useSnackbar('交易序號已複製');

  const {
    order: { doneReason, id },
    nextNavigation,
  } = state;
  const createdTime = order?.createdTime; // 交易時間
  const txId = order?.id; // 交易ID
  const invoiceNo = order?.invoiceInfo?.invoiceNo; // 發票號碼
  const fund = order?.fund; // 本次交易金額
  const txSize = order?.size; // 交易虛幣數量
  const txSide = order?.side; // 買入或賣出虛幣 Ex. buy, sell
  const productId = order?.productId; // 產品ID EX. BTC-TWD
  const fee = order?.invoiceInfo?.price; // 手續費金額
  const carrierType = order?.invoiceInfo?.carrierType; // 手續費發票類型
  const doneeCode = order?.doneeCode; // 捐贈編號
  const carrierId = order?.carrierId; // 載具號碼
  const npoName = order?.npoName; // 捐贈單位
  const address = order?.invoiceInfo?.address; // 發票寄送地址

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      const order = await OrderAPI.getOrder(id);
      setOrder(order);
      setLoading(false);
    };
    if (doneReason !== 'cancel') {
      fetchOrder();
    }
  }, [doneReason, id, setLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    if (nextNavigation) {
      navigate(nextNavigation);
    } else {
      navigate(-1);
    }
  };

  const handleClickAction = async () => {
    await navigator.clipboard.writeText(txId);
    showSnackbar((prev) => !prev);
  };

  // 訂單詳情
  const orderRecords = [
    {
      title: '交易時間',
      content: formatUnixTimestamp(createdTime),
    },
    {
      title: '交易序號',
      content: (
        <Stack alignItems="end" spacing={0.7}>
          <Typography
            fontSize="15px"
            align="right"
            sx={{
              maxWidth: '200px',
              wordBreak: 'break-all',
            }}>
            {txId}
          </Typography>
          <CopyButton variant="outlined" onClick={handleClickAction}>
            複製
          </CopyButton>
        </Stack>
      ),
    },

    // TODO: 這裡需要補上銀行名，之後好像有規劃 user 會給
    txSide === 'buy' && {
      title: '交易方式',
      content: '**' + user?.bankAccount?.accountNumber.slice(-5),
    },
    {
      title: '手續費金額',
      content: <CoinNumberFormat value={fee} prefix="$" />,
    },
    {
      title: '手續費發票',
      content: invoiceType(carrierType, doneeCode),
    },
    {
      title: '發票資訊',
      content: invoiceDetail(carrierId, npoName, address),
    },
    {
      title: '發票號碼',
      content: invoiceNo,
    },
  ];

  // 處理訂單狀態
  const getOrderStatus = (order) => {
    let state = 'pending';
    if (order.status === 'done') {
      if (order.doneReason === 'succeed') {
        state = 'success';
      } else if (order.doneReason === 'rejected') {
        state = 'rejected';
      } else if (order.doneReason === 'cancel') {
        state = 'cancel';
      }
    }
    return state;
  };

  return (
    <>
      <Box padding="60px 16px">
        <NavigationBar leftButtonHandler={handleBack} title="訂單詳情" />
        <Hero
          imageSrc={
            txSide === 'buy' ? '/icons/bc_ntd.svg' : productDict[productId].src
          }
          imageDest={
            txSide === 'buy' ? productDict[productId].src : '/icons/bc_ntd.svg'
          }
          content={`${txSide === 'buy' ? '買入' : '賣出'} - ${
            productDict[productId].name
          }`}
        />
        <Box marginTop={1}>
          <Content
            amount={fund}
            status={orderStatus[getOrderStatus(order)]}
            records={orderRecords}
            txSize={txSize}
            txSide={txSide}
            productImage={productDict[productId].src}
            type="order"
          />
        </Box>
      </Box>
      <EveSnackbar />
    </>
  );
}
