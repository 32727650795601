import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import moment from 'moment/moment';
// import TradeDetailPanel from '../../components/asset/TradeDetailPanel';
// import StyledButton from '../../components/StyledButton';
//import CopyToClipboardButton from '../../components/CopyToClipboardButton';
import { navigationBarHeight } from '../../components/Layout';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { SubTitleLabel } from '../../components/Label';
import ButtonContainer from '../../components/ButtonContainer';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
  pl: '16px',
  pr: '16px',
};

const withdrawRecordStatus = {
  open: '處理中',
  fbo_confirmed: '處理中',
  txt_done: '處理中',
  refunding: '處理中',
  processing: '處理中',
  succeed: '提領成功',
  rejected: '提領失敗',
};

const formatUnixTimestamp = (timestamp) => {
  if (timestamp) {
    return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
  }
  return moment().format('YYYY-MM-DD HH:mm:ss');
};

// const InvoiceButton = ({ records }) => {
//   return (
//     <StyledButton
//       variant="contained"
//       fullWidth
//       disableElevation
//       sx={{ height: '48px', fontSize: '15px' }}
//       component={Link}
//       to="/invoiceInfo"
//       state={{ order: records }}>
//       發票
//     </StyledButton>
//   );
// };

export default function BankWithdrawDonePage() {
  const { state, pathname } = useLocation();
  const tradeType = pathname.split('/')[1];
  const { title, bankRecord, back, fee, bankInfo, message } = state;
  // const hasInvoiceNo = bankRecord?.invoiceInfo?.invoiceNo;
  // const invoicePrice = bankRecord?.invoiceInfo?.price;
  const createdTime = bankRecord?.createdTime;
  const txId = bankRecord?.txId;
  const amount = bankRecord?.amount;
  const status = bankRecord?.status;
  const [openCopyMessage, setOpenCopyMessage] = useState(false);
  const navigate = useNavigate();

  //let withdrawFee = fee ? fee : invoicePrice;

  // const handleClickCopy = () => {
  //   navigator.clipboard.writeText(txId);
  //   setOpenCopyMessage((prev) => !prev);
  // };

  // const abbrOrderId = (id) => {
  //   return id.slice(0, 10) + '...';
  // };

  // const withdrawBankRecords = [
  //   {
  //     title: '提領紀錄號碼',
  //     content: (
  //       <Box display="flex" alignItems="center">
  //         <TitleLabel fontSize="15px">{abbrOrderId(txId)}</TitleLabel>
  //         <CopyToClipboardButton
  //           //openCopyMessage={openCopyMessage}
  //           onClick={handleClickCopy}
  //           message="提領紀錄號碼已複製"
  //         />
  //       </Box>
  //     ),
  //   },
  //   {
  //     title: '日期',
  //     content: formatUnixTimestamp(createdTime),
  //   },
  //   {
  //     title: '提領方式',
  //     content: '銀行轉帳',
  //     fontWeight: 'bold',
  //   },
  //   {
  //     title: '提領金額',
  //     content: (
  //       <>
  //         <CoinNumberFormat value={amount} /> TWD
  //       </>
  //     ),
  //   },
  //   { title: '手續費', content: `${withdrawFee} TWD` },
  //   {
  //     title: '實際入帳金額',
  //     content: (
  //       <>
  //         <CoinNumberFormat value={amount - withdrawFee} /> TWD
  //       </>
  //     ),
  //     fontWeight: 'bold',
  //   },
  //   {
  //     title: '狀態',
  //     content: withdrawRecordStatus[status],
  //   },
  // ];

  const TitleLabel = styled(Typography)(({ theme }) => ({
    color: '#a7a3bb',
    fontSize: '14px',
    fontWeight: '400',
  }));

  const ValueLabel = styled(Typography)(({ theme }) => ({
    color: 'white',
    fontSize: '20px',
    fontWeight: '400',
  }));

  function TransactionDetails() {
    return (
      <>
        <Stack flexDirection="column" gap="16px">
          <Stack>
            <TitleLabel>實際入帳金額 (TWD)</TitleLabel>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 32,
              }}>
              $<CoinNumberFormat value={amount - fee} />
            </Typography>
          </Stack>
          <Stack>
            <TitleLabel>提領金額</TitleLabel>
            <ValueLabel>
              <CoinNumberFormat value={amount} prefix="$" />{' '}
            </ValueLabel>
          </Stack>
          <Stack>
            <TitleLabel>銀行處理手續費</TitleLabel>
            <ValueLabel>${fee}</ValueLabel>
          </Stack>
          <Stack>
            <TitleLabel>存入帳號</TitleLabel>
            <ValueLabel>{bankInfo}</ValueLabel>
          </Stack>
          <Stack>
            <TitleLabel>交易序號</TitleLabel>
            <ValueLabel>{txId}</ValueLabel>
          </Stack>
        </Stack>
        <Divider
          sx={{
            pt: '40px',
            pb: '16px',
            borderColor: 'rgba(255, 255, 255, 0.5)',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            width: '100%',
          }}
        />
        <BankDepositCautions />
        <BottomButton error={false} />
      </>
    );
  }

  function BankDepositCautions() {
    return (
      <Box marginTop="20px">
        <SubTitleLabel>
          ※ 提領依照銀行營業作業時間約 3 個工作天。
          <br />
          ※ 提領申請後，無法再做更改或取消。
          <br />※ 提領申請銀行端將收手續費 {fee} 元，提領失敗仍須支付手續費。
        </SubTitleLabel>
      </Box>
    );
  }

  function HeadingTitle() {
    return (
      <Box
        sx={{
          pt: '30px',
          pb: '50px',
        }}>
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 30,
            }}>
            {title}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 14,
            }}>
            {formatUnixTimestamp(createdTime)}
          </Typography>
        </Stack>
      </Box>
    );
  }

  function TransactionError() {
    return (
      <>
        <Box marginTop="68px">
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 16,
            }}>
            {message}
          </Typography>
        </Box>
        <BottomButton error={true} />
      </>
    );
  }

  const BottomButton = (error) => {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '8px',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            letterSpacing: '-0.4px',
            justifyContent: 'flex-start',
            padding: '8px 16px',
            gap: '10px',
            backgroundColor: '#231a54',
          }}>
          {error ? (
            <ButtonContainer
              direction="column"
              buttons={[
                {
                  label: '關閉',
                  variant: 'contained',
                  onClick: () => {
                    navigate('/');
                  },
                },
              ]}
            />
          ) : (
            <ButtonContainer
              direction="row"
              buttons={[
                {
                  label: '交易紀錄',
                  variant: 'outlined',
                  onClick: () => {
                    navigate('/assetHistory/withdraw', {
                      state: {
                        recordType: 2,
                      },
                    });
                  },
                },
                {
                  label: '完成',
                  variant: 'contained',
                  onClick: () => {
                    navigate('/');
                  },
                },
              ]}
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={sxContainer}>
      <Box
        component="img"
        src={
          title === '提領申請已送出'
            ? '/images/confirmImage.svg'
            : '/icons/result_error.svg'
        }
        alt="Confirmation Image"
        sx={{
          position: 'fixed',
          right: -10,
          top: `${navigationBarHeight}px`,
          width: '200px',
          height: '150px',
          zIndex: 1,
        }}
      />
      {/* <TradeDetailPanel
        title={title}
        records={withdrawBankRecords}
        tradeType={tradeType}
        originFrom={state?.originFrom ? state?.originFrom : ''}
      />
      {hasInvoiceNo && <InvoiceButton records={bankRecord} />}
      {back && <BottomButton />} */}
      <HeadingTitle />
      {title === '提領申請已送出' ? (
        <>
          <TransactionDetails />
        </>
      ) : (
        <TransactionError />
      )}
    </Box>
  );
}

// const InvoiceButton = ({ records }) => {
//   return (
//     <StyledButton
//       variant="contained"
//       fullWidth
//       disableElevation
//       sx={{ height: '48px', fontSize: '15px' }}
//       component={Link}
//       to="/invoiceInfo"
//       state={{ order: records }}>
//       發票
//     </StyledButton>
//   );
// };

// const BottomButton = () => {
//   return (
//     <BottomPanel>
//       <StyledButton
//         variant="contained"
//         fullWidth
//         disableElevation
//         component={Link}
//         to="/assetHistory/withdraw"
//         state={{ recordType: 2 }}>
//         查看提領紀錄
//       </StyledButton>
//     </BottomPanel>
//   );
// };
