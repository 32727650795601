import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { Box, Typography } from '@mui/material';
import { UserAPI } from '../../apis/UserAPI';
import { notifyOpenBrower } from '../../utils/WebViewCallback';
import TextField from '@mui/material/TextField';
import CustomDropDown from '../../components/CustomDropDown';
import DrawerWrapper from '../../components/DrawerWrapper';
import CheckIcon from '@mui/icons-material/Check';
import BottomPanel from '../../components/BottomPanel';
import CustomButton from '../../components/CustomButton';
import validator from './validator';
import { styled } from '@mui/material/styles';
import Modal from '../../components/Modal';
import useInvoice from '../../hooks/useInvoice';
import { UserContext } from '../../UserContext';

export default function InvoiceSettingPage() {
  const { user, setLoading } = useContext(UserContext);
  const [billInfo, setBillInfo] = useState(null);
  const [drawerHeight, setDrawerHeight] = useState('50vh');
  const [invoiceTypeCurrent, setInvoiceTypeCurrent] = useState();
  const [generalDialogOpen, setGeneralDialogOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const navigate = useNavigate();

  const {
    getCarrierType,
    getDonees,
    getDonee,
    getDoneeCode,
    cities,
    getDistrictCode,
    getDistricts,
  } = useInvoice();
  const [listContent, setListContent] = useState();
  const [dialogTitle, setDialogTitle] = useState('選擇發票類型');

  const [errors] = useState({});
  const phoneBarcodeRef = useRef(null);
  const citizenDigitalCertificateRef = useRef(null);
  const doneeCodeRef = useRef(null);
  const doneeRef = useRef(null);
  const cityRef = useRef(null);
  const districtRef = useRef(null);
  const zipCodeRef = useRef(null);
  const addressRef = useRef(null);

  const invoiceTypes = [
    '會員載具',
    '手機條碼載具',
    '自然人憑證條碼',
    '個人紙本發票',
    '捐贈發票',
  ];

  function getCurrentType(code, isDonee) {
    if (isDonee) {
      return '捐贈發票';
    } else {
      return getCarrierType(code);
    }
  }

  useEffect(() => {
    async function fetchBillInfoData() {
      const userData = await UserAPI.getBillInfo();
      setBillInfo(userData);
      setInvoiceTypeCurrent(
        getCurrentType(userData?.carrierType, userData?.isDonee),
      );
      phoneBarcodeRef.current = userData?.phoneBarcode;
      citizenDigitalCertificateRef.current =
        userData?.citizenDigitalCertificate;
      doneeCodeRef.current = userData?.doneeCode;
      doneeRef.current = userData?.doneeName;
      cityRef.current = userData?.billAddrCity;
      districtRef.current = userData?.billAddrDistrict;
      addressRef.current = userData?.billAddr;
      zipCodeRef.current = userData?.billAddrZipCode;
    }
    fetchBillInfoData();
  }, []);

  const sxTextField = {
    alignItems: 'center',
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'white',
    },
    '& .MuiInputBase-input.Mui-disabled::placeholder': {
      WebkitTextFillColor: (theme) => theme.palette.secondary.main,
    },
  };

  const txtInputProps = {
    disableUnderline: true,
    style: {
      fontSize: '16px',
    },
  };

  const InputBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#FFF',
    width: '100%',
    height: '56px',
    borderRadius: '8px',
  }));

  const sxContainer = {
    pt: `${navigationBarHeight}px`,
  };

  async function getEipUrl() {
    const response = await UserAPI.getEipUrl();
    notifyOpenBrower(response.redirectUrl);
  }

  const handleCarrierBindPage = () => {
    getEipUrl();
  };

  const handleBack = () => {
    navigate(-1);
  };

  async function tryUpdateBillInfo(payload, failTitle, failMessage) {
    setLoading(true);
    const userData = await UserAPI.updateBillInfo(payload);
    setLoading(false);

    if (userData !== null) {
      handleBack();
    } else {
      alertTitle.current = failTitle;
      alertMessage.current = failMessage;
      setShowAlert(true);
    }
  }

  async function handleUpdatePhoneBarcode() {
    let payload = {};
    let alert = null;

    switch (invoiceTypeCurrent) {
      case '會員載具':
        if (billInfo.carrierType !== 'A' || billInfo.isDonee !== false) {
          payload = {
            carrierType: 'A',
            isDonee: false,
          };
          await tryUpdateBillInfo(
            payload,
            '載具更新失敗',
            '請確認載具是否正確',
          );
        } else {
          handleBack();
        }
        break;

      case '手機條碼載具':
        if (
          phoneBarcodeRef.current !== billInfo.phoneBarcode ||
          billInfo.carrierType !== 'B' ||
          billInfo.isDonee !== false
        ) {
          if (!validator.isPhoneBarcode(phoneBarcodeRef.current, errors)) {
            alert = errors.barcode;
          }

          payload = {
            phone_barcode: phoneBarcodeRef.current,
            carrierType: 'B',
            isDonee: false,
          };

          if (alert) {
            alertTitle.current = '載具更新失敗';
            alertMessage.current = alert;
            setShowAlert(true);
          } else {
            await tryUpdateBillInfo(
              payload,
              '載具更新失敗',
              '請確認載具條碼是否正確',
            );
          }
        } else {
          handleBack();
        }
        break;

      case '自然人憑證條碼':
        if (
          citizenDigitalCertificateRef.current !==
            billInfo.citizenDigitalCertificate ||
          billInfo.carrierType !== 'C' ||
          billInfo.isDonee !== false
        ) {
          if (
            !validator.isCitizenDigital(
              citizenDigitalCertificateRef.current,
              errors,
            )
          ) {
            alert = errors.citizenDigital;
          }

          payload = {
            citizen_digital_certificate: citizenDigitalCertificateRef.current,
            carrierType: 'C',
            isDonee: false,
          };

          if (alert) {
            alertTitle.current = '載具更新失敗';
            alertMessage.current = alert;
            setShowAlert(true);
          } else {
            await tryUpdateBillInfo(
              payload,
              '自然人憑證條碼載具更新失敗',
              '請確認自然人憑證條碼載具是否正確',
            );
          }
        } else {
          handleBack();
        }
        break;
      case '個人紙本發票':
        let needUpdate = false;
        payload = {
          carrierType: 'P',
          isDonee: false,
        };

        if (billInfo.billAddrCity !== cityRef.current) {
          payload.bill_addr_city = cityRef.current;
          needUpdate = true;
        }

        if (billInfo.billAddrDistrict !== districtRef.current) {
          payload.bill_addr_zip_code = zipCodeRef.current;
          payload.bill_addr_district = districtRef.current;
          needUpdate = true;
        }

        if (billInfo.billAddr !== addressRef.current) {
          payload.bill_addr = addressRef.current;
          needUpdate = true;
        }

        if (!(cityRef.current && districtRef.current && addressRef.current)) {
          alert = '請輸入完整地址';
        } else if (
          !needUpdate &&
          billInfo.carrierType === 'P' &&
          billInfo.isDonee === false
        ) {
          handleBack();
          return;
        }

        if (alert) {
          alertTitle.current = '通訊地址更新失敗';
          alertMessage.current = alert;
          setShowAlert(true);
        } else {
          await tryUpdateBillInfo(
            payload,
            '通訊地址更新失敗',
            '請確認資料是否正確',
          );
        }
        break;
      case '捐贈發票':
        if (
          doneeCodeRef.current !== billInfo.doneeCode ||
          billInfo.isDonee === false
        ) {
          payload = {
            donee_code: doneeCodeRef.current,
            isDonee: true,
          };

          await tryUpdateBillInfo(
            payload,
            '愛心碼更新失敗',
            '請確認愛心碼是否正確',
          );
        } else {
          handleBack();
        }
        break;
      default:
        alertTitle.current = '系統錯誤';
        alertMessage.current = '請等待一段時間後重試';
        setShowAlert(true);
    }
  }

  const navigation = () => {
    return <NavigationBar title="手續費發票" leftButtonHandler={handleBack} />;
  };

  function InvoiceTypeSection() {
    const handleChooseInvoiceTypeClick = () => {
      setDrawerHeight('50vh');
      setListContent(
        <ListDrawItems
          setItem={setInvoiceTypeCurrent}
          listItems={invoiceTypes}
          currentItem={invoiceTypeCurrent}
        />,
      );
      setDialogTitle('選擇發票類型');
      setGeneralDialogOpen(true);
    };
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          paddingBottom: '8px',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '20px',
            justifyContent: 'space-between',
          }}>
          <Typography fontSize="14px">發票類型</Typography>
          {invoiceTypeCurrent === '會員載具' && (
            <Typography
              fontSize="14px"
              color="#FFD66E"
              onClick={() => handleCarrierBindPage()}>
              我要歸戶
            </Typography>
          )}
        </Box>
        <CustomDropDown onClick={() => handleChooseInvoiceTypeClick()}>
          <TextField
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            sx={sxTextField}
            disabled
            value={invoiceTypeCurrent}
          />
        </CustomDropDown>
        {invoiceTypeCurrent === '手機條碼載具' && <PhoneInvoice />}
        {invoiceTypeCurrent === '自然人憑證條碼' && <CitizenDigitalInvoice />}
        {invoiceTypeCurrent === '個人紙本發票' && <AddressInvoice />}
        {invoiceTypeCurrent === '捐贈發票' && <DoneeInvoice />}
      </Box>
    );
  }

  // General Dialog
  const ListDrawItems = ({ setItem, currentItem, listItems }) => {
    return (
      <Box marginTop="10px" padding="0px 17px">
        {listItems?.map((listItem, index) => (
          <Box
            key={index}
            onClick={() => {
              setItem(listItem);
              setGeneralDialogOpen(false);
            }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginLeft="8px"
              marginRight="8px"
              height="55px">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 16,
                  color: 'white',
                }}>
                {listItem}
              </Typography>
              {currentItem === listItem && <CheckIcon />}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };
  // 手機條碼載具
  function PhoneInvoice() {
    const handlePhoneBarcodeInput = (value) => {
      phoneBarcodeRef.current = value;
    };
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          paddingBottom: '8px',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '20px',
            justifyContent: 'space-between',
          }}>
          <Typography fontSize="14px">條碼</Typography>
        </Box>

        <InputBox
          alignItems="center"
          sx={{ padding: '16px 16px', backgroundColor: '#150F32' }}>
          <TextField
            type="text"
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, maxLength: 8, style: { textAlign: 'left' } }}
            placeholder="請輸入 / 開頭的英數字元"
            sx={sxTextField}
            onChange={(e) => {
              handlePhoneBarcodeInput(e.target.value);
            }}
            defaultValue={billInfo?.phoneBarcode}
          />
        </InputBox>
      </Box>
    );
  }
  // 自然人憑證條碼
  function CitizenDigitalInvoice() {
    const handleCitizenDigitalCertificateInput = (value) => {
      citizenDigitalCertificateRef.current = value;
    };
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          paddingBottom: '8px',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '20px',
            justifyContent: 'space-between',
          }}>
          <Typography fontSize="14px">條碼</Typography>
        </Box>

        <InputBox
          alignItems="center"
          sx={{ padding: '16px 16px', backgroundColor: '#150F32' }}>
          <TextField
            type="text"
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, maxLength: 16, style: { textAlign: 'left' } }}
            placeholder="請輸入卡片右下角大寫英數字元"
            sx={sxTextField}
            onChange={(e) => {
              handleCitizenDigitalCertificateInput(e.target.value);
            }}
            defaultValue={billInfo?.citizenDigitalCertificate}
          />
        </InputBox>
      </Box>
    );
  }
  // 個人紙本發票
  function AddressInvoice() {
    const handleAddrInput = (value) => {
      addressRef.current = value;
    };
    const handleCityClick = (item) => {
      if (cityRef.current !== item) {
        cityRef.current = item;
        districtRef.current = '';
        addressRef.current = null;
      }
    };
    const handleCityDropdownClick = () => {
      setDrawerHeight('90vh');
      setListContent(
        <ListDrawItems
          setItem={handleCityClick}
          listItems={cities}
          currentItem={cityRef.current}
        />,
      );
      setDialogTitle('選擇縣市');
      setGeneralDialogOpen(true);
    };

    const handleDistrictClick = (item) => {
      districtRef.current = item;
      zipCodeRef.current = getDistrictCode(cityRef.current, item);
    };

    const handleDistrictDropdownClick = () => {
      setDrawerHeight('90vh');
      setListContent(
        <ListDrawItems
          setItem={handleDistrictClick}
          listItems={getDistricts(cityRef.current)}
          currentItem={districtRef.current}
        />,
      );
      setDialogTitle('選擇區域');
      setGeneralDialogOpen(true);
    };

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          paddingBottom: '8px',
          marginTop: '10px',
        }}>
        <Typography fontSize="20px" marginBottom="-8px">
          紙本發票寄送資訊
        </Typography>
        <InputBox
          alignItems="center"
          sx={{ padding: '16px 16px', backgroundColor: '#150F3275' }}>
          <TextField
            type="text"
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            fullWidth
            disabled
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: (theme) => theme.palette.secondary.main,
              },
            }}
            value={user?.chineseName}
          />
        </InputBox>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '17px',
          }}>
          <CustomDropDown onClick={() => handleCityDropdownClick()}>
            <TextField
              variant="standard"
              InputProps={txtInputProps}
              hiddenLabel
              autoComplete="off"
              fullWidth
              inputProps={{ min: 0, style: { textAlign: 'left' } }}
              sx={sxTextField}
              disabled
              value={cityRef?.current}
              placeholder="請選擇縣市"
            />
          </CustomDropDown>
          <CustomDropDown onClick={() => handleDistrictDropdownClick()}>
            <TextField
              variant="standard"
              InputProps={txtInputProps}
              hiddenLabel
              autoComplete="off"
              fullWidth
              inputProps={{ min: 0, style: { textAlign: 'left' } }}
              sx={sxTextField}
              disabled
              value={districtRef?.current}
              placeholder="選擇鄉鎮市區"
            />
          </CustomDropDown>
        </Box>
        <InputBox
          alignItems="center"
          sx={{ padding: '16px 16px', backgroundColor: '#150F32' }}>
          <TextField
            type="text"
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            sx={sxTextField}
            onChange={(e) => {
              handleAddrInput(e.target.value);
            }}
            defaultValue={addressRef?.current}
          />
        </InputBox>
      </Box>
    );
  }
  // 捐贈發票
  function DoneeInvoice() {
    const handleDoneeClick = (item) => {
      if (doneeRef.current !== item) {
        doneeRef.current = item;
        let doneeCode = getDoneeCode(item);
        doneeCodeRef.current = doneeCode;
      }
    };

    const handleDoneeDropdownClick = () => {
      setDrawerHeight('90vh');
      setListContent(
        <ListDrawItems
          setItem={handleDoneeClick}
          listItems={getDonees()}
          currentItem={doneeRef.current}
        />,
      );
      setDialogTitle('選擇捐贈單位');
      setGeneralDialogOpen(true);
    };

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '20px',
            justifyContent: 'space-between',
          }}>
          <Typography fontSize="14px">捐贈單位</Typography>
        </Box>
        <CustomDropDown onClick={() => handleDoneeDropdownClick()}>
          <TextField
            variant="standard"
            InputProps={txtInputProps}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            sx={sxTextField}
            disabled
            value={doneeRef?.current}
          />
        </CustomDropDown>
      </>
    );
  }

  const BottomButton = () => {
    return (
      <BottomPanel>
        <CustomButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleUpdatePhoneBarcode}>
          儲存
        </CustomButton>
      </BottomPanel>
    );
  };

  const warningModalButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => {
        setShowAlert(false);
      },
    },
  ];

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box margin="16px">
          <Box marginBottom="16px">
            <Typography fontSize="28px" fontWeight={500}>
              發票類型
            </Typography>
          </Box>
          <InvoiceTypeSection />
          <Typography marginTop="10px" fontSize="14px">
            ※ 將以交易完成當下系統紀錄的發票開立類型為準。
          </Typography>
          <DrawerWrapper
            title={dialogTitle}
            anchor="bottom"
            drawerContent={listContent}
            open={generalDialogOpen}
            setOpen={setGeneralDialogOpen}
            height={drawerHeight}></DrawerWrapper>
        </Box>
        <BottomButton />
        {/* Warning modal */}
        <Modal
          title={alertTitle.current}
          enabled={showAlert}
          buttons={warningModalButtons}
          buttonDirection="column">
          <Box
            sx={{
              paddingTop: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography>{alertMessage.current}</Typography>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
