import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import { Box } from '@mui/material';
import formatUnixTimestamp from '../../utils/formatUnixTimestamp';
import { UserContext } from '../../UserContext';
import Content from '../../components/trade/detailPage/Content';
import { CopyButton } from '../../components/trade/detailPage/CopyButton';
import Hero from '../../components/trade/detailPage/Hero';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from '../../hooks/useSnackbar';

const withdrawRecordStatus = {
  open: { text: '處理中', color: '#FFC16D' },
  fbo_confirmed: { text: '處理中', color: '#FFC16D' },
  txt_done: { text: '處理中', color: '#FFC16D' },
  refunding: { text: '處理中', color: '#FFC16D' },
  processing: { text: '處理中', color: '#FFC16D' },
  succeed: { text: '交易成功', color: '#FFFFFF' },
  rejected: { text: '交易失敗', color: '#FBA28B' },
};

export default function WithdrawInfo() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { showSnackbar, EveSnackbar } = useSnackbar('提領編號已複製');
  const {
    bankRecord,
    //back
  } = state;
  const invoiceNo = bankRecord?.invoiceInfo?.invoiceNo;
  const invoicePrice = bankRecord?.invoiceInfo?.price;
  const createdTime = bankRecord?.createdTime;
  const txId = bankRecord?.txId;
  const amount = bankRecord?.amount;
  const status = bankRecord?.status;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = async () => {
    navigate(-1, {
      state: { originFrom: state?.originFrom ? state?.originFrom : '' },
    });
  };

  const handleClickAction = async () => {
    await navigator.clipboard.writeText(txId);
    showSnackbar((prev) => !prev);
  };

  const records = [
    {
      title: '提領金額',
      content: <CoinNumberFormat value={4000} prefix="$" />,
    },
    {
      title: '交易時間',
      content: formatUnixTimestamp(createdTime),
    },
    {
      title: '提領編號',
      content: (
        <Stack alignItems="right" spacing={0.7}>
          <Typography
            fontSize="15px"
            align="end"
            sx={{
              maxWidth: '200px',
              wordBreak: 'break-all',
            }}>
            {txId}
          </Typography>
          <CopyButton variant="outlined" onClick={handleClickAction}>
            複製
          </CopyButton>
        </Stack>
      ),
    },
    {
      title: '提領方式',
      content: '**' + user?.bankAccount?.accountNumber.slice(-5),
    },
    {
      title: '手續費金額',
      content: <CoinNumberFormat value={invoicePrice} prefix="$" />,
    },
    {
      title: '手續費發票',
      content: '手機條碼載具',
    },
    {
      title: '發票資訊',
      content: '/X87RWS4',
    },
    {
      title: '發票號碼',
      content: invoiceNo ?? '(發票開立中)',
    },
  ];

  return (
    <>
      <Box padding="60px 16px">
        <NavigationBar leftButtonHandler={handleBack} title="明細" />
        <Hero
          imageSrc="/icons/bc_ntd.svg"
          imageDest="/icons/transfer.svg"
          content="提領"
        />
        <Box marginTop={1}>
          <Content
            amount={amount}
            status={withdrawRecordStatus[status]}
            records={records}
            type="withdraw"
          />
        </Box>
      </Box>
      <EveSnackbar />
    </>
  );
}
