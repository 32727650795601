import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Portal from '@mui/material/Portal';

export const useSnackbar = (
  message,
  position = { vertical: 'bottom', horizontal: 'center' },
) => {
  const [open, setOpen] = useState(false);
  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function EveSnackbar() {
    return (
      // 用 Portal 包裹 Snackbar 可以讓 Snackbar 渲染在 dom tree 的最外層
      <Portal>
        <Snackbar
          anchorOrigin={position}
          autoHideDuration={5000}
          open={open}
          onClose={handleClose}
          message={message}
          sx={{ zIndex: 9999 }}
          ContentProps={{
            sx: {
              display: 'block',
              textAlign: 'center',
              backgroundColor: '#000000',
              color: '#FFFFFF',
              zIndex: 1400,
            },
          }}
        />
      </Portal>
    );
  }

  return {
    showSnackbar: handleShow,
    EveSnackbar,
  };
};
