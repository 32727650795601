import { React, useRef, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import BottomPanel from '../../components/BottomPanel';
import validator from './validator';
import Dialog from '../../components/Dialog';
import { UserContext } from '../../UserContext';
import { UserAPI } from '../../apis/UserAPI';
import Modal from '../../components/Modal';
import CustomButton from '../../components/CustomButton';
const sxContainer = {
  padding: '16px',
  pt: `${navigationBarHeight}px`,
};

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '15px',
  fontWeight: '400',
}));

export default function ChangeEmailPage() {
  const navigate = useNavigate();
  const { setLoading, setUser } = useContext(UserContext);
  const inputUserEmail = useRef();
  const [errors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showInlineAlert, setShowInlineAlert] = useState(false);
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  const { state } = useLocation();
  const navTitle = state?.navTitle;
  const nextNavigation = state?.nextNavigation;

  const handleBack = () => {
    navigate(-1);
  };

  const checkEmail = () => {
    let alert = null;
    if (!validator.isEmail(inputUserEmail.current.value, errors)) {
      alert = errors.email;
    } else if (inputUserEmail.current.value === '') {
      alert = '請填寫Email';
    }

    if (alert) {
      alertMessage.current = alert;
      setShowInlineAlert(true);
      return false;
    } else {
      return true;
    }
  };

  const handleResendVerifyCode = async () => {
    if (!checkEmail()) {
      return;
    }

    setLoading(true);

    const userData = await UserAPI.updateEmail({
      email: inputUserEmail.current.value,
    });
    setLoading(false);

    if (userData.error) {
      alertTitle.current = '發生錯誤';
      alertMessage.current = userData.error.message;
      setShowAlert(true);
    } else {
      setUser(userData);
      if (nextNavigation) {
        navigate(nextNavigation);
      } else {
        navigate('/emailVerify', {
          state: {
            entry: '/changeEmail',
          },
        });
      }
    }
  };

  const navigation = () => {
    return (
      <NavigationBar title="變更電子郵件" leftButtonHandler={handleBack} />
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <CustomButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleResendVerifyCode}>
          下一步
        </CustomButton>
      </BottomPanel>
    );
  };

  const sxTextField = {
    padding: '13px 16px',
    backgroundColor: '#150F32',
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'white',
    },
    '& .MuiInputBase-input.Mui-disabled::placeholder': {
      WebkitTextFillColor: (theme) => theme.palette.secondary.main,
    },
  };

  const emailField = () => {
    return (
      <Stack marginTop="10px" marginBottom="20px">
        <Typography fontSize={26} fontWeight={400} marginTop="12px">
          您新的電子郵件
        </Typography>
        <Typography color="text.purple" marginTop="4px" marginBottom="16px">
          請輸入 Email 驗證
        </Typography>
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: '15px',
            },
          }}
          hiddenLabel
          autoComplete="off"
          fullWidth
          inputProps={{ min: 0, style: { textAlign: 'left' } }}
          placeholder="請輸入您的 Email"
          sx={sxTextField}
          inputRef={inputUserEmail}
          onChange={() => {
            setShowInlineAlert(false);
          }}
        />
        {showInlineAlert && (
          <Typography color="#FBA28B" marginTop="4px" fontSize="14px">
            {alertMessage.current}
          </Typography>
        )}
      </Stack>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        {emailField()}
        {bottomPanel() /* 驗證按鈕 */}
        {/* Warning modal */}
        <Modal
          title={alertTitle.current}
          enabled={showAlert}
          buttons={[
            {
              variant: 'contained',
              label: '確定',
              onClick: () => {
                setShowAlert(false);
              },
            },
          ]}
          buttonDirection="column">
          <Box
            sx={{
              paddingTop: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography>{alertMessage.current}</Typography>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
