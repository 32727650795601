import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import Icon from '../../components/Icon';
import { UserContext } from '../../UserContext';
import { CS_EMAIL } from '../../constants/customerService';
import Typography from '@mui/material/Typography';
import CustomButton from '../../components/CustomButton';

const TradeButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

export default function KycFailedPage() {
  const { setTabIndex, setNavTitle } = useContext(UserContext);
  const navigate = useNavigate();

  const handleBack = () => {
    setTabIndex(0);
    navigate('/');
  };

  const handleComplete = () => {
    window.location.href = `mailto:${process.env.REACT_APP_CS_EMAIL}`;
  };

  const kycDoneDesc = () => {
    return (
      <Stack
        sx={{ mt: '80px' }}
        spacing={2}
        direction={'column'}
        alignItems="center">
        <Box>
          <Icon
            src="images/img_credential_result.svg"
            width={175}
            height={175}
          />
        </Box>
        <Box>
          <Typography fontSize="26px">審核未通過</Typography>
        </Box>
        <Box width={'100%'} marginTop="10px" marginBottom="37px">
          <Typography
            sx={{
              fontSize: '16px',
              textAlign: 'left',
              letterSpacing: '-0.4px',
            }}>
            很抱歉，由於您的身份驗證未能通過我們的審核標準，基於法規遵循與安全考量，我們無法為您提供服務，造成您的不便，懇請見諒。
          </Typography>
        </Box>
      </Stack>
    );
  };

  const bottomPanel = () => {
    return (
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: '80px',
          padding: '16px',
          zIndex: '1200',
          backgroundColor: 'transparent',
          width: '100%',
        }}>
        <Box display="flex" height="100%">
          <CustomButton
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => {
              handleBack();
            }}>
            返回首頁
          </CustomButton>
        </Box>
      </Paper>
      // <BottomPanel>
      //   <TradeButton
      //     variant="contained"
      //     fullWidth
      //     disableElevation
      //     onClick={handleComplete}>
      //     聯繫客服
      //   </TradeButton>
      // </BottomPanel>
    );
  };
  return (
    <Box sx={sxContainer}>
      <Box padding="30px">{kycDoneDesc()}</Box>
      {bottomPanel() /* 進行身分驗證按鈕 */}
    </Box>
  );
}
