import { api } from './configs/axiosConfigs';

export const InvoiceAPI = {
  getInvoiceNpo: async function () {
    const response = await api.request({
      url: 'invoice_npo/',
      method: 'GET',
    });
    return response;
  },
};
