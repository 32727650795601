import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ProviderInformation() {
  return (
    <Box>
      <Typography
        color="#FFFFFF33"
        fontWeight={400}
        fontSize="14px"
        lineHeight="16.71px"
        letterSpacing="-0.4px"
        textAlign="center">
        本服務由富昇數位提供
      </Typography>
    </Box>
  );
}
