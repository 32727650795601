import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GroupButtons from '../../components/GroupButtons';
import {
  XYChart,
  Tooltip,
  AreaSeries,
  GlyphSeries,
  Grid,
  Axis,
  LineSeries,
} from '@visx/xychart';
import { LinearGradient } from '@visx/gradient';
import { ThousandSeparator } from '../../utils/CoinNumberFormat';

const defaultPeriodLabels = ['1日', '1週', '1月', '3月', '1年', '全部'];
const gradientTopColor = '#3E1EE6';
const gradientBottomColor = '#3E1EE600';

const accessors = {
  xAccessor: (d) => d?.x,
  yAccessor: (d) => d?.y,
};

const PriceHistory = ({
  product,
  chartData,
  selection,
  handleSelectPriceRange,
  periodLabels = defaultPeriodLabels,
}) => {
  // 用來處理拖曳事件的起始位置
  const startPosition = useRef({ x: 0, y: 0 });

  const calcPointX = (tooltipData) => {
    const shift = 10;
    return (accessors.xAccessor(tooltipData) / window.innerWidth) * 100 > 10
      ? accessors.xAccessor(tooltipData) - shift
      : accessors.xAccessor(tooltipData) + shift;
  };

  return (
    <Box>
      <GroupButtons
        selection={selection}
        items={periodLabels}
        selectionHandler={handleSelectPriceRange}
        sx={{
          selected: { width: '67px', borderRadius: '14px' },
          unselected: {
            width: '67px',
            borderRadius: '14px',
            background: 'transparent',
          },
        }}
      />

      {product && chartData.seriesData && chartData.seriesData.length > 0 ? (
        <Box sx={{ height: '300px' }}>
          <XYChart
            height={300}
            xScale={{ type: 'point', paddingRight: 5 }}
            yScale={{
              type: 'linear',
              domain: [chartData?.minValue, chartData?.maxValue],
              zero: false,
              nice: true,
            }}
            margin={{ top: 20, right: 62, bottom: 40, left: 0 }}
            onPointerDown={(d) => {
              startPosition.current = {
                x: d.svgPoint.x,
                y: d.svgPoint.y,
              };
            }}
            onPointerMove={(d) => {
              const diffX = d.svgPoint.x - startPosition.current.x;
              const diffY = d.svgPoint.y - startPosition.current.y;

              if (Math.abs(diffX) > 10 && Math.abs(diffY) < 20) {
                document.body.style.overflow = 'hidden';
              } else {
                document.body.style.overflow = 'scroll';
              }
            }}
            onPointerUp={() => (document.body.style.overflow = 'scroll')}
            onPointerOut={() => (document.body.style.overflow = 'scroll')}>
            <Axis
              orientation="bottom"
              tickStroke="transparent"
              numTicks={4}
              xScale={{ type: 'time' }}
              stroke="#FFFFFF1A"
              tickLabelProps={() => ({
                textAnchor: 'left',
              })}
            />
            <Axis
              orientation="right"
              tickStroke="transparent"
              tickFormat={(value) => Math.floor(value).toString()}
              numTicks={5}
              stroke="#FFFFFF1A"
              tickLabelProps={() => ({
                fontSize: '12px',
              })}
            />

            <Grid
              rows={true}
              columns={false}
              lineStyle={{ stroke: '#FFFFFF1A', strokeWidth: 1 }}
              numTicks={6}
            />

            <LinearGradient
              id="area-gradient"
              from={gradientTopColor}
              to={gradientBottomColor}
            />

            <AreaSeries
              dataKey={product?.baseCurrencyId}
              data={chartData?.seriesData}
              {...accessors}
              fill="url(#area-gradient)"
              stroke="transparent"
            />

            <LineSeries
              dataKey={product?.baseCurrencyId}
              data={chartData?.seriesData}
              {...accessors}
              stroke="#FFFFFF"
              strokeWidth={1}
            />

            <Tooltip
              snapTooltipToDatumX
              snapTooltipToDatumY
              showVerticalCrosshair
              renderTooltip={({ tooltipData, colorScale }) => (
                <div>
                  <div
                    style={{
                      color: colorScale(tooltipData.nearestDatum.key),
                    }}>
                    {tooltipData.nearestDatum.key}
                  </div>
                  {accessors.xAccessor(tooltipData.nearestDatum.datum)}
                  {', '}
                  {ThousandSeparator(
                    accessors.yAccessor(tooltipData.nearestDatum.datum),
                  )}
                </div>
              )}
            />

            <GlyphSeries
              data={[chartData?.minMaxData?.[0]]}
              {...accessors}
              enableEvents={false}
              renderGlyph={(tooltipData) => (
                <g>
                  <text
                    textAnchor="start"
                    dy={'-10px'}
                    dx={'-10px'}
                    fill="#FFFFFF"
                    fontSize={10}
                    x={calcPointX(tooltipData)}
                    y={accessors.yAccessor(tooltipData)}>
                    {ThousandSeparator(tooltipData.datum.y)}
                  </text>
                </g>
              )}
            />
          </XYChart>
        </Box>
      ) : (
        <Typography>資料載入中...</Typography>
      )}
    </Box>
  );
};

export default React.memo(PriceHistory);
