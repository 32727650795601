import React, { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { Box, TextField } from '@mui/material';
import BottomPanel from '../../components/BottomPanel';
import Panel from '../../components/Panel';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import validator from './validator';
import StyledButton from '../../components/StyledButton';

const InputBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FAFAFA',
  width: '100%',
  height: '56px',
  borderRadius: '9px',
}));

export default function ChangePhoneBarcodePage() {
  const { state } = useLocation();
  const [errors] = useState({});
  const billInfo = state?.billInfo;
  const [disableButton, setDisableButton] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const carrierIdRef = useRef(null);
  const navigate = useNavigate();
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const alertActionLabel = useRef(null);

  const handleBack = () => {
    navigate(-1);
  };

  const sxContainer = {
    pt: `${navigationBarHeight}px`,
  };

  const sxTextField = {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'black',
    },
  };

  const txtInputProps = {
    disableUnderline: true,
    style: {
      fontSize: '15px',
    },
  };

  async function handleUpdatePhoneBarcode() {
    let payload = {};
    let alert = null;
    if (carrierIdRef.current !== billInfo.phoneBarcode) {
      if (!validator.isPhoneBarcode(carrierIdRef.current, errors)) {
        alert = errors.barcode;
      }
      payload['phone_barcode'] = carrierIdRef.current;
    } else {
      navigate(-1);
      return;
    }

    if (alert) {
      alertTitle.current = '載具更新失敗';
      alertMessage.current = alert;
      alertActionLabel.current = '確定';
      setShowAlert(true);
    } else {
      const userData = await UserAPI.updateBillInfo(payload);
      if (userData !== null) {
        navigate(-1);
      } else {
        alertTitle.current = '載具更新失敗';
        alertMessage.current = '請確認載具條碼是否正確';
        alertActionLabel.current = '確定';
        setShowAlert(true);
      }
    }
  }

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleUpdatePhoneBarcode}
          disabled={disableButton}>
          儲存
        </StyledButton>
      </BottomPanel>
    );
  };

  const navigation = () => {
    return (
      <NavigationBar title="手機載具設定" leftButtonHandler={handleBack} />
    );
  };

  const handleCarrierIdInput = (value) => {
    carrierIdRef.current = value;
    if (
      carrierIdRef.current.length === 8 ||
      carrierIdRef.current.length === 0
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handleAlertAction = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box margin="16px">
          <Panel>
            <Box margin="16px">
              <TitleLabel fontSize="18px" fontWeight="bold">
                手機條碼載具
              </TitleLabel>

              <InputBox
                display="flex"
                alignItems="center"
                sx={{
                  padding: '16px',
                  marginTop: '8px',
                  border: 1,
                  borderColor: '#C1C1C1',
                }}>
                <TextField
                  variant="standard"
                  InputProps={txtInputProps}
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'left' },
                  }}
                  placeholder="請輸入含/的手機條碼載具"
                  defaultValue={billInfo.phoneBarcode}
                  sx={sxTextField}
                  onChange={(e) => {
                    handleCarrierIdInput(e.target.value);
                  }}
                />
              </InputBox>
              <Box marginTop="16px" marginLeft="16px">
                <SubTitleLabel>格式：/555ABCD，共8碼</SubTitleLabel>
              </Box>
            </Box>
          </Panel>
        </Box>
        {bottomPanel()}
      </Box>
      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel={alertActionLabel.current}
        actionHandler={handleAlertAction}
      />
    </>
  );
}
