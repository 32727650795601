import { Box, Stack, Typography } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { useNavigate } from 'react-router-dom';
import EveButton from '../../components/EveButton';
import { SvgIcon } from '../../components/Icon';
import ProviderInformation from '../../components/ProviderInformation';

export default function ChangePhoneNumber() {
  const navigate = useNavigate();

  return (
    <>
      <NavigationBar leftButtonHandler={() => navigate('/')} />
      <Box
        padding="72px 16px"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Stack
          spacing={0}
          direction="column"
          alignItems="center"
          sx={{ flex: 1, position: 'relative' }}>
          <SvgIcon
            src={'/icons/phonenumber_different.svg'}
            width={176}
            height={176}
          />
          <Typography
            marginTop={3}
            fontWeight={400}
            fontSize={26}
            textAlign="center">
            您的手機號碼
            <br />
            與上一次登入不同
          </Typography>
          <Box marginTop="25px">
            <ProviderInformation />
          </Box>

          <Stack
            width="100%"
            spacing={2}
            alignItems="center"
            sx={{
              position: 'fixed',
              bottom: 40,
              left: 0,
              right: 0,
              padding: '0 20px',
            }}>
            <EveButton
              variant="contained"
              sx={{ height: '48px', width: '100%' }}
              onClick={() => navigate('/landing')}>
              重新登入
            </EveButton>
            <Box>
              <EveButton
                variant="text"
                onClick={() => navigate('/identificationNumberConfirm')}>
                變更手機號碼
              </EveButton>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
