import { useState, useRef } from 'react';
import { Box, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SliderThumb } from '@mui/material/Slider';

const OrderSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  width: '100',
  marginX: 'auto',
  '& .MuiSlider-thumb': {
    height: 56,
    width: 56,
    backgroundColor: '#3E1EE6',
    backgroundImage: `url("/icons/ic_exchange.svg")`,
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxShadow: 'none',
    '& .order-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 54,
    marginLeft: '-29px',
    paddingRight: '29px',
    borderRadius: ' 50px 0 0 50px',
    backgroundColor: '#3E1EE6',
  },
  '& .MuiSlider-rail': {
    color: '#fff',
    opacity: '0%',
    height: 56,
    borderRadius: '50px',
  },
}));

function OrderThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="order-bar" />
      <span className="order-bar" />
      <span className="order-bar" />
    </SliderThumb>
  );
}

function OrderCheckSlider({ createOrder }) {
  const [value, setValue] = useState(0);
  const isSliding = useRef(false);
  const sliderValueRef = useRef(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    sliderValueRef.current = newValue;
  };

  const handleReset = () => {
    if (isSliding.current) return;
    isSliding.current = false;

    const start = value;
    const end = 0;
    const duration = 500;
    let startTime = null;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      let progress = (timestamp - startTime) / duration;

      if (progress > 1) progress = 1;
      let newValue = start + (end - start) * progress;
      setValue(newValue);
      sliderValueRef.current = newValue;

      if (progress < 1 && !isSliding.current) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  function handleMouseDown() {
    isSliding.current = true;

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleMouseUp);
  }

  const handleMouseUp = () => {
    isSliding.current = false;
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('touchend', handleMouseUp);

    if (sliderValueRef.current > 96) {
      createOrder();
      return;
    }

    handleReset();
  };

  return (
    <Box
      width="80%"
      sx={{
        marginX: 'auto',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}>
      <Box
        width="calc(100% + 62px)"
        sx={{
          backgroundColor: '#fff',
          position: 'absolute',
          height: '60px',
          borderRadius: '50px',
          border: '4px solid #fff',
          color: '#3E1EE6',
          textAlign: 'center',
          lineHeight: '50px',
          fontWeight: '600',
        }}>
        確認交易資訊
      </Box>
      <OrderSlider
        slots={{ thumb: OrderThumbComponent }}
        onChange={handleChange}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        aria-label="custom thumb label"
        value={value}
        sx={{
          transition: 'all 0.3s ease-out',
        }}
      />
    </Box>
  );
}

export default OrderCheckSlider;
