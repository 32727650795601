import { useRef, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Box, Portal } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { UserAPI } from '../../apis/UserAPI';
import { UserContext } from '../../UserContext';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EveButton from '../../components/EveButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Modal from '../../components/Modal';
import PasswordInput from '../../components/PasswordInput';

export default function SignInPage() {
  const { loginPhone } = useContext(UserContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <NavigationBar leftButtonHandler={() => navigate('/landing')} />
      <Stack padding="72px 16px" spacing={2}>
        <Typography fontWeight={400} fontSize={26} letterSpacing={-0.4}>
          登入密碼
        </Typography>
        <PasswordInput
          lengthLimit={16}
          showPassword={showPassword}
          password={password}
          handlePasswordChange={handlePasswordChange}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          handleMouseUpPassword={handleMouseUpPassword}
        />

        <EveButton
          variant="text"
          sx={{ color: 'text.primary', fontSize: '14px', fontWeight: 400 }}
          onClick={() => navigate('/resetPassword/phoneNumber')}>
          忘記密碼
        </EveButton>
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            left: 16,
            right: 16,
          }}>
          <SignInButton phoneNumber={loginPhone} password={password} />
        </Box>
      </Stack>
    </>
  );
}

function SignInButton({ phoneNumber, password }) {
  const { setLoading, setUser, setTabIndex, setNavTitle } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const errorMessageRef = useRef('');

  const handleLogin = async () => {
    if (phoneNumber === '' || password === '') {
      errorMessageRef.current = '輸入的資料格式不正確，請確認後重試';
      setShowDialog(true);
    } else {
      setLoading(true);
      const payload = {
        msisdn: phoneNumber,
        password: password,
      };
      const response = await UserAPI.getUserWithPhoneAndPassword(payload);
      setLoading(false);

      if (response?.error) {
        errorMessageRef.current = response?.error.message;
        setShowDialog(true);
      } else if (response?.user) {
        setUser(response?.user);
        setTabIndex(0);
        setNavTitle('OP加密資產存摺');
        navigate('/');
      }
    }
  };

  const confirmModalButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setShowDialog(false),
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="end" alignItems="center">
        <EveButton
          variant="contained"
          sx={{
            width: '56px',
            height: '56px',
            borderRadius: '50%',
            minWidth: 'unset', // 確保不受預設 minWidth 影響
            padding: 0, // 清除 padding 讓圖示置中
          }}
          onClick={() => handleLogin()}>
          <ArrowForwardIcon />
        </EveButton>
      </Box>
      <Portal>
        <Modal
          title="登入失敗"
          enabled={showDialog}
          buttons={confirmModalButtons}
          buttonDirection="column">
          <Typography
            fontWeight={400}
            fontSize={16}
            textAlign="center"
            color="text.secondary">
            {errorMessageRef.current}
          </Typography>
        </Modal>
      </Portal>
    </>
  );
}
