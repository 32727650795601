import { useNavigate } from 'react-router-dom';

export const useRecordNavigation = (originFrom) => {
  const navigate = useNavigate();

  const handleRecordClick = (record, type) => {
    if (record.type === 'withdraw') {
      handleWithdrawNavigation(record);
      return;
    }

    switch (type) {
      case 'order':
        handleOrderNavigation(record);
        break;
      case 'deposit':
        handleDepositNavigation(record);
        break;
      case 'withdraw':
        handleWithdrawNavigation(record);
        break;
      default:
        break;
    }
  };

  const handleOrderNavigation = (order) => {
    if (
      (order.payment === 'store' || order.payment === 'bank') &&
      order.status === 'open'
    ) {
      navigate('/orderPayment', {
        state: { order, cancelOrder: true, originFrom },
      });
    } else {
      navigate('/orderInfo', { state: { order, originFrom } });
    }
  };

  const handleDepositNavigation = (deposit) => {
    navigate(`/deposit/done`, {
      state: {
        depositRecord: deposit,
        originFrom,
      },
    });
  };

  const handleWithdrawNavigation = (withdraw) => {
    navigate('/withdraw/bank/info', {
      state: {
        title: '提領詳情',
        bankRecord: withdraw,
        originFrom,
      },
    });
  };

  return { handleRecordClick };
};
