import { Box, TextField } from '@mui/material';

const HiddenTextField = ({ inputRef, value, onChange }) => {
  return (
    <Box
      sx={{
        opacity: 0,
        pointerEvents: 'none',
      }}
      width="100%">
      <TextField
        inputRef={inputRef}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        hiddenLabel
        autoComplete="off"
        fullWidth
        inputProps={{
          min: 0,
          style: { textAlign: 'center' },
          inputMode: 'numeric',
        }}
        value={value}
        autoFocus={true}
        onChange={onChange}
      />
    </Box>
  );
};

export default HiddenTextField;
