import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import { useState, useEffect, useRef, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal';
import secureLocalStorage from 'react-secure-storage';

export default function KycLoading() {
  const { setUser } = useContext(UserContext);
  const alertMessage = useRef('');
  const [showAlert, setShowAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getKycResult() {
      const csAppUserData = secureLocalStorage.getItem('csAppUserData');

      const payload = {
        sessionId: searchParams.get('sessionid'),
        result: searchParams.get('result'),
        kycTid: searchParams.get('kycTid'),
        data: csAppUserData,
      };
      const response = await UserAPI.getKycResult(payload);

      if (response?.error) {
        const user = await UserAPI.getUser();
        if (user) {
          setUser(user);
        }
        alertMessage.current = response?.error.message;
        setShowAlert(true);
      } else if (response?.user) {
        setUser(response?.user);
        setTimeout(() => navigate(response.page, { replace: true }), 5000);
      }
    }

    getKycResult();
  }, [setShowAlert, searchParams]);

  return (
    <>
      <Box sx={{ pt: `${navigationBarHeight}px` }}>
        <NavigationBar hideLeftButton={true} />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '400px' }}>
        <CircularProgress
          size={48}
          thickness={3}
          sx={{
            color: '#FFF',
          }}
        />
      </Box>

      <Modal
        title="身分驗證失敗"
        enabled={showAlert}
        buttons={[
          {
            variant: 'contained',
            label: '確定',
            onClick: () => {
              navigate('/signUpCompleted');
            },
          },
        ]}
        buttonDirection="column">
        <Box
          sx={{
            paddingTop: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography>{alertMessage.current}</Typography>
        </Box>
      </Modal>

      {/* <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title="身分驗證失敗"
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => {
          navigate('/signUpCompleted');
        }}
      /> */}
    </>
  );
}
