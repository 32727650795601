import { Label } from '../../../components/Label';
import { Box, Stack } from '@mui/material';
import SectionList from './SectionList';

const Section = ({ section }) => {
  return (
    <div>
      <Box style={{ marginBottom: '8px', fontWeight: 'bold' }}>
        {section.title}
      </Box>
      <Stack sx={{ textAlign: 'justify' }}>
        {section.description &&
          section.description.map((desc, index) => (
            <Label mb="8px" key={index}>
              {desc}
            </Label>
          ))}
        {section.sectionList && section.sectionList.items.length > 0 && (
          <SectionList
            items={section.sectionList.items}
            orderBy={section.sectionList.orderBy}
          />
        )}
        {section.additionInfo && <Label mb="8px">{section.additionInfo}</Label>}
      </Stack>
    </div>
  );
};

export default Section;
