import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { UserAPI } from '../../apis/UserAPI';
import { UserContext } from '../../UserContext';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import Icon from '../../components/Icon';
import Dialog from '../../components/Dialog';
import ButtonContainer from '../../components/ButtonContainer';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

export default function KycReverifyPage() {
  const alertMessage = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const { setLoading, setTabIndex, setNavTitle } = useContext(UserContext);
  const navigate = useNavigate();

  const handleBack = () => {
    setTabIndex(0);
    setNavTitle('OP加密資產存摺');
    navigate('/');
  };

  const reverifyKyc = async () => {
    setShowAlert(false);
    setLoading(true);
    const response = await UserAPI.getKycUrl();
    setLoading(false);
    if (response?.error) {
      alertMessage.current = response?.error.message;
      setShowAlert(true);
    } else {
      window.location.replace(response.redirectUrl);
    }
  };

  const kycDoneDesc = () => {
    return (
      <Stack
        sx={{ mt: '80px' }}
        spacing={2}
        direction={'column'}
        alignItems="center">
        <Box>
          <Icon
            src="images/img_credential_result.svg"
            width={175}
            height={175}
          />
        </Box>
        <Box>
          <Typography fontSize="26px">身分驗證失敗</Typography>
        </Box>
        <Box width={'100%'} marginTop="10px" marginBottom="37px">
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'left',
              letterSpacing: '-0.4px',
            }}>
            驗證失敗可能原因如下：
            <br />
            1. 會員註冊資料與身分證件不符。
            <br />
            2. 需為身份證正本，不可翻拍或複印本。
            <br />
            3. 需為身分證，不接受健保卡、駕照等其他證件。
            <br />
            4. 身分證件拍攝模糊或反光。
            <br />
            5. 身分證件與人臉拍攝無法判別為同一人。
            <br />
            <br />
            請再次確認上述原因後，重新提交身分證件，謝謝
          </Typography>
        </Box>
      </Stack>
    );
  };

  const bottomPanel = () => {
    return (
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: '150px',
          padding: '16px',
          zIndex: '1200',
          backgroundColor: 'transparent',
          width: '100%',
        }}>
        <Box display="flex" height="100%">
          <ButtonContainer
            direction="column"
            buttons={[
              {
                label: '進行身分驗證',
                variant: 'contained',
                onClick: reverifyKyc,
              },
              {
                label: '先不要',
                variant: 'empty',
                onClick: () => {
                  navigate('/');
                },
              },
            ]}
          />
        </Box>
      </Paper>
      // <BottomPanel>
      //   <TradeButton
      //     variant="contained"
      //     fullWidth
      //     disableElevation
      //     onClick={reverifyKyc}>
      //     重新進行身分驗證
      //   </TradeButton>
      // </BottomPanel>
    );
  };

  return (
    <Box sx={sxContainer}>
      <Box padding="32px">{kycDoneDesc()}</Box>
      {bottomPanel() /* 進行身分驗證按鈕 */}

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title="KYC 頁面轉導失敗"
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />
    </Box>
  );
}
