import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import moment from 'moment/moment';
import Dialog from '../../components/Dialog';
import BottomPanel from '../../components/BottomPanel';
import NavigationBar from '../../components/NavigationBar';
import AssetRecordCell from '../../components/asset/AssetRecordCell';

import { navigationBarHeight } from '../../components/Layout';
import { TitleLabel } from '../../components/Label';
import { ProductAPI } from '../../apis/ProductAPI';
import { OrderAPI } from '../../apis/OrderAPI';
import { UserContext } from '../../UserContext';
import Icon from '../../components/Icon';
import PriceHistory from './PriceHistory';
import CurrentPrice from './CurrentPrice';

const periodIds = ['1D', '7D', '1M', '3M', '1Y', 'ALL'];
const coinIntro = {
  BTC: '比特幣（Bitcoin, BTC）是全球第一個去中心化的數位加密貨幣，於2009年由一位化名為中本聰的人發明。它基於區塊鏈技術運作，透過點對點的網絡實現去中心化的資金轉帳，無需第三方機構的介入。比特幣具有稀缺性，總供應量被限定在2100萬枚，這為其提供了「數位黃金」的特性。隨著越來越多的投資者和機構參與其中，比特幣已成為最具價值和知名度的加密貨幣，常用於價值儲存及投資。',
  ETH: '以太幣（Ether, ETH）是一種去中心化的數位加密貨幣，是以太坊（Ethereum）區塊鏈上的原生代幣。與比特幣不同的是，以太坊不僅僅是一種虛擬貨幣系統，它還是一個可以支援去中心化應用程式（DApps）和智能合約的平台。這使得以太幣成為了區塊鏈技術的核心組成部分之一。以太幣不僅用於支付交易手續費和資源租用，還被視為智能合約的「燃料」，支持著整個以太坊網絡的運行。\n\n在過去幾年中，隨著去中心化金融（DeFi）和不可替代代幣（NFT）的興起，以太幣的價值和重要性大幅增長。如今，以太幣是全球第二大數位加密貨幣，僅次於比特幣，不論是在市值、技術應用還是社群支持方面，都處於領導地位。',
  USDC: 'USD Coin（USDC） 是一種穩定幣（Stablecoin），其價值與美元（USD）掛鉤，目標是保持1 USDC = 1 USD的穩定匯率。由Circle和Coinbase聯合推出，USDC在以太坊和其他區塊鏈上運行，允許快速、安全的全球交易。由於其價格穩定性，USDC被廣泛用於加密貨幣市場中的交易、支付和作為避險資產，特別是在價格波動較大的加密貨幣環境下。',
};

const coinChineseName = { BTC: '比特幣', ETC: '以太幣' };

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const DescriptionTitleLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '17px',
  fontWeight: 'bold',
  marginBottom: '13px',
}));

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '13px',
  fontWeight: 'medium',
  whiteSpace: 'pre-line',
}));

const BuySellButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 'bold',
  borderRadius: '8px',
  backgroundColor: '#3E1EE6',
}));

export default function CoinPricePage() {
  const { setLoading, user, setTabIndex, setNavTitle } =
    useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const productId = useMemo(() => params.productId, [params.productId]);
  const initData = useRef(false);
  const [product, setProduct] = useState(null);
  const [orders, setOrders] = useState([]);
  const [chartData, setChartData] = useState({
    seriesData: [],
    minMaxData: [],
    minValue: 0,
    maxValue: 0,
  });
  const [selection, setSelection] = useState(0);
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const [showOrderDialog, setShowOrderDialog] = useState(false);
  const unpaidOrderRef = useRef(null);
  const [currentTab, setCurrentTab] = useState(0);
  const { state } = useLocation();

  const tradeBtnTitle = [
    {
      side: user ? '前往交易' : '開始成為會員',
      sx: { marginRight: '8px' },
      disable: false,
    },
  ];

  // 初始載入產品與訂單資料
  useEffect(() => {
    if (initData.current === false) {
      initData.current = true;
      async function fetchProductData() {
        const productData = await ProductAPI.getProduct(productId);
        setProduct(productData);
      }
      async function fetchOrderData() {
        if (user) {
          const allOrders = await OrderAPI.getOrders();
          const productOrders = allOrders.filter(
            (order) => order.productId === productId,
          );
          setOrders(productOrders);
        }
      }
      fetchProductData();
      fetchOrderData();
    }
  }, [productId, user]);

  useEffect(() => {
    if (product) {
      fetchPriceChartData(periodIds[0]);
    }
  }, [product]);

  const fetchPriceChartData = useCallback(
    async (period) => {
      const prices = await ProductAPI.getPrice(productId, period);
      let seriesData = [];
      let maxPrice = 0;
      let minPrice = Number.MAX_VALUE;
      let maxDataPoint = {};
      let minDataPoint = {};
      for (let i = 0; i < prices.length; i++) {
        const priceData = prices[i];
        const price = ['USDC-TWD', 'USDT-TWD'].includes(productId)
          ? Math.round(priceData.totalPrice * 10000) / 10000
          : Math.round(priceData.totalPrice);
        const strDate = moment.unix(priceData.datetime).format('MM/DD HH:mm');
        const dataPoint = { x: strDate, y: price };
        seriesData.push(dataPoint);
        if (price > maxPrice) {
          maxPrice = price;
          maxDataPoint = dataPoint;
        }
        if (price < minPrice && price > 0) {
          minPrice = price;
          minDataPoint = dataPoint;
        }
      }

      setChartData({
        seriesData: seriesData,
        minMaxData: [maxDataPoint, minDataPoint],
        minValue: minPrice,
        maxValue: maxPrice,
      });
    },
    [productId],
  );

  const memoizedChartData = useMemo(() => chartData, [chartData]);

  const handleBack = () => {
    state?.originFrom ? navigate(state?.originFrom) : navigate('/');
  };

  const handleSelectPriceRange = useCallback(
    (index) => {
      setLoading(true);
      setSelection(index);
      fetchPriceChartData(periodIds[index]);
      setLoading(false);
    },
    [setLoading, fetchPriceChartData],
  );

  const handleTradeCoins = (side) => {
    const unpaidOrder = orders?.find((order) => checkUnpaidOrder(order));
    if (unpaidOrder !== undefined) {
      unpaidOrderRef.current = unpaidOrder;
      setShowOrderDialog(true);
    } else {
      navigate('/buy/' + productId);
    }
  };

  const checkUnpaidOrder = (order) => {
    if (
      (order.payment === 'store' || order.payment === 'bank') &&
      order.status === 'open'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOrderInfo = (order) => {
    if (checkUnpaidOrder(order)) {
      navigate('/orderPayment', { state: { order, cancelOrder: true } });
    } else {
      navigate('/orderInfo', { state: { order } });
    }
  };

  const Navigation = () => {
    const { productId } = useParams();
    function getCoinChineseName() {
      switch (productId) {
        case 'BTC-TWD':
          return '比特幣/TWD';
        case 'ETH-TWD':
          return '以太幣/TWD';
        default:
          return '';
      }
    }

    return (
      <NavigationBar
        leftTitle={getCoinChineseName()}
        leftButtonHandler={handleBack}>
        {currentTab === 1 && (
          <CurrentPrice product={product} navigation={true} />
        )}
      </NavigationBar>
    );
  };

  const orderRecords = () => {
    const maxItems = 5;
    let items = [];
    const max = orders.length > maxItems ? maxItems : orders.length;
    for (let i = 0; i < max; i++) {
      const order = orders[i];

      items.push(
        <AssetRecordCell
          fund={order.fund}
          time={moment.unix(order.createdTime).format('YYYY-MM-DD HH:mm:ss')}
          status={getOrderState(order)}
          side={order.side}
          product={product?.baseCurrencyId}
          onClick={() => handleOrderInfo(order)}
          paymentMethod={order.payment}
          size={order.size}
        />,
      );
    }

    return (
      <>
        {items.length > 0 && (
          <Box marginTop="30px">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <TitleLabel fontSize="18px" fontWeight="medium">
                買賣紀錄
              </TitleLabel>
            </Box>

            <Box sx={{ height: '8px' }}></Box>
            {items}
          </Box>
        )}
      </>
    );
  };

  const getOrderState = (order) => {
    if (order.status === 'done') {
      if (order.doneReason === 'succeed') {
        return '交易成功';
      } else if (order.doneReason === 'rejected') {
        return '交易失敗';
      } else if (order.doneReason === 'cancel') {
        return '交易取消';
      }
    }
    return '處理中';
  };

  function handleHomePage(coin) {
    switch (coin) {
      case 'BTC':
        return `https://bitcoin.org/zh_TW`;
      case 'ETH':
        return `https://ethereum.org/zh-tw`;
      default:
        return '';
    }
  }

  const coinDescription = () => {
    const coinInfos = [
      {
        icon: 'homePage',
        wording: '官網',
        url: product?.baseCurrencyId && handleHomePage(product.baseCurrencyId),
        img: '/icons/homepage.svg',
      },
      {
        icon: 'github',
        wording: 'Github',
        url: `https://github.com/${product?.baseCurrency.name.toLowerCase()}?ext_browser=1`,
        img: '/icons/github.svg',
      },
      {
        icon: 'whitepaper',
        wording: '白皮書',
        url: `https://staging.opcryptoex.com/document/${
          product?.baseCurrency.name === 'Ethereum'
            ? product?.baseCurrency.name
            : product?.baseCurrency.name.toLowerCase()
        }.pdf?ext_browser=1`,
        img: '/icons/whitepaper.svg',
      },
    ];

    //幣價跟文案的tab sprint 2 再做，文案待以及連結待art提供
    return (
      <>
        <Box marginTop="23px">
          <DescriptionTitleLabel>
            什麼是{coinChineseName[product?.baseCurrencyId]} (
            {product?.baseCurrencyId})?
          </DescriptionTitleLabel>
          <DescriptionLabel>
            {coinIntro[product?.baseCurrency.id]}
          </DescriptionLabel>
        </Box>
        <Box marginTop="23px" marginBottom="90px">
          <DescriptionTitleLabel>連結</DescriptionTitleLabel>
          {coinInfos.map((coinInfo, index) => (
            <Link
              key={index}
              marginBottom="19px"
              href={coinInfo.url}
              target={'_blank'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '4px',
                width: '70px',
              }}>
              <Icon src={coinInfo.img} width="18px" />
              <DescriptionLabel>{coinInfo.wording}</DescriptionLabel>
            </Link>
          ))}
        </Box>
      </>
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        {tradeBtnTitle.map((btn) => (
          <BuySellButton
            variant="contained"
            key={btn.side}
            fullWidth
            disableElevation
            onClick={() => handleTradeCoins(btn.side)}
            sx={btn.sx}
            disabled={btn.disable}>
            {btn.side}
          </BuySellButton>
        ))}
      </BottomPanel>
    );
  };

  const verifyDialog = () => {
    return (
      <Dialog
        title="身分驗證中"
        message="您好，系統正在驗證您的身分，驗證完成後才可開始進行交易，謝謝。"
        showDialog={showVerifyDialog}
        setShowDialog={setShowVerifyDialog}
        actionLabel="確認"
        actionHandler={() => {
          setShowVerifyDialog(false);
        }}
      />
    );
  };

  const orderDialog = () => {
    return (
      <Dialog
        showDialog={showOrderDialog}
        setShowDialog={setShowOrderDialog}
        title="訂單尚未完成"
        message="您有尚未繳款的訂單，請先完成該筆訂單"
        actionLabel="前往訂單"
        actionHandler={() => {
          handleOrderInfo(unpaidOrderRef.current);
        }}
        secondaryActionLabel="確認"
        secondaryActionHandler={() => {
          setShowOrderDialog(false);
        }}
      />
    );
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      <div style={{ display: value === index ? 'block' : 'none' }}>
        {children}
      </div>
    );
  };

  const handleChange = (event, newIndex) => {
    setCurrentTab(newIndex);
  };

  return (
    <Box sx={sxContainer}>
      <Navigation />
      {/* navigation bar */}
      <Box padding="12px 16px">
        <Box sx={{ borderBottom: 1, borderColor: '#FFFFFF1A', mb: '10px' }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#8571EF', // Bottom border color when selected
                height: '2px', // Stroke thickness
              },
            }}>
            <Tab
              sx={{
                minWidth: '40px',
                maxWidth: '45px',
                padding: '0px',
                marginRight: '18px',
              }}
              label={
                <span
                  style={{ color: 'white', fontSize: '14px', fontWeight: 600 }}>
                  價格
                </span>
              }
            />
            <Tab
              sx={{ minWidth: '40px', maxWidth: '45px', padding: '0px' }}
              label={
                <span
                  style={{ color: 'white', fontSize: '14px', fontWeight: 600 }}>
                  資訊
                </span>
              }
            />
          </Tabs>
        </Box>
        {/* 即時買/賣價格 */}
        {currentTab === 0 && <CurrentPrice product={product} />}
        <TabPanel value={currentTab} index={0}>
          {/* 歷史幣價曲線表 */}
          <PriceHistory
            product={product}
            chartData={memoizedChartData}
            selection={selection}
            handleSelectPriceRange={handleSelectPriceRange}
          />
          {orderRecords() /* 交易紀錄 */}
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          {coinDescription() /* 虛擬貨幣介紹 */}
        </TabPanel>

        {bottomPanel() /* 購買 / 賣出按鈕 */}
      </Box>
      {verifyDialog()}
      {orderDialog()}
    </Box>
  );
}
