import React from 'react';
import { Paper } from '@mui/material';
//import { makeStyles } from "@mui/styles";

/*
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    boxShadow: "0px 0px 25px rgba(0,0,0,0.1)",
    marginTop: (settings) => {
      return settings.top === undefined
        ? theme.spacing(4)
        : theme.spacing(settings.top);
    },
    marginBottom: (settings) => {
      return settings.bottom === undefined
        ? theme.spacing(4)
        : theme.spacing(settings.bottom);
    },
    height: (settings) => {
      return settings.height === undefined
        ? null
        : theme.spacing(settings.height);
    },
  },
}));
*/
export default function Panel({
  top,
  bottom,
  height,
  gutterBottom,
  children,
  padding,
  border,
  backgroundColor,
}) {
  //const settings = { top, bottom, height };
  //const classes = useStyles(settings);
  const sxPanel = {
    borderRadius: '8px',
    marginBottom: gutterBottom ? '8px' : '0px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)',
    padding: padding ? padding : '16px',
    backgroundColor: (theme) => {
      return backgroundColor ?? theme.palette.background.secondary;
    },
    border: border ? border : 'none',
  };

  return (
    <Paper
      //   className={classes.paper}
      variant="outlined"
      sx={sxPanel}>
      {children}
    </Paper>
  );
}
