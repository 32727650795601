import NavigationBar from '../../components/NavigationBar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Box from '@mui/material/Box';
import EveButton from '../../components/EveButton';
import PasswordInput from '../../components/PasswordInput';
import Modal from '../../components/Modal';

const validatePassword = (value) => {
  if (!/^[A-Za-z0-9]+$/.test(value))
    return '僅能輸入英文字母和數字，不可包含特殊符號';
  return '';
};

export default function PasswordConfirm() {
  const [password, setPassword] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showServerAlert, setShowServerAlert] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setValidationMessage(validatePassword(passwordValue));
  };

  console.log(validationMessage);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  // 服務器端驗證 alert 的按鈕配置
  const serverAlertButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setShowServerAlert(false),
    },
  ];

  // 處理表單提交
  const handleSubmit = async () => {
    try {
      // TODO: 這裡要加入 API 呼叫
      // const response = await api.passwordConfirm(password);
      navigate('/newPhoneConfirm');
    } catch (error) {
      setShowServerAlert(true);
    }
  };

  return (
    <>
      <NavigationBar
        title="變更手機號碼"
        leftButtonHandler={() => navigate(-1)}
      />
      <Stack padding="72px 16px" spacing={2}>
        <Stack spacing={1}>
          <Typography fontWeight={400} fontSize={26} letterSpacing={-0.4}>
            請輸入登入密碼
          </Typography>
        </Stack>
        <PasswordInput
          lengthLimit={16}
          showPassword={showPassword}
          password={password}
          handlePasswordChange={handleChange}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          handleMouseUpPassword={handleMouseUpPassword}
        />
        <Typography fontSize="12px" color="text.red">
          {validationMessage}
        </Typography>
      </Stack>

      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          left: 16,
          right: 16,
        }}>
        <EveButton
          disabled={password.trim() === '' || !!validationMessage}
          fullWidth
          sx={{ color: '#FFFFFF' }}
          onClick={handleSubmit}>
          下一步
        </EveButton>
      </Box>

      {/* 服務器端驗證的 Modal */}
      <Modal
        title="驗證錯誤"
        enabled={showServerAlert}
        buttons={serverAlertButtons}
        buttonDirection="column">
        <Typography
          fontWeight={400}
          fontSize={16}
          textAlign="center"
          color="text.secondary">
          {/* // TODO: 之後要替換成 API 回傳的 error message */}
          嘿嘿嘿嘿嘿嘿
        </Typography>
      </Modal>
    </>
  );
}
