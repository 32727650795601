import { Paper, Typography, IconButton, Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { navigationBarHeight } from './Layout';
import { ImageIcon } from './Icon';
import { useTheme } from '@mui/material/styles';
import { Label } from './Label';

// TODO: 這整個應該要用 material UI 的 App Bar 做
export default function NavigationBar({
  title,
  titleIcon,
  leftButtonIcon,
  leftButtonHandler,
  leftTitle,
  hideLeftButton,
  children,
  noBackgroundColor, //Boolean
}) {
  const theme = useTheme();
  const {
    typography: { fontWeightBold },
    zIndex: { drawer }, // 1200
  } = theme;

  const sxNavigationBar = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: `${navigationBarHeight}px`,
    zIndex: drawer,
    borderRadius: '0px',
    backgroundColor: noBackgroundColor
      ? 'transparent'
      : (theme) => theme.palette.background.default,
  };

  return (
    <Paper sx={sxNavigationBar} elevation={0}>
      {/* navigation title */}
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        position="absolute"
        alignItems="center"
        justifyContent="center"
        sx={{ height: `${navigationBarHeight}px` }}>
        {titleIcon && (
          <ImageIcon
            src={titleIcon}
            width="25px"
            sx={{ marginRight: '10px' }}
          />
        )}

        <Typography
          textAlign="center"
          variant="h6"
          color="text"
          sx={{
            fontSize: 17,
            fontWeight: fontWeightBold,
          }}>
          {title}
        </Typography>
      </Box>

      {/* navigation items */}
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        // justifyContent="space-between"
        marginLeft="8px"
        marginRight="8px"
        height="100%">
        {/* left navigation bar item */}
        {!hideLeftButton && (
          <IconButton onClick={leftButtonHandler}>
            {leftButtonIcon ? (
              leftButtonIcon
            ) : (
              <ArrowBackIosNewIcon
                style={{ fontSize: 25 }}
                sx={{
                  color: '#fff',
                }}
              />
            )}
          </IconButton>
        )}
        <Label fontWeight="medium">{leftTitle}</Label>

        {/* right navigation bar item */}
        <Box marginLeft="auto" sx={{ zIndex: 1000 }}>
          {children}
        </Box>
      </Box>
    </Paper>
  );
}
