import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { TitleLabel, Label } from '../../components/Label';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UserContext } from '../../UserContext';
import Icon from '../../components/Icon';
import CustomButton from '../../components/CustomButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const iconSize = '32px';
const sxAuthorizeContent = {
  display: 'flex',
  height: '50px',
  alignItems: 'center',
};

const sxContainer = {
  pt: `${navigationBarHeight}px`,
  pl: '30px',
  pr: '30px',
};

const Navigation = () => {
  // const navigate = useNavigate();
  // const { setTabIndex, setNavTitle } = useContext(UserContext);
  // const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <NavigationBar hideLeftButton={true} />
      {/* <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="身分驗證中"
        message="身分驗證正在進行中，大約需要1~2分鐘，完成後我們將會通知您，您可以選擇返回首頁或停留此頁等待驗證完成，謝謝。"
        actionLabel="返回首頁"
        actionHandler={() => navigate('/')}
        secondaryActionLabel="停留此頁"
        secondaryActionHandler={() => setShowDialog(false)}
      /> */}
    </>
  );
};

const RegisterDesc = ({ text }) => {
  return (
    <Box width="100%" marginTop="36px">
      <Label fontSize="15px" align="center">
        {text}
      </Label>
    </Box>
  );
};

const DoneItem = ({ text }) => {
  return (
    <Box sx={sxAuthorizeContent}>
      <CheckCircleIcon style={{ fontSize: iconSize }} />
      <Box marginLeft="16px">
        <TitleLabel>{text}</TitleLabel>
      </Box>
    </Box>
  );
};

const ProgressItem = ({ text }) => {
  return (
    <Box sx={sxAuthorizeContent}>
      {/* <CircularProgress color="inherit" size={'32px'} /> */}
      <Box width={'32px'}></Box>
      <Box marginLeft="16px">
        <TitleLabel>{text}</TitleLabel>
      </Box>
    </Box>
  );
};

const BankAccountNotification = () => {
  return (
    <Box
      sx={{
        letterSpacing: '-0.4px',
        fontFamily: 'PingFang TC, sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        width: '100%',
        marginBottom: '16px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '14px 14px 14px 14px',
        border: '1px solid rgba(255, 255, 255, 0.4)',
      }}>
      <Box
        display="flex"
        flexDirection="row"
        gap="10px"
        sx={{
          alignItems: 'center',
        }}>
        <Icon
          src="/icons/ic_alert_sm.svg"
          alt=""
          sx={{
            width: '18px',
          }}
        />
        <Box sx={{ position: 'relative' }}>
          <Typography
            sx={{
              fontSize: '16px',
              alignContent: 'center',
            }}>
            等待身分驗證審核的同時，您可先進行銀行帳戶綁定申請。
          </Typography>
          <Stack
            sx={{
              position: 'absolute',
              bottom: '0px',
              right: '-5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '5px',
            }}>
            <Typography
              sx={{
                color: '#FFD66E',
              }}>
              前往綁定
            </Typography>
            <ArrowForwardIcon
              style={{ fontSize: 18 }}
              sx={{
                color: '#FFD66E',
              }}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

const RegisterContent = () => {
  return (
    <Stack
      sx={{ mt: '80px' }}
      spacing={2}
      direction={'column'}
      alignItems="center">
      <Box>
        <Icon src="images/img_credential_verify.svg" width={175} height={175} />
      </Box>
      <Box>
        <Typography fontSize="26px">身分驗證審核中</Typography>
      </Box>
      <Box width="100%">
        <Label fontSize="16px" align="left">
          您的身分驗證申請已送出，人工審查作業時間約 5-7
          個工作天(不含例假日)，請耐心等候 。
        </Label>
      </Box>
    </Stack>
  );
};

function BottomPanel({ children, noShadow, height }) {
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: height,
        padding: '16px',
        zIndex: '1200',
        backgroundColor: 'transparent',
      }}
      elevation={noShadow ? 0 : 3}>
      <Box display="flex" height="100%">
        {children}
      </Box>
    </Paper>
  );
}

function BottomSection({ navigate, user, setTabIndex, state }) {
  const handleBack = () => {
    if (
      state?.from === '/changeAccountInfo' ||
      state?.from === '/changeInvoice'
    ) {
      navigate('/member');
    } else {
      setTabIndex(0);
      navigate('/');
    }
  };
  return (
    <BottomPanel
      height={user?.bankAccount?.status !== 'succeed' ? '200px' : '80px'}>
      <Stack width="100%">
        {user?.bankAccount?.status !== 'succeed' && (
          <Box
            onClick={() => {
              if (
                !user.bankAccount ||
                !user.bankAccount?.status ||
                user.bankAccount.status === 'unverified'
              ) {
                navigate('/bankAccount');
              } else {
                navigate('/bankStatus');
              }
            }}
            sx={{
              padding: '14px',
              paddingBottom: '5px',
            }}>
            <BankAccountNotification />
          </Box>
        )}
        <CustomButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={() => {
            handleBack();
          }}>
          返回首頁
        </CustomButton>
      </Stack>
    </BottomPanel>
  );
}

export default function KycVerifyingPage() {
  const navigate = useNavigate();
  const { user, setTabIndex } = useContext(UserContext);
  const { state } = useLocation();
  return (
    <Box sx={sxContainer}>
      <Navigation />
      <RegisterContent />
      <BottomSection
        navigate={navigate}
        user={user}
        setTabIndex={setTabIndex}
        state={state}
      />
    </Box>
  );
}
