import { useState, useEffect } from 'react';
import { UserAPI } from '../apis/UserAPI';

export function useBillInfo(user, setLoading) {
  const [billInfo, setBillInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchBillInfo() {
      if (!user) return;

      setLoading(true);
      try {
        const result = await UserAPI.getBillInfo();
        setBillInfo(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchBillInfo();
  }, [user, setLoading]);

  return { billInfo, error };
}
