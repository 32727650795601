import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// import { Label } from '../Label';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import { SubTitleLabel } from '../Label';
const MethodDict = {
  deposit: '儲值',
  withdraw: '提領',
};

const TypeDict = {
  withdraw: '銀行帳號轉帳',
  bank: '銀行帳號轉帳',
  store: '門市儲值',
  order: '訂單儲值',
};

const statusColorMap = {
  done: '#FFFFFF',
  succeed: '#FFFFFF',
  open: '#FFDD86',
  processing: '#FFDD86',
  rejected: '#FBA28B',
  fbo_confirmed: '#FFDD86',
  txt_done: '#FFDD86',
  refunding: '#969696',
};

const statusTextMap = {
  done: '已完成',
  succeed: '提領成功',
  open: '處理中',
  processing: '處理中',
  rejected: '提領失敗',
  fbo_confirmed: '處理中',
  txt_done: '處理中',
  refunding: '處理中',
};

export default function FundRecordCell({
  onClick,
  type,
  method,
  amount,
  time,
  status,
}) {
  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      height="72px"
      display="flex"
      borderBottom={1}
      borderColor="common.borderColor"
      onClick={onClick}>
      {/* 出入金方式 */}
      <Stack alignItems="start">
        <Typography fontSize={12} fontWeight={400}>
          {time}
        </Typography>

        <Typography fontSize={14} fontWeight={400} letterSpacing={-0.4}>
          {MethodDict[type]}
        </Typography>

        {type === 'deposit' && (
          <Typography
            fontSize={12}
            fontWeight={400}
            letterSpacing={-0.4}
            sx={{ opacity: 0.6 }}>
            {TypeDict[method]}
          </Typography>
        )}
      </Stack>

      {/* 狀態和金額 */}
      <Stack alignItems="end">
        <Typography color={statusColorMap[status]} fontSize={12}>
          {statusTextMap[status]}
        </Typography>
        <Typography fontWeight={400} fontSize={18}>
          <CoinNumberFormat
            value={amount}
            prefix={type === 'deposit' ? '+' : '-'}
            suffix=" TWD"
          />
        </Typography>
      </Stack>
    </Box>
  );
}
