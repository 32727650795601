import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const CopyButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: '1px solid #FFFFFF',
  fontSize: '15px',
  padding: '1px',
  color: '#FFFFFF',
  '&:hover, &:focus, &:active': {
    border: '1px solid #FFFFFF',
  },
  '&:focus': {
    outline: '2px solid #FFFFFF',
    outlineOffset: '2px',
  },
  '&[aria-hidden="true"]': {
    display: 'none',
  },
}));
