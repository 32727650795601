import Decimal from 'decimal.js';
import NumberFormat from 'react-number-format';

const CoinNumberFormat = ({ value, prefix, suffix }) => {
  let decimalScale = 2;
  if (value < 0.01) {
    decimalScale = 4;
  } else if (value < 1) {
    decimalScale = 3;
  }

  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      decimalScale={decimalScale}
      fixedDecimalScale={false}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

const PriceNumberFormat = ({ value, prefix, suffix }) => {
  let decimalScale = 0;
  let fixedDecimalScale = true;
  if (value < 0.01) {
    decimalScale = 4;
    fixedDecimalScale = false;
  } else if (value < 0.1) {
    decimalScale = 3;
    fixedDecimalScale = false;
  } else if (value < 100) {
    decimalScale = 2;
    if (value < 1) fixedDecimalScale = false;
  }

  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

const AssetPriceNumberFormat = ({ value, prefix, suffix }) => {
  let decimalScale = 0;
  let fixedDecimalScale = true;
  if (value < 0.01) {
    decimalScale = 4;
    fixedDecimalScale = false;
  } else if (value < 0.1) {
    decimalScale = 3;
    fixedDecimalScale = false;
  } else if (value < 1) {
    decimalScale = 2;
    fixedDecimalScale = false;
  }

  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

export function priceNumberRoundedToInt(value) {
  if (!value || typeof value !== 'number') {
    value = 0;
  }
  let decimalScale = 0;

  if (value < 0.01 && value > 0) {
    decimalScale = 4;
  } else if (value < 0.1) {
    decimalScale = 3;
  } else if (value < 1) {
    decimalScale = 2;
  }
  const formattedValue = Number(value.toFixed(decimalScale));
  return formattedValue;
}

const RoundDown = (price, decimal = 0) => {
  const num = 10 ** decimal;
  if (decimal > 0) {
    const length = price.toString().split('.')[0].length; // 看整數位有幾位

    // Math.floor() 會回傳小於等於所給數字的最大整數
    // toPrecision() 會傳回一個以指定精度表示該數字的字串(從第一個非 0 數字開始往後算)
    // Intl.NumberFormat() Creates a new NumberFormat object. 可以設定數字小數位數最大上限，所以比 toFixed() 更有彈性
    const resultNum =
      Math.floor(parseFloat(price * num).toPrecision(length + decimal + 2)) /
      num;

    return Intl.NumberFormat('en-US', {
      maximumFractionDigits: decimal,
    }).format(resultNum);
  } else {
    return Math.floor(price * num) / num;
  }
};

const RoundUp = (price, decimal = 0) => {
  const num = 10 ** decimal;
  if (decimal > 0) {
    const length = price.toString().split('.')[0].length;
    let result =
      Math.ceil(parseFloat(price * num)).toPrecision(length + decimal) / num;
    return result % 1 === 0 ? result.toString() : Decimal(result).toFixed();
  } else {
    return Math.ceil(price * num) / num;
  }
};

const Round = (price, decimal = 0) => {
  const num = 10 ** decimal;
  if (decimal > 0) {
    const length = price.toString().split('.')[0].length;
    let result =
      Math.round(parseFloat(price * num)).toPrecision(length + decimal) / num;
    return result % 1 === 0 ? result.toString() : Decimal(result).toFixed();
  } else {
    return Math.round(price * num) / num;
  }
};

const CountDecimals = (value) => {
  if (value === undefined) return 0;
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

const ThousandSeparator = (value) => {
  let parts = value.toString().split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1];
  return (
    numberPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (decimalPart ? `.${decimalPart}` : '')
  );
};

const ReplaceCommaToNumber = (value) => {
  return Number(value.toString().replace(/,/g, ''));
};

const ReplaceCommaToInt = (value) => {
  return parseInt(value.toString().replace(/,/g, ''));
};

const ReplaceStringToInt = (value) => {
  if (value.length === 0) {
    return '0';
  } else {
    return value.replace(/[^0-9]/g, '');
  }
};

const ReplaceStringToNumber = (value) => {
  if (value === '') {
    return '0';
  }

  return value.replace(/[^0-9.]/g, '');
};

const CheckLeadingZero = (value) => {
  let decimalCount = (value.match(/\./g) || '').length;

  if (value.indexOf('.') === 0) {
    return value.slice(1);
  } else if (value.indexOf('.') > 0) {
    if (value.substring(0, 1) === '0' && value.substring(1, 2) === '0') {
      return value.replace(/^(.*?)0/, '');
    }
  } else {
    if (
      value.substring(0, 1) === '0' &&
      value.substring(1, 2) !== '0' &&
      value.length >= 2
    ) {
      return value.replace(/^0+/, '');
    }
    if (value.substring(0, 1) === '0' && value.substring(1, 2) === '0') {
      return value.replace(/^0+/, 0);
    }
  }

  if (decimalCount > 1) {
    let parts = value.split('.');
    value = parts[0] + '.' + parts.slice(1).join('');
  }

  if (decimalCount > 0) {
    let parts = value.split('.');
    if (parts[1].length >= 8) {
      parts[1] = parts[1].slice(0, 8);
      value = parts[0] + '.' + parts.slice(1).join('');
    }
  } else {
    if (value.length >= 8) {
      value = value.slice(0, 8);
    }
  }

  return value;
};

const InputCursorPosition = (event, ref) => {
  if (Number(event.target.value) === 0) {
    setTimeout(() => {
      ref.current.setSelectionRange(1, 1);
    }, 0);
  }
};

export {
  CoinNumberFormat,
  PriceNumberFormat,
  AssetPriceNumberFormat,
  RoundDown,
  RoundUp,
  Round,
  CountDecimals,
  ThousandSeparator,
  ReplaceCommaToNumber,
  ReplaceCommaToInt,
  ReplaceStringToInt,
  CheckLeadingZero,
  ReplaceStringToNumber,
  InputCursorPosition,
};
