import { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { navigationBarHeight } from '../../components/Layout';
import { UserContext } from '../../UserContext';
import ResultTemplate from './components/ResultTemplate';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import EveButton from '../../components/EveButton';

export default function SignUpCompletedPage() {
  const alertMessage = useRef('');
  const [showAlert, setShowAlert] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { setLoading } = useContext(UserContext);

  const navigate = useNavigate();

  const handleRedirectToKyc = async () => {
    setShowAlert(false);
    setDisabledButton(true);
    setLoading(true);
    const response = await UserAPI.getKycUrl();
    setLoading(false);
    if (response?.error) {
      alertMessage.current = response?.error.message;
      setShowAlert(true);
    } else {
      window.location.replace(response.redirectUrl);
    }
  };

  return (
    <Box sx={{ pt: `${navigationBarHeight}px` }}>
      <Box>
        <ResultTemplate
          title="請開始進行身分驗證"
          desc={'您已完成會員註冊，請繼續完成身分驗證'}
          succeed={true}
        />
      </Box>

      <Stack
        width="100%"
        spacing={2}
        alignItems="center"
        sx={{
          position: 'fixed',
          bottom: 40,
          left: 0,
          right: 0,
          padding: '0 20px',
        }}>
        <EveButton
          variant="contained"
          sx={{ height: '48px', width: '100%' }}
          onClick={handleRedirectToKyc}
          disabled={disabledButton}>
          進行身分驗證
        </EveButton>
        <Box>
          <EveButton variant="text" onClick={() => navigate('/')}>
            先不要
          </EveButton>
        </Box>
      </Stack>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title="KYC 頁面轉導失敗"
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />
    </Box>
  );
}
