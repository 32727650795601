import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { React, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomPanel from '../../components/BottomPanel';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import { UserContext } from '../../UserContext';
// import { getBankName } from '../../utils/BankCode';
import { UserAPI } from '../../apis/UserAPI';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Modal from '../../components/Modal';
import CustomButton from '../../components/CustomButton';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

export default function BankWithdrawPage() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [disable, setDisable] = useState(true);
  const { user, setLoading } = useContext(UserContext);
  const { state } = useLocation();
  const { accounts } = state;
  const [availableBalance, setAvailableBalance] = useState(false);
  const inputAmount = useRef();
  const alertMessage = useRef('');
  const [availableWithdrawAmount, setAvailableWithdrawAmount] = useState('');
  const [message, setMessage] = useState('');
  const [value, setValue] = useState('');
  const [actualValue, setActualValue] = useState('');

  useEffect(() => {
    async function getAvailableWithdrawAmount() {
      setLoading(true);
      const response = await UserAPI.availableWithdrawAmount();
      setLoading(false);
      setAvailableWithdrawAmount(response);
    }
    getAvailableWithdrawAmount();
  }, [setLoading]);

  useEffect(() => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].currencyId === 'TWD') {
        setAvailableBalance(accounts[i].availableBalance);
      }
    }
  }, [accounts]);

  // useEffect(() => {
  //   const _getBankName = async () => {
  //     const _bankName = await getBankName(bankCode);
  //     setBankName(_bankName);
  //   };
  //   _getBankName();
  // }, [bankCode]);

  const handleBack = () => {
    navigate(-1);
  };

  function numberWithCommas(x) {
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const handleNext = async () => {
    let amount = parseInt(inputAmount.current.value.replace(/,/g, ''));
    if (amount > Math.min(availableBalance, 1000000)) {
      alertMessage.current =
        '您的提領金額超出可提領額度，請確認後再次申請提領。';
      setShowAlert(true);
      return;
    } else if (amount < 16) {
      alertMessage.current = '提領金額不得低於16元';
      setShowAlert(true);
      return;
    }
    navigate('/withdraw/bank/confirm', {
      state: {
        amount: amount,
        withdrawFee: availableWithdrawAmount?.withdrawFee,
        bankAccountNumber: user?.bankAccount?.accountNumber,
        bankName: user?.bankAccount?.simpleBankName,
      },
    });
  };

  const navigation = () => {
    return <NavigationBar title="提領" leftButtonHandler={handleBack} />;
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <CustomButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleNext}
          disabled={disable}>
          {message ? message : '下一步'}
        </CustomButton>
      </BottomPanel>
    );
  };

  const bankDepositCautions = () => {
    return (
      <Box marginTop="20px" marginBottom="90px">
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 14,
          }}>
          ※ 單筆提領限額{' '}
          <CoinNumberFormat
            value={
              availableWithdrawAmount &&
              availableWithdrawAmount.withdrawTransactionUpperBound.single
                .amount
            }
          />{' '}
          TWD。
          <br />
          ※ 提領依照銀行營業作業時間約 3 個工作天。
          <br />
          ※ 提領申請後，無法再做更改或取消。
          <br />※ 提領申請銀行端將收手續費{' '}
          {availableWithdrawAmount && availableWithdrawAmount?.withdrawFee}{' '}
          元，提領失敗仍須支付手續費。
        </Typography>
      </Box>
    );
  };

  const BankDepositInfo = () => {
    const handleAmountUpdate = (e) => {
      const amount = e.target.value.replace(/\D/g, '');
      const actualAmount =
        parseFloat(amount) - parseFloat(availableWithdrawAmount?.withdrawFee);
      setValue(numberWithCommas(amount));
      setActualValue(numberWithCommas(actualAmount.toString()));
      const digitalAmount = Number(amount); // empty string 轉數字會為
      if (digitalAmount === 0) {
        setMessage('下一步');
        setDisable(true);
      } else if (
        digitalAmount <
        availableWithdrawAmount.withdrawTransactionLowerBound.single.amount
      ) {
        setMessage(
          availableWithdrawAmount.withdrawTransactionLowerBound.single.message,
        );
        setDisable(true);
      } else if (
        digitalAmount >
        availableWithdrawAmount.withdrawTransactionUpperBound.affordable.amount
      ) {
        setMessage(
          availableWithdrawAmount.withdrawTransactionUpperBound.affordable
            .message,
        );
        setDisable(true);
      } else if (
        digitalAmount >
        availableWithdrawAmount.withdrawTransactionUpperBound.single.amount
      ) {
        setMessage(
          availableWithdrawAmount.withdrawTransactionUpperBound.single.message,
        );
        setDisable(true);
      } else if (
        digitalAmount >
        availableWithdrawAmount.withdrawTransactionUpperBound.daily.amount
      ) {
        setMessage(
          availableWithdrawAmount.withdrawTransactionUpperBound.daily.message,
        );
        setDisable(true);
      } else if (
        digitalAmount >
        availableWithdrawAmount.withdrawTransactionUpperBound.monthly.amount
      ) {
        setMessage(
          availableWithdrawAmount.withdrawTransactionUpperBound.monthly.message,
        );
        setDisable(true);
      } else {
        setMessage('下一步');
        setDisable(
          amount === '' ||
            amount.length < 0 ||
            parseInt(amount) === 0 ||
            parseInt(amount) < 16,
        );
      }
    };

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography
          sx={{
            marginBottom: '13px',
          }}>
          帳戶餘額{' '}
          <CoinNumberFormat
            value={availableBalance}
            prefix="NT$"></CoinNumberFormat>
        </Typography>
        <Box
          sx={{
            borderRadius: '8px 8px 0px 0px',
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          backgroundColor="#150F32"
          height="141px"
          alignItems="center"
          padding="16px"
          paddingTop="24px"
          paddingBottom="40px"
          marginBottom="-19px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 24,
              }}>
              NT$
            </Typography>
            <TextField
              sx={{
                backgroundColor: 'inherit',
                border: 'none',
                '&:hover fieldset': {
                  border: 'none',
                },

                '& .MuiInputBase-input': {
                  fontWeight: 500,
                  fontSize: '36px',
                  fontFamily: 'PingFang TC, sans-serif',
                  color: 'white',
                  caretColor: '#ffba0d',
                  padding: '9px 14px 9px 14px',
                },
              }}
              autoComplete="off"
              fullWidth
              id="amount"
              value={value}
              inputRef={inputAmount}
              onChange={handleAmountUpdate}
              inputProps={{
                inputMode: 'numeric',
                maxLength: 10,
              }}
            />
          </Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 12,
              mb: '10px',
            }}>
            預期實際入帳金額
            <CoinNumberFormat
              value={actualValue < 0 ? '' : actualValue}
              prefix="NT$"></CoinNumberFormat>
          </Typography>
        </Box>
        <IconButton
          sx={{
            color: 'white',
            backgroundColor: '#231A54',
            width: '40px',
            height: '40px',
            display: 'flex',
            borderRadius: '50%',
            zIndex: 1,
            padding: '-8px',
          }}>
          <ArrowDownwardIcon fontSize="50px" />
        </IconButton>
        <Box
          display="flex"
          justifyContent="center"
          gap="5px"
          width="100%"
          minheight="67px"
          alignContent="center"
          padding="16px"
          paddingBottom="26px"
          paddingTop="40px"
          marginTop="-19px"
          sx={{
            backgroundColor: '#150F32',
            borderRadius: '0px 0px 8px 8px',
          }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 20,
            }}>
            {user?.bankAccount?.simpleBankName} **
            {user?.bankAccount?.accountNumber.substr(
              user?.bankAccount?.accountNumber.length - 5,
            )}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 20,
              ml: '5px',
            }}>
            {'(' + user?.chineseName + ')'}
          </Typography>
        </Box>
      </Box>
    );
  };

  const warningModalButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => {
        setShowAlert(false);
      },
    },
  ];

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box padding="16px">
          {BankDepositInfo()}
          {bankDepositCautions()}
        </Box>
        {bottomPanel() /* 按鈕 */}
      </Box>

      {/* Warning modal */}
      <Modal
        title="提領金額錯誤"
        enabled={showAlert}
        buttons={warningModalButtons}
        buttonDirection="column">
        <Box
          sx={{
            paddingTop: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography>{alertMessage.current}</Typography>
        </Box>
      </Modal>
    </>
  );
}
