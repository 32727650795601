import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomPanel from '../../components/BottomPanel';
import Icon from '../../components/Icon';
import { Label, TitleLabel } from '../../components/Label';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import Panel from '../../components/Panel';
import StyledButton from '../../components/StyledButton';
import { UserContext } from '../../UserContext';
import { getBankBranchName, getBankName } from '../../utils/BankCode';
import ButtonContainer from '../../components/ButtonContainer';
import EvePaper from '../../components/EvePaper';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const CustomerServiceButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  height: '48px',
  borderRadius: '9px',
  border: '1px solid #828282',
  '&:focus': {
    color: '#000',
    backgroundColor: '#FFF',
  },
}));

const ListItem = styled('li')(({ theme }) => ({
  color: '#000',
  fontSize: '15px',
  fontWeight: 'regular',
}));

export default function BankStatusPage() {
  const { user, setTabIndex, setLoading, setNavTitle } =
    useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const status = user?.bankAccount?.status;
  const bankCode = user?.bankAccount?.bankCode;
  const bankBranchCode = user?.bankAccount?.branchNumber;
  const [bankName, setBankName] = useState('');
  const [bankBranchName, setBankBranchName] = useState('');

  useEffect(() => {
    // console.log('Redirected from:', state?.from);
    if (status === 'succeed') {
      const getBankBranchNames = async () => {
        setLoading(true);
        const _bankName = await getBankName(bankCode);
        const _bankBranchName = await getBankBranchName(
          bankCode,
          bankBranchCode,
        );
        setBankName(_bankName);
        setBankBranchName(_bankBranchName);
        setLoading(false);
      };
      getBankBranchNames();
    }
  }, [bankCode, bankBranchCode, setLoading, status, state]);

  const handleBack = () => {
    const pathsToBankAccount = ['/member', '/asset'];
    if (!pathsToBankAccount.includes(state?.from)) {
      setTabIndex(0);
      setNavTitle('OP加密資產存摺');
      navigate('/');
    } else {
      navigate(state?.from);
    }
  };

  const handleSetBankAccount = () => {
    navigate('/bankAccount', {
      state: { from: '/bankStatus', originFrom: state?.from },
    });
  };

  const handleCustomerService = () => {
    window.location.href = `mailto:${process.env.REACT_APP_CS_EMAIL}`;
  };

  const handleComplete = () => {
    // 需要盤一下，這一頁回上一頁的邏輯
    // if (backNavigation) {
    //     navigate(backNavigation);
    // } else {
    //     navigate(-1);
    // }
    setTabIndex(0);
    setNavTitle('OP加密資產存摺');
    navigate('/');
  };

  const navigation = () => {
    return (
      <NavigationBar
        title="銀行帳戶"
        leftButtonHandler={handleBack}
        //hideLeftButton={status === 'verifing'}
      >
        <Typography
          onClick={() => {
            navigate('/bankLimited');
          }}
          sx={{
            alignSelf: 'stretch',
            gap: '10px',
            color: 'white',
            whiteSpace: 'nowrap',
            textAlign: 'right',
            padding: '16px 9px',
            fontSize: 17,
          }}>
          銀行限額
        </Typography>
      </NavigationBar>
    );
  };

  const bankAccountVerifying = () => {
    return (
      <Stack
        sx={{ mt: '80px' }}
        spacing={2}
        direction={'column'}
        alignItems="center">
        <Box>
          <Icon src="images/img_account_link.svg" width={176} height={176} />
        </Box>
        <Box>
          <TitleLabel fontSize="24px">銀行帳號綁定審核中</TitleLabel>
        </Box>
        <Box width={'100%'} marginBottom="37px">
          <Label fontSize="15px" align="center">
            審核作業需約3個工作天
          </Label>
          <Label fontSize="15px" align="center">
            完成後將會透過電子郵件通知您
          </Label>
        </Box>
      </Stack>
    );
  };

  const bankAccountVerifyFail = () => {
    return (
      <Stack
        sx={{ mt: '40px' }}
        spacing={2}
        direction={'column'}
        alignItems="center">
        <Box>
          <Icon src="img_fail.png" width={122} height={122} />
        </Box>

        <Box>
          <TitleLabel>銀行帳號驗證失敗</TitleLabel>
        </Box>

        <Box width={'100%'} marginTop="10px" marginBottom="37px">
          <Label fontSize="15px" align="center">
            您好，因您提供的銀行帳號驗證失敗，請確認為本人使用的帳戶且帳戶資訊正確並重新提交，若有任何問題，請聯繫我們的客服人員，謝謝
          </Label>
        </Box>

        <Box />

        <Box width={'100%'} marginTop="10px">
          <StyledButton
            variant="contained"
            fullWidth
            disableElevation
            sx={{ height: '50px' }}
            onClick={handleSetBankAccount}>
            重新提交帳號
          </StyledButton>

          <CustomerServiceButton
            variant="contained"
            fullWidth
            disableElevation
            sx={{ height: '50px', marginTop: '20px' }}
            onClick={handleCustomerService}>
            聯繫客服
          </CustomerServiceButton>
        </Box>
      </Stack>
    );
  };

  const bankAccountVerifySuccess = () => {
    const items = [
      '用戶僅能用綁定的銀行帳號進行入金和提領的操作',
      '用戶同一時間僅能綁定一個銀行帳號',
      '若你欲更改已綁定的帳號，請聯繫客服申請換綁',
    ];

    const bankAccount = user?.bankAccount?.accountNumber;
    return (
      <>
        <EvePaper>
          <Box
            padding="8px"
            display="flex"
            alignItems="center"
            flexDirection="column">
            <Box
              sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                border: '1px solid white',
                padding: '2px 12px',
              }}>
              <Typography fontSize="16px">已完成驗證</Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ marginTop: '24px' }}>
              <Typography
                sx={{
                  fontSize: '22px',
                  fontWeight: 600,
                  letterSpacing: '0.6px',
                }}>
                {bankCode} {bankName}
              </Typography>
            </Box>

            <Typography
              sx={{
                fontSize: '18px',
                marginTop: '4px',
                letterSpacing: '0.6px',
              }}>
              {bankBranchCode} {bankBranchName}
            </Typography>

            <Typography
              sx={{
                marginTop: '12px',
                letterSpacing: '0.6px',
                fontSize: '26px',
                fontWeight: 600,
              }}>
              {bankAccount}
            </Typography>
          </Box>
        </EvePaper>

        <Box
          sx={{
            marginRight: '16px',
            marginTop: '31px',
          }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: '12px' }}>
            變更銀行帳戶請注意：
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>
            ※ 銀行帳號需與會員帳號為同一人。
            <br /> ※ 將於約定帳戶存入台幣1元進行驗證，審核約3個工作天完成。
            <br /> ※
            若開始進行新帳戶驗證階段，將無法進行儲值、提領、買賣幣，需驗證完成後才可繼續執行。
            <br /> ※
            若驗證失敗則須重新完成驗證才可繼續使用儲值、提領、買賣幣服務。
          </Typography>
        </Box>
      </>
    );
  };

  const renderByStatus = (status) => {
    switch (status) {
      case 'succeed':
        return bankAccountVerifySuccess();
      case 'failed':
        return bankAccountVerifyFail();
      case 'verifing':
        return bankAccountVerifying();
      default:
        return;
    }
  };

  const bottomPanel = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          flexDirection: 'column',
          textAlign: 'center',
          letterSpacing: '-0.4px',
          justifyContent: 'flex-start',
          padding: '8px 16px',
          marginBottom: '9px',
          gap: '10px',
          backgroundColor: 'rgba(35, 26, 84, 1)',
        }}>
        <ButtonContainer
          direction="column"
          buttons={[
            {
              label: '我知道了',
              variant: 'contained',
              onClick: handleComplete,
            },
          ]}></ButtonContainer>
      </Box>
    );
  };

  return (
    <Box sx={sxContainer}>
      {navigation() /* navigation bar */}

      <Box padding="16px">{renderByStatus(status)}</Box>

      {status === 'verifing' && bottomPanel()}
    </Box>
  );
}
