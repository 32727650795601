import { useContext } from 'react';
import { UserContext } from '../../UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserAPI } from '../../apis/UserAPI';
import { notifyLoginSuccess } from '../../utils/WebViewCallback';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import secureLocalStorage from 'react-secure-storage';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ImageIcon } from '../../components/Icon';
import EveBackground from '../../components/EveBackground';
import ProviderInformation from '../../components/ProviderInformation';
import { Box } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';

// ! 去除這頁的 layout 之後 logo 就會在中間了
export default function LoginPage() {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { search: queryUrl } = useLocation();
  const queryParams = new URLSearchParams(queryUrl);
  const csAppUserData = queryParams.get('data');

  // 給開發者可以進行帳號密碼登入所用的token
  const csappTokenParam = queryParams.get('csappToken');
  if (csappTokenParam) {
    secureLocalStorage.setItem('csappToken', csappTokenParam);
    navigate('/introduction', { replace: true });
  }

  // data 只能被只用一次，所以這裡確實要讓 useEffect 只執行一次
  useEffectOnce(() => {
    async function login(data) {
      const responseData = await UserAPI.getUserToken({ data });
      const { user, page } = responseData || {};

      if (user) {
        setUser(user);
        notifyLoginSuccess();
      }

      // isRegistered 會是 做完 emai OPT 和 csapp 有給 user 的身分證字號
      const isRegistered = user?.isRegistered || false;

      // 1. 有電話號碼但未註冊
      // 2. 有電話號碼，且拿到要導轉的頁面是 "/mobileDeviceVerification"
      if (
        user?.phoneNumber &&
        (page === '/mobileDeviceVerification' || !isRegistered)
      ) {
        //  代表 user 現在的號碼 => 可能與他過去註冊的電話號碼不同
        secureLocalStorage.setItem('phoneNumber', user.phoneNumber);
      }

      if (responseData) {
        if (isRegistered) {
          navigate(page || '/', { replace: true });
        } else {
          // 未註冊
          navigate('/introduction', { replace: true });
        }
      } else {
        navigate('/introduction', { replace: true });
      }
    }

    const initializeLogin = async () => {
      if (csAppUserData) {
        secureLocalStorage.setItem('csAppUserData', csAppUserData);
        await login(csAppUserData);
      } else {
        navigate('/introduction', { replace: true });
      }
      return;
    };

    const timer = setTimeout(initializeLogin, 3000);
    return () => clearTimeout(timer);
  });

  return (
    <>
      <EveBackground />
      <NavigationBar
        title=""
        leftButtonHandler={() => navigate('/')}
        noBackgroundColor={true}
      />
      <Stack
        alignItems="center"
        direction="column"
        height={'100vh'}
        width={'100vw'}
        position="relative">
        <ImageIcon
          src={'/icons/twexlogo.svg'}
          width={150}
          height={150}
          sx={{ marginTop: '20vh' }}
        />
        <Typography
          fontWeight={400}
          fontSize={19}
          position="absolute"
          bottom="100px"
          letterSpacing="3.6px">
          全台最可靠的交易所
        </Typography>
        <Box position="absolute" bottom="33px">
          <ProviderInformation />
        </Box>
      </Stack>
    </>
  );
}
