import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '15px',
  fontWeight: 'medium',
  textAlign: 'center',
}));

export default DescriptionLabel;
