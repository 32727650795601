import useUpdateUserStatus from './useUpdateUserStatus';

const emailVerifyStatus = {
  notSet: '/emailVerify',
  verifing: '/emailVerify',
};

const phoneVerifyStatus = {
  intraUnverified: '/phoneVerify',
  interUnverified: '/phoneVerify',
};

const redirectPathForKycStatus = {
  start: '/signUpCompleted',
  needFillOutEdd: '/Edd',
  waitingForProgress: '/kycVerifying',
  kycReverify: '/kycReverify',
  end: '/kycSucceed',
  amlDenied: '/kycFailed',
  amlBlocked: '/kycFailed',
};

const bankAccountStatus = {
  unverified: '/bankAccount',
  verifing: '/bankStatus',
  failed: '/bankStatus',
};

const useRedirectPath = (pathName, verifiedPaths) => {
  const { user } = useUpdateUserStatus(pathName, verifiedPaths);
  let redirectPath = pathName || '/';
  let state = { from: pathName };

  if (!user?.isRegistered) {
    console.log('1.未註冊');
    redirectPath = '/landing';
  } else if (
    user?.phoneVerifyStatus !== 'intraVerified' &&
    user?.phoneVerifyStatus !== 'interVerified'
  ) {
    console.log('1.1.未註冊完,phone otp 未驗證');
    redirectPath = '/phoneVerify';
  } else if (user?.emailVerifyStatus !== 'succeed') {
    console.log('1.1.未註冊完,email otp 未驗證');
    redirectPath = '/emailVerify';
  } else if (user?.freezeStatus === 'c') {
    console.log('2.凍結C');
    redirectPath = '/freezeReject';
  } else if (user?.freezeStatus === 'b') {
    console.log('3.凍結B');
    redirectPath = '/freezeReject';
  } else if (user?.freezeStatus === 'a') {
    console.log('4.凍結A');
    redirectPath = '/freezeReject';
  } else if (user?.amlStatus === 'blocked') {
    console.log('5.aml blocked');
    redirectPath = redirectPathForKycStatus.amlBlocked;
  } else if (user?.amlStatus === 'denied') {
    console.log('6.aml denined');
    redirectPath = redirectPathForKycStatus.amlDenied;
  } else if (
    user?.kycStatus === 'unverified' &&
    user?.amlStatus === 'succeed'
  ) {
    console.log('7.kyc fail && aml success==>重新審核');
    redirectPath = redirectPathForKycStatus.kycReverify;
  } else if (
    !user?.kycStatus ||
    !user?.amlStatus ||
    (user?.kycStatus === 'unverified' && user?.amlStatus === 'unverified')
  ) {
    console.log('8.kyc unverified && aml unverified==>未審核');
    redirectPath = redirectPathForKycStatus.start;
  } else if (['awaitingInput'].includes(user?.amlStatus)) {
    console.log('9.aml awaitingInput==>edd表單');
    redirectPath = redirectPathForKycStatus.needFillOutEdd;
  } else if (
    ['pendingReview', 'submmitted', 'verifing'].includes(user?.kycStatus) ||
    ['pendingReview', 'submmitted', 'verifing'].includes(user?.amlStatus)
  ) {
    console.log('10.kyc aml 審核中');
    redirectPath = redirectPathForKycStatus.waitingForProgress;
  } else if (
    !user.bankAccount ||
    !user.bankAccount?.status ||
    user.bankAccount.status !== 'succeed'
  ) {
    console.log('11.銀行帳號未驗證成功');
    if (!user.bankAccount?.status) {
      redirectPath = bankAccountStatus.unverified;
    } else {
      redirectPath = bankAccountStatus[user.bankAccount.status];
    }
    /*
  } else if (
    ['intraUnverified', 'interUnverified'].includes(user?.phoneVerifyStatus)
  ) {
    redirectPath = phoneVerifyStatus[user?.phoneVerifyStatus];
  } else if (user?.emailVerifyStatus !== 'succeed') {
    redirectPath = emailVerifyStatus[user?.emailVerifyStatus];
  */
  } else {
    // console.log('10');
    //redirectPath = '/KycFailed'; // 這裏應該改成權限不足要轉到告知用戶原因的頁面，EX. aml denied 後，開放資產頁，但不可儲值，但如果點擊儲值，應該怎麼做？簡單來說就是開放頁面，但不開放服務的情形
    redirectPath = '/';
    console.log('all success');
  }
  console.log('useRedirectPath[redirectPath]=>' + redirectPath);
  //console.log("useRedirectPath[state]=>"+state)
  return { redirectPath, state };
};

export { useRedirectPath };
