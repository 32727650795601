import { useMemo, useState, useContext } from 'react';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto';
import secureLocalStorage from 'react-secure-storage';
import { UserContext } from './UserContext';
import { useRedirectPath } from './hooks/useRedirectPath';
import Layout from './components/Layout';
import MarketPage from './pages/market/MarketPage';
import AssetPage from './pages/asset/AssetPage';
import MemberPage from './pages/member/MemberPage';
import FAQPage from './pages/member/FAQPage';
import CoinPricePage from './pages/market/CoinPricePage';
import BuyPage from './pages/trade/BuyPage';
import SellPage from './pages/trade/SellPage';
import OrderPaymentPage from './pages/trade/OrderPaymentPage';
import SignUpPage from './pages/auth/SignUpPage';
import AssetHistoryPage from './pages/asset/AssetHistoryPage';
import Loading from './components/Loading';
import EmailVerifyPage from './pages/account/EmailVerifyPage';
import KycVerifyingPage from './pages/account/KycVerifyingPage';
import KycFailedPage from './pages/account/KycFailedPage';
import InvoiceSettingPage from './pages/account/InvoiceSettingPage';
import TradeDonePage from './pages/trade/TradeDonePage';
import OrderInfoPage from './pages/trade/OrderInfoPage';
import PrivacyPage from './pages/terms/PrivacyPage';
import UserTermsPage from './pages/terms/UserTermsPage';
import DisclaimerPage from './pages/terms/DisclaimerPage';
import LoginPage from './pages/auth/LoginPage';
import MobileDeviceVerificationPage from './pages/auth/MobileDeviceVerificationPage';
import ChangeEmailPage from './pages/account/ChangeEmailPage';
import BankDepositPage from './pages/trade/BankDepositPage';
import StoreDepositPage from './pages/trade/StoreDepositPage';
import InvoiceInfoPage from './pages/trade/InvoiceInfoPage';
import BankAccountPage from './pages/account/BankAccountPage';
import BankMenuPage from './pages/account/BankMenuPage';
import BankWithdrawPage from './pages/trade/BankWithdrawPage';
import BankWithdrawConfirmPage from './pages/trade/BankWithdrawConfirmPage';
import BankWithdrawDonePage from './pages/trade/BankWithdrawDonePage';
import BankStatusPage from './pages/account/BankStatusPage';
import ChangePhoneBarcodePage from './pages/account/ChangePhoneBarcodePage';
import ChangeCitizenDigitalPage from './pages/account/ChangeCitizenDigitalPage';
import ChangeAccountInfoPage from './pages/account/ChangeAccountInfoPage';
import ChangeDoneeCodePage from './pages/account/ChangeDoneeCodePage';
import EipEmailPage from './pages/account/EipEmailPage';
import EipEmailVerifyPage from './pages/account/EipEmailVerifyPage';
import DepositDonePage from './pages/trade/DepositDonePage';
import LandingPage from './pages/account/LandingPage';
import SignInPage from './pages/auth/SignInPage';
import PhoneVerifyPage from './pages/account/PhoneVerifyPage';
import SignUpCompletedPage from './pages/account/SignUpCompletedPage';
import KycSucceedPage from './pages/account/KycSucceedPage';
import ChangePhoneNumberPage from './pages/changePhoneNumber/ChangePhoneNumberPage';
import ChangePhoneNumberSucceedPage from './pages/account/ChangePhoneNumberSucceedPage';
import KycLoadingPage from './pages/account/KycLoadingPage';
import EddPage from './pages/account/EddPage';
import ScrollToTop from './components/ScrollToTop';
import KycReverifyPage from './pages/account/KycReverifyPage';
import ResetPasswordPhoneNumberPage from './pages/account/ResetPasswordPhoneNumberPage';
import ResetPasswordIdentityPage from './pages/account/ResetPasswordIdentityPage';
import ResetPasswordOtpPage from './pages/account/ResetPasswordOtpPage';
import ResetPasswordPage from './pages/account/ResetPasswordPage';
import AccountAssetPage from './pages/asset/AccountAssetPage';
import WithdrawInfo from './pages/trade/WithdrawInfo';
import MarketAllPage from './pages/market/MarketAllPage';
import Indroduction from './pages/auth/Indroduction';
import BankLimitedPage from './pages/asset/BankLimitedPage';
import PhoneNumberPage from './pages/account/PhoneNumberPage';
import IdentificationNumberConfirm from './pages/changePhoneNumber/IdentificationNumberConfirmPage';
import PasswordConfirm from './pages/changePhoneNumber/PasswordConfirm';
import NewPhoneConfirmPage from './pages/changePhoneNumber/NewPhoneConfirmPage';
import EmailPage from './pages/account/EmailPage';
import FreezeRejectPage from './pages/account/FreezeRejectPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#150f32',
    },
    secondary: {
      main: '#6C757D',
    },
    background: {
      default: '#231A54',
      secondary: '#2E255D',
      tertiary: '#150F32',
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
      black: '#000000',
      neutral: '#4A4A4A',
      orange: '#FF6700',
      gray: '#999999',
      purple: '#CCC3F8',
      red: '#FBA28B',
    },
    info: {
      main: '#FFFFFF',
    },
    common: {
      white: '#FFFFFF',
      lightPurple: '#CCC4F9',
      purple: '#8A7ADE',
      darkBlue: '#8571EF',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      inputBackground: '#150F32',
    },
  },
  typography: {
    fontFamily: 'roboto',
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 900,
  },
});

// TODO: 因為頁面命名有更動，所以未來需要對 router 名稱進行修改

const routes = [
  { path: '/landing', element: <LandingPage /> },
  { path: '/signIn', element: <SignInPage /> },
  { path: '/signUp', element: <SignUpPage /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/', element: <MarketPage /> },
  { path: '/asset', element: <AssetPage /> },
  { path: '/member', element: <MemberPage /> },
  { path: '/faq', element: <FAQPage /> },
  { path: '/market/:productId', element: <CoinPricePage /> },
  { path: '/markets', element: <MarketAllPage /> },
  { path: '/buy/:coin', element: <BuyPage /> },
  { path: '/sell/:coin', element: <SellPage /> },
  { path: '/emailVerify', element: <EmailVerifyPage /> },
  { path: '/eipEmail', element: <EipEmailPage /> },
  { path: '/eipEmailVerify', element: <EipEmailVerifyPage /> },
  { path: '/email', element: <EmailPage /> },
  { path: '/changeEmail', element: <ChangeEmailPage /> },
  { path: '/changeInvoice', element: <InvoiceSettingPage /> },
  { path: '/changePhoneBarcode', element: <ChangePhoneBarcodePage /> },
  { path: '/changeCitizenDigital', element: <ChangeCitizenDigitalPage /> },
  { path: '/changeAccountInfo', element: <ChangeAccountInfoPage /> },
  { path: '/changeDoneeCode', element: <ChangeDoneeCodePage /> },
  { path: '/kycVerifying', element: <KycVerifyingPage /> },
  { path: '/kycFailed', element: <KycFailedPage /> },
  { path: '/freezeReject', element: <FreezeRejectPage /> },
  { path: '/kycReverify', element: <KycReverifyPage /> },
  { path: '/assetHistory/:defaultFilter', element: <AssetHistoryPage /> },
  { path: '/orderPayment', element: <OrderPaymentPage /> },
  { path: '/tradeDone', element: <TradeDonePage /> },
  { path: '/orderInfo', element: <OrderInfoPage /> },
  { path: '/invoiceInfo', element: <InvoiceInfoPage /> },
  { path: '/privacy', element: <PrivacyPage /> },
  { path: '/terms', element: <UserTermsPage /> },
  { path: '/disclaimer', element: <DisclaimerPage /> },
  { path: '/deposit/bank', element: <BankDepositPage /> },
  { path: '/deposit/store', element: <StoreDepositPage /> },
  { path: '/deposit/done', element: <DepositDonePage /> },
  { path: '/bankAccount', element: <BankAccountPage /> },
  { path: '/bankMenu', element: <BankMenuPage /> },
  { path: '/bankStatus', element: <BankStatusPage /> },
  { path: '/withdraw/bank', element: <BankWithdrawPage /> },
  { path: '/withdraw/bank/confirm', element: <BankWithdrawConfirmPage /> },
  { path: '/withdraw/bank/done', element: <BankWithdrawDonePage /> },
  { path: '/phoneVerify', element: <PhoneVerifyPage /> },
  { path: '/signUpCompleted', element: <SignUpCompletedPage /> },
  { path: '/changePhoneNumber', element: <ChangePhoneNumberPage /> },
  {
    path: '/changePhoneNumberSucceed',
    element: <ChangePhoneNumberSucceedPage />,
  },
  { path: '/kycSucceed', element: <KycSucceedPage /> },
  { path: '/kycLoading', element: <KycLoadingPage /> },
  { path: '/edd', element: <EddPage /> },
  { path: '/resetPassword', element: <ResetPasswordPage /> },
  {
    path: '/resetPassword/phoneNumber',
    element: <ResetPasswordPhoneNumberPage />,
  },
  { path: '/resetPassword/identity', element: <ResetPasswordIdentityPage /> },
  { path: '/resetPassword/otp', element: <ResetPasswordOtpPage /> },
  { path: '*', element: <Navigate to="/" /> },
  { path: '/accountAsset/:currencyId', element: <AccountAssetPage /> },
  { path: '/withdraw/bank/info', element: <WithdrawInfo /> },
  {
    path: '/mobileDeviceVerification',
    element: <MobileDeviceVerificationPage />,
  },
  {
    path: '/introduction',
    element: <Indroduction />,
  },
  {
    path: '/bankLimited',
    element: <BankLimitedPage />,
  },
  {
    path: '/phoneNumber',
    element: <PhoneNumberPage />,
  },
  {
    path: '/identificationNumberConfirm',
    element: <IdentificationNumberConfirm />,
  },
  {
    path: '/passwordConfirm',
    element: <PasswordConfirm />,
  },
  { path: '/newPhoneConfirm', element: <NewPhoneConfirmPage /> },
];

const CheckPath = ({ pathName, children }) => {
  // 頁面導頁邏輯參考: https://www.figma.com/board/gEL78OVVw5DBEUNKENaH3Q/EVE-%E6%9C%83%E5%93%A1%E8%A8%BB%E5%86%8A%E7%99%BB%E5%85%A5%E5%8F%8A%E8%BA%AB%E5%88%86%E9%A9%97%E8%AD%89%E8%A6%8F%E5%8A%83%E6%96%87%E4%BB%B6?node-id=457-4111&t=Cx2nKWRsaqzRZlW4-0
  // 完全沒有驗證的人可以看的頁面
  let verifiedPaths = [
    '/login',
    '/introduction', // 未註冊登入介紹頁
    '/', // 首頁(有幣價和簡易資產說明)
    '/landing', // 輸入門號可以導向進行登入或註冊

    '/signIn', // 登入密碼輸入頁
    '/signUp', // 會員註冊頁

    /*註冊相關*/
    '/phoneVerify', // 手機號碼驗證頁(註冊)
    //設定登入密碼(註冊)
    //輸入email(註冊)
    '/emailVerify', // 信箱驗證頁(註冊)

    '/resetPassword/phoneNumber', // 忘記密碼: 輸入手機號碼
    '/resetPassword/otp', // 忘記密碼: 簡訊OTP驗證
    '/resetPassword/identity', // 忘記密碼: 輸入身分證字號
    '/resetPassword', // 忘記密碼: 重設密碼

    '/bankLimited',
    '/kycLoading', //kyc call back
    '/faq', //幫助中心
    '/terms', //使用者條款
    '/privacy', //隱私權政策
    '/disclaimer', //免責聲明
  ];

  console.log('CheckPath[pathName]=>' + pathName);
  const { user } = useContext(UserContext);
  const { redirectPath, state } = useRedirectPath(pathName, verifiedPaths);
  const {
    isRegistered,
    phoneVerifyStatus,
    emailVerifyStatus,
    kycStatus,
    amlStatus,
    bankAccount,
    freezeStatus,
  } = user || {};
  const bankAccountStatus = bankAccount?.status;
  const csappToken = secureLocalStorage.getItem('csappToken');
  //sprint2 todo 沒有csappToken要導到CSAPP
  if (!csappToken && (pathName === '/signIn' || pathName === '/signUp')) {
    return <Navigate to="/" />;
  }

  //被凍結無法登入
  if (freezeStatus === 'c') {
    if (pathName !== redirectPath) {
      if (pathName === '/landing') {
        return children;
      } else {
        return <Navigate to={redirectPath} state={state} />;
      }
    } else {
      return children;
    }
  }

  if (freezeStatus) {
    verifiedPaths = [
      ...verifiedPaths,
      '/markets', // 加密貨幣幣價頁
      '/market/:productId', // 加密貨幣幣價頁
      '/asset', //我的資產
    ];
  }

  if (!freezeStatus && isRegistered) {
    verifiedPaths = [
      ...verifiedPaths,

      '/kycSucceed', //kyc 成功頁
      '/edd', //edd
      '/kycVerifying', // KYC 驗證等待頁
      '/kycFailed', // KYC驗證失敗頁
      '/kycReverify',

      '/mobileDeviceVerification', //自動登入門號比對不符'
      '/identificationNumberConfirm', //輸入身分證(換選號)
      '/passwordConfirm', //輸入登入密碼(換選號)
      '/newPhoneConfirm',
      '/phoneVerify', //門號OTP(換選號)
    ];

    if (kycStatus === 'unverified' && amlStatus === 'unverified') {
      verifiedPaths = [
        ...verifiedPaths,
        '/signUpCompleted', //註冊完成頁
      ];
    }
  }

  if (
    !freezeStatus &&
    amlStatus !== 'unverified' &&
    amlStatus !== 'denied' &&
    amlStatus !== 'blocked'
  ) {
    if (
      !bankAccountStatus ||
      bankAccountStatus === 'unverified' ||
      bankAccountStatus === 'failed'
    ) {
      verifiedPaths = [
        ...verifiedPaths,
        '/bankAccount', //銀行綁定頁
        '/bankStatus', //銀行狀態頁
        '/bankLimited',
      ];
    } else {
      verifiedPaths = [
        ...verifiedPaths,
        '/bankStatus', //銀行狀態頁
        '/bankLimited',
      ];
    }
  }

  if (!freezeStatus && amlStatus !== 'blocked') {
    verifiedPaths = [
      ...verifiedPaths,
      '/market/:productId', // 加密貨幣幣價頁
    ];
  }

  if (!freezeStatus && kycStatus === 'succeed' && amlStatus === 'succeed') {
    verifiedPaths = [
      ...verifiedPaths,
      '/asset', //我的資產
    ];
  }

  if (verifiedPaths.includes('/asset')) {
    verifiedPaths = [
      ...verifiedPaths,
      '/member',
      '/assetHistory/:defaultFilter',
      '/accountAsset/:currencyId',
    ];

    if (freezeStatus === 'b') {
      //增加閱讀性保留此判斷
    } else if (freezeStatus === 'a') {
      if (bankAccountStatus === 'succeed') {
        verifiedPaths = [
          ...verifiedPaths,
          '/withdraw/bank', //提領
          '/sell/:coin', //賣幣
        ];
      }
    } else if (!freezeStatus && bankAccountStatus === 'succeed') {
      verifiedPaths = [
        ...verifiedPaths,
        '/deposit/bank', //儲值
        '/withdraw/bank', //提領
        '/buy/:coin', //買幣
        '/sell/:coin', //賣幣
      ];
    }
  }

  if (verifiedPaths.includes('/member')) {
    verifiedPaths = [
      ...verifiedPaths,
      '/phoneNumber', //(變更手機號碼)
      '/email', //變更mail
      '/bankAccount', //銀行綁定頁(變更銀行帳號)
      '/bankStatus', //銀行狀態頁(變更銀行帳號)
      '/bankLimited', //(變更銀行帳號)
      '/changeInvoice', //發票設定
      '/faq', //幫助中心
      '/terms', //使用者條款
      '/privacy', //隱私權政策
      '/disclaimer', //免責聲明
    ];
  }

  if (verifiedPaths.includes('/bankAccount')) {
    verifiedPaths = [
      ...verifiedPaths,
      '/bankMenu', //銀行選單頁
    ];
  }

  if (verifiedPaths.includes('/assetHistory/:defaultFilter')) {
    verifiedPaths = [
      ...verifiedPaths,
      '/orderInfo', //訂單詳情頁
      '/deposit/done', //儲值詳情頁
      '/withdraw/bank/info', //提領詳情頁
    ];
  }

  if (
    verifiedPaths.includes('/sell/:coin') ||
    verifiedPaths.includes('/buy/:coin')
  ) {
    verifiedPaths = [
      ...verifiedPaths,
      '/orderPayment', //訂單付款頁
      '/tradeDone', //交易結果
    ];
  }

  if (verifiedPaths.includes('/email')) {
    verifiedPaths = [
      ...verifiedPaths,
      '/changeEmail', //輸入變更email頁面
      '/emailVerify', //email輸入驗證碼
    ];
  }

  if (verifiedPaths.includes('/withdraw/bank')) {
    verifiedPaths = [
      ...verifiedPaths,
      '/withdraw/bank/confirm', //訂單付款頁
      '/withdraw/bank/done', //交易結果
    ];
  }

  const isPathVerified = verifiedPaths.includes(pathName);

  // 確認這個人是否完成所有認證(完成就跳過所有轉導，讓使用者去任何想去的頁面)
  const isIdentityVerified =
    isRegistered &&
    !freezeStatus &&
    (phoneVerifyStatus === 'intraVerified' ||
      phoneVerifyStatus === 'interVerified') &&
    kycStatus === 'succeed' &&
    amlStatus === 'succeed' &&
    bankAccountStatus === 'succeed';

  if (isPathVerified || isIdentityVerified) {
    return children;
  } else {
    if (pathName !== redirectPath) {
      return <Navigate to={redirectPath} state={state} />;
    } else {
      return children;
    }
  }
};

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route) => {
        const { path, element } = route;
        return (
          <Route
            key={path}
            path={path}
            element={<CheckPath pathName={path}>{element}</CheckPath>}
          />
        );
      })}
    </Routes>
  );
};

function App() {
  const [navTitle, setNavTitle] = useState('OP加密資產存摺');
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [loginPhone, setLoginPhone] = useState('');
  const value = useMemo(
    () => ({
      navTitle,
      setNavTitle,
      loading,
      setLoading,
      tabIndex,
      setTabIndex,
      user,
      setUser,
      loginPhone,
      setLoginPhone,
    }),
    [navTitle, tabIndex, loading, user, loginPhone],
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <UserContext.Provider value={value}>
          <CssBaseline />
          <Layout>
            <AppRoutes />
          </Layout>
          {/* Loading */}
          <Loading enabled={loading} />
        </UserContext.Provider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
