import Box from '@mui/material/Box';
import CustomButton from './CustomButton';

const ButtonContainer = ({ direction, buttons }) => {
  //buttons is a list of button. Example:
  // const buttons = [
  //   { variant: 'outlined', label: 'Button', onClick: submit },
  //   { variant: 'contained', label: 'Button', onClick: setEnable },
  // ];
  if (direction === 'column') {
    return (
      <Box
        display="flex"
        flexDirection="column"
        overflow="hidden"
        gap="11px"
        width="100%">
        {buttons.map((button, index) => (
          <CustomButton
            key={index}
            variant={button.variant}
            onClick={button.onClick}>
            {button.label}
          </CustomButton>
        ))}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '11px',
          width: '100%',
        }}>
        {buttons.map((button, index) => (
          <CustomButton
            key={index}
            variant={button.variant}
            onClick={button.onClick}>
            {button.label}
          </CustomButton>
        ))}
      </Box>
    );
  }
};

export default ButtonContainer;
