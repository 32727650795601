import React from 'react';
import Box from '@mui/material/Box';
import Icon from './Icon';

function CustomDropDown({ children, ...props }) {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: (theme) => theme.palette.secondary.main,
        whiteSpace: 'nowrap',
        fontFamily: 'PingFang TC, sans-serif',
        fontWeight: 400,
        fontSize: '16px',
      }}>
      <Box
        sx={{
          borderRadius: '8px',
          backgroundColor: (theme) => theme.palette.primary.main,
          display: 'flex',
          width: '100%',
          gap: '20px',
          justifyContent: 'space-between',
          padding: '13px 16px',
        }}>
        {children}
        <Icon
          src="/icons/ic_drop_dowm_sm.svg"
          alt=""
          color="white"
          sx={{
            width: '18px',
            alignSelf: 'center',
            transform: 'rotate(90deg)',
          }}
        />
      </Box>
    </Box>
  );
}

export default CustomDropDown;
