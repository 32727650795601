import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { SvgIcon } from '../../Icon';

export default function Hero({ imageSrc, imageDest, content }) {
  return (
    <Stack spacing={2} borderBottom={1} borderColor="#D9D9D9" padding={2}>
      <Stack
        direction="row"
        spacing={4}
        alignItems="center"
        justifyContent="center">
        <SvgIcon src={imageSrc} width={48} height={48} />
        <SvgIcon src={'/icons/arrow_path.svg'} width={28} height={28} />
        <SvgIcon src={imageDest} width={48} height={48} />
      </Stack>
      <Typography
        fontWeight={400}
        fontSize={18}
        letterSpacing={-0.4}
        textAlign="center">
        {content}
      </Typography>
    </Stack>
  );
}
