import { UserAPI } from '../apis/UserAPI';

const getBankName = async (bankCode) => {
  const bankList = await UserAPI.getBankList(bankCode);
  const bankName = await bankList?.find((item) => item.bankCode === bankCode);
  return bankName?.name;
};

const getBankBranchName = async (bankCode, bankBranchCode) => {
  const branchList = await UserAPI.getBankBranchList(bankCode);
  const branchName = await branchList?.find(
    (item) => item.branchCode === bankBranchCode,
  );
  return branchName?.name;
};

export { getBankName, getBankBranchName };
