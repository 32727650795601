import React from 'react';
import Box from '@mui/material/Box';
import { Label } from '../Label';
import Icon from '../Icon';

const BankAccountNotification = () => {
  return (
    <Box
      sx={{
        letterSpacing: '-0.4px',
        fontFamily: 'PingFang TC, sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        width: '100%',
        marginBottom: '16px',
        borderRadius: '15px',
        backgroundColor: '#443C6E',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 16px 20px 16px',
        border: '1px solid rgba(255, 255, 255, 0.4)',
      }}>
      <Box display="flex" gap="10px">
        <Icon
          src="/icons/ic_alert_sm.svg"
          alt=""
          sx={{
            width: '18px',
          }}
        />
        <Label>完成銀行帳戶認證才能開始進行交易！</Label>
      </Box>
      <Icon
        src="/icons/ic_drop_dowm_sm.svg"
        alt=""
        style={{ fontSize: 25 }}
        sx={{
          color: 'fff',
        }}
      />
    </Box>
  );
};

export default BankAccountNotification;
