import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Label, SubTitleLabel } from '../Label.js';
import Icon from '../Icon.js';
import { PriceNumberFormat } from '../../utils/CoinNumberFormat.js';

const sxGreen = {
  color: '#22DDB9',
};

const sxRed = {
  color: '#FF5656',
};

const sxWhite = {
  color: 'white',
};

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const CryptoInfoItem = ({ product, realtimePrices, onClick }) => {
  const [price, setPrice] = useState(0);
  const [fluctuation, setFluctuation] = useState(0);

  useEffect(() => {
    if (realtimePrices) {
      const priceData = realtimePrices[product.id]['balance'];
      const fluctuation = priceData.fluctuation.toString();
      setTimeout(function () {
        setPrice(priceData.buy_price);
        if (fluctuation[2] === '.')
          setFluctuation(parseFloat(priceData.fluctuation).toFixed(2));
      }, randomIntFromInterval(0, 8000));
    } else if (product) {
      const priceData = product.realtimePrice.balance;
      setPrice(priceData.buyPrice);
      setFluctuation(parseFloat(priceData.fluctuation).toFixed(2));
    }
  }, [product, realtimePrices]);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
      onClick={onClick}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap="10px"
        ml={1}>
        <Icon
          src={`/icons/${product.baseCurrency.id}.svg`}
          alt={`${product.baseCurrency.name} icon`}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between">
          <Typography fontSize="16px">
            {product.baseCurrency.chineseName
              ? `${product.baseCurrency.chineseName} / ${product.quoteCurrency.id}`
              : product.name}
          </Typography>
          <SubTitleLabel>{product.baseCurrency.name}</SubTitleLabel>
        </Box>
      </Box>
      <Box ml={1} textAlign="right">
        <Typography variant="body1" component="div">
          $<PriceNumberFormat value={price} />
        </Typography>
        <Typography
          sx={fluctuation > 0 ? sxRed : fluctuation < 0 ? sxGreen : sxWhite}>
          {fluctuation > 0 ? '+' + fluctuation : fluctuation}%
        </Typography>
      </Box>
    </Box>
  );
};

export default CryptoInfoItem;
