import NavigationBar from '../../components/NavigationBar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Box from '@mui/material/Box';
import EveButton from '../../components/EveButton';
import Modal from '../../components/Modal';

// TODO: 待有 API 時需要補上這頁按下一步送出新電話號碼的 Error message 的 component

const validatePhoneNumber = (phoneNumber) => {
  if (!/^[0-9]+$/.test(phoneNumber)) return '電話號碼需為全數字';
  if (!phoneNumber.startsWith('09')) return '電話號碼需為 09 開頭';
  if (phoneNumber.length !== 10) return '電話號碼長度不符合規定';
  return '';
};

export default function NewPhoneConfirmPage() {
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [showServerAlert, setShowServerAlert] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const phoneNumber = e.target.value;
    setNewPhoneNumber(phoneNumber);
    setValidationMessage(validatePhoneNumber(phoneNumber));
  };

  // 服務器端驗證 alert 的按鈕配置
  const serverAlertButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setShowServerAlert(false),
    },
  ];

  // 處理表單提交
  const handleSubmit = async () => {
    try {
      // TODO: 這裡加入 API 呼叫
      // const response = await api.updatePhoneNumber(newPhoneNumber);
      navigate('/phoneVerifyPage', {
        state: { entry: '/newPhoneConfirm' },
      });
    } catch (error) {
      setShowServerAlert(true);
    }
  };

  return (
    <>
      <NavigationBar
        title="變更手機號碼"
        leftButtonHandler={() => navigate(-1)}
      />
      <Stack padding="72px 16px" spacing={2}>
        <Stack spacing={1}>
          <Typography fontWeight={400} fontSize={26} letterSpacing={-0.4}>
            請輸入新手機號碼
          </Typography>
        </Stack>
        <TextField
          autoFocus
          inputProps={{
            inputMode: 'numeric',
            maxLength: 10,
          }}
          placeholder="請輸入新手機號碼"
          fullWidth
          variant="outlined"
          size="small"
          sx={{
            borderRadius: '8px',
            backgroundColor: (theme) => theme.palette.common.inputBackground,
            '& .MuiInputBase-input.Mui-disabled': {
              color: (theme) => theme.palette.text.primary,
              WebkitTextFillColor: (theme) => theme.palette.text.primary, // this is needed to override WebKit browsers default behavior
            },
            '& .MuiInputBase-input': {
              height: '56px',
              padding: '0 14px',
            },
          }}
          value={newPhoneNumber}
          onChange={handleChange}
        />
        <Typography fontSize="12px" color="text.red">
          {validationMessage}
        </Typography>
      </Stack>

      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          left: 16,
          right: 16,
        }}>
        <EveButton
          disabled={newPhoneNumber.trim() === '' || !!validationMessage}
          fullWidth
          sx={{ color: '#FFFFFF' }}
          onClick={handleSubmit}>
          送出
        </EveButton>
      </Box>

      {/* 服務器端驗證的 Modal */}
      <Modal
        title="驗證錯誤"
        enabled={showServerAlert}
        buttons={serverAlertButtons}
        buttonDirection="column">
        <Typography
          fontWeight={400}
          fontSize={16}
          textAlign="center"
          color="text.secondary">
          {/* // TODO: 之後要替換成 API 回傳的 error message */}
          嘿嘿嘿嘿嘿嘿
        </Typography>
      </Modal>
    </>
  );
}
