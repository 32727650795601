import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { React, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserAPI } from '../../apis/UserAPI';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import { UserContext } from '../../UserContext';
import ButtonContainer from '../../components/ButtonContainer';
import Modal from '../../components/Modal';
import CustomDropDown from '../../components/CustomDropDown';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const ListItem = styled('li')(({ theme }) => ({
  color: '#000',
  fontSize: '15px',
  fontWeight: 'regular',
}));

const InputBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFF',
  width: '100%',
  height: '56px',
  borderRadius: '8px',
}));

const txtInputProps = {
  disableUnderline: true,
  style: {
    fontSize: '15px',
  },
};

const sxTextField = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: 'white',
  },
  '& .MuiInputBase-input.Mui-disabled::placeholder': {
    WebkitTextFillColor: (theme) => theme.palette.secondary.main,
  },
};

export default function BankAccountPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, setUser, setLoading, setTabIndex, setNavTitle } =
    useContext(UserContext);
  const bankCode = useRef(''); //user?.bankAccount?.bankCode;
  const bankBranchCode = useRef(''); //user?.bankAccount?.branchNumber;
  const accountNumber = user?.bankAccount?.accountNumber;
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [fetchBankName, setFetchBankName] = useState('');
  const bankName = useRef(''); //user.bankAccount?.bankName;
  const bankBranchName = useRef(''); //user.bankAccount?.bankBranchName;
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const alertActionLabel = useRef(null);
  const accountCompleted = useRef(false);
  const tBankAccount = useRef(null);
  const originFrom = useRef(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (state?.superfrom === 'bankMenu') {
      tBankAccount.current = state?.tBankAccount;
      console.log('[return]bankCode:' + JSON.stringify(tBankAccount.current));
      setUser({
        ...user,
        bankAccount: {
          ...user.tBankAccount,
          bankCode: tBankAccount.current?.bankCode,
          branchNumber: tBankAccount.current?.branchNumber,
        },
      });
      originFrom.current = state?.originFrom;
    } else {
      tBankAccount.current = user?.bankAccount;
      originFrom.current = state?.from;
    }
    bankCode.current = tBankAccount.current?.bankCode
      ? tBankAccount.current?.bankCode
      : '';
    bankName.current = tBankAccount.current?.bankName
      ? tBankAccount.current?.bankName
      : '';
    bankBranchCode.current = tBankAccount.current?.branchNumber
      ? tBankAccount.current?.branchNumber
      : '';
    bankBranchName.current = tBankAccount.current?.bankBranchName
      ? tBankAccount.current?.bankBranchName
      : '';
  }, []);

  useEffect(() => {
    if (state?.superfrom !== 'bankMenu') {
      (async function () {
        const getBankLists = await UserAPI.getBankList();
        bankName.current = getBankLists.find(
          (bank) => bank.bankCode === user.bankAccount.bankCode,
        ).name;
        tBankAccount.current.bankName = bankName.current;
        setFetchBankName(bankName.current);
      })();
      (async function () {
        const getBankBranchLists = await UserAPI.getBankBranchList(
          user.bankAccount.bankCode,
        );

        bankBranchName.current = getBankBranchLists.find(
          (bankBranch) =>
            bankBranch.branchCode === user.bankAccount.branchNumber,
        ).name;
        tBankAccount.current.bankBranchName = bankBranchName.current;
        setFetchBankName(bankBranchName.current);
      })();
      originFrom.current = state?.originFrom;
    }
  }, []);

  const handleBack = () => {
    const pathsToBankAccount = [
      '/member',
      '/kycSucceed',
      '/bankStatus',
      '/asset',
    ];
    if (!pathsToBankAccount.includes(originFrom.current)) {
      setTabIndex(0);
      setNavTitle('OP加密資產存摺');
      navigate('/');
    } else {
      navigate(originFrom.current);
    }
  };

  const handleClickSelectBank = () => {
    navigate('/bankMenu', {
      state: {
        selected: bankCode.current,
        superfrom: 'bankAccount',
        tBankAccount: tBankAccount.current,
        originFrom: originFrom.current,
      },
    });
  };

  const handleClickSelectBankBranch = () => {
    if (!bankCode.current) {
      alertTitle.current = '設定銀行帳號';
      alertMessage.current = '請選擇銀行代碼';
      alertActionLabel.current = '確定';
      accountCompleted.current = false;
      setShowValidationModal(true);
      return;
    }

    let code = null;
    if (bankCode.current && bankBranchCode.current) {
      code = bankBranchCode.current;
    }
    navigate('/bankMenu', {
      state: {
        bankCode: bankCode.current,
        selected: code,
        superfrom: 'bankAccount',
        tBankAccount: tBankAccount.current,
        originFrom: originFrom.current,
      },
    });
  };

  const handleSetBankAccount = () => {
    const err = checkBankAccount();
    if (err) {
      alertTitle.current = '設定銀行帳號';
      alertMessage.current = err;
      alertActionLabel.current = '確定';
      accountCompleted.current = false;
      setShowValidationModal(true);
    } else {
      accountCompleted.current = true;
      setShowModal(true);
    }
  };

  const handleDialogAction = async () => {
    if (accountCompleted.current) {
      setLoading(true);

      const payload = {
        bankCode: bankCode.current,
        branchNumber: bankBranchCode.current,
        accountNumber: accountNumber,
      };
      const responseData = await UserAPI.setBankAccount(payload);
      if (responseData) {
        console.log(responseData);
        setUser(responseData);
      }

      setLoading(false);
      navigate('/bankStatus');
    } else {
      setShowValidationModal(true);
    }
  };

  const title = () => {
    return (
      <>
        <Typography
          sx={{
            font: '400 28px PingFang TC, sans-serif',
            color: 'white',
            marginLeft: '16px',
          }}>
          綁定銀行帳戶
        </Typography>
        <Typography
          sx={{
            font: '400 20px PingFang TC, sans-serif',
            color: 'white',
            marginLeft: '16px',
            marginBottom: '24px',
          }}>
          未來僅能以此帳號進行網路銀行轉帳
        </Typography>
      </>
    );
  };

  const checkBankAccount = () => {
    if (!bankCode.current) {
      return '請選擇銀行代碼';
    } else if (!bankBranchCode.current) {
      return '請選擇銀行分行代碼';
    } else if (!accountNumber) {
      return '請填寫銀行帳號';
    } else if (accountNumber.length < 12 || accountNumber.length > 16) {
      return '銀行帳號格式錯誤';
    }
    return null;
  };

  const navigation = () => {
    return (
      <NavigationBar leftButtonHandler={handleBack}>
        <Typography
          onClick={() => {
            navigate('/bankLimited');
          }}
          sx={{
            alignSelf: 'stretch',
            gap: '10px',
            color: 'white',
            whiteSpace: 'nowrap',
            textAlign: 'right',
            padding: '16px 9px',
            fontSize: 17,
          }}>
          銀行限額
        </Typography>
      </NavigationBar>
    );
  };

  const bottomPanel = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          textAlign: 'center',
          letterSpacing: '-0.4px',
          justifyContent: 'flex-start',
          padding: '8px 16px',
          gap: '10px',
          backgroundColor: '#231a54',
        }}>
        <ButtonContainer
          direction="column"
          buttons={[
            {
              label: '下一步',
              variant: 'contained',
              onClick: handleSetBankAccount,
            },
          ]}></ButtonContainer>
      </Box>
    );
  };

  const description = () => {
    const items = [
      '※ 銀行帳號需與會員帳號為同一人。',
      '※ 將於約定帳戶存入台幣 1元進行驗證，審核約3個工作天完成',
    ];
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginTop: '16px',
          }}>
          {items.map((item, index) => (
            <Typography key={index} fontSize="14px" color="white">
              {item}
            </Typography>
          ))}
        </Box>
      </>
    );
  };

  const bankAccountFields = () => {
    return (
      <>
        {/* 銀行代碼 */}
        <Box
          onClick={handleClickSelectBank}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingBottom: '8px',
          }}>
          <Typography fontSize="14px">銀行代碼</Typography>
          <CustomDropDown>
            <TextField
              variant="standard"
              InputProps={txtInputProps}
              hiddenLabel
              autoComplete="off"
              fullWidth
              inputProps={{ min: 0, style: { textAlign: 'left' } }}
              placeholder="請選擇銀行代碼"
              sx={sxTextField}
              disabled
              value={
                bankCode.current
                  ? `${bankCode.current} ${bankName.current || ''}`.trim()
                  : ''
              }
            />
          </CustomDropDown>
        </Box>
        {/* 銀行分行代碼 */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingBottom: '8px',
          }}
          onClick={handleClickSelectBankBranch}>
          <Typography fontSize="14px">銀行分行代碼</Typography>
          <CustomDropDown>
            <TextField
              variant="standard"
              InputProps={txtInputProps}
              hiddenLabel
              autoComplete="off"
              fullWidth
              inputProps={{ min: 0, style: { textAlign: 'left' } }}
              placeholder="請選擇銀行分行代碼"
              sx={sxTextField}
              value={
                bankBranchCode.current
                  ? `${bankBranchCode.current} ${
                      bankBranchName.current || ''
                    }`.trim()
                  : ''
              }
            />
          </CustomDropDown>
        </Box>

        {/* 銀行帳號 */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingBottom: '8px',
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '20px',
              justifyContent: 'space-between',
            }}>
            <Typography fontSize="14px">銀行帳號</Typography>
            <Typography fontSize="14px" color="#FBA28B">
              ※請注意該帳戶非約定轉帳的限額
            </Typography>
          </Box>

          <InputBox
            alignItems="center"
            sx={{ padding: '16px 16px', backgroundColor: '#150F32' }}>
            <TextField
              type="number"
              variant="standard"
              InputProps={txtInputProps}
              hiddenLabel
              autoComplete="off"
              fullWidth
              inputProps={{ min: 0, style: { textAlign: 'left' } }}
              placeholder="請填寫銀行帳號"
              sx={sxTextField}
              onChange={(e) => {
                setUser({
                  ...user,
                  bankAccount: {
                    ...tBankAccount.current,
                    accountNumber: e.target.value,
                  },
                });
              }}
              value={accountNumber ? accountNumber : ''}
            />
          </InputBox>
        </Box>
      </>
    );
  };

  const confirmModalButtons = [
    {
      variant: 'outlined',
      label: '返回',
      onClick: () => {
        setShowModal(false);
      },
    },
    {
      variant: 'contained',
      label: '確定',
      onClick: handleDialogAction,
    },
  ];

  const warningModalButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => {
        setShowValidationModal(false);
      },
    },
  ];

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        {title() /* title section */}
        <Box padding="16px" marginBottom="14px">
          {bankAccountFields()}
        </Box>
        <Box padding="0px 16px" marginBottom="30px">
          {description() /* description section */}
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '8px',
          }}>
          {bottomPanel() /* bottom button */}
        </Box>
      </Box>
      {/* Confirm Modal */}
      <Modal
        title="確認銀行帳號"
        enabled={showModal}
        buttons={confirmModalButtons}
        buttonDirection="row">
        <Box>
          <Typography>
            未來此帳號為網路銀行轉帳的固定帳戶，請確認以下銀行帳號正確：
          </Typography>
          <Box
            sx={{
              marginTop: '32px',
            }}>
            <Typography
              sx={{
                font: '600 16px PingFang TC, sans-serif',
              }}>
              {`${bankCode.current} ${bankName.current} ｜ ${
                bankBranchName.current || ''
              }`}
            </Typography>
            <Typography
              sx={{
                font: '600 16px PingFang TC, sans-serif',
                marginTop: '6px',
              }}>
              {accountNumber}
            </Typography>
          </Box>
        </Box>
      </Modal>

      {/* Warning modal */}
      <Modal
        title={alertTitle.current}
        enabled={showValidationModal}
        buttons={warningModalButtons}
        buttonDirection="column">
        <Box
          sx={{
            paddingTop: '12px',
            display: 'flex',
            alignItems: 'center', // Centers items vertically
            justifyContent: 'center', // Centers items horizontally
          }}>
          <Typography>{alertMessage.current}</Typography>
        </Box>
      </Modal>
    </>
  );
}
