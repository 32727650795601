import React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

export default function DrawerWrapper({
  children, // 觸發 drawer 的元件
  drawerContent, // drawer 內的內容
  title = '', // 標題
  anchor = 'bottom', // 位置
  transitionDuration = { enter: 300, exit: 250 }, // 動畫進出時長
  open = false, //dialog開關
  setOpen, //dialog開關設定
  height = false,
}) {
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 檢查是否是 ios 裝置
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: (e) => {
            toggleDrawer(true)(e);
            // 保留原有的 onClick
            if (child.props.onClick) {
              child.props.onClick(e);
            }
          },
        }),
      )}

      <SwipeableDrawer
        anchor={anchor}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        transitionDuration={transitionDuration}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        PaperProps={{
          sx: {
            bgcolor: 'background.default',
            borderRadius: (() => {
              switch (anchor) {
                case 'bottom':
                  return '16px 16px 0 0';
                case 'top':
                  return '0 0 16px 16px';
                case 'left':
                  return '0 16px 16px 0';
                case 'right':
                  return '16px 0 0 16px';
                default:
                  return 0;
              }
            })(),
          },
        }}>
        <Box
          sx={{
            width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
            height:
              anchor === 'left' || anchor === 'right'
                ? '100%'
                : height
                ? height
                : '50vh', // 設置為視窗高度的 50%
            bgcolor: (theme) => theme.palette.background.default,
          }}
          role="dialog">
          {/* 標題區域 */}
          <Box
            sx={{
              p: 3,
              position: 'sticky',
              top: 0,
              zIndex: 10,
            }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.text.primary,
              })}>
              <CloseIcon />
            </IconButton>

            {title && (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '17px',
                  lineHeight: '22px',
                  letterSpacing: '-0.41px',
                  textAlign: 'center',
                }}>
                {title}
              </Typography>
            )}
          </Box>

          {/* 內容區域 */}
          {drawerContent}
        </Box>
      </SwipeableDrawer>
    </>
  );
}
