import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { SvgIcon } from './Icon';

export default function PasswordInput({
  lengthLimit,
  showPassword,
  password,
  handlePasswordChange,
  handleClickShowPassword,
  handleMouseDownPassword,
  handleMouseUpPassword,
}) {
  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      autoFocus
      placeholder="請輸入登入密碼"
      fullWidth
      variant="outlined"
      size="small"
      sx={{
        borderRadius: '8px',
        backgroundColor: (theme) => theme.palette.common.inputBackground,
        '& .MuiInputBase-input.Mui-disabled': {
          color: (theme) => theme.palette.text.primary,
          WebkitTextFillColor: (theme) => theme.palette.text.primary, // this is needed to override WebKit browsers default behavior
        },
        '& .MuiInputBase-input': {
          height: '56px',
          padding: '0 14px',
        },
      }}
      value={password}
      onChange={(e) => handlePasswordChange(e)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={showPassword ? '隱藏密碼' : '顯示密碼'}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end">
              {showPassword ? (
                <SvgIcon src={'/icons/eye_open.svg'} width={20} height={20} />
              ) : (
                <SvgIcon src={'/icons/eye_off.svg'} width={20} height={20} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: lengthLimit,
      }}
    />
  );
}
