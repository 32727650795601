import { Box } from '@mui/material';

export default function FileUploadBox({ content }) {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 55,
        lineHeight: '42px',
        border: '1px solid #FFFFFF',
        borderRadius: '6px',
        boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 500,
      }}>
      {content}
    </Box>
  );
}
