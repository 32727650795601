import FundRecordCell from './FundRecordCell';
import AssetRecordCell from './AssetRecordCell';
import moment from 'moment/moment';
import Stack from '@mui/material/Stack';

export default function RecordList({ type, records, onItemClick }) {
  return (
    <Stack padding="6px 0px">
      {records.map((record, index) => (
        <div key={index}>
          <RecordCell record={record} type={type} onItemClick={onItemClick} />
        </div>
      ))}
    </Stack>
  );
}

function RecordCell({ type, record, onItemClick }) {
  switch (type) {
    case 'order':
      return (
        <AssetRecordCell
          fund={record.fund}
          time={moment.unix(record.createdTime).format('YYYY-MM-DD HH:mm:ss')}
          status={getOrderState(record)}
          side={record.side}
          product={getCurrencySymbolFromProductId(record.productId)}
          onClick={() => onItemClick(record)}
          paymentMethod={record.payment}
          size={record.size}
        />
      );
    case 'deposit':
    case 'withdraw':
      return (
        <FundRecordCell
          type={record.type ?? type}
          method={record.type || record.depositMethod}
          amount={record.amount}
          time={moment.unix(record.createdTime).format('YYYY-MM-DD HH:mm:ss')}
          status={record.status}
          onClick={() => onItemClick(record)}
        />
      );
    default:
      return null;
  }
}

function getOrderState(order) {
  if (order.status === 'done') {
    if (order.doneReason === 'succeed') {
      return '交易成功';
    } else if (order.doneReason === 'rejected') {
      return '交易失敗';
    } else if (order.doneReason === 'cancel') {
      return '交易取消';
    }
  }
  return '處理中';
}

function getCurrencySymbolFromProductId(productId) {
  const symbols = productId.split('-');
  return symbols[0];
}
