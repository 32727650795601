import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { React, useContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserAPI } from '../../apis/UserAPI';
import Typography from '@mui/material/Typography';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import { UserContext } from '../../UserContext';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

let isOnComposition = false;

export default function BankMenuPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, setUser, setLoading } = useContext(UserContext);
  const bankCode = state?.bankCode;
  const selected = state?.selected;
  const [navTitle, setNavTitle] = useState('');
  const [listItems, setListItems] = useState([]);
  const [query, setQuery] = useState({ inputValue: '', innerValue: '' });
  const tBankAccount = useRef(state?.tBankAccount);

  console.log('[BankMenu]tBankAccount==>' + JSON.stringify(tBankAccount));

  useEffect(() => {
    function focusoutFunc() {
      document.activeElement.blur();
    }

    window.addEventListener('touchmove', focusoutFunc);

    return () => {
      window.removeEventListener('touchmove', focusoutFunc);
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [query.innerValue]);

  const isMatched = (item) => {
    if (bankCode) {
      return (
        item.name.includes(query.innerValue) ||
        item.branchCode.includes(query.innerValue)
      );
    } else {
      return (
        item.name.includes(query.innerValue) ||
        item.bankCode.includes(query.innerValue)
      );
    }
  };

  const filteredLists = listItems?.filter((item) => isMatched(item));

  useEffectOnce(() => {
    const getLists = async (bankCode) => {
      setLoading(true);
      if (bankCode) {
        const bankBranchList = await UserAPI.getBankBranchList(bankCode);
        setNavTitle('銀行分行代碼');
        setListItems(bankBranchList);
      } else {
        const bankList = await UserAPI.getBankList();
        setNavTitle('銀行代碼');
        setListItems(bankList);
      }
      setLoading(false);
    };
    getLists(bankCode);
  }, [bankCode]);

  const InputBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#FFF',
    width: '100%',
    height: '56px',
    borderRadius: '8px',
  }));

  const handleBack = () => {
    if (state?.superfrom === 'bankAccount') {
      navigate('/bankAccount', {
        state: {
          superfrom: 'bankMenu',
          tBankAccount: tBankAccount.current,
          originFrom: state?.originFrom,
        },
      });
    } else {
      navigate(-1);
    }
  };

  const handleClickItem = (item) => {
    if (bankCode) {
      //選擇銀行分行
      // setUser({
      //     ...user,
      //     bankAccount: {
      //         ...user.bankAccount,
      //         branchNumber: item.branchCode,
      //         bankBranchName: item.name,
      //     },
      // });
      tBankAccount.current = {
        ...tBankAccount.current,
        branchNumber: item.branchCode,
        bankBranchName: item.name,
      };
    } else {
      //選擇銀行
      const bankCode = item?.bankCode;
      let bankBranchCode = tBankAccount.current?.branchNumber;
      if (bankCode !== tBankAccount.current?.bankCode) {
        // 切換銀行時, 分行代碼重置
        bankBranchCode = null;
      }
      // setUser({
      //     ...user,
      //     bankAccount: {
      //         bankCode,
      //         branchNumber: bankBranchCode,
      //         bankName: item.name,
      //         accountNumber: "",
      //     },
      // });
      tBankAccount.current = {
        bankCode,
        branchNumber: bankBranchCode,
        bankName: item.name,
        accountNumber: '',
      };
    }

    handleBack();
  };

  const handleChangeKeyword = (e) => {
    if (!(e.target instanceof HTMLInputElement)) return;
    // when is on composition, change inputValue only
    // when not in composition change inputValue and innerValue both
    if (!isOnComposition) {
      setQuery({
        inputValue: e.target.value,
        innerValue: e.target.value,
      });
    } else {
      setQuery({ ...query, inputValue: e.target.value });
    }
  };

  const handleComposition = (e) => {
    if (!(e.target instanceof HTMLInputElement)) return;
    if (e.type === 'compositionend') {
      setQuery({ ...query, innerValue: e.target.value });
      isOnComposition = false;
    } else {
      isOnComposition = true;
    }
  };

  const navigation = () => {
    return (
      <NavigationBar
        title={navTitle}
        leftButtonHandler={handleBack}></NavigationBar>
    );
  };

  const bankCodeList = () => {
    return (
      <Box marginTop="10px" padding="0px 17px">
        {filteredLists?.map((listItem, index) => (
          <Box key={index} onClick={() => handleClickItem(listItem)}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginLeft="8px"
              marginRight="8px"
              height="55px">
              <Typography
                sx={{
                  font: '600 16px PingFang TC, sans-serif',
                  color: 'white',
                }}>
                {bankCode ? listItem?.branchCode : listItem?.bankCode}
                {listItem?.name}
              </Typography>
              {!!selected && selected === listItem?.bankCode && <CheckIcon />}
              {!!selected && selected === listItem?.branchCode && <CheckIcon />}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const searchingField = () => {
    return (
      <Box sx={{ padding: '0px 16px' }}>
        <InputBox
          alignItems="center"
          sx={{ padding: '16px 16px', backgroundColor: '#150F32' }}>
          <InputBase
            placeholder={
              navTitle !== ''
                ? navTitle === '銀行代碼'
                  ? '搜尋銀行代碼'
                  : '搜尋分行代碼'
                : ''
            }
            inputProps={{
              'aria-label': 'search google maps',
            }}
            value={query.inputValue}
            onChange={handleChangeKeyword}
            onCompositionUpdate={handleComposition}
            onCompositionEnd={handleComposition}
            onCompositionStart={handleComposition}></InputBase>
        </InputBox>
      </Box>
    );
  };

  return (
    <Box sx={sxContainer}>
      {navigation() /* navigation bar */}
      {searchingField() /* searching panel */}
      {bankCodeList()}
    </Box>
  );
}
