import React, { useState, useRef, useEffect, useContext } from 'react';
import useWebSocket from 'react-use-websocket';
import { UserContext } from '../../UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import Panel from '../../components/Panel';
import {
  Paper,
  Box,
  TextField,
  Grid,
  Alert,
  List,
  ListItem,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BottomPanel from '../../components/BottomPanel';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import GroupButtons from '../../components/GroupButtons';
import { TitleLabel, Label } from '../../components/Label';
import NumberFormat from 'react-number-format';
import { ProductAPI } from '../../apis/ProductAPI';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Round,
  CountDecimals,
  ThousandSeparator,
  ReplaceCommaToNumber,
  ReplaceCommaToInt,
  ReplaceStringToInt,
  ReplaceStringToNumber,
  CheckLeadingZero,
  InputCursorPosition,
} from '../../utils/CoinNumberFormat';
import { UserAPI } from '../../apis/UserAPI';
import StyledButton from '../../components/StyledButton';
import humps from 'humps';
import useInvoice from '../../hooks/useInvoice';
import DrawerWrapper from '../../components/DrawerWrapper';
import CheckIcon from '@mui/icons-material/Check';
import OrderCheckSlider from '../../components/trade/OrderCheckSlider';
import { AccountAPI } from '../../apis/AccountAPI';

import Modal from '../../components/Modal';
import { OrderAPI } from '../../apis/OrderAPI';
const priceRange = [0.25, 0.5, 0.75, 1];

export default function SellPage() {
  const navigate = useNavigate();
  const { user, setLoading } = useContext(UserContext);
  const { getCarrierType, getCarrierCode } = useInvoice();
  const params = useParams();
  const productId = params.coin;
  const initData = useRef(false);
  const [expired, setExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10);
  const expiredIdRef = useRef(null);
  const timeLeftIdRef = useRef(false);
  const lastPriceRef = useRef(0);
  const lastSizeRef = useRef(0);
  const [product, setProduct] = useState(null);
  const [sellPrice, setSellPrice] = useState(0);
  const [size, setSize] = useState(0);
  const sizeRef = useRef(0);
  const priceRef = useRef(0);
  const sellPriceRef = useRef(0);
  const websocketPriceRef = useRef(0);
  const [sizeFocus, setSizeFocus] = useState(true);
  const [price, setPrice] = useState(0);
  const [priceFocus, setPriceFocus] = useState(false);
  const inputSizeRef = useRef(null);
  const inputPriceRef = useRef(null);
  const [disableButton, setDisableButton] = useState(true);
  const [alert, setAlert] = useState(false);
  const billInfoDataRef = useRef(null);
  const carrierRef = useRef(getCarrierType('A'));
  const phoneBarcodeRef = useRef(null);
  const citizenDigitalRef = useRef(null);
  const cityRef = useRef(null);
  const districtRef = useRef(null);
  const addressRef = useRef(null);
  const doneeRef = useRef(null);
  const doneeCodeRef = useRef(null);
  const billNameRef = useRef(null);
  const [percentageButton, setPercentageButton] = useState(false);
  const [isCoinDialogOpen, setIsCoinDialogOpen] = useState(false);
  const [currentCoin, setCurrentCoin] = useState('');
  const [coinInfo, setCoinInfo] = useState([]);
  const [remainAmount, setRemainAmount] = useState(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [totalSize, setTotalSize] = useState(false);
  const [priceSelection, setPriceSelection] = useState(3);
  // const invoiceTabRef = useRef(0);
  const realtimePricesRef = useRef(null);
  const [lastPrice, setLastPrice] = useState(0);
  const [lastSize, setLastSize] = useState(0);
  const [lastRealtime, setLastRealtime] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);

  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => true,
    reconnectInterval: 3000,
    queryParams: {
      token: getWebsocketToken(),
    },
  });
  const handleBack = () => {
    navigate(`/market/${productId}`);
  };
  const balance = useRef(0);

  useEffect(() => {
    // 取得用戶帳戶資料
    async function fetchPaymentInfo() {
      const paymentInfo = await UserAPI.getPaymentInfo(productId, 'sell');
      balance.current = paymentInfo[0].remainingAmount.toFixed(8);
      setRemainAmount(paymentInfo[0].remainingAmount.toFixed(8));
      fetchProductData();
    }

    // 取得當下幣價
    async function fetchProductData() {
      setLoading(true);
      const product = await ProductAPI.getProduct(productId);
      realtimePricesRef.current = product?.realtimePrice;
      setProduct(product);
      sellPriceRef.current = product.realtimePrice['balance'].sellPrice;
      const defaultSize = 100 / sellPriceRef.current;

      if (ReplaceCommaToNumber(balance.current) <= defaultSize) {
        sizeRef.current = balance.current;
        setSize(balance.current);

        const newTWDPrice =
          ReplaceCommaToNumber(balance.current) * sellPriceRef.current;
        const roundedPrice = Round(newTWDPrice);
        priceRef.current = roundedPrice;
        setPrice(ThousandSeparator(roundedPrice));
        setPercentageButton(true);
      } else {
        const roundedSize = Round(
          defaultSize,
          CountDecimals(product?.baseIncrement),
        );
        sizeRef.current = roundedSize;
        setSize(roundedSize);
        priceRef.current = 100;
        setPrice(100);
        setPercentageButton(false);
      }

      validateOrder();
    }

    // 取得用戶交易細節
    async function fetchBillInfoData() {
      const userData = await UserAPI.getBillInfo();
      billInfoDataRef.current = userData;

      if (userData.carrierType) {
        carrierRef.current = getCarrierType(userData.carrierType);
        phoneBarcodeRef.current = userData.phoneBarcode;
        citizenDigitalRef.current = userData.citizenDigitalCertificate;
      }
      cityRef.current = userData.billAddrCity.replace(/台/g, '臺');
      districtRef.current = userData.billAddrDistrict.replace(/台/g, '臺');
      addressRef.current = userData.billAddr;
      doneeRef.current = userData.doneeName;
      doneeCodeRef.current = userData.doneeCode;
      billNameRef.current = userData.billInfoName;
    }

    if (initData.current === false) {
      initData.current = true;
      fetchBillInfoData();
      fetchPaymentInfo();
    }
    if (realtimePricesRef.current) {
      setSellPrice(realtimePricesRef.current?.['balance'].sellPrice);
    }
  }, [productId]);

  const renewSize = () => {
    const newSize = ReplaceCommaToInt(priceRef.current) / sellPriceRef.current;
    const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
    sizeRef.current = roundedSize;
    setSize(roundedSize);
    validateOrder();
  };

  const renewPrice = () => {
    const newPrice =
      ReplaceCommaToNumber(sizeRef.current) * sellPriceRef.current;
    const roundedPrice = Round(newPrice);
    priceRef.current = roundedPrice;
    setPrice(ThousandSeparator(roundedPrice));
    validateOrder();
  };

  useEffect(() => {
    if (lastMessage) {
      const realtimePrices = JSON.parse(lastMessage.data);
      if (realtimePrices['id'] && realtimePrices['status']) {
        return;
      }
      realtimePricesRef.current = realtimePrices[product.id];

      const updateProductRealtimePrice = (realtimePrice) => {
        const newProduct = { ...product };
        newProduct.realtimePrice = humps.camelizeKeys(realtimePrice);
        setProduct(newProduct);
        realtimePricesRef.current = newProduct.realtimePrice;
      };

      if (product) {
        // to avoid using init product price when payment method change
        updateProductRealtimePrice(realtimePrices[productId]);
      }

      const priceData = realtimePrices[productId]['balance'];
      setSellPrice(priceData.sell_price);
      websocketPriceRef.current = priceData.sell_price;

      if (!isConfirmDialogOpen) {
        sellPriceRef.current = websocketPriceRef.current;

        if (sizeFocus) renewPrice();
        if (priceFocus) renewSize();
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    async function fetchOrderData() {
      setLoading(true);
      const response = await AccountAPI.getAccounts();
      setTotalSize(response);
      setLoading(false);
    }
    fetchOrderData();
  }, [product?.baseCurrencyId]);

  function fetchPriceRangeData(index) {
    const newSize = balance.current * priceRange[index];
    const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
    sizeRef.current = roundedSize;

    priceRef.current = Round(
      balance.current * priceRange[index] * sellPriceRef.current,
    );
    setSize(roundedSize);
    setPrice(
      ThousandSeparator(
        Round(balance.current * priceRange[index] * sellPriceRef.current),
      ),
    );
    validateOrder();
  }

  function handleNextButton() {
    const limitResult = lowerUpperLimitCheck();
    if (!limitResult) {
      expiredIdRef.current = setTimeout(() => setExpired(true), 10000);
      timeLeftIdRef.current = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      setLastSize(sizeRef.current);
      setLastPrice(priceRef.current);
      setLastRealtime(realtimePricesRef.current);
    } else {
      setIsConfirmDialogOpen(false);
    }
  }

  async function createOrder() {
    const payload = {
      placeOrderType: sizeFocus ? 'quantity' : 'amount',
      productId: productId,
      type: 'limit',
      side: 'sell',
      size: ReplaceCommaToNumber(lastSize),
      fund: ReplaceCommaToInt(lastPrice),
      payment: 'balance',
      totalPrice:
        lastRealtime?.balance?.sell_price ??
        lastRealtime?.balance?.sellPrice ??
        null, //last
      exchangeRate:
        lastRealtime?.balance?.sell_exchange_rate ??
        lastRealtime?.balance?.sellExchangeRate ??
        null,
      originalPrice:
        lastRealtime?.balance?.sell_original_price ??
        lastRealtime?.balance?.sellOriginalPrice ??
        null,
    };

    // const response = await fetch('http://localhost:3001/orders/');

    // const response = await fetch(
    //   'https://526fe8ba-b81a-4596-aac9-f79954d8a925.mock.pstmn.io/v1/user/me/orders/',
    // );

    // const order = await response.json();

    const order = await OrderAPI.createOrder(payload);

    if (!order?.error) {
      if (getCarrierCode(carrierRef.current) === 'A') {
        const invoiceDetail = {
          phoneNumber: user.phoneNumber,
          email: billInfoDataRef.current.billInfoEmail,
          address: cityRef.current + districtRef.current + addressRef.current,
        };
        order['invoiceDetail'] = invoiceDetail;
      }
      setLoading(true);
      navigate('/tradeDone', {
        state: { order },
      });
      setAlert(false);
    } else {
      navigate('/tradeDone', {
        state: {
          order,
          message: order?.error?.message,
        },
      });
      setLoading(false);
      handleExpired();
    }
    setDisableButton(false);
    setIsConfirmDialogOpen(false);
  }

  const handleSizeChange = (event) => {
    setSizeFocus(true);
    setPriceFocus(false);
    setPercentageButton(false);
    event.target.value = CheckLeadingZero(
      ReplaceStringToNumber(event.target.value),
    );
    // InputCursorPosition(event, inputSizeRef);

    /*符合幣值最小位數長度*/

    if (event.target.value >= ReplaceCommaToNumber(balance.current)) {
      sizeRef.current = ReplaceCommaToNumber(balance.current);
      setSize(balance.current);
    } else {
      if (event.target.value < 1) {
        if (
          event.target.value.indexOf('.') > 0 &&
          event.target.value.charAt(event.target.value.length - 1) !== '.' &&
          Number(event.target.value.charAt(event.target.value.length - 1)) !== 0
        ) {
          const roundedSize = Round(
            ReplaceCommaToNumber(event.target.value),
            CountDecimals(product?.baseIncrement),
          );
          sizeRef.current = roundedSize;
          setSize(roundedSize);
        } else {
          if (
            Number(event.target.value.charAt(event.target.value.length - 1)) !==
            0
          ) {
            sizeRef.current = event.target.value;
          } else {
            sizeRef.current = Round(
              ReplaceCommaToNumber(event.target.value),
              CountDecimals(product?.baseIncrement),
            );
          }
          setSize(event.target.value);
        }
      } else {
        if (
          event.target.value.indexOf('.') > 0 &&
          event.target.value.charAt(event.target.value.length - 1) !== '.'
        ) {
          if (
            Number(event.target.value.charAt(event.target.value.length - 1)) !==
            0
          ) {
            const roundedSize = Round(
              ReplaceCommaToNumber(event.target.value),
              CountDecimals(product?.baseIncrement),
            );
            sizeRef.current = roundedSize;
            setSize(roundedSize);
          } else {
            setSize(event.target.value);
            sizeRef.current = event.target.value;
          }
        } else {
          sizeRef.current = event.target.value;
          setSize(event.target.value);
        }
      }
    }

    const newTWDPrice = sizeRef.current * sellPriceRef.current;
    const roundedPrice = Round(newTWDPrice);
    priceRef.current = roundedPrice;
    setPrice(ThousandSeparator(roundedPrice));
    validateOrder();
  };

  const handleTWDChange = (event) => {
    setSizeFocus(false);
    setPriceFocus(true);
    setPercentageButton(false);
    event.target.value = CheckLeadingZero(
      ReplaceStringToInt(event.target.value),
    );

    InputCursorPosition(event, inputPriceRef);

    const newSize = event.target.value / sellPriceRef.current;

    // 如果輸入的price大於使用者有的幣值
    if (newSize >= ReplaceCommaToNumber(balance.current)) {
      // 設定price的最大值
      const newTWDPrice =
        ReplaceCommaToNumber(balance.current) * sellPriceRef.current;
      const roundedPrice = Round(newTWDPrice);
      priceRef.current = roundedPrice;
      setPrice(ThousandSeparator(roundedPrice));

      const roundedSize = Round(
        roundedPrice / sellPriceRef.current,
        CountDecimals(product?.baseIncrement),
      );
      sizeRef.current = roundedSize;
      setSize(roundedSize);
    } else {
      const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
      sizeRef.current = roundedSize;
      setSize(roundedSize);
      priceRef.current = ReplaceCommaToNumber(event.target.value);
      setPrice(ThousandSeparator(event.target.value));
    }

    validateOrder();
  };

  const handleSelectPriceRange = (index) => {
    setPriceSelection(index);
    setPercentageButton(true);
    fetchPriceRangeData(index);
    setSizeFocus(true);
    setPriceFocus(false);
  };

  const handleFocus = () => {
    if (priceFocus === true) {
      setPriceFocus(false);
      setSizeFocus(true);
      inputSizeRef.current.focus();
    }
    if (sizeFocus === true) {
      setPriceFocus(true);
      setSizeFocus(false);
      inputPriceRef.current.focus();
    }
    if (sizeFocus === false && priceFocus === false) {
      setSizeFocus(true);
      inputSizeRef.current.focus();
    }
  };

  const resetTimeout = () => {
    clearTimeout(expiredIdRef.current);
    setExpired(false);
    clearInterval(timeLeftIdRef.current);
    setTimeLeft(10);
    lastSizeRef.current = sizeRef.current;
    lastPriceRef.current = priceRef.current;
    // lastSellPriceRef.current = sellPriceRef.current;
  };

  const handleExpired = () => {
    resetTimeout();
    // setOpenDrawer(false);
    // if (websocketPriceRef.current !== 0) {
    //   sellPriceRef.current = websocketPriceRef.current;
    // }
    if (priceFocus) {
      renewSize();
      setPriceFocus(true);
      setSizeFocus(false);
      inputPriceRef.current.focus();
    } else {
      renewPrice();
      setSizeFocus(true);
      setPriceFocus(false);
      inputSizeRef.current.focus();
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      handleRenewPrice();
    }
  }, [timeLeft]);

  const handleRenewPrice = () => {
    resetTimeout();
    // if (websocketPriceRef.current !== 0) {
    //   sellPriceRef.current = websocketPriceRef.current;
    // }
    // if (priceFocus) renewSize();
    // if (sizeFocus) renewPrice();
    // setLastPrice(price);
    // setLastSize(size);
    // setLastRealtime(realtimePricesRef.current);
    expiredIdRef.current = setTimeout(() => setExpired(true), 10000);
    timeLeftIdRef.current = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    if (priceFocus) {
      const newSize =
        ReplaceCommaToInt(priceRef.current) /
        realtimePricesRef?.current?.balance.sell_price;

      const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
      setLastPrice(priceRef.current);
      setLastSize(roundedSize);
    }
    if (sizeFocus) {
      const newPrice =
        ReplaceCommaToNumber(sizeRef.current) *
        realtimePricesRef?.current?.balance.sell_price;

      const roundedPrice = Round(newPrice);
      setLastPrice(roundedPrice);
      setLastSize(sizeRef.current);
    }
    setLastRealtime(realtimePricesRef.current);
    validateOrder();
  };

  const navigation = () => {
    return (
      <NavigationBar leftButtonHandler={handleBack}>
        <Button
          onClick={() => {
            navigate('/bankLimited');
          }}
          sx={{ color: '#fff', fontSize: '17px', fontWeight: '400' }}>
          銀行限額
        </Button>
      </NavigationBar>
    );
  };
  const Icon = styled('img')({
    maxHeight: 40,
    maxWidth: 40,
  });
  const sxContainer = {
    pt: `${navigationBarHeight}px`,
  };
  const validateOrder = () => {
    if (
      sizeRef.current === 0 ||
      priceRef.current === 0 ||
      sizeRef.current < 0 ||
      priceRef.current < 0
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  const abbrFund = (number) => {
    const stringifyNumber = number.toString();
    if (stringifyNumber.length > 4) {
      return `${stringifyNumber.slice(0, -4)} 萬`;
    } else return stringifyNumber;
  };

  function lowerUpperLimitCheck() {
    let limitResult = false;
    if (priceRef.current > product.maxFund) {
      setShowAlert(true);
      limitResult = true;
      alertTitle.current = '超過限額';
      alertMessage.current = `超出單筆交易上限NT$ ${abbrFund(product.maxFund)}`;
    }

    if (priceRef.current < product.minFund) {
      setShowAlert(true);
      limitResult = true;
      alertTitle.current = '請輸入金額';
      alertMessage.current = `低於單筆交易下限NT$ ${abbrFund(product.minFund)}`;
    }
    return limitResult;
  }

  // fetch data for Coin Type List
  useEffect(() => {
    async function fetchProducts() {
      const data = await ProductAPI.getProducts();
      setCoinInfo(data);
    }
    fetchProducts();
  }, [currentCoin]);

  // 購買顆數的幣種列表 Dialog
  function CoinTypeList() {
    const navigate = useNavigate();
    function directPage(directCoin) {
      if (directCoin !== product?.baseCurrencyId) {
        setCurrentCoin(directCoin);
        initData.current = false;
        navigate(`/sell/${directCoin}-TWD`);
      }
      setIsCoinDialogOpen(false);
      return;
    }

    return (
      <List>
        {coinInfo?.map(
          (option, index) =>
            totalSize.find((size) => size.currencyId === option.baseCurrency.id)
              ?.twdValue === 0 || (
              <ListItem
                key={index}
                onClick={() => directPage(option.baseCurrency.id)}
                sx={{ marginBottom: '10px' }}>
                <Icon
                  sx={{
                    maxHeight: 40,
                    maxWidth: 40,
                    marginRight: '10px',
                  }}
                  src={`../icons/${option.baseCurrency.id}.svg`}
                />
                <Label>{option.baseCurrency.id}</Label>
                {product?.baseCurrencyId === option.baseCurrency.id && (
                  <CheckIcon
                    sx={{
                      maxHeight: 40,
                      maxWidth: 40,
                      marginLeft: 'auto',
                    }}
                  />
                )}
              </ListItem>
            ),
        )}
      </List>
    );
  }

  const titleLabel = () => {
    const percent = ['25%', '50%', '75%', 'Max'];
    return (
      <Box margin="16px">
        <Grid container alignItems="center" rowSpacing={1}>
          <Grid item xs={8}>
            <Label fontSize="28px">你想賣多少顆？</Label>
          </Grid>
          <Grid item xs={4}>
            <Label
              onClick={() => {
                navigate(`/buy/${productId}`);
              }}
              fontSize="28px"
              textAlign="end"
              sx={{
                cursor: 'pointer',
                fontWeight: '400 !important',
                background: 'linear-gradient(to right, #FFF, #231A54 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                MozBackgroundClip: 'text',
                MozTextFillColor: 'transparent',
                msBackgroundClip: 'text',
                msTextFillColor: 'transparent',
                backgroundClip: 'text',
                textFillColor: 'transparent',
              }}>
              我要買幣
            </Label>
          </Grid>
        </Grid>

        <Grid container alignItems="center" marginTop="10px">
          <Grid item xs={5.3}>
            <Label sx={{ opacity: 0.5 }} fontSize="14px">
              賣出顆數
            </Label>
            <Panel
              backgroundColor="#150F32"
              border={sizeFocus ? '#3E1EE6 1px solid' : '#150F32 1px solid'}>
              <TitleLabel fontSize="13px" fontWeight="medium">
                <DrawerWrapper
                  title="選擇幣種"
                  anchor="bottom"
                  drawerContent={<CoinTypeList />}
                  open={isCoinDialogOpen}
                  setOpen={setIsCoinDialogOpen}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    sx={{ cursor: 'pointer' }}>
                    <Icon
                      sx={{
                        maxHeight: 40,
                        maxWidth: 40,
                      }}
                      src={`../icons/${product?.baseCurrencyId}.svg`}
                    />
                    <Label>{product?.baseCurrencyId}</Label>
                    <ArrowForwardIosIcon
                      style={{ fontSize: 18 }}
                      sx={{
                        color: '#fff',
                        marginLeft: 'auto',
                      }}
                    />
                  </Box>
                </DrawerWrapper>
                <TextField
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 22,
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '20px 0px 4px 0px ',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                        borderWidth: '0px',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        borderWidth: '0px',
                      },
                    },
                  }}
                  defaultValue={0}
                  inputRef={inputSizeRef}
                  onChange={handleSizeChange}
                  onClick={() => {
                    setSizeFocus(true);
                    setPriceFocus(false);
                  }}
                  value={size}
                />
              </TitleLabel>
            </Panel>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="5px"
              gap="4px">
              <Label fontSize="12px" sx={{ opacity: 0.5 }}>
                可用數量
              </Label>
              <Label fontSize="12px" align="center" sx={{ opacity: 0.5 }}>
                {remainAmount}
              </Label>
            </Box>
          </Grid>

          <Grid
            item
            xs={1.4}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <IconButton>
              <Icon
                onClick={handleFocus}
                sx={{
                  maxHeight: 40,
                  maxWidth: 40,
                  marginX: 'auto',
                  color: '#fff',
                }}
                src="../icons/arrow_path.svg"
              />
            </IconButton>
          </Grid>
          <Grid item xs={5.3}>
            <Label fontSize="14px" sx={{ opacity: 0.5 }}>
              等值金額
            </Label>
            <Panel
              backgroundColor="#150F32"
              border={priceFocus ? '#3E1EE6 1px solid' : '#150F32 1px solid'}>
              <TitleLabel fontSize="13px" fontWeight="medium">
                <Box display="flex" alignItems="center" gap="8px">
                  <Icon
                    sx={{
                      maxHeight: 40,
                      maxWidth: 40,
                    }}
                    src="../icons/NTD.svg"
                  />
                  <Label>TWD</Label>
                </Box>
                <TextField
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  autoFocus={true}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 22,
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '20px 0px 4px 0px ',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                        borderWidth: '0px',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        borderWidth: '0px',
                      },
                    },
                  }}
                  inputRef={inputPriceRef}
                  defaultValue={0}
                  onChange={handleTWDChange}
                  onClick={() => {
                    setSizeFocus(false);
                    setPriceFocus(true);
                  }}
                  value={price}
                />
              </TitleLabel>
            </Panel>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="5px">
              <Label fontSize="12px">最小下單金額 100 TWD</Label>
            </Box>
          </Grid>
        </Grid>

        <Box marginTop="24px" marginBottom="16px">
          <GroupButtons
            selection={priceSelection}
            items={percent}
            selectionHandler={handleSelectPriceRange}
            selected={percentageButton}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="5px">
          <Label fontSize="14px" sx={{ opacity: 0.5 }}>
            <strong>1</strong>&nbsp;{product?.baseCurrencyId}
            &nbsp;&nbsp;≈&nbsp;&nbsp;
          </Label>
          <Label fontSize="14px" align="center" sx={{ opacity: 0.5 }}>
            <NumberFormat
              value={sellPrice}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={sellPrice < 100 ? 2 : 0}
              fixedDecimalScale={true}
            />
          </Label>
          <Label fontSize="14px" sx={{ opacity: 0.5 }}>
            &nbsp;&nbsp;TWD
          </Label>
        </Box>

        <Label fontSize="12px" marginTop="30px">
          ※ 幣價已含手續費
          <br />
          ※ 下單成功後無法退款或取消。
          <br />※ 依法規定，發票開立後無法變更開立方式與任一資訊。
        </Label>

        {isConfirmDialogOpen && (
          <Paper
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              height: '100px',
              padding: '16px',
              zIndex: '1500',
              backgroundColor: 'transparent',
            }}>
            <OrderCheckSlider createOrder={createOrder} />
          </Paper>
        )}
      </Box>
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <DrawerWrapper
          title=""
          anchor="bottom"
          drawerContent={<BottomDrawer />}
          open={isConfirmDialogOpen}
          setOpen={setIsConfirmDialogOpen}
          height="calc(100vh - 150px)">
          <StyledButton
            variant="contained"
            fullWidth
            disabled={disableButton}
            onClick={handleNextButton}
            disableElevation>
            下一步
          </StyledButton>
        </DrawerWrapper>
      </BottomPanel>
    );
  };

  useEffect(() => {
    if (!isConfirmDialogOpen) {
      handleExpired();
    }
  }, [isConfirmDialogOpen]);

  const BottomDrawer = () => {
    return (
      <Box sx={{ margin: '16px' }}>
        <TitleLabel fontSize="20px" fontWeight="bold" textAlign="center">
          請確認您的交易資訊
          <br />
          訂單成功後無法退款或取消
        </TitleLabel>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="12px">
          {!expired ? (
            <TitleLabel fontSize="13px" color="#FF8F08">
              價格有效時間 {timeLeft}
            </TitleLabel>
          ) : (
            ''
          )}
        </Box>
        <Grid container display="flex" alignItems="center" marginTop="40px">
          <Grid item xs={5.3}>
            <Panel backgroundColor="#150F32">
              <Label textAlign="center">{product?.baseCurrencyId}</Label>
              <Label fontSize="24px" textAlign="center">
                {lastSize}
              </Label>
            </Panel>
          </Grid>
          <Grid
            item
            xs={1.4}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Icon
              sx={{
                maxHeight: 40,
                maxWidth: 40,
                marginX: 'auto',
              }}
              src="../icons/arrow_path.svg"
            />
          </Grid>
          <Grid item xs={5.3}>
            <Panel backgroundColor="#150F32">
              <Label textAlign="center">TWD</Label>
              <Label fontSize="24px" textAlign="center">
                {lastPrice}
              </Label>
            </Panel>
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="12px"
          marginBottom="8px">
          <Label fontSize="14px" sx={{ opacity: 0.5 }}>
            <strong>1</strong>&nbsp;{product?.baseCurrencyId}
            &nbsp;&nbsp;≈&nbsp;&nbsp;
          </Label>
          <Label fontSize="14px" align="center" sx={{ opacity: 0.5 }}>
            <NumberFormat
              value={sellPrice}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={sellPrice < 100 ? 2 : 0}
              fixedDecimalScale={true}
            />
          </Label>
          <Label fontSize="14px" sx={{ opacity: 0.5 }}>
            &nbsp;&nbsp;TWD
          </Label>
        </Box>
        <Label fontSize="12px" marginTop="42px">
          ※ 確認過程中因幣價波動過大，台灣大虛擬資產交易所保留取消此筆交易權利。
          <br />※ 幣價已含手續費。
        </Label>
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: '100px',
            padding: '16px',
            zIndex: '1200',
            backgroundColor: 'transparent',
          }}></Paper>
      </Box>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {alert ? (
          <Alert severity="error">Something Wrong, please try again.</Alert>
        ) : (
          <></>
        )}
        {navigation() /* navigation bar */}
        {titleLabel() /* 幣值 */}
        {bottomPanel() /* 購買 / 賣出按鈕 */}
        {/* Warning modal */}
        <Modal
          title={alertTitle.current}
          enabled={showAlert}
          buttons={[
            {
              variant: 'contained',
              label: '確定',
              onClick: () => {
                setShowAlert(false);
              },
            },
          ]}
          buttonDirection="column">
          <Box
            sx={{
              paddingTop: '12px',
              display: 'flex',
              alignItems: 'center', // Centers items vertically
              justifyContent: 'center', // Centers items horizontally
            }}>
            <Typography>{alertMessage.current}</Typography>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
