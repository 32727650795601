import { useState, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Link, Stack, Checkbox, FormControlLabel } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { UserAPI } from '../../apis/UserAPI';
import { UserContext } from '../../UserContext';
import validator from '../account/validator';
import { Label } from '../../components/Label';
import StyledButton from '../../components/StyledButton';
import secureLocalStorage from 'react-secure-storage';
import TextInput from '../account/components/TextInput';
import Modal from '../../components/Modal';
import Typography from '@mui/material/Typography';

const terms = [
  { title: '《隱私權條款》', path: '/privacy' },
  { title: '《使用者條款》', path: '/terms' },
  { title: '《免責聲明》', path: '/disclaimer' },
];

export default function SignUpPage() {
  const { setLoading, setUser, loginPhone } = useContext(UserContext);
  const { state } = useLocation();
  let signUpData;
  if (state?.previousPage === '/landing') {
    secureLocalStorage.removeItem('signUpData');
  } else if (
    ['/privacy', '/terms', '/disclaimer'].includes(state?.previousPage)
  ) {
    signUpData = secureLocalStorage.getItem('signUpData');
  }

  const [email, setEmail] = useState(signUpData ? signUpData.email : '');
  const [password, setPassword] = useState(
    signUpData ? signUpData.password : '',
  );
  const [passwordConfirm, setPasswordConfirm] = useState(
    signUpData ? signUpData.passwordConfirm : '',
  );
  const csAppUserData = secureLocalStorage.getItem('csAppUserData');
  const [isChecked, setIsChecked] = useState(false);
  const errorsRef = useRef({});
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const handleToggleCheck = () => setIsChecked((isChecked) => !isChecked);

  async function handleSignUpClick() {
    if (checkLoginInfo()) {
      setLoading(true);
      const payload = {
        data: csAppUserData || '',
        msisdn: loginPhone,
        email: email,
        password: password,
        passwordConfirm: passwordConfirm,
      };
      const response = await UserAPI.postRegister(payload);
      setLoading(false);

      if (response?.error) {
        alertTitle.current = '建立會員失敗';
        alertMessage.current = response?.error.message;
        setShowAlert(true);
      } else if (response?.user) {
        setUser(response?.user);

        secureLocalStorage.removeItem('signUpData');
        navigate('/phoneVerify', { state: { entry: '/signUp' } });
      }
    }
  }

  const checkLoginInfo = () => {
    let alert = null;
    if (!validator.isPhoneNumber(loginPhone, errorsRef.current)) {
      alert = errorsRef.current.phone;
    } else if (!validator.isEmail(email, errorsRef.current)) {
      alert = errorsRef.current.email;
    } else if (!validator.isPassword(password, errorsRef.current)) {
      alert = errorsRef.current.password;
    } else if (
      !validator.isPasswordConfirm(password, passwordConfirm, errorsRef.current)
    ) {
      alert = errorsRef.current.passwordConfirm;
    }

    if (alert) {
      alertTitle.current = '會員註冊資料';
      alertMessage.current = alert;
      setShowAlert(true);
      return false;
    } else {
      return true;
    }
  };

  const confirmModalButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setShowAlert(false),
    },
  ];

  return (
    <>
      <NavigationBar leftButtonHandler={() => navigate('/landing')} />
      <Stack padding="72px 16px" spacing={2}>
        <InputSection
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          passwordConfirm={passwordConfirm}
          setPasswordConfirm={setPasswordConfirm}
        />
        <SignUpCheckBox
          onChange={handleToggleCheck}
          isChecked={isChecked}
          state={{ loginPhone, email, password, passwordConfirm }}
        />
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            left: 16,
            right: 16,
          }}>
          <BottomButton isChecked={isChecked} onClick={handleSignUpClick} />
        </Box>

        <Modal
          title={alertTitle.current}
          enabled={showAlert}
          buttons={confirmModalButtons}
          buttonDirection="column">
          <Typography
            fontWeight={400}
            fontSize={16}
            textAlign="center"
            color="text.secondary">
            {alertMessage.current}
          </Typography>
        </Modal>
      </Stack>
    </>
  );
}

function InputSection({
  email,
  setEmail,
  password,
  setPassword,
  passwordConfirm,
  setPasswordConfirm,
}) {
  const inputInfo = [
    {
      inputLabel: 'Email',
      placeholder: 'example@gmail.com',
      id: 'email',
      background: '#FFF',
      type: '',
      value: email,
      setState: setEmail,
    },
    {
      inputLabel: '密碼設定',
      placeholder: '設定最少8位英數字的密碼',
      id: 'password',
      background: '#FFF',
      type: 'password',
      value: password,
      setState: setPassword,
    },
    {
      inputLabel: '密碼確認',
      placeholder: '再次輸入密碼',
      id: 'passwordConfirm',
      background: '#FFF',
      type: 'password',
      value: passwordConfirm,
      setState: setPasswordConfirm,
    },
  ];
  return (
    <Stack spacing={4} pt="16px">
      {inputInfo.map((input, index) => {
        const {
          inputLabel,
          placeholder,
          id,
          background,
          type,
          value,
          setState,
        } = input;
        return (
          <TextInput
            key={index}
            inputLabel={inputLabel}
            placeholder={placeholder}
            id={id}
            background={background}
            type={type}
            value={value}
            setState={setState}
          />
        );
      })}
    </Stack>
  );
}

function SignUpCheckBox({ isChecked, onChange, state }) {
  const navigate = useNavigate();

  function handleLinkClick(path) {
    secureLocalStorage.setItem('signUpData', {
      phoneNumber: state.loginPhone,
      email: state.email,
      password: state.password,
      passwordConfirm: state.passwordConfirm,
    });
    navigate(path, { state: { previousPage: '/signUp' } });
  }

  return (
    <FormControlLabel
      sx={{ marginRight: '0px' }}
      control={
        <Checkbox
          color="default"
          checked={isChecked}
          onChange={onChange}
          size="medium"
        />
      }
      label={
        <Box>
          <Label fontSize="15px">
            我是本國人且已成年，已詳閱並同意以下條款
          </Label>
          <Label fontSize="15px">
            {terms.map((term) => {
              return (
                <Link
                  key={term.title}
                  onClick={() => handleLinkClick(term.path)}
                  underline="none"
                  color="theme.palette.text.primary">
                  {term.title}
                </Link>
              );
            })}
          </Label>
        </Box>
      }
    />
  );
}

function BottomButton({ isChecked, onClick }) {
  return (
    <StyledButton
      variant="contained"
      fullWidth
      disabled={!isChecked}
      disableElevation
      onClick={onClick}>
      註冊會員
    </StyledButton>
  );
}
