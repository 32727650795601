import { useState, useEffect } from 'react';
import { OrderAPI } from '../apis/OrderAPI';
import { UserAPI } from '../apis/UserAPI';
import { ProductAPI } from '../apis/ProductAPI';

// TODO: 這裡API 會等比較久，未來最好要有前端 API response 的 cache 功能
export function useAssetHistory(user, setLoading) {
  const [orders, setOrders] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [coinInfo, setCoinInfo] = useState([]);

  useEffect(() => {
    async function fetchAssetsData() {
      if (!user) return;

      setLoading(true);
      try {
        const [ordersResult, depositsResult, withdrawsResult, coinInfoResult] =
          await Promise.all([
            OrderAPI.getOrders(),
            UserAPI.getDepositRecords(),
            UserAPI.getWithdrawRecords(),
            ProductAPI.getProducts(),
          ]);

        setOrders(ordersResult);
        setDeposits(depositsResult);
        setWithdraws(withdrawsResult);
        setCoinInfo(coinInfoResult);
      } finally {
        setLoading(false);
      }
    }
    fetchAssetsData();
  }, [user, setLoading]);

  return { orders, deposits, withdraws, coinInfo };
}
