import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '37px',
  padding: '9px 48px',
  fontFamily: 'PingFang TC, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '-0.4px',
  whiteSpace: 'nowrap',
  color: theme.palette.common.white,

  '&:disabled': {
    backgroundColor: '#CCC3F8',
    color: '#FFF',
  },
  ...(variant === 'outlined' && {
    border: '1px solid #ffffff',
    color: theme.palette.common.white,
    '&:hover': {
      border:
        variant === 'outlined'
          ? `1px solid ${theme.palette.text.purple}`
          : 'none',
    },
  }),
  ...(variant === 'contained' && {
    borderRadius: '8px',
    backgroundColor: '#3E1EE6',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#4525F0',
    },
  }),
  ...(variant === 'text' && {
    color: '#CCC3F8',
    padding: '9px 16px',
  }),
}));

const EveButton = ({ children, variant = 'contained', ...props }) => {
  return (
    <StyledButton variant={variant} {...props}>
      {children}
    </StyledButton>
  );
};

export default EveButton;
