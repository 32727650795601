import { useState, useRef, useContext, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Box, Stack } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
// import Icon from '../../components/Icon';
import { UserContext } from '../../UserContext';
import BottomPanel from '../../components/BottomPanel';
import { UserAPI } from '../../apis/UserAPI';
// import Dialog from '../../components/Dialog';
import { Label } from '../../components/Label';
import StyledButton from '../../components/StyledButton';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import DigitLabelBox from './components/DigitLabelBox';
import HiddenTextField from './components/HiddenTextField';
import moment from 'moment/moment';
import Modal from '../../components/Modal';
import EveButton from '../../components/EveButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CustomButton from '../../components/CustomButton';

const maxVerifyCodeLength = 6;
// const sxContainer = {
//   pt: `${navigationBarHeight}px`,
// };
// const DescriptionLabel = styled(Typography)(({ theme }) => ({
//   color: theme.palette.text.primary,
//   fontSize: '15px',
//   fontWeight: 'medium',
//   textAlign: 'center',
// }));

export default function EmailVerifyPage() {
  const countDown = moment.duration(60, 'seconds');
  const { setLoading, user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState('');
  const [disable, setDisable] = useState(true);
  const [disableResend, setDisableResend] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showResendFailAlert, setShowResendFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const alertTitle = useRef('');
  const textInput = useRef(null);
  const [showCountDown, setShowCountDown] = useState(false);

  const [duration, setDuration] = useState(countDown);
  const intervalRef = useRef();
  const { state } = useLocation();

  useEffectOnce(() => {
    handleGetEmailExpireTime();
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setDuration(moment.duration(duration.asMilliseconds() - 1000));
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [duration]);

  useEffect(() => {
    if (duration.asMilliseconds() < 0) {
      clearInterval(intervalRef.current);
      setShowCountDown(false);
      setDisableResend(false);
    }
  }, [duration]);

  const handleBack = () => {
    navigate('/');
  };

  const handleVerifyCodeUpdate = (e) => {
    const code = e.target.value.replace(/\D/g, '');
    if (code.length > maxVerifyCodeLength) {
      return;
    }
    setVerifyCode(code);
    setDisable(code.length === maxVerifyCodeLength ? false : true);
  };

  const handleVerifyCode = async () => {
    setLoading(true);
    const responseData = await UserAPI.emailVerifyCode({
      code: verifyCode,
    });
    setLoading(false);

    if (responseData) {
      setUser(responseData);
      if (state.entry === '/changePhoneNumber') {
        navigate('/changePhoneNumberSucceed');
      } else if (state.entry === '/changeEmail') {
        setShowSuccessAlert(true);
      } else {
        navigate('/signUpCompleted');
      }
    } else {
      alertTitle.current = '驗證失敗';
      setShowAlert(true);
    }
  };

  const handleGetEmailExpireTime = async () => {
    setShowAlert(false);
    setLoading(true);
    // TODO: 從這裡可以發現根本沒有用到 ExpireTime，這代表當你離開頁面時，再回來這個頁面秒數會從60秒開始倒數，因為沒有去計算還剩多少時間
    const success = await UserAPI.getEmailExpireTime();
    setLoading(false);

    if (success) {
      setDuration(countDown);
      setShowCountDown(true);
      setDisableResend(true);
      setVerifyCode('');
    } else {
      handleResendVerifyCode();
    }
  };

  const handleResendVerifyCode = async () => {
    setShowAlert(false);
    setLoading(true);
    const success = await UserAPI.resendEmailVerifyCode();
    setLoading(false);

    if (success) {
      setDuration(countDown);
      setShowCountDown(true);
      setDisableResend(true);
      setVerifyCode('');
    } else {
      setShowResendFailAlert(true);
    }
  };

  // const handleChangeEmail = () => {
  //   setShowAlert(false);
  //   navigate('/changeEmail', { state: { entry: '/signUp' } });
  // };

  // const verifyDescription = () => {
  //   return (
  //     <Box width={'100%'} marginTop="28px" marginBottom="37px">
  //       <DescriptionLabel>
  //         驗證碼已傳送至{user?.email}，請輸入驗證碼以完成會員建立程序
  //       </DescriptionLabel>
  //     </Box>
  //   );
  // };

  const getDigitGroup = () => {
    let digits = [];
    for (let i = 0; i < maxVerifyCodeLength; i++) {
      const isEditing = i === verifyCode.length;
      const value = isEditing ? '|' : verifyCode.charAt(i);
      digits.push(
        <DigitLabelBox
          key={`digit-${i}`}
          value={value}
          animated={isEditing}
          isEditing={isEditing}
        />,
      );
    }
    return (
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        width="100%"
        onClick={() => {
          textInput.current.focus();
        }}>
        {digits}
      </Stack>
    );
  };

  const emailDesc = () => {
    return (
      <Stack
        spacing={0}
        direction="column"
        alignItems="center"
        marginTop="16px">
        {getDigitGroup()}

        {/* 隱藏的input*/}
        <HiddenTextField
          inputRef={textInput}
          value={verifyCode}
          onChange={handleVerifyCodeUpdate}
        />

        <Box>
          {showCountDown ? (
            <Label fontSize="14px" color="#FFFFFF" align="center">
              {duration.asSeconds().toFixed(0)}s
            </Label>
          ) : (
            <EveButton
              variant="text"
              onClick={handleResendVerifyCode}
              sx={{
                pointerEvents: disableResend ? 'none' : 'auto',
                color: disableResend ? 'transparent' : '#FFFFFF',
                fontSize: '14px',
                fontWeight: 400,
              }}>
              重新發送驗證碼
            </EveButton>
          )}

          {/* {showCountDown ? (
            <Label
              fontSize="15px"
              fontWeight="bold"
              color="#717171"
              align="center">
              <span style={{ color: '#FF8F08' }}>
                {String(duration.minutes()).padStart(2, '0')}
                &nbsp;:&nbsp;
                {String(duration.seconds()).padStart(2, '0')}
              </span>
              &nbsp;後即可重新發送
            </Label>
          ) : (
            <Label
              fontSize="15px"
              fontWeight="bold"
              color="#717171"
              align="center">
              驗證碼已過期請選擇
            </Label>
          )} */}
        </Box>
        {/* <Box
          display="flex"
          justifyContent="center"
          width="100%"
          marginTop="16px">
          <StyledButton
            variant="contained"
            fullWidth
            disableElevation
            sx={{ height: '48px', marginRight: '16px' }}
            disabled={disableResend}
            onClick={handleResendVerifyCode}>
            重新寄送驗證碼
          </StyledButton>
          {state.entry !== '/changePhoneNumber' && (
            <StyledButton
              variant="contained"
              fullWidth
              disableElevation
              sx={{ height: '48px' }}
              onClick={handleChangeEmail}>
              更改Email重寄
            </StyledButton>
          )}
        </Box> */}
      </Stack>
    );
  };

  // const bottomPanel = () => {
  //   return (
  //     <BottomPanel>
  //       <StyledButton
  //         variant="contained"
  //         fullWidth
  //         disableElevation
  //         onClick={handleVerifyCode}
  //         disabled={disable}>
  //         驗證
  //       </StyledButton>
  //     </BottomPanel>
  //   );
  // };

  const confirmModalButtonForResendFail = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setShowResendFailAlert(false),
    },
  ];

  const confirmModalButtonForVerifyFail = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setShowAlert(false),
    },
  ];

  const confirmModalButtonForSuccess = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => navigate('/email'),
    },
  ];

  return (
    <>
      <NavigationBar leftButtonHandler={handleBack} noBackgroundColor />
      <Box padding="72px 16px">
        <Typography fontSize={26} fontWeight={400}>
          Email 驗證碼
        </Typography>
        <Typography color="text.purple">將傳送至 {user?.email}</Typography>
        {emailDesc()}

        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            left: 16,
            right: 16,
          }}>
          <Box display="flex" justifyContent="end" alignItems="center">
            {state?.entry === '/signUp' ? (
              <EveButton
                variant="contained"
                sx={{
                  width: '56px',
                  height: '56px',
                  borderRadius: '50%',
                  minWidth: 'unset',
                  padding: 0,
                }}
                onClick={handleVerifyCode}
                disabled={disable}>
                <ArrowForwardIcon />
              </EveButton>
            ) : (
              <CustomButton
                variant="contained"
                fullWidth
                disableElevation
                onClick={handleVerifyCode}
                disabled={disable}>
                送出
              </CustomButton>
            )}
          </Box>
        </Box>

        {/* <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message="您輸入的驗證碼有誤，請確認輸入內容是否正確。"
        actionLabel="確定"
        actionHandler={() => setShowAlert((prev) => !prev)}
      /> */}
        {/* <Dialog
        showDialog={showResendFailAlert}
        setShowDialog={setShowResendFailAlert}
        title="重新寄送失敗"
        message="請稍後再試。"
        actionLabel="確定"
        actionHandler={() => setShowResendFailAlert(false)}
      /> */}

        <Modal
          title="重新寄送失敗"
          enabled={showResendFailAlert}
          buttons={confirmModalButtonForResendFail}
          buttonDirection="column">
          <Typography
            fontWeight={400}
            fontSize={16}
            textAlign="center"
            color="text.secondary">
            請稍後再試。
          </Typography>
        </Modal>

        <Modal
          title={alertTitle.current}
          enabled={showAlert}
          buttons={confirmModalButtonForVerifyFail}
          buttonDirection="column">
          <Typography
            fontWeight={400}
            fontSize={16}
            textAlign="center"
            color="text.secondary">
            您輸入的驗證碼有誤，請確認輸入內容是否正確。
          </Typography>
        </Modal>
        {/* Change email successful modal */}
        <Modal
          title="電子郵件變更申請"
          enabled={showSuccessAlert}
          buttons={confirmModalButtonForSuccess}
          buttonDirection="column">
          <Typography
            fontWeight={400}
            fontSize={16}
            textAlign="left"
            color="text.secondary">
            您的 Email 已完成變更。
          </Typography>
        </Modal>
      </Box>
    </>
  );
}
