import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  backgroundColor: '#3E1EE6',

  '&.Mui-disabled': {
    backgroundColor: '#CCC3F8',
    color: theme.palette.text.primary,
  },
}));

export default StyledButton;
