import { useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { TitleLabel } from '../../../components/Label';
import { List, ListItem } from '@mui/material';
import FileUploadButton from './FileUploadButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EddImageList from './EddImageList';

// anchor<string>: top, left, bottom, right
export default function SwipeableTemporaryDrawer({
  anchor,
  onUpload,
  children,
  descriptItem,
  images,
  onDelete,
}) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const getDrawerContent = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        padding: '16px 24px',
        backgroundColor: '#231A54',
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}>
      <TitleLabel display="flex" justifyContent="center">
        上傳檔案
        <CloseOutlinedIcon
          style={{ fontSize: 30 }}
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            right: '18px',
            color: 'white',
          }}
          onClick={toggleDrawer(anchor, false)}
        />
      </TitleLabel>

      <Box
        sx={{
          fontSize: '15px',
          fontWeight: 700,
        }}>
        範例說明
      </Box>
      <List
        sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside',
        }}>
        {descriptItem.map(
          (
            text,
            index, //上傳圖片的說明文字
          ) => (
            <ListItem
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                fontSize: '15px',
                fontWeight: 400,
                '&:before': {
                  content: '"•"',
                  paddingRight: '8px',
                  fontSize: '20px',
                  lineHeight: '20px',
                },
              }}>
              {text}
            </ListItem>
          ),
        )}
      </List>
      {/*
      <Box display="flex" justifyContent="center">
        <img src="demo_img_for_proof_of_financial_resources.png" alt="" />
      </Box>
       */}
      <Box display="flex" justifyContent="center">
        {images.length > 0 && (
          <EddImageList images={images} onDelete={onDelete} />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          marginTop: '54px',
        }}>
        <FileUploadButton
          content="從相簿選擇"
          type="file"
          onUpload={onUpload}
        />
        <FileUploadButton content="拍照" type="camera" onUpload={onUpload} />
      </Box>
    </Box>
  );

  return (
    <div>
      <Box onClick={toggleDrawer(anchor, true)}>{children}</Box>
      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
        PaperProps={{
          sx: {
            borderRadius: '20px 20px 0 0',
          },
        }}>
        {getDrawerContent(anchor)}
      </SwipeableDrawer>
    </div>
  );
}
