import { React, useState, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Box, Stack, TextField } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import Icon from '../../components/Icon';
import { UserContext } from '../../UserContext';
import BottomPanel from '../../components/BottomPanel';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import { Label } from '../../components/Label';
import StyledButton from '../../components/StyledButton';

const maxVerifyCodeLength = 6;

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '17px',
  fontWeight: 'medium',
  textAlign: 'center',
}));

const DigitLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '23px',
  fontWeight: 'medium',
  textAlign: 'center',
}));

export default function EmailVerifyPage() {
  const { setLoading, user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState('');
  const { state } = useLocation();
  const [disable, setDisable] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showResendFailAlert, setShowResendFailAlert] = useState(false);
  const alertTitle = useRef('');
  const textInput = useRef(null);

  const handleBack = () => {
    navigate('/');
  };

  const navigation = () => {
    return <NavigationBar title="輸入驗證碼" leftButtonHandler={handleBack} />;
  };

  const handleVerifyCodeUpdate = (e) => {
    const code = e.target.value.replace(/\D/g, '');
    if (code.length > maxVerifyCodeLength) {
      return;
    }
    setVerifyCode(code);
    setDisable(code.length === maxVerifyCodeLength ? false : true);
  };

  const handleVerifyCode = async () => {
    setLoading(true);
    const responseData = await UserAPI.verifyEipEmailCode({
      code: verifyCode,
      token: state.token,
      email: state.email,
    });
    setLoading(false);

    if (responseData) {
      window.open(responseData.redirectUrl, '_blank');
    } else {
      alertTitle.current = '發生錯誤，請稍後再試';
      setShowAlert(true);
    }
  };

  const handleResendVerifyCode = async () => {
    setShowAlert(false);
    setLoading(true);
    const success = await UserAPI.getEipEmailCode({
      email: state.email,
    }).catch((error) => {
      setShowResendFailAlert(true);
    });
    setLoading(false);

    if (success) {
      setVerifyCode('');
    }
  };

  const handleChangeEmail = () => {
    setShowAlert(false);
    navigate('/eipEmail?token=' + state.token);
  };

  const verifyDescription = () => {
    return (
      <Box width={'100%'} marginTop="10px" marginBottom="37px">
        <DescriptionLabel>
          驗證碼已傳送至{state.email}，請輸入驗證碼以完成載具綁定程序
        </DescriptionLabel>
      </Box>
    );
  };

  const getDigitLabel = (value) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="35px"
        sx={{ margin: '8px' }}>
        <Box height="40px">
          <DigitLabel>{value}</DigitLabel>
        </Box>

        <Box
          sx={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}></Box>
      </Box>
    );
  };

  const getDigitGroup = () => {
    let digits = [];
    for (let i = 0; i < maxVerifyCodeLength; i++) {
      const value = verifyCode.charAt(i);
      const digit = getDigitLabel(value);
      digits.push(digit);
    }
    return (
      <Box
        display="flex"
        onClick={() => {
          textInput.current.focus();
        }}>
        {digits}
      </Box>
    );
  };

  const emailDesc = () => {
    return (
      <Stack
        spacing={2}
        direction={'column'}
        alignItems="center"
        sx={{ mt: '40px' }}>
        <Box>
          <Icon src="img_email.png" width={150} height={70} />
        </Box>

        <Box>{verifyDescription()}</Box>

        {getDigitGroup()}

        <Box sx={{ opacity: 0 }} width="100%">
          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            hiddenLabel
            autoComplete="off"
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
            value={verifyCode}
            onChange={handleVerifyCodeUpdate}
            autoFocus={true}
            inputRef={textInput}
          />
        </Box>

        <Box>
          <Label
            fontSize="15px"
            fontWeight="bold"
            color="#717171"
            align="center"
            sx={{ marginTop: '30px' }}>
            沒有收到驗證信或驗證碼過期?
          </Label>

          <Box display="flex" justifyContent="center">
            <StyledButton variant="text" onClick={handleResendVerifyCode}>
              重新寄送
            </StyledButton>
            <Label
              fontSize="15px"
              fontWeight="bold"
              color="#717171"
              align="center"
              sx={{ marginTop: '8px' }}>
              或
            </Label>
            <StyledButton variant="text" onClick={handleChangeEmail}>
              更改Email
            </StyledButton>
          </Box>
        </Box>
      </Stack>
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleVerifyCode}
          disabled={disable}>
          驗證
        </StyledButton>
      </BottomPanel>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box padding="16px">{emailDesc()}</Box>
        {bottomPanel() /* 驗證按鈕 */}
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message="請點選重新寄送驗證碼，如一直無法收到驗證信，請考慮更換其他電子信箱，謝謝。"
        actionLabel="重新寄送"
        actionHandler={handleResendVerifyCode}
        secondaryActionLabel="更換電子信箱"
        secondaryActionHandler={handleChangeEmail}
      />

      <Dialog
        showDialog={showResendFailAlert}
        setShowDialog={setShowResendFailAlert}
        title="重新寄送失敗"
        message="驗證碼尚未失效，請稍後再試。"
        actionLabel="確定"
        actionHandler={() => setShowResendFailAlert(false)}
      />
    </>
  );
}
