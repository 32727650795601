import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import Box from '@mui/material/Box';
import { PriceNumberFormat } from '../../utils/CoinNumberFormat';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { SvgIcon } from '../../components/Icon';
import PairActionButton from './PairActionButton';

const iconMap = {
  TWD: '/icons/NTD.svg',
  BTC: '/icons/BTC.svg',
  ETH: '/icons/ETH.svg',
};

export default function AccountAssetLayout({
  currencyId,
  chineseName,
  availableBalance,
  twdValue,
  actionNameOne,
  actionNameTwo,
  actionOne,
  actionTwo,
  children,
  isDisabled,
}) {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/asset');
  };

  return (
    <>
      <NavigationBar title="" leftButtonHandler={handleBack} />
      {/* // TODO: 要跟 Melody 確認 figma 沒有標題，是真低沒有嗎？ */}
      <Box padding="60px 16px">
        <Stack spacing={1.5}>
          <Stack direction="row" spacing={1} alignItems="center">
            <SvgIcon src={iconMap[currencyId]} width={30} height={30} />
            <Typography fontSize={24} fontWeight={400}>
              {chineseName}
            </Typography>
          </Stack>
          <Typography fontSize={16} fontWeight={300}>
            資產估值 (TWD)
          </Typography>
          <Typography fontSize={40} fontWeight={400}>
            <PriceNumberFormat
              value={currencyId === 'TWD' ? availableBalance : twdValue}
              prefix="$"
              suffix=""
            />
          </Typography>
        </Stack>
        <PairActionButton
          nameOne={actionNameOne}
          nameTwo={actionNameTwo}
          actionOne={actionOne}
          actionTwo={actionTwo}
          isDisabled={isDisabled}
        />
        <Typography fontSize={18} fontWeight={590} marginTop={2.5}>
          資產紀錄
        </Typography>
        {children}
      </Box>
    </>
  );
}
