import React from 'react';
import { Box } from '@mui/material';
import { Label } from '../Label';

export default function AccountInfoCell({ item }) {
  const handleClick = () => {
    if (item?.handler) {
      item.handler();
    }
  };

  return (
    <Box
      // bgcolor='primary.main'
      alignItems="center"
      justifyContent="space-between"
      height="72px"
      display="flex"
      onClick={handleClick}>
      <Box display="flex" alignItems="center">
        <Label fontSize="17px" fontWeight="bold">
          {item.textLeft}
        </Label>
      </Box>

      <Box display="flex" alignItems="center">
        {item.textRight && (
          <Label fontSize="17px" fontWeight="regular">
            {item.textRight}
          </Label>
        )}
      </Box>
    </Box>
  );
}
