import {
  useState,
  useEffect,
  useRef,
  useReducer,
  useContext,
  useCallback,
} from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { UserAPI } from '../../apis/UserAPI';
import DigitLabelBox from './components/DigitLabelBox';
import HiddenTextField from './components/HiddenTextField';
import { Label } from '../../components/Label';
import NavigationBar from '../../components/NavigationBar';
import { UserContext } from '../../UserContext';
import deepCopy from '../../utils/deepCopy';
import {
  maxVerifyCodeLength,
  phoneVerifyCodeReducer as reducer,
} from '../../reducers/phoneVerifyCodeReducer';
import EveButton from '../../components/EveButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Modal from '../../components/Modal';
import CustomButton from '../../components/CustomButton';

const initState = {
  numbers: [{ value: '|', animated: true }].concat(
    Array(maxVerifyCodeLength - 1).fill({ value: '', animated: false }),
  ),
  currentPos: 0,
  verifyDisable: true,
};

export default function PhoneVerifyPage() {
  const countDown = moment.duration(60, 'seconds');
  const [verifyCode, dispatch] = useReducer(reducer, initState, deepCopy);
  const navigate = useNavigate();
  const { setLoading, user, setUser } = useContext(UserContext);
  const { state } = useLocation();
  const [disableResend, setDisableResend] = useState(true);
  const [showCountDown, setShowCountDown] = useState(false);
  const intervalRef = useRef(); // 用來存 setInterval 的 id
  const [duration, setDuration] = useState(countDown);
  const [showAlert, setShowAlert] = useState(false);
  const [showResendFailAlert, setShowResendFailAlert] = useState(false);
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  const textInput = useRef(null);

  const handleBack = () => {
    if (state?.entry) {
      return navigate(state?.entry);
    }

    return navigate('/');
  };

  const handleKeyDown = (event) => {
    if (event.key.match(/^[0-9]$/)) {
      dispatch({ type: 'addNumber', payload: event.key });
    } else if (event.key === 'Backspace') {
      dispatch({ type: 'delete' });
    }
  };

  const handleResendVerifyCode = useCallback(async () => {
    setShowAlert(false);
    setLoading(true);
    const response = await UserAPI.resendPhoneVerifyCode(); //TODO: changeReasonType here when API available
    setLoading(false);
    if (response?.error) {
      alertMessage.current = response?.error.message;
      setShowResendFailAlert(true);
      setShowCountDown(false);
      dispatch('reset');
    } else {
      setDuration(countDown);
      setShowCountDown(true);
      setDisableResend(true);
      dispatch('reset');
    }
  }, [setLoading]);

  const handleGetPhoneExpireTime = useCallback(async () => {
    setShowAlert(false);
    setLoading(true);
    // TODO: 從這裡可以發現根本沒有用到 ExpireTime，這代表當你離開頁面時，再回來這個頁面秒數會從60秒開始倒數，因為沒有去計算還剩多少時間
    const response = await UserAPI.getPhoneExpireTime();
    setLoading(false);

    if (response?.error) {
      handleResendVerifyCode();
    } else {
      setDuration(countDown);
      setShowCountDown(true);
      setDisableResend(true);
      dispatch('reset');
    }
  }, [setLoading, handleResendVerifyCode]);

  const handleVerifyCode = async () => {
    setLoading(true);
    const response = await UserAPI.phoneVerifyCode({
      code: verifyCode.numbers.map((number) => number.value).join(''),
    });
    setLoading(false);
    if (response?.error) {
      alertTitle.current = '驗證失敗';
      alertMessage.current = response?.error.message;
      setShowAlert(true);
    } else {
      if (state.entry === '/email') {
        navigate('/changeEmail', { state: { entry: state.entry } });
      } else {
        setUser(response);
        navigate('/emailVerify', { state: { entry: state.entry } });
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setDuration(moment.duration(duration.asMilliseconds() - 1000));
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [duration]);

  useEffect(() => {
    if (duration.asMilliseconds() < 0) {
      clearInterval(intervalRef.current);
      setDisableResend(false);
      setShowCountDown(false);
    }
  }, [duration]);

  useEffect(() => {
    handleGetPhoneExpireTime();
  }, [handleGetPhoneExpireTime]);

  const confirmModalButtonForResendFail = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setShowResendFailAlert(false),
    },
  ];

  const confirmModalButtonForVerifyFail = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setShowAlert(false),
    },
  ];

  function getTitle(previousPage) {
    if (previousPage === '/newPhoneConfirm') {
      return '變更手機號碼';
    } else if (previousPage === '/email') {
      return '變更電子郵件';
    }
    return '';
  }

  return (
    <>
      <NavigationBar
        title={getTitle(state?.entry)}
        leftButtonHandler={handleBack}
        noBackgroundColor
      />
      <Box padding="72px 16px">
        <Typography fontSize={26} fontWeight={400} marginTop="12px">
          手機驗證碼
        </Typography>
        <Typography color="text.purple">
          將傳送至 {user?.phoneNumber}
        </Typography>
        <Stack
          spacing={0}
          direction="column"
          alignItems="center"
          marginTop="16px">
          <Box
            display="flex"
            width="100%"
            flexDirection="row"
            spacing={1}
            gap="11px"
            justifyContent="space-between"
            // click數字框重新focus
            onClick={() => {
              textInput.current.focus();
            }}>
            {verifyCode.numbers.map((number, index) => {
              return (
                <DigitLabelBox
                  key={index}
                  value={number.value}
                  animated={number.animated}
                  isEditing={index === verifyCode.currentPos}
                />
              );
            })}
          </Box>

          {/* 隱藏的input*/}
          <HiddenTextField inputRef={textInput} value={verifyCode} />

          <Box>
            {showCountDown ? (
              <Label fontSize="14px" color="#FFFFFF" align="center">
                {duration.asSeconds().toFixed(0)}s
              </Label>
            ) : (
              <EveButton
                variant="text"
                onClick={handleResendVerifyCode}
                sx={{
                  pointerEvents: disableResend ? 'none' : 'auto',
                  color: disableResend ? 'transparent' : '#FFFFFF',
                  fontSize: '14px',
                  fontWeight: 400,
                }}>
                · 重新發送驗證碼
              </EveButton>
            )}
          </Box>
        </Stack>
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            left: 16,
            right: 16,
          }}>
          {state?.entry === '/email' ? (
            <CustomButton
              variant="contained"
              disabled={verifyCode.verifyDisable}
              fullWidth
              disableElevation
              onClick={handleVerifyCode}>
              送出
            </CustomButton>
          ) : (
            <Box display="flex" justifyContent="end" alignItems="center">
              <EveButton
                variant="contained"
                sx={{
                  width: '56px',
                  height: '56px',
                  borderRadius: '50%',
                  minWidth: 'unset',
                  padding: 0,
                }}
                onClick={handleVerifyCode}
                disabled={verifyCode.verifyDisable}>
                <ArrowForwardIcon />
              </EveButton>
            </Box>
          )}
        </Box>

        <Modal
          title="寄送失敗"
          enabled={showResendFailAlert}
          buttons={confirmModalButtonForResendFail}
          buttonDirection="column">
          <Typography
            fontWeight={400}
            fontSize={16}
            textAlign="center"
            color="text.secondary">
            {alertMessage.current}
          </Typography>
        </Modal>

        <Modal
          title={alertTitle.current}
          enabled={showAlert}
          buttons={confirmModalButtonForVerifyFail}
          buttonDirection="column">
          <Typography
            fontWeight={400}
            fontSize={16}
            textAlign="center"
            color="text.secondary">
            {alertMessage.current}
          </Typography>
        </Modal>
      </Box>
    </>
  );
}
