import { useContext, useEffect } from 'react';
import { UserAPI } from '../apis/UserAPI';
import { UserContext } from '../UserContext';
/*
const UPDATE_USER_STATUS_PATH = [
  '/', // 首頁(幣價頁)
  '/member', // 會員中心頁
  '/asset', // 我的資產頁
  '/withdraw/bank', // 台幣提領頁
  '/deposit/bank', // 台幣儲值頁(銀行)
  '/deposit/store', // 台幣儲值頁(門市)
  '/bankAccount', // 銀行綁定資訊頁面
  '/bankStatus', // 銀行狀態頁面
];*/

function useUpdateUserStatus(pathName, verifiedPaths) {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    async function getUserStatus() {
      const response = await UserAPI.getUser();
      if (!response?.error) {
        setUser(response);
      }
    }

    if (user && !verifiedPaths.includes(pathName)) {
      getUserStatus();
    }
  }, [pathName]);

  return { user };
}

export default useUpdateUserStatus;
