import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { TitleLabel } from '../../components/Label';
import { useContext, useState } from 'react';
import { UserContext } from '../../UserContext';
import secureLocalStorage from 'react-secure-storage';
import EveButton from '../../components/EveButton';
import TextField from '@mui/material/TextField';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSnackbar } from '../../hooks/useSnackbar';

const EntranceWarning = () => {
  return (
    <Box mt="16px">
      <TitleLabel fontSize="15px" mt="48px" align="center">
        請由台灣大客服 APP 登入
      </TitleLabel>
    </Box>
  );
};

function ActionButtons({ loginPhone, navigate }) {
  const [phoneError, setPhoneError] = useState('');
  const { showSnackbar, EveSnackbar } = useSnackbar(phoneError, {
    vertical: 'top',
    horizontal: 'center',
  });

  const validatePhoneNumber = (value) => {
    if (!value) {
      setPhoneError('請輸入手機號碼');
      showSnackbar(true);
      return false;
    }
    if (!/^\d+$/.test(value)) {
      setPhoneError('只能輸入數字');
      showSnackbar(true);
      return false;
    }
    if (value.length !== 10) {
      setPhoneError('手機號碼必須為10碼');
      showSnackbar(true);
      return false;
    }
    if (!value.startsWith('09')) {
      setPhoneError('手機號碼必須以09開頭');
      showSnackbar(true);
      return false;
    }
    setPhoneError('');
    return true;
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <EveButton
          variant="text"
          sx={{ color: '#FFFFFF' }}
          onClick={() => {
            const isValid = validatePhoneNumber(loginPhone);
            if (isValid) navigate('/signIn');
          }}>
          我要直接登入
        </EveButton>
        <EveButton
          variant="contained"
          sx={{ padding: '9px 20px', borderRadius: '20px' }}
          onClick={() => {
            const isValid = validatePhoneNumber(loginPhone);
            if (isValid) navigate('/signUp');
          }}>
          註冊 <ArrowForwardIcon sx={{ ml: 0.4 }} />
        </EveButton>
      </Stack>
      <EveSnackbar />
    </>
  );
}

export default function LandingPage() {
  const { user, setNavTitle, loginPhone, setLoginPhone } =
    useContext(UserContext);

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Only allow numbers
    if (value === '' || /^\d+$/.test(value)) {
      setLoginPhone(value);
    }
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/');
  };
  const csappToken = secureLocalStorage.getItem('csappToken');

  return (
    <>
      <NavigationBar leftButtonHandler={handleBack} />
      <Stack padding="72px 16px" spacing={2}>
        <Stack spacing={1}>
          <Typography fontWeight={400} fontSize={26} letterSpacing={-0.4}>
            請輸入手機號碼
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={14}
            letterSpacing={-0.4}
            color="text.purple">
            登入/註冊您的帳戶
          </Typography>
        </Stack>
        <TextField
          autoFocus
          inputProps={{
            inputMode: 'numeric',
            maxLength: 10,
          }}
          placeholder="請輸入您的手機號碼"
          fullWidth
          variant="outlined"
          size="small"
          sx={{
            borderRadius: '8px',
            backgroundColor: (theme) => theme.palette.common.inputBackground,
            '& .MuiInputBase-input.Mui-disabled': {
              color: (theme) => theme.palette.text.primary,
              WebkitTextFillColor: (theme) => theme.palette.text.primary, // this is needed to override WebKit browsers default behavior
            },
            '& .MuiInputBase-input': {
              height: '56px',
              padding: '0 14px',
            },
          }}
          value={loginPhone}
          onChange={handlePhoneChange}
        />
        <Box display="flex" justifyContent="center" alignItems="center">
          <EveButton
            variant="text"
            sx={{ color: '#FFFFFF' }}
            onClick={() => setLoginPhone(user?.phoneNumber || '')}>
            填入 {user?.phoneNumber}
          </EveButton>
        </Box>

        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            left: 16,
            right: 16,
          }}>
          {csappToken ? (
            <ActionButtons loginPhone={loginPhone} navigate={navigate} />
          ) : (
            <EntranceWarning />
          )}
        </Box>
      </Stack>
    </>
  );
}
