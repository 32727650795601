import { useState, useEffect, useRef, useContext } from 'react';
import useWebSocket from 'react-use-websocket';
import humps from 'humps';
import { UserContext } from '../../UserContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Panel from '../../components/Panel';
import {
  Paper,
  Typography,
  TextField,
  Grid,
  List,
  ListItem,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BottomPanel from '../../components/BottomPanel';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { TitleLabel, Label } from '../../components/Label';
import CheckIcon from '@mui/icons-material/Check';
import NumberFormat from 'react-number-format';
import { ProductAPI } from '../../apis/ProductAPI';
import { OrderAPI } from '../../apis/OrderAPI';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';
import Dialog from '../../components/Dialog';
import StyledButton from '../../components/StyledButton';
import OrderCheckSlider from '../../components/trade/OrderCheckSlider';
import {
  Round,
  CountDecimals,
  ThousandSeparator,
  ReplaceCommaToNumber,
  ReplaceCommaToInt,
  ReplaceStringToInt,
  ReplaceStringToNumber,
  CheckLeadingZero,
  InputCursorPosition,
} from '../../utils/CoinNumberFormat';
import { UserAPI } from '../../apis/UserAPI';
import useInvoice from '../../hooks/useInvoice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DrawerWrapper from '../../components/DrawerWrapper';
import { AccountAPI } from '../../apis/AccountAPI';
import Modal from '../../components/Modal';

export default function BuyPage() {
  const [showAlert, setShowAlert] = useState(false);
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const { user, setLoading } = useContext(UserContext);
  const { getCarrierType, getCarrierCode } = useInvoice();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const productId = params.coin;
  const initData = useRef(false);
  const [product, setProduct] = useState(null);
  const buyPriceRef = useRef({
    balance: 0,
    bank: 0,
    store: 0,
    dcb: 0,
  });
  const [size, setSize] = useState(0);
  const [expired, setExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10);
  const timeLeftIdRef = useRef(false);
  const sizeRef = useRef(0);
  const priceRef = useRef(0);
  const websocketPriceRef = useRef(0);
  const [sizeFocus, setSizeFocus] = useState(false);
  const [price, setPrice] = useState(0);
  const sizeFocusRef = useRef(false);
  const [priceFocus, setPriceFocus] = useState(true);
  const priceFocusRef = useRef(true);
  const defaultPaymentValue = location?.state?.paymentValue;
  const [paymentValue, setPaymentValue] = useState(
    defaultPaymentValue ? defaultPaymentValue : 'balance',
  );
  const [showDialog, setShowDialog] = useState(false);
  const [balanceState, setBalanceState] = useState(0);
  const [coinInfo, setCoinInfo] = useState([]);
  const [isCoinDialogOpen, setIsCoinDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [currentCoin, setCurrentCoin] = useState('');
  const [accountsRes, setAccountsRes] = useState([]);
  const invoiceTabRef = useRef(0);
  const billInfoDataRef = useRef(null);
  const carrierRef = useRef(getCarrierType('A'));
  const phoneBarcodeRef = useRef(null);
  const citizenDigitalRef = useRef(null);
  const cityRef = useRef(null);
  const districtRef = useRef(null);
  const addressRef = useRef(null);
  const doneeRef = useRef(null);
  const doneeCodeRef = useRef(null);
  const billNameRef = useRef(null);
  const balance = useRef(0);
  const balanceAvailableRef = useRef(true);
  const bankAvailableRef = useRef(true);
  const storeRemainingRef = useRef(0);
  const storeMaxAmountRef = useRef(0);
  const storeAvailableRef = useRef(true);
  const dcbMaxAmountRef = useRef(0);
  const dcbAvailableRef = useRef(true);
  const inputSizeRef = useRef(null);
  const inputPriceRef = useRef(null);
  const realtimePricesRef = useRef(null);
  const expiredIdRef = useRef(null);
  const maxFund = Number(product?.maxFund);
  const minFund = Number(product?.minFund);
  const [lastPrice, setLastPrice] = useState(0);
  const [lastSize, setLastSize] = useState(0);
  const [lastRealtime, setLastRealtime] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const defaultPrice = location?.state?.inputPrice;

  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => true,
    reconnectInterval: 3000,
    queryParams: {
      token: getWebsocketToken(),
    },
  });

  async function fetchProductData() {
    setLoading(true);
    const product = await ProductAPI.getProduct(productId);
    setLoading(false);
    realtimePricesRef.current = product?.realtimePrice;
    setProduct(product);
    let newSize;
    if (defaultPrice) {
      setPrice(ThousandSeparator(defaultPrice));
      priceRef.current = defaultPrice;
      newSize = defaultPrice / product?.realtimePrice[paymentValue].buyPrice;
    } else {
      setPrice(ThousandSeparator(product?.minFund));
      priceRef.current = product?.minFund;
      newSize =
        product?.minFund / product?.realtimePrice[paymentValue].buyPrice;
    }
    const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
    sizeRef.current = roundedSize;
    setSize(roundedSize);

    const priceData = (paymentType) =>
      product?.realtimePrice[paymentType].buyPrice;

    buyPriceRef.current = {
      balance: priceData('balance'),
      bank: priceData('bank'),
      store: priceData('store'),
      dcb: priceData('dcb'),
    };
    validateOrder();
  }

  async function fetchPaymentInfo() {
    const paymentInfo = await UserAPI.getPaymentInfo(productId, 'buy');
    setPaymentInfo(paymentInfo);
    for (let i = 0; i < paymentInfo.length; i++) {
      if (paymentInfo[i].payment === 'balance') {
        balance.current = parseInt(
          paymentInfo[i].remainingAmount,
        ).toLocaleString();
        setBalanceState(
          parseInt(paymentInfo[i].remainingAmount).toLocaleString(),
        );
        balanceAvailableRef.current = paymentInfo[i].available;
      }
      if (paymentInfo[i].payment === 'bank') {
        bankAvailableRef.current = paymentInfo[i].available;
      }
      if (paymentInfo[i].payment === 'store') {
        storeRemainingRef.current = parseInt(
          paymentInfo[i].remainingAmount,
        ).toLocaleString();
        storeMaxAmountRef.current = parseInt(
          paymentInfo[i].maxAmount,
        ).toLocaleString();
        storeAvailableRef.current = paymentInfo[i].available;
      }
      if (paymentInfo[i].payment === 'dcb') {
        dcbMaxAmountRef.current = parseInt(
          paymentInfo[i].maxAmount,
        ).toLocaleString();
        dcbAvailableRef.current = paymentInfo[i].available;
      }
    }

    // 判斷從儲值頁面回來時的付款方式
    if (defaultPaymentValue) {
      setPaymentValue(defaultPaymentValue);
    } else {
      const paymentOptions = ['balance', 'bank'];
      const selectedPayment = paymentOptions.find(
        (_, index) => paymentType[index]?.payment,
      );

      if (selectedPayment) {
        setPaymentValue(selectedPayment);
      }
    }
    validateOrder();
  }

  async function fetchBillInfoData() {
    const userData = await UserAPI.getBillInfo();
    billInfoDataRef.current = userData;

    if (userData.carrierType) {
      carrierRef.current = getCarrierType(userData.carrierType);
      phoneBarcodeRef.current = userData.phoneBarcode;
      citizenDigitalRef.current = userData.citizenDigitalCertificate;
    }
    cityRef.current = userData.billAddrCity.replace(/台/g, '臺');
    districtRef.current = userData.billAddrDistrict.replace(/台/g, '臺');
    addressRef.current = userData.billAddr;
    billNameRef.current = userData.billInfoName;
    doneeRef.current = userData.doneeName;
    doneeCodeRef.current = userData.doneeCode;
  }

  // fetch data for page usage
  useEffect(() => {
    if (initData.current === false) {
      initData.current = true;
      fetchProductData();
      fetchBillInfoData();
      fetchPaymentInfo();
    }
  }, [currentCoin]);

  // fetch data for Product Realtime Price
  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage?.data);
      if (data['id'] && data['status']) {
        return;
      }
      const realtimePrices = data;
      realtimePricesRef.current = realtimePrices[product.id];

      const updateProductRealtimePrice = (realtimePrice) => {
        const newProduct = { ...product };
        newProduct.realtimePrice = humps.camelizeKeys(realtimePrice);
        setProduct(newProduct);
      };

      if (product) {
        // to avoid using init product price when payment method change
        updateProductRealtimePrice(realtimePrices[product.id]);
      }

      const priceData = (paymentType) =>
        realtimePrices[product.id][paymentType].buy_price;

      websocketPriceRef.current = {
        balance: priceData('balance'),
        bank: priceData('bank'),
        store: priceData('store'),
        dcb: priceData('dcb'),
      };

      if (!isConfirmDialogOpen) {
        buyPriceRef.current = websocketPriceRef.current;
        if (sizeFocus) {
          handleSizeFocus();
        }
        if (priceFocus) {
          handlePriceFocus();
        }
      }
    }
  }, [lastMessage, sizeFocus, priceFocus, currentCoin]);

  // fetch data for Coin Type List
  useEffect(() => {
    async function fetchProducts() {
      const data = await ProductAPI.getProducts();
      setCoinInfo(data);
    }
    fetchProducts();
  }, [currentCoin]);

  useEffect(() => {
    async function fetchOrderData() {
      setLoading(true);
      const response = await AccountAPI.getAccounts();
      setAccountsRes(response);
      setLoading(false);
    }
    fetchOrderData();
  }, [product?.baseCurrencyId]);

  // 購買顆數的幣種列表 Dialog
  function CoinTypeList() {
    const navigate = useNavigate();
    function directPage(directCoin) {
      if (directCoin !== product?.baseCurrencyId) {
        setCurrentCoin(directCoin);
        initData.current = false;
        navigate(`/buy/${directCoin}-TWD`);
      }
      setIsCoinDialogOpen(false);
      return;
    }

    return (
      <List>
        {coinInfo?.map((option, index) => (
          <ListItem
            key={index}
            onClick={() => directPage(option.baseCurrency.id)}
            sx={{ marginBottom: '10px' }}>
            <Icon
              sx={{
                maxHeight: 40,
                maxWidth: 40,
                marginRight: '10px',
              }}
              src={`../icons/${option.baseCurrency.id}.svg`}
            />
            <Label>{option.baseCurrency.id}</Label>
            {product?.baseCurrencyId === option.baseCurrency.id && (
              <CheckIcon
                sx={{
                  maxHeight: 40,
                  maxWidth: 40,
                  marginLeft: 'auto',
                }}
              />
            )}
          </ListItem>
        ))}
      </List>
    );
  }

  // 付款方式列表 Dialog
  function PaymentList() {
    function handleChangePaymentType(payment) {
      setPaymentValue(payment);
      setIsPaymentDialogOpen(false);

      if (sizeFocus) {
        const newPrice = roundedPrice(payment);
        setPrice(ThousandSeparator(newPrice));
        priceRef.current = newPrice;
      }
      if (priceFocus) {
        const newSize = roundedSize(ReplaceCommaToInt(price), payment);
        setSize(newSize);
        sizeRef.current = newSize;
      }
      validateOrder(payment);
    }

    return (
      <List>
        {paymentType?.map((option, index) => (
          <ListItem
            key={index}
            onClick={() => handleChangePaymentType(option.payment)}
            sx={{ marginBottom: '10px', cursor: 'pointer' }}>
            <Icon
              sx={{
                maxHeight: 40,
                maxWidth: 40,
                marginRight: '10px',
              }}
              src={`../icons/${option.payment}.svg`}
            />
            <Box>
              <Box display="flex" gap="12px">
                <Label>{option.title}</Label>
                <Label>{option.subTitle}</Label>
              </Box>
              <Label fontSize="12px" sx={{ opacity: 0.7 }}>
                手續費 {option.fee}%
              </Label>
            </Box>
            {paymentValue === option.payment && (
              <CheckIcon
                sx={{
                  maxHeight: 40,
                  maxWidth: 40,
                  marginLeft: 'auto',
                }}
              />
            )}
          </ListItem>
        ))}
      </List>
    );
  }

  const handleExpired = () => {
    clearTimeout(expiredIdRef.current);
    setExpired(false);
    clearInterval(timeLeftIdRef.current);
    setTimeLeft(10);
    // setIsConfirmDialogOpen(false);
    // if (websocketPriceRef.current !== 0) {
    //   buyPriceRef.current = websocketPriceRef.current;
    // }
    if (priceFocus) {
      handlePriceFocus();
      setPriceFocus(true);
      priceFocusRef.current = true;
      setSizeFocus(false);
      sizeFocusRef.current = false;
    } else {
      handleSizeFocus();
      setSizeFocus(true);
      sizeFocusRef.current = true;
      setPriceFocus(false);
      priceFocusRef.current = false;
    }
  };

  const createOrder = async () => {
    const payload = {
      placeOrderType: sizeFocusRef.current ? 'quantity' : 'amount',
      product_id: productId,
      type: 'limit',
      side: 'buy',
      size: ReplaceCommaToNumber(lastSize),
      fund: ReplaceCommaToInt(lastPrice),
      payment: paymentValue,
      totalPrice:
        lastRealtime?.[paymentValue]?.buy_price ??
        lastRealtime?.[paymentValue]?.buyPrice ??
        null, //last
      exchangeRate:
        lastRealtime?.[paymentValue]?.buy_exchange_rate ??
        lastRealtime?.[paymentValue]?.buyExchangeRate ??
        null,
      originalPrice:
        lastRealtime?.[paymentValue]?.buy_original_price ??
        lastRealtime?.[paymentValue]?.buyOriginalPrice ??
        null,
    };

    setLoading(true);

    // const response = await fetch('http://localhost:3001/orders/');

    // const response = await fetch(
    //   'https://526fe8ba-b81a-4596-aac9-f79954d8a925.mock.pstmn.io/v1/user/me/orders/',
    // );

    // const order = await response.json();
    const order = await OrderAPI.createOrder(payload);

    if (!order?.error) {
      if (order.payment === 'balance') {
        if (getCarrierCode(carrierRef.current) === 'A') {
          const invoiceDetail = {
            phoneNumber: user.phoneNumber,
            email: billInfoDataRef.current.billInfoEmail,
            address: cityRef.current + districtRef.current + addressRef.current,
          };
          order['invoiceDetail'] = invoiceDetail;
        }
        setLoading(true);
        navigate('/tradeDone', {
          state: { order },
        });
      } else {
        navigate('/orderPayment', {
          state: { order: order, backNavigation: -2 },
        });
      }
    } else {
      navigate('/tradeDone', {
        state: {
          order,
          message: order?.error?.message,
        },
      });
      setLoading(false);
      handleExpired();
    }
    setLoading(false);
    setDisableButton(false);
    setIsConfirmDialogOpen(false);
  };

  function getFee(payment) {
    return paymentInfo?.find((data) => data.payment === payment)?.paymentFee;
  }

  const paymentType = [
    {
      title: '餘額帳戶',
      subTitle: balanceState + ' TWD',
      fee: getFee('balance'),
      payment: 'balance',
    },
    {
      title: '網路銀行轉帳',
      subTitle: '**' + user.virtualAccountNumber.slice(9),
      fee: getFee('bank'),
      payment: 'bank',
    },
  ];

  const handleSizeChange = (event) => {
    setSizeFocus(true);
    sizeFocusRef.current = true;
    setPriceFocus(false);
    priceFocusRef.current = false;
    event.target.value = CheckLeadingZero(
      ReplaceStringToNumber(event.target.value),
    );
    // InputCursorPosition(event, inputSizeRef);

    /*符合幣值最小位數長度*/
    /* 當比特幣數值小於時，才會觸發以下 function */
    if (event.target.value < 1) {
      // 輸入的值 !== 0 且至少有小數第一位
      if (
        event.target.value.indexOf('.') > 0 &&
        event.target.value.charAt(event.target.value.length - 1) !== '.' &&
        Number(event.target.value.charAt(event.target.value.length - 1)) !== 0
      ) {
        const roundedSize = Round(
          ReplaceCommaToNumber(event.target.value), // 如果整數位部分有 `,` 則刪除
          CountDecimals(product?.baseIncrement), // 回傳小數位數
        );
        sizeRef.current = roundedSize;
        setSize(roundedSize);
      } else {
        // 值 = 0
        if (
          // 值 = 0.
          Number(event.target.value.charAt(event.target.value.length - 1)) !== 0
        ) {
          sizeRef.current = event.target.value;
        } else {
          // 值 = 0
          sizeRef.current = Round(
            ReplaceCommaToNumber(event.target.value),
            CountDecimals(product?.baseIncrement),
          );
        }
        setSize(event.target.value);
      }
    } else {
      if (
        event.target.value.indexOf('.') > 0 &&
        event.target.value.charAt(event.target.value.length - 1) !== '.'
      ) {
        if (
          Number(event.target.value.charAt(event.target.value.length - 1)) !== 0
        ) {
          const roundedSize = Round(
            ReplaceCommaToNumber(event.target.value),
            CountDecimals(product?.baseIncrement),
          );
          sizeRef.current = roundedSize;
          setSize(roundedSize);
        } else {
          sizeRef.current = event.target.value;
          setSize(event.target.value);
        }
      } else {
        sizeRef.current = event.target.value;
        setSize(event.target.value);
      }
    }
    const newPrice = roundedPrice();
    setPrice(ThousandSeparator(newPrice));
    priceRef.current = newPrice;
    validateOrder();
  };

  const handleTWDChange = (event) => {
    setSizeFocus(false);
    sizeFocusRef.current = false;
    setPriceFocus(true);
    priceFocusRef.current = true;
    event.target.value = CheckLeadingZero(
      ReplaceStringToInt(event.target.value),
    );
    setPrice(ThousandSeparator(event.target.value));
    InputCursorPosition(event, inputPriceRef);
    priceRef.current = ReplaceCommaToInt(event.target.value);

    const newSize = roundedSize(ReplaceCommaToInt(event.target.value));
    setSize(newSize);
    sizeRef.current = newSize;
    validateOrder();
  };

  const handlePriceFocus = () => {
    const newSize = roundedSize(ReplaceCommaToInt(priceRef.current));
    sizeRef.current = newSize;
    setSize(newSize);
    validateOrder();
  };

  const handleSizeFocus = () => {
    priceRef.current = roundedPrice();
    setPrice(ThousandSeparator(priceRef.current));
    validateOrder();
  };

  useEffect(() => {
    if (timeLeft === 0) {
      handleRenewPrice();
    }
  }, [timeLeft]);

  const handleRenewPrice = () => {
    clearTimeout(expiredIdRef.current);
    setExpired(false);
    clearInterval(timeLeftIdRef.current);
    setTimeLeft(10);
    expiredIdRef.current = setTimeout(() => setExpired(true), 10000);
    timeLeftIdRef.current = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    if (priceFocus) {
      const newSize = Round(
        priceRef.current / realtimePricesRef.current?.[paymentValue]?.buy_price,
        CountDecimals(product?.baseIncrement),
      );
      setLastSize(newSize);
      setLastPrice(priceRef.current);
    }

    if (sizeFocus) {
      const newPrice = Round(
        ReplaceCommaToNumber(sizeRef.current) *
          realtimePricesRef.current?.[paymentValue]?.buy_price,
      );
      setLastSize(sizeRef.current);
      setLastPrice(newPrice);
    }
    setLastRealtime(realtimePricesRef.current);
    validateOrder();
  };

  const roundedSize = (price, paymentType = paymentValue) => {
    const newSize = price / buyPriceRef.current[paymentType];
    return Round(newSize, CountDecimals(product?.baseIncrement));
  };

  const roundedPrice = (paymentType = paymentValue) => {
    const newTWDPrice =
      ReplaceCommaToNumber(sizeRef.current) * buyPriceRef.current[paymentType];
    return Round(newTWDPrice);
  };

  const Icon = styled('img')({
    maxHeight: 40,
    maxWidth: 40,
  });

  const sxContainer = {
    pt: `${navigationBarHeight}px`,
  };

  const handleBack = () => {
    navigate(`/market/${productId}`);
  };

  const navigation = () => {
    return (
      <NavigationBar leftButtonHandler={handleBack}>
        <Button
          onClick={() => {
            navigate('/bankLimited');
          }}
          sx={{ color: '#fff', fontSize: '17px', fontWeight: '400' }}>
          銀行限額
        </Button>
      </NavigationBar>
    );
  };

  const handleFocus = () => {
    if (priceFocus === true) {
      setPriceFocus(false);
      setSizeFocus(true);
      inputSizeRef.current.focus();
    }
    if (sizeFocus === true) {
      setPriceFocus(true);
      setSizeFocus(false);
      inputPriceRef.current.focus();
    }
    if (sizeFocus === false && priceFocus === false) {
      setSizeFocus(true);
      inputSizeRef.current.focus();
    }
  };

  const titleLabel = () => {
    let inputPrice = priceRef.current;
    let tab = invoiceTabRef.current;
    let donee = doneeRef.current;
    let doneeCode = doneeCodeRef.current;
    let carrier = carrierRef.current;
    let phoneBarcode = phoneBarcodeRef.current;
    let citizenDigital = citizenDigitalRef.current;
    let city = cityRef.current;
    let district = districtRef.current;
    let address = addressRef.current;
    let billName = billNameRef.current;
    const matchedAccount = accountsRes.find(
      (size) => size.currencyId === product?.baseCurrencyId,
    );

    return (
      <Box margin="16px">
        <Grid container alignItems="center" rowSpacing={1}>
          <Grid item xs={8}>
            <Label fontSize="28px">你想買多少顆？</Label>
          </Grid>
          <Grid item xs={4}>
            {matchedAccount && matchedAccount.twdValue !== 0 && (
              <Label
                onClick={() => {
                  navigate(`/sell/${productId}`);
                }}
                fontSize="28px"
                textAlign="end"
                sx={{
                  cursor: 'pointer',
                  fontWeight: '400 !important',
                  background: 'linear-gradient(to right, #FFF, #231A54 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  MozBackgroundClip: 'text',
                  MozTextFillColor: 'transparent',
                  msBackgroundClip: 'text',
                  msTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                }}>
                我要賣幣
              </Label>
            )}
          </Grid>
        </Grid>

        <Grid container alignItems="center" marginTop="10px">
          <Grid item xs={5.3}>
            <Label fontSize="14px" sx={{ opacity: 0.5 }}>
              付款金額
            </Label>
            <Panel
              backgroundColor="#150F32"
              border={priceFocus ? '#3E1EE6 1px solid' : '#150F32 1px solid'}>
              <TitleLabel fontSize="13px" fontWeight="medium">
                <Box display="flex" alignItems="center" gap="8px">
                  <Icon
                    sx={{
                      maxHeight: 40,
                      maxWidth: 40,
                    }}
                    src="../icons/NTD.svg"
                  />
                  <Label>TWD</Label>
                </Box>
                <TextField
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  autoFocus={true}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 22,
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '20px 0px 4px 0px ',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                        borderWidth: '0px',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        borderWidth: '0px',
                      },
                    },
                  }}
                  inputRef={inputPriceRef}
                  defaultValue={0}
                  onChange={handleTWDChange}
                  onClick={() => {
                    setSizeFocus(false);
                    sizeFocusRef.current = false;
                    setPriceFocus(true);
                    priceFocusRef.current = true;
                  }}
                  value={price}
                />
              </TitleLabel>
            </Panel>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="5px">
              <Label fontSize="12px">最小下單金額 100 TWD</Label>
            </Box>
          </Grid>
          <Grid
            item
            xs={1.4}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <IconButton>
              <Icon
                onClick={handleFocus}
                sx={{
                  maxHeight: 40,
                  maxWidth: 40,
                  marginX: 'auto',
                  color: '#fff',
                }}
                src="../icons/arrow_path.svg"
              />
            </IconButton>
          </Grid>
          <Grid item xs={5.3}>
            <Label sx={{ opacity: 0.5 }} fontSize="14px">
              購買顆數
            </Label>
            <Panel
              backgroundColor="#150F32"
              border={sizeFocus ? '#3E1EE6 1px solid' : '#150F32 1px solid'}>
              <TitleLabel fontSize="13px" fontWeight="medium">
                <DrawerWrapper
                  title="選擇幣種"
                  anchor="bottom"
                  drawerContent={<CoinTypeList />}
                  open={isCoinDialogOpen}
                  setOpen={setIsCoinDialogOpen}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    sx={{ cursor: 'pointer' }}>
                    <Icon
                      sx={{
                        maxHeight: 40,
                        maxWidth: 40,
                      }}
                      src={`../icons/${product?.baseCurrencyId}.svg`}
                    />
                    <Label>{product?.baseCurrencyId}</Label>
                    <ArrowForwardIosIcon
                      style={{ fontSize: 18 }}
                      sx={{
                        color: '#fff',
                        marginLeft: 'auto',
                      }}
                    />
                  </Box>
                </DrawerWrapper>
                <TextField
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 22,
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '20px 0px 4px 0px ',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                        borderWidth: '0px',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        borderWidth: '0px',
                      },
                    },
                  }}
                  defaultValue={0}
                  inputRef={inputSizeRef}
                  onChange={handleSizeChange}
                  onClick={() => {
                    setSizeFocus(true);
                    sizeFocusRef.current = true;
                    setPriceFocus(false);
                    priceFocusRef.current = false;
                  }}
                  value={size}
                />
              </TitleLabel>
            </Panel>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="5px">
              <Label fontSize="12px" sx={{ opacity: 0.5 }}>
                <strong>1</strong>&nbsp;{product?.baseCurrencyId}
                &nbsp;&nbsp;≈&nbsp;&nbsp;
              </Label>
              <Label fontSize="12px" align="center" sx={{ opacity: 0.5 }}>
                <NumberFormat
                  value={buyPriceRef.current[paymentValue]}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={buyPriceRef.current[paymentValue] < 100 ? 2 : 0}
                  fixedDecimalScale={true}
                />
              </Label>
              <Label fontSize="12px" sx={{ opacity: 0.5 }}>
                &nbsp;&nbsp;TWD
              </Label>
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="space-between">
          <Label fontSize="14px" marginTop="24px">
            付款方式
          </Label>
          <Label
            fontSize="14px"
            marginTop="24px"
            color="#FFD66E"
            onClick={() =>
              navigate('/deposit/bank', {
                state: {
                  inputPrice,
                  productId,
                  paymentValue,
                  invoice: {
                    tab,
                    donee,
                    doneeCode,
                    carrier,
                    phoneBarcode,
                    citizenDigital,
                    city,
                    district,
                    address,
                    billName,
                  },
                },
              })
            }>
            我要儲值
          </Label>
        </Box>

        <DrawerWrapper
          title="選擇付款方式"
          anchor="bottom"
          drawerContent={<PaymentList />}
          open={isPaymentDialogOpen}
          setOpen={setIsPaymentDialogOpen}>
          <Box sx={{ cursor: 'pointer' }}>
            <Panel marginTop="6px" backgroundColor="#150F32">
              <Grid container alignItems="center">
                <Grid item xs={5}>
                  <Label fontSize="18px">
                    {
                      paymentType.find((item) => item.payment === paymentValue)
                        ?.title
                    }
                  </Label>
                </Grid>

                <Grid item xs={7}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end">
                    <Label>
                      {
                        paymentType.find(
                          (item) => item.payment === paymentValue,
                        )?.subTitle
                      }
                    </Label>

                    <ArrowForwardIosIcon
                      style={{ fontSize: 18, marginLeft: '16px' }}
                      sx={{
                        color: '#fff',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Panel>
          </Box>
        </DrawerWrapper>
        <Label
          fontSize="14px"
          color="#FBA28B"
          textAlign="end"
          marginTop="6px"
          sx={{ height: '27px' }}>
          {getWording(paymentValue)}
        </Label>
        <Label fontSize="12px" marginTop="42px">
          ※ 幣價已含手續費
          <br />
          ※ 下單成功後無法退款或取消。
          <br />※ 依法規定，發票開立後無法變更開立方式與任一資訊。
        </Label>
        {isConfirmDialogOpen && (
          <Paper
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              height: '100px',
              padding: '16px',
              zIndex: '1500',
              backgroundColor: 'transparent',
            }}>
            <OrderCheckSlider createOrder={createOrder} />
          </Paper>
        )}
      </Box>
    );
  };

  const abbrFund = (number) => {
    const stringifyNumber = number.toString();
    if (stringifyNumber.length > 4) {
      return `${stringifyNumber.slice(0, -4)} 萬`;
    } else return stringifyNumber;
  };

  function lowerUpperLimitCheck() {
    const price_int = ReplaceCommaToInt(priceRef.current);
    let limitResult = false;
    if (
      price_int > maxFund &&
      roundedPrice('store') > maxFund &&
      roundedPrice('bank') > maxFund
    ) {
      setShowAlert(true);
      limitResult = true;
      alertTitle.current = '超過限額';
      alertMessage.current = `超出單筆交易上限NT$ ${abbrFund(maxFund)}`;
    }

    if (
      price_int < minFund &&
      roundedPrice('store') < minFund &&
      roundedPrice('bank') < minFund
    ) {
      limitResult = true;
      setShowAlert(true);
      alertTitle.current = '請輸入金額';
      alertMessage.current = `低於單筆交易下限NT$ ${abbrFund(minFund)}`;
    }
    return limitResult;
  }

  const validateOrder = (payment = paymentValue) => {
    if (payment === 'balance') {
      if (!balanceAvailableRef.current) {
        return setDisableButton(true);
      }
      if (balance.current < 100) {
        return setDisableButton(true);
      }
      if (getWording(payment)) {
        return setDisableButton(true);
      }
    } else if (payment === 'bank') {
      if (!bankAvailableRef.current) {
        return setDisableButton(true);
      }
      if (getWording(payment)) {
        return setDisableButton(true);
      }
    }
    return setDisableButton(false);
  };

  const getWording = (paymentItem) => {
    const price_int = ReplaceCommaToInt(priceRef.current);
    const balance_int = ReplaceCommaToInt(balance.current);

    if (paymentItem === 'balance') {
      let basedPrice = '';
      let wording = '';
      if (sizeFocusRef.current) basedPrice = roundedPrice('balance');
      if (priceFocusRef.current) basedPrice = price_int;

      if (basedPrice > balance_int && balance_int >= 0) {
        // 帳戶餘額扣款
        wording = '餘額不足';
        return wording;
      }
    }

    return false;
  };

  // 把按鈕判斷邏輯也改成跟sell page一樣的 validate order!!!

  function handleNextButton() {
    const limitResult = lowerUpperLimitCheck();
    if (!limitResult) {
      expiredIdRef.current = setTimeout(() => setExpired(true), 10000);
      timeLeftIdRef.current = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      setLastSize(sizeRef.current);
      setLastPrice(priceRef.current);
      setLastRealtime(realtimePricesRef.current);
    } else {
      setIsConfirmDialogOpen(false);
    }
  }

  const bottomPanelButton = () => {
    return (
      <BottomPanel>
        <DrawerWrapper
          title=""
          anchor="bottom"
          drawerContent={<BottomDrawer />}
          open={isConfirmDialogOpen}
          setOpen={setIsConfirmDialogOpen}
          height="calc(100vh - 150px)">
          <StyledButton
            variant="contained"
            fullWidth
            disabled={disableButton}
            onClick={handleNextButton}
            disableElevation>
            下一步
          </StyledButton>
        </DrawerWrapper>
      </BottomPanel>
    );
  };

  useEffect(() => {
    if (!isConfirmDialogOpen) {
      handleExpired();
    }
  }, [isConfirmDialogOpen]);

  const BottomDrawer = () => {
    return (
      <Box sx={{ margin: '16px' }}>
        <TitleLabel fontSize="20px" fontWeight="bold" textAlign="center">
          請確認您的交易資訊
          <br />
          訂單成功後無法退款或取消
        </TitleLabel>

        <TitleLabel
          fontSize="13px"
          color="#FF8F08"
          sx={{ marginTop: '12px', textAlign: 'center' }}>
          價格有效時間 {timeLeft}
        </TitleLabel>

        <Grid container display="flex" alignItems="center" marginTop="40px">
          <Grid item xs={5.3}>
            <Panel backgroundColor="#150F32">
              <Label textAlign="center">TWD</Label>
              <Label fontSize="24px" textAlign="center">
                {lastPrice}
              </Label>
            </Panel>
          </Grid>
          <Grid
            item
            xs={1.4}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Icon
              sx={{
                maxHeight: 40,
                maxWidth: 40,
                marginX: 'auto',
              }}
              src="../icons/arrow_path.svg"
            />
          </Grid>
          <Grid item xs={5.3}>
            <Panel backgroundColor="#150F32">
              <Label textAlign="center">{product?.baseCurrencyId}</Label>
              <Label fontSize="24px" textAlign="center">
                {lastSize}
              </Label>
            </Panel>
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="12px"
          marginBottom="8px">
          <Label fontSize="14px" sx={{ opacity: 0.5 }}>
            <strong>1</strong>&nbsp;{product?.baseCurrencyId}
            &nbsp;&nbsp;≈&nbsp;&nbsp;
          </Label>
          <Label fontSize="14px" align="center" sx={{ opacity: 0.5 }}>
            <NumberFormat
              value={
                lastRealtime?.[paymentValue]?.buyPrice ??
                lastRealtime?.[paymentValue]?.buy_price ??
                null
              }
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
            />
          </Label>
          <Label fontSize="14px" sx={{ opacity: 0.5 }}>
            &nbsp;&nbsp;TWD
          </Label>
        </Box>
        <Panel backgroundColor="#150F32">
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <Label fontSize="16px">付款方式</Label>
            </Grid>

            <Grid item xs={7}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Label>
                  {
                    paymentType.find((item) => item.payment === paymentValue)
                      ?.title
                  }
                  {
                    paymentType.find((item) => item.payment === paymentValue)
                      ?.subTitle
                  }
                </Label>
              </Box>
            </Grid>
          </Grid>
        </Panel>
        <Label fontSize="12px" marginTop="42px">
          ※ 台灣大虛擬資產交易所保留取消此筆交易權利。
          <br />※ 幣價已含手續費。
        </Label>
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: '100px',
            padding: '16px',
            zIndex: '1200',
            backgroundColor: 'transparent',
          }}></Paper>
      </Box>
    );
  };

  const depositDialog = () => {
    let price = priceRef.current;
    let donee = doneeRef.current;
    let doneeCode = doneeCodeRef.current;
    let carrier = carrierRef.current;
    let phoneBarcode = phoneBarcodeRef.current;
    let citizenDigital = citizenDigitalRef.current;
    let city = cityRef.current;
    let district = districtRef.current;
    let address = addressRef.current;
    let billName = billNameRef.current;
    return (
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="台幣儲值"
        message="請選擇想要的台幣儲值方式"
        actionLabel="台灣大哥大門市"
        secondaryActionLabel="銀行轉帳"
        actionHandler={() => {
          navigate('/deposit/store', {
            state: {
              price,
              productId,
              paymentValue,
              invoice: {
                donee,
                doneeCode,
                carrier,
                phoneBarcode,
                citizenDigital,
                city,
                district,
                address,
                billName,
              },
            },
          });
        }}
        secondaryActionHandler={() => {
          if (user?.bankAccount?.status === 'succeed') {
            navigate('/deposit/bank', {
              state: {
                price,
                productId,
                paymentValue,
                invoice: {
                  donee,
                  doneeCode,
                  carrier,
                  phoneBarcode,
                  citizenDigital,
                  city,
                  district,
                  address,
                  billName,
                },
              },
            });
          } else if (
            user?.bankAccount?.status === 'failed' ||
            user?.bankAccount?.status === 'verifing'
          ) {
            navigate('/bankStatus', {
              state: { backNavigation: -1 },
            });
          } else {
            navigate('/bankAccount');
          }
        }}
      />
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        {titleLabel() /* 輸入購買幣值 */}
        <Box marginLeft="16px" marginBottom="90px">
          {bottomPanelButton() /* 購買 / 賣出按鈕 */}
        </Box>
      </Box>
      {/* Warning modal */}
      <Modal
        title={alertTitle.current}
        enabled={showAlert}
        buttons={[
          {
            variant: 'contained',
            label: '確定',
            onClick: () => {
              setShowAlert(false);
            },
          },
        ]}
        buttonDirection="column">
        <Box
          sx={{
            paddingTop: '12px',
            display: 'flex',
            alignItems: 'center', // Centers items vertically
            justifyContent: 'center', // Centers items horizontally
          }}>
          <Typography>{alertMessage.current}</Typography>
        </Box>
      </Modal>
      {depositDialog() /* 台幣儲值 */}
    </>
  );
}
