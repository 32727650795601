import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';
import { ProductAPI } from '../../apis/ProductAPI';
import { UserContext } from '../../UserContext';
import './slick-dot.css';
import Box from '@mui/material/Box';
import CryptoInfoItem from '../../components/market/CryptoInfoItem';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';

export default function MarketAllPage() {
  const { setLoading } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [realtimePrices, setRealtimePrices] = useState(null);
  const initData = useRef(false);
  const navigate = useNavigate();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => false,
    queryParams: {
      token: getWebsocketToken(),
    },
  });

  useEffect(() => {
    async function fetchProductData() {
      setLoading(true);
      const products = await ProductAPI.getProducts();
      setProducts(products);
      setLoading(false);
    }

    if (initData.current === false) {
      initData.current = true;
      fetchProductData();
    }

    if (lastMessage) {
      const data = JSON.parse(lastMessage?.data);
      if (data['id'] && data['status']) {
        return;
      }

      const prices = data;
      setRealtimePrices(prices);
    }
  }, [products, lastMessage, setLoading]);

  //Coin Price List
  const coinPriceList = () => {
    return (
      <>
        {products.map((product, index) => (
          <CryptoInfoItem
            key={index}
            product={product}
            realtimePrices={realtimePrices}
            onClick={() => {
              navigate(`/market/${product.id}`);
            }}
          />
        ))}
      </>
    );
  };

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Box sx={{ pt: `${navigationBarHeight}px` }}>
      <NavigationBar
        leftButtonHandler={handleBack}
        noBackgroundColor={true}
        title="Markets"
      />
      <Box margin="16px">{coinPriceList()}</Box>
    </Box>
  );
}
