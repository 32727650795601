import NavigationBar from '../../components/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { SvgIcon } from '../../components/Icon';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import EveButton from '../../components/EveButton';
import ProviderInformation from '../../components/ProviderInformation';
import EvePaper from '../../components/EvePaper';
import { UserContext } from '../../UserContext';
import { useContext } from 'react';

export default function PhoneNumberPage() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  return (
    <>
      <NavigationBar title="手機號碼" leftButtonHandler={() => navigate(-1)} />
      <Box
        padding="72px 16px"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Stack
          spacing={0}
          direction="column"
          alignItems="center"
          sx={{ flex: 1, position: 'relative' }}>
          <SvgIcon
            src={'/icons/info_phoneumber.svg'}
            width={176}
            height={176}
          />

          <EvePaper sx={{ height: '120px', marginTop: '20px' }}>
            <Stack spacing={0.5} marginTop="5px">
              <Typography fontWeight={400} fontSize={20} textAlign="center">
                目前綁定的手機號碼
              </Typography>
              <Typography fontWeight={510} fontSize={36} textAlign="center">
                {user?.phoneNumber}
              </Typography>
            </Stack>
          </EvePaper>
          <Box marginTop="25px">
            <ProviderInformation />
          </Box>

          <Stack
            width="100%"
            spacing={2}
            alignItems="center"
            sx={{
              position: 'fixed',
              bottom: 40,
              left: 0,
              right: 0,
              padding: '0 20px',
            }}>
            <EveButton
              variant="contained"
              sx={{ height: '48px', width: '100%' }}
              onClick={() => navigate('/identificationNumberConfirm')}>
              變更手機號碼
            </EveButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
