// 倒數計時會造成整頁渲染，需要優化
import { useState, useEffect, useContext, useRef, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import moment from 'moment/moment';

import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import BottomPanel from '../../components/BottomPanel';
import { SubTitleLabel, Label } from '../../components/Label';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import { UserContext } from '../../UserContext';
import { UserAPI } from '../../apis/UserAPI';
import DigitLabelBox from '../account/components/DigitLabelBox';
import {
  maxVerifyCodeLength,
  phoneVerifyCodeReducer as reducer,
} from '../../reducers/phoneVerifyCodeReducer';
import deepCopy from '../../utils/deepCopy';
import Modal from '../../components/Modal';
import CustomButton from '../../components/CustomButton';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

//Initialize value for OTP code
const initState = {
  numbers: [{ value: '|', animated: true }].concat(
    Array(maxVerifyCodeLength - 1).fill({ value: '', animated: false }),
  ),
  currentPos: 0,
  verifyDisable: true,
};

export default function BankWithdrawConfirmPage() {
  const navigate = useNavigate();
  const { setLoading, user, setUser, setTabIndex } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const { state } = useLocation();
  const { amount, bankAccountNumber, withdrawFee, bankName } = state;
  const alertRef = useRef({});
  const fee = withdrawFee;
  //const [verifyCode, setVerifyCode] = useState('');
  const [enableSendVefiyCode, setEnableSendVefiyCode] = useState(true);
  const intervalIdRef = useRef();
  //const intervalRef = useRef(); // 用來存 setInterval 的 id
  const [duration, setDuration] = useState(60); // 倒數秒數預設60s
  const [enableWithdraw, setEnableWithdraw] = useState(true);
  const [isCountDowning, setIsCountDowning] = useState(false);
  const [numberOfAttemptToSendCode, setNumberOfAttemptToSendCode] = useState(0);
  const [numberOfavailableConfirmation, setNumberOfavailableConfirmation] =
    useState(0);
  const [verifyCode, dispatch] = useReducer(reducer, initState, deepCopy);
  const [countSendCodeAttempt, setCountSendCodeAttempt] = useState(0);

  //Handle OTP Input
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key.match(/^[0-9]$/)) {
      dispatch({ type: 'addNumber', payload: event.key });
    } else if (event.key === 'Backspace') {
      dispatch({ type: 'delete' });
    }
  };

  async function initialize() {
    setLoading(true);

    const { isVerifyIncorrectOverLimit, isSendVerifyCountOverLimit } =
      await getWithdrawLimitation();

    if (!isVerifyIncorrectOverLimit && !isSendVerifyCountOverLimit) {
      await getWithdrawExpireTime();
    }

    setLoading(false);
  }

  async function getWithdrawLimitation() {
    const response = await UserAPI.getWithdrawLimitation();
    const isVerifyIncorrectOverLimit =
      response.isVerifyIncorrectOverLimit.result;
    const isSendVerifyCountOverLimit =
      response.isSendVerifyCountOverLimit.result;
    const sendVerifyCountRemain = response.isSendVerifyCountOverLimit.remain;
    const verifyIncorrectRemain = response.isVerifyIncorrectOverLimit.remain;
    const verifyIncorrectOverLimitMessage =
      response.isVerifyIncorrectOverLimit.message;
    const sendVerifyCountOverLimitMessage =
      response.isSendVerifyCountOverLimit.message;

    // 超過驗證碼錯誤上限次數，不能發送驗證碼，也不能確認提領
    if (isVerifyIncorrectOverLimit) {
      setEnableSendVefiyCode(false);
      setEnableWithdraw(false);
      navigate('/withdraw/bank/done', {
        state: {
          back: true,
          title: '提領申請失敗',
          message: verifyIncorrectOverLimitMessage,
        },
      });
      // 超過驗證碼發送上限次數，不能再發送驗證碼，但還是可以確認提領
    } else if (isSendVerifyCountOverLimit) {
      setEnableSendVefiyCode(false);
      navigate('/withdraw/bank/done', {
        state: {
          back: true,
          title: '提領申請失敗',
          message: sendVerifyCountOverLimitMessage,
        },
      });
      setNumberOfavailableConfirmation(verifyIncorrectRemain);
    } else {
      setNumberOfAttemptToSendCode(sendVerifyCountRemain);
      setNumberOfavailableConfirmation(verifyIncorrectRemain);
    }

    return { isVerifyIncorrectOverLimit, isSendVerifyCountOverLimit };
  }

  async function getWithdrawExpireTime() {
    const response = await UserAPI.getWithdrawExpireTime();
    // 驗證碼過期
    if (response?.error?.message === '驗證碼已失效') {
      setEnableSendVefiyCode(true);
    } else {
      // 驗證碼尚未到期，但倒數已超過1分鐘
      if (moment().unix() > Number(response.expiredTime) - 120) {
        setEnableSendVefiyCode(true);
      } else {
        setDuration(response.expiredTime - moment().unix() - 120);
        setEnableSendVefiyCode(false);
        setIsCountDowning(true);
      }
    }
  }

  function handleBack() {
    navigate(-1);
  }

  async function handleConfirm() {
    setLoading(true);

    const payload = {
      code: verifyCode.numbers.map((number) => number.value).join(''),
      amount: amount,
    };
    const response = await UserAPI.withdrawVerifyCode(payload);

    setLoading(false);

    if (response?.error) {
      if (response?.error.message === '驗證碼錯誤或已失效') {
        setShowAlert(true);
        setNumberOfavailableConfirmation((prev) => prev - 1);
        alertRef.current = {
          title: '發送驗證碼',
          message: response.error.message,
        };
      } else {
        navigate('/withdraw/bank/done', {
          state: {
            back: true,
            title: '提領申請失敗',
            bankRecord: response,
            fee: withdrawFee,
            message: response?.error.message,
          },
        });
      }
    } else {
      navigate('/withdraw/bank/done', {
        state: {
          back: true,
          title: '提領申請已送出',
          bankRecord: response,
          fee: withdrawFee,
          bankInfo:
            bankName +
            ' **' +
            user?.bankAccount?.accountNumber.substr(
              user?.bankAccount?.accountNumber.length - 5,
            ) +
            ' (' +
            user?.chineseName +
            ')',
        },
      });
    }
  }

  // 發送驗證碼
  async function handleSendverifyCode() {
    setLoading(true);
    setCountSendCodeAttempt((prev) => prev + 1);

    const response = await UserAPI.resendWithdrawVerifyCode();
    setIsCountDowning(true);

    if (response?.error) {
      alertRef.current.title = '發送驗證碼';
      alertRef.current.message = response?.error.message;
      setShowAlert(true);
    } else {
      setEnableSendVefiyCode(false);
      setNumberOfAttemptToSendCode((prev) => prev - 1);
    }

    setLoading(false);
  }

  // 進入頁面初始化
  useEffect(() => {
    initialize();
  }, []);

  // 倒數計時器
  // 1. 由isCountDowning判斷是否該啟動倒數計時器
  useEffect(() => {
    if (isCountDowning) {
      intervalIdRef.current = setInterval(() => {
        setDuration((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(intervalIdRef.current);
  }, [isCountDowning]);

  // 倒數結束(duration<=0)
  // 1. 清除interval
  // 2. 重設倒數秒數
  // 3. 設定現在不需繼續倒數
  // 4. 如果驗證碼發送次數已達上限或驗證碼錯誤已達上限則不允許再發送驗證碼;反之可以發送
  useEffect(() => {
    if (duration <= 0) {
      clearInterval(intervalIdRef.current);
      setDuration(60);
      setIsCountDowning(false);
      if (
        numberOfAttemptToSendCode <= 0 ||
        numberOfavailableConfirmation <= 0
      ) {
        getWithdrawLimitation();
      } else {
        setEnableSendVefiyCode(true);
      }
    }
  }, [duration]);

  function BankDepositConfirmButton() {
    // 確認提領按鈕是否能按
    // 1. 如果達到驗證碼錯誤次數上限則無法按
    // 2. 輸入的驗證碼不符合格式也不能按

    return (
      <BottomPanel>
        <CustomButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleConfirm}>
          確認提領
        </CustomButton>
      </BottomPanel>
    );
  }

  function BankDepositInfo({
    enableSendVefiyCode,
    duration,
    verifyCode,
    handleSendverifyCode,
  }) {
    // 發送驗證碼按鈕是否能按
    // 1. 如果已達發送驗證碼次數上限或驗證碼錯誤次數上限族不能按，並顯示稍後再試
    // 2. 按下發送驗證碼按鈕後顯示倒數秒數
    // 3. 其餘情況可按發送驗證碼按鈕
    const textInput = useRef(null);

    function LinkGetCode(text) {
      return (
        <Box display="flex" onClick={handleSendverifyCode}>
          <Label fontSize="15px">{text}</Label>
        </Box>
      );
    }

    return (
      <>
        <Box
          sx={{
            alignItems: 'center',
            justifyItems: 'center',
            pt: `${navigationBarHeight}px`,
          }}>
          <Box
            display="flex"
            width="100%"
            justifyContent="flex-start"
            alignItems="flex-start">
            <Typography fontSize="20px" fontWeight="400">
              驗證碼會傳送至 {user?.phoneNumber}
            </Typography>
          </Box>
          <Box
            display="flex"
            marginTop="12px"
            width="100%"
            justifyContent="space-between"
            // click數字框重新focus
            onClick={() => {
              textInput.current.focus();
            }}>
            {verifyCode.numbers.map((number, index) => {
              return (
                <DigitLabelBox
                  key={index}
                  value={number.value}
                  animated={number.animated}
                  isEditing={index === verifyCode.currentPos}
                />
              );
            })}
          </Box>
          <Box display="flex" pl="16px" pr="16px" pt="22px" mb="40px">
            {enableSendVefiyCode
              ? countSendCodeAttempt >= 1
                ? LinkGetCode('重新發送驗證碼')
                : LinkGetCode('發送驗證碼')
              : duration + 's'}
          </Box>
        </Box>
      </>
    );
  }

  const TransactionDetails = ({ amount, fee }) => {
    const transactionData = [
      {
        label: '存入帳戶',
        value:
          bankName +
          ' **' +
          user?.bankAccount?.accountNumber.substr(
            user?.bankAccount?.accountNumber.length - 5,
          ) +
          ' (' +
          user?.chineseName +
          ')',
      },
      {
        label: '提領金額',
        value: <CoinNumberFormat value={amount} prefix="$" />,
      },
      {
        label: '銀行處理手續費',
        value: <CoinNumberFormat value={fee} prefix="$" />,
      },
      {
        label: '實際入帳金額',
        value: <CoinNumberFormat value={amount - fee} prefix="$" />,
      },
    ];

    return (
      <Box
        sx={{
          borderRadius: '8px',
          backgroundColor: '#FFFFFF26',
          padding: '16px',
          paddingTop: '0px',
          color: 'white',
        }}>
        <Grid container>
          {transactionData.map((item, index) => (
            <>
              <Grid item xs={4.5} sx={{ paddingTop: '16px' }}>
                <Typography fontSize="16px" fontWeight="600">
                  {item.label}
                </Typography>
              </Grid>
              <Grid
                item
                xs={7.5}
                sx={{ paddingLeft: '8px', paddingTop: '16px' }}>
                <Typography fontSize="16px" fontWeight="400">
                  {item.value}
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    );
  };

  const warningModalButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => {
        setShowAlert(false);
      },
    },
  ];

  function BankDepositCautions({ fee }) {
    return (
      <Box marginTop="20px">
        <SubTitleLabel>
          ※ 提領依照銀行營業作業時間約 3 個工作天。
          <br />
          ※ 提領申請後，無法再做更改或取消。
          <br />※ 提領申請銀行端將收手續費 {fee} 元，提領失敗仍須支付手續費。
        </SubTitleLabel>
      </Box>
    );
  }

  //Main Return
  return (
    <>
      <Box>
        <NavigationBar title="提領" leftButtonHandler={handleBack} />
        <Box padding="16px">
          <BankDepositInfo
            key="depositInfo"
            enableSendVefiyCode={enableSendVefiyCode}
            numberOfAttemptToSendCode={numberOfAttemptToSendCode}
            numberOfavailableConfirmation={numberOfavailableConfirmation}
            duration={duration}
            amount={amount}
            bankAccountNumber={bankAccountNumber}
            fee={fee}
            verifyCode={verifyCode}
            handleSendverifyCode={handleSendverifyCode}
          />
          <TransactionDetails
            amount={amount}
            bankAccountNumber={bankAccountNumber}
            fee={fee}
          />
          <Divider
            sx={{
              pt: '16px',
              ml: '-16px',
              mr: '-16px',
            }}
          />
          <BankDepositCautions key="depositCautions" fee={fee} />
        </Box>
        <BankDepositConfirmButton key="depositConfirm" />
      </Box>

      {/* Warning modal */}
      <Modal
        title={alertRef.current.title}
        enabled={showAlert}
        buttons={warningModalButtons}
        buttonDirection="column">
        <Box
          sx={{
            paddingTop: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography>{alertRef.current.message}</Typography>
        </Box>
      </Modal>
    </>
  );
}
