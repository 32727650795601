import { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import BottomPanel from '../../components/BottomPanel';
import { UserContext } from '../../UserContext';
import ResultTemplate from './components/ResultTemplate';
import { UserAPI } from '../../apis/UserAPI';

const TradeButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

export default function SignUpCompletedPage() {
  const { setTabIndex, user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleBack = () => {
    setTabIndex(0);
    navigate('/');
  };

  const handleComplete = async () => {
    let redirectUrl = '/';
    const bankStatus = await getBankStatus();
    if (
      user?.kycStatus === 'succeed' &&
      user?.amlStatus === 'succeed' &&
      (!bankStatus || bankStatus === 'unverified')
    ) {
      redirectUrl = '/bankAccount';
    }
    navigate(redirectUrl, { state: { from: '/kycSucceed' } });
  };

  const getBankStatus = async () => {
    let bankStatus;
    const result = await UserAPI.getUser();
    if (!result?.error) {
      bankStatus = result?.bankAccount?.status;
    }
    return bankStatus;
  };

  return (
    <Box sx={{ pt: `${navigationBarHeight}px` }}>
      <NavigationBar
        title="驗證結果"
        leftButtonIcon={
          <CloseOutlinedIcon
            style={{ fontSize: 25 }}
            sx={{
              color: (theme) => theme.palette.common.black,
            }}
          />
        }
        leftButtonHandler={handleBack}
      />
      <Box>
        <ResultTemplate
          title="驗證完成"
          desc="您已完成身分驗證，歡迎使用OP加密資產存摺!"
          succeed={true}
        />
      </Box>
      <BottomPanel>
        <TradeButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleComplete}>
          立即使用
        </TradeButton>
      </BottomPanel>
    </Box>
  );
}
