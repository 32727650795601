import Stack from '@mui/material/Stack';
import EveButton from '../../components/EveButton';

export default function PairActionButton({
  nameOne,
  nameTwo,
  actionOne,
  actionTwo,
  isDisabled,
}) {
  return (
    <Stack spacing={2.5} direction="row" justifyContent="center">
      <EveButton
        variant="contained"
        sx={{ width: '100%', height: '44px' }}
        onClick={actionOne}>
        {nameOne}
      </EveButton>
      <EveButton
        variant="contained"
        sx={{ width: '100%', height: '44px' }}
        disabled={isDisabled}
        onClick={actionTwo}>
        {nameTwo}
      </EveButton>
    </Stack>
  );
}
