// API: /user/me/orders/
// ===============================================
// 手續費發票類型: order?.invoiceInfo?.carrierType;
// 捐贈編號: order?.doneeCode;
// 載具號碼: order?.carrierId;
// 捐贈單位: order?.npoName;
// 發票寄送地址: order?.invoiceInfo?.address;

export function invoiceType(type, doneeCode) {
  if (doneeCode) return '捐贈發票';

  switch (type) {
    case 'A':
      return '會員載具';
    case 'B':
      return '手機條碼載具';
    case 'C':
      return '自然人憑證';
    case 'P':
      return '紙本';
    default:
      return;
  }
}

export function invoiceDetail(carrierId, npoName, address) {
  if (carrierId) {
    return `載具號碼：${carrierId}`;
  }
  if (npoName) {
    return `捐贈單位：${npoName}`;
  }
  if (address) {
    return `發票寄送地址：${address}`;
  }
}
