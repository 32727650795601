import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '../../components/Dialog';
import { SubTitleLabel } from '../../components/Label';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import { UserContext } from '../../UserContext';
import Panel from '../../components/Panel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Modal from '../../components/Modal';
import { UserAPI } from '../../apis/UserAPI';
import { PriceNumberFormat } from '../../utils/CoinNumberFormat';

export default function BankDepositPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [openCopyMessage, setOpenCopyMessage] = useState(false);
  const { user, setLoading } = useContext(UserContext);
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  const [limitQuota, setLimitQuota] = useState(0);

  const inputPrice = location?.state?.inputPrice;
  const productId = location?.state?.productId;
  const paymentValue = location?.state?.paymentValue;
  const invoice = location?.state?.invoice;

  function handleBack() {
    if (productId) {
      navigate(`/buy/${productId}`, {
        state: { inputPrice, paymentValue, invoice },
      });
    } else {
      navigate(-1);
    }
  }

  const confirmModalButtons = [
    {
      variant: 'contained',
      label: '確定',
      onClick: () => setOpenCopyMessage(false),
    },
  ];

  function BankDepositCautions() {
    return (
      <Box marginTop="20px" marginBottom="90px">
        <SubTitleLabel>
          ※ 除了實體 ATM / 網路銀行外，不接受其他轉帳方式，包含臨櫃匯款、ATM
          現金存款、電子支付帳戶轉帳等。
        </SubTitleLabel>
      </Box>
    );
  }

  useEffect(() => {
    async function fetchLimitQuota() {
      if (user) {
        setLoading(true);
        const limitQuota = await UserAPI.getLimitQuota();
        setLimitQuota(limitQuota);
        setLoading(false);
      }
    }
    fetchLimitQuota();
  }, [user, setLoading, setLimitQuota]);

  return (
    <>
      <Box
        sx={{
          pt: `${navigationBarHeight}px`,
        }}>
        <NavigationBar title="儲值" leftButtonHandler={handleBack}>
          <Button
            onClick={() => navigate('/bankLimited')}
            sx={{ color: 'white' }}>
            <Typography fontWeight={400} fontSize={17} letterSpacing={-0.41}>
              銀行限額
            </Typography>
          </Button>
        </NavigationBar>
        <Stack padding="16px" spacing={3} alignItems="center">
          <Caution user={user} />
          <DepositLimitation
            maximumDepositAmountBank={user.maximumDepositAmountBank}
            maximumDepositAmountBankPerMonth={
              limitQuota?.monthlyDepositQuota?.remainingAmount
            }
          />
          <BankDepositInfo
            user={user}
            setOpenCopyMessage={setOpenCopyMessage}
            openCopyMessage={openCopyMessage}
          />
          <Divider
            sx={{
              ml: '-16px',
              mr: '-16px',
            }}
          />
          <BankDepositCautions />
        </Stack>
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />

      <Modal
        title="已複製！"
        enabled={openCopyMessage}
        buttons={confirmModalButtons}
        buttonDirection="column">
        <Box>
          <Typography
            fontWeight={400}
            fontSize={16}
            textAlign="center"
            color="text.secondary">
            僅可使用您綁定的銀行帳號進行轉帳
          </Typography>
          <Box
            sx={{
              marginTop: '32px',
            }}>
            <Typography
              fontWeight={600}
              fontSize={20}
              textAlign="center"
              color="text.secondary">
              {`${user?.bankAccount?.bankCode} ${
                user?.bankAccount?.simpleBankName
              } ｜ ${user?.bankAccount?.branchName || ''}`}
            </Typography>
            <Typography
              fontWeight={500}
              fontSize={20}
              textAlign="center"
              color="text.secondary">
              {user?.bankAccount?.accountNumber}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function DepositLimitation({
  maximumDepositAmountBank,
  maximumDepositAmountBankPerMonth,
}) {
  return (
    <Stack spacing={6} direction="row">
      <Stack spacing={0.5} alignItems="center">
        <Box>單筆限額</Box>
        <PriceNumberFormat value={maximumDepositAmountBank} />
      </Stack>
      <Divider
        sx={{ bgcolor: 'text.secondary' }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
      <Stack spacing={0.5} alignItems="center">
        <Box>單月剩餘限額</Box>
        <PriceNumberFormat value={maximumDepositAmountBankPerMonth} />
      </Stack>
    </Stack>
  );
}

function Caution({ user }) {
  return (
    <Stack spacing={1} alignItems="center">
      <Typography
        variant="h5"
        component="h5"
        sx={{
          fontWeight: 500,
          textAlign: 'center',
          lineHeight: 1.5,
        }}>
        僅可使用
        <br />
        {user?.bankAccount?.bankCodeName} **
        {user?.bankAccount?.accountNumber.slice(-5)} ({user?.chineseName})
        <br />
        轉帳存入金額
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 400,
          textAlign: 'center',
          color: '#FFA8A8',
        }}>
        請注意該帳戶非約定轉帳的限額
      </Typography>
    </Stack>
  );
}

function BankDepositInfo({ user, setOpenCopyMessage }) {
  const handleAccountIdCopy = async () => {
    await navigator.clipboard.writeText(user?.virtualAccountNumber);
    setOpenCopyMessage(true);
  };

  return (
    <>
      <Panel backgroundColor={'#FFFFFF'}>
        <Stack spacing={0.5}>
          <Typography
            fontSize="16px"
            color="text.orange"
            fontWeight={480}
            align="center">
            儲值成功後無法退款或取消，
            <br />
            請務必確認下方帳號由您的帳號產出
          </Typography>
          <Typography
            fontSize="14px"
            color="text.black"
            fontWeight={480}
            align="center">
            將儲存至 {user?.phoneNumber}
          </Typography>
        </Stack>

        <Stack spacing={2} alignItems="center" direction="row" marginTop={2}>
          <Typography fontSize="15px" color="text.neutral">
            專屬帳號
          </Typography>
          <Typography
            fontSize="20px"
            color="text.black"
            fontWeight={480}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>
            <span>{user?.virtualAccountNumber}</span>
            <ContentCopyIcon
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={handleAccountIdCopy}
            />
          </Typography>
        </Stack>
        <Stack spacing={2} alignItems="center" direction="row">
          <Typography fontSize="15px" color="text.neutral">
            銀行代碼
          </Typography>
          <Typography fontSize="20px" color="text.black" fontWeight={480}>
            012 富邦銀行
          </Typography>
        </Stack>
      </Panel>
    </>
  );
}
