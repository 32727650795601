import { useEffect, useState } from 'react';
import moment from 'moment/moment';

const useCountdown = (targetDate) => {
  const [duration, setDuration] = useState(
    moment.duration(moment.unix(targetDate).diff(moment.now())),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const newDuration = moment.duration(
        moment.unix(targetDate).diff(moment.now()),
      );
      setDuration(newDuration);

      const minutes = newDuration.minutes();
      const seconds = newDuration.seconds();

      if (minutes <= 0 && seconds <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return { expired: duration.asMilliseconds() < 0, duration };
};

export { useCountdown };
