import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { UserContext } from '../../UserContext';
import { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import BottomPanel from '../../components/BottomPanel';
import CustomButton from '../../components/CustomButton';
import { useNavigate } from 'react-router-dom';
const GradientPaper = styled(Paper)({
  width: '100%',
  height: '120px',
  borderRadius: '16px',
  backgroundColor: '#3211A0',
  position: 'relative',
  overflow: 'hidden',
  padding: '16px',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-220px',
    left: '-230px',
    width: '480px',
    height: '480px',
    borderRadius: '50%',
    background: 'radial-gradient(circle, #2A93D5, transparent 95%)',
    filter: 'blur(60px)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-250px',
    right: '-240px',
    width: '480px',
    height: '480px',
    borderRadius: '50%',
    background: 'radial-gradient(circle, #9E5A7D, transparent 95%)',
    filter: 'blur(60px)',
  },
});

// 要使用 content wrapper 主要是為了，讓 children 內容可以在 z-index 的基礎上往上排序，不會吃到 filter
const ContentWrapper = styled('div')({
  position: 'relative',
  zIndex: 1,
  height: '100%',
});
const sxContainer = {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  alignItems: 'center',
  gap: '25px',
  pt: `${navigationBarHeight}px`,
};

export default function EmailPage() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  function handleBack() {
    navigate(-1);
  }

  const PageBottomPanel = () => {
    return (
      <BottomPanel>
        {user.emailVerifyStatus === 'succeed' ? (
          <CustomButton
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => {
              navigate('/phoneVerify', {
                state: {
                  entry: '/email',
                },
              });
            }}>
            變更電子郵件
          </CustomButton>
        ) : (
          <CustomButton
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => {
              navigate('/emailVerify', {
                state: {
                  entry: '/email',
                },
              });
            }}>
            重新發送驗證碼
          </CustomButton>
        )}
      </BottomPanel>
    );
  };
  return (
    <>
      <NavigationBar title="電子郵件" leftButtonHandler={handleBack} />
      <Box sx={sxContainer}>
        <Box
          component="img"
          height={176}
          width={176}
          src="/images/img_info_mail.svg"
          alt="Confirmation Image"
          sx={{ mt: '30px' }}
        />
        <GradientPaper elevation={3}>
          <ContentWrapper>
            <Stack
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
                padding: '8px',
              }}>
              <Typography sx={{ fontSize: '20px' }}>
                目前綁定的 E-mail
              </Typography>
              <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                {user?.email}
              </Typography>
            </Stack>
          </ContentWrapper>
        </GradientPaper>
      </Box>
      <PageBottomPanel />
    </>
  );
}
