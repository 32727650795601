import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

export const navigationBarHeight = 56;
export const tabBarHeight = 64;

// TODO: 開發用，以後要拿掉
const withoutLayout = [
  '/login',
  '/mobileDeviceVerification',
  'changePhoneNumber',
  '/changePhoneNumber',
  '/phoneVerify',
  '/introduction',
  '/bankLimited',
  '/privacy',
  '/terms',
  '/phoneNumber',
  '/identificationNumberConfirm',
  '/passwordConfirm',
];

const ProviderInformation = () => {
  return (
    <Box margin="16px 0 90px">
      <Typography
        color="#FFFFFF33"
        fontWeight={400}
        fontSize="14px"
        lineHeight="16.71px"
        letterSpacing="-0.4px"
        textAlign="center">
        本服務由富昇數位提供
      </Typography>
    </Box>
  );
};

const LayoutDisabled = ({ children }) => {
  return (
    <>
      {children}
      <ProviderInformation />
      {/* <TabBar /> */}
    </>
  );
};

export default function Layout({ children }) {
  const location = useLocation();

  // TODO: 開發用，以後要拿掉
  if (withoutLayout.includes(location.pathname)) {
    return <>{children}</>;
  }

  return (
    <>
      <LayoutDisabled>{children}</LayoutDisabled>
    </>
  );
}
