import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Decimal from 'decimal.js';

const paymentMethodDict = {
  balance: '餘額帳戶',
  bank: '銀行帳號轉帳',
  store: '門市繳款',
};

export default function AssetRecordCell({
  onClick,
  status,
  side,
  product,
  fund,
  time,
  paymentMethod,
  size,
}) {
  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      height="72px"
      display="flex"
      borderBottom={1}
      borderColor="common.borderColor"
      onClick={onClick}>
      {/* 買入 / 賣出 */}
      <Stack alignItems="start">
        <Typography fontSize={12} fontWeight={400}>
          {time}
        </Typography>

        {side === 'buy' ? (
          <Typography fontSize={14} fontWeight={400} letterSpacing={-0.4}>
            買入 - {product}
          </Typography>
        ) : (
          <Typography fontSize={14} fontWeight={400} letterSpacing={-0.4}>
            賣出 - {product}
          </Typography>
        )}

        <Typography
          fontSize={12}
          fontWeight={400}
          letterSpacing={-0.4}
          sx={{ opacity: 0.6 }}>
          {paymentMethodDict[paymentMethod]}
        </Typography>
      </Stack>

      {/* 訂單狀態 */}
      <Stack alignItems="end">
        <StatusInfo status={status} />
        <Typography fontWeight={400} fontSize={18}>
          {side === 'buy' ? '-' : '+'}
          {fund} TWD
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={400}
          letterSpacing={-0.4}
          sx={{ opacity: 0.6 }}>
          {side === 'buy' ? '+' : '-'} {Number(Decimal(size)).toFixed(8)}{' '}
          {product}
        </Typography>
      </Stack>
    </Box>
  );
}

function StatusInfo({ status }) {
  const statusColorMap = {
    交易成功: '#FFFFFF',
    交易失敗: '#FBA28B',
    交易取消: '#969696',
    處理中: '#FFDD86',
  };

  return (
    <Typography color={statusColorMap[status]} fontSize={12}>
      {status}
    </Typography>
  );
}
