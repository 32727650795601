import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';
import { ProductAPI } from '../../apis/ProductAPI';
import { AccountAPI } from '../../apis/AccountAPI';
import Dialog from '../../components/Dialog';
import { TitleLabel } from '../../components/Label';
import { UserContext } from '../../UserContext';
import { notifyLoginFail } from '../../utils/WebViewCallback';
import './slick-dot.css';
import Box from '@mui/material/Box';
import EveTopPaper from '../../components/EveTopPaper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { SvgIcon } from '../../components/Icon';
import CryptoInfoItem from '../../components/market/CryptoInfoItem';
import BankAccountNotification from '../../components/market/BankAccountNotification';
import { PriceNumberFormat } from '../../utils/CoinNumberFormat';
import NavigationBar from '../../components/NavigationBar';
import { notifyQuitApp } from '../../utils/WebViewCallback';
import Modal from '../../components/Modal';
import { priceNumberRoundedToInt } from '../../utils/CoinNumberFormat';
import PromotionBannerSection from '../../components/PromotionBannerSection';

export default function MarketPage() {
  const { setLoading, user } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [realtimePrices, setRealtimePrices] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  const initData = useRef(false);
  const navigate = useNavigate();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => false,
    queryParams: {
      token: getWebsocketToken(),
    },
  });

  let digitalAssetSum = 0;
  let ntdAssetSum = 0;
  for (let i = 0; i < accounts.length; i++) {
    if (accounts[i].currencyId === 'TWD') {
      ntdAssetSum = accounts[i].availableBalance;
    } else {
      digitalAssetSum += priceNumberRoundedToInt(accounts[i].twdValue);
    }
  }
  const estimatedTotalAssets = digitalAssetSum + ntdAssetSum;

  useEffect(() => {
    async function fetchAccountsData() {
      if (user) {
        setLoading(true);
        const response = await AccountAPI.getAccounts();
        setAccounts(response);
        setLoading(false);
      }
    }
    fetchAccountsData();
  }, [user, setLoading, setAccounts]);

  useEffect(() => {
    async function fetchProductData() {
      setLoading(true);
      const products = await ProductAPI.getProducts();
      setProducts(products);
      setLoading(false);
    }

    if (initData.current === false) {
      initData.current = true;
      fetchProductData();
    }

    if (lastMessage) {
      const data = JSON.parse(lastMessage?.data);
      if (data['id'] && data['status']) {
        return;
      }

      const prices = data;
      setRealtimePrices(prices);
    }
  }, [products, lastMessage, setLoading]);

  const handleLoginFailAlertDismiss = () => {
    setShowAlert(false);
    notifyLoginFail();
  };

  //Eve Top Panel Section
  const VerificationButton = styled(Button)({
    borderRadius: '37px',
    backgroundColor: 'white',
    color: '#3E1EE6',
    padding: '9px 70px',
    marginTop: '40px',
    fontWeight: 600,
    width: '85%',
    fontSize: '16px',
    height: '40px',
    fontFamily: 'PingFang TC, sans-serif',
    letterSpacing: '-0.41px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
  });

  function NotFinishedRegisterPanel() {
    if (user) {
      if (user.isRegistered === true) {
        if (user.kycStatus !== 'succeed' || user.amlStatus !== 'succeed') {
          return (
            <EveTopPaper>
              <Box
                sx={{
                  width: '100vw',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}>
                <TitleLabel
                  sx={{
                    color: 'white',
                    fontSize: '20px',
                    lineHeight: '30px',
                    fontWeight: 274,
                    fontFamily: 'SF Pro, sans-serif',
                  }}>
                  歡迎加入！開始第一筆交易前
                  <br />
                  請先完成身分驗證
                </TitleLabel>
                <VerificationButton
                  onClick={() => {
                    navigate('/signUpCompleted');
                  }}>
                  進行身分驗證
                </VerificationButton>
              </Box>
            </EveTopPaper>
          );
        } else {
          return;
        }
      }
    }
    return (
      <EveTopPaper>
        <Box
          sx={{
            width: '100vw',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}>
          <TitleLabel
            sx={{
              color: 'white',
              fontSize: '20px',
              lineHeight: '30px',
              fontWeight: 274,
              fontFamily: 'SF Pro, sans-serif',
            }}>
            進入虛擬資產的世界
            <br />
            就是這麼簡單
          </TitleLabel>
          {/* TODO: Navigate to B1 when completed */}
          <VerificationButton
            onClick={() => {
              navigate('/landing');
            }}>
            馬上開始
          </VerificationButton>
        </Box>
      </EveTopPaper>
    );
  }

  function EveFunctionPanel({ estimatedTotalAssets, accounts }) {
    const [showAsset, setShowAsset] = useState(false);
    const CoinTransactionButton = styled(Button)({
      borderRadius: '37px',
      padding: '9px 39px',
      border: '1px solid #fff',
      textTransform: 'none',
      color: 'white',
      fontSize: '16px',
      height: '40px',
    });

    const initProfitLoss = 0;
    const profitLossTotal = accounts?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.profitLoss,
      initProfitLoss,
    );
    function profitLossSymbol() {
      if (profitLossTotal > 0) {
        return '+';
      } else if (profitLossTotal < 0) {
        return '-';
      } else {
        return '';
      }
    }

    if (user) {
      if (
        user.isRegistered === true &&
        user.kycStatus === 'succeed' &&
        user.amlStatus === 'succeed'
      ) {
        return (
          <EveTopPaper>
            <Box
              sx={{
                padding: '0px 24px 0px 24px',
              }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                height="17px"
                marginBottom="10px">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography fontSize={15} color="text.primary">
                    總資產估值 (TWD)
                  </Typography>
                  <IconButton
                    onClick={() => setShowAsset(!showAsset)}
                    aria-label="show total asset">
                    {showAsset ? (
                      <SvgIcon
                        src={'/icons/eye_open.svg'}
                        width={20}
                        height={20}
                      />
                    ) : (
                      <SvgIcon
                        src={'/icons/eye_off.svg'}
                        width={20}
                        height={20}
                      />
                    )}
                  </IconButton>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  onClick={() => {
                    navigate('/asset');
                  }}
                  sx={{ cursor: 'pointer' }}>
                  <Typography fontSize={15} color="text.primary">
                    我的帳戶
                  </Typography>
                  <SvgIcon
                    src={'/icons/ic_drop_dowm_sm.svg'}
                    width={20}
                    height={20}
                  />
                </Stack>
              </Stack>
              <Stack direction="row">
                {showAsset ? (
                  <Typography
                    fontWeight={590}
                    fontSize={36}
                    letterSpacing={-0.4}
                    lineHeight="38px">
                    <PriceNumberFormat
                      value={estimatedTotalAssets}
                      prefix="$"
                    />
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={480}
                    fontSize={36}
                    letterSpacing={-0.4}
                    lineHeight="38px">
                    ＊＊＊＊＊＊
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" paddingTop="2px">
                <Typography fontSize={15} color="#FFA48E">
                  {profitLossSymbol()}$
                  {profitLossTotal?.toString()?.split('.')[0] >= 0
                    ? profitLossTotal?.toString()?.split('.')[0]
                    : profitLossTotal?.toString()?.split('.')[0]?.slice(1)}
                  {profitLossTotal?.toString()?.split('.')[1] && '.'}
                </Typography>
                <Typography fontSize={15} color="text.gray">
                  {profitLossTotal?.toString()?.split('.')[1]?.slice(0, 2)}
                </Typography>
                <Typography fontSize={15} color="text.gray"></Typography>
              </Stack>
              <Stack direction="row" spacing={2} paddingTop="19px">
                {/* TODO: Add navigation after buy coin, top up function finish */}
                <CoinTransactionButton
                  variant="outlined"
                  onClick={() => {
                    navigate('/markets');
                  }}>
                  買幣
                </CoinTransactionButton>
                <CoinTransactionButton
                  variant="outlined"
                  onClick={() => {
                    navigate('/deposit/bank');
                  }}>
                  儲值
                </CoinTransactionButton>
              </Stack>
            </Box>
          </EveTopPaper>
        );
      }
    }
  }

  //Bank Status Verfication Banner
  function BankStatusVerificationBanner() {
    if (user) {
      if (user.isRegistered === true && user.bankAccount?.status !== 'succeed')
        return <BankAccountNotification />;
    }
  }

  //Coin Price List
  const coinPriceList = () => {
    return (
      <>
        <TitleLabel marginBottom="8px">市場行情</TitleLabel>
        {products.map((product, index) => (
          <CryptoInfoItem
            key={index}
            product={product}
            realtimePrices={realtimePrices}
            onClick={() => {
              navigate(`/market/${product.id}`);
            }}
          />
        ))}
      </>
    );
  };
  const images = ['banner1.jpg', 'banner2.jpg', 'banner3.jpg', 'banner4.jpg'];

  return (
    <>
      <HomePageHeader />
      <NotFinishedRegisterPanel />
      <EveFunctionPanel
        estimatedTotalAssets={estimatedTotalAssets}
        accounts={accounts}
      />
      <Box marginTop="236px" padding="16px">
        <Box
          onClick={() => {
            if (
              !user.bankAccount ||
              !user.bankAccount?.status ||
              user.bankAccount.status === 'unverified'
            ) {
              navigate('/bankAccount');
            } else {
              navigate('/bankStatus');
            }
          }}>
          {BankStatusVerificationBanner()}
        </Box>
        <PromotionBannerSection images={images} height="95px" />
        {coinPriceList()}
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={handleLoginFailAlertDismiss}
        closeHandler={handleLoginFailAlertDismiss}
      />
    </>
  );
}

function HomePageHeader() {
  const [showDialog, setShowDialog] = useState(false);

  const handleDismiss = () => {
    setShowDialog(true);
  };
  const confirmModalButtons = [
    {
      variant: 'outlined',
      label: '繼續使用',
      onClick: () => setShowDialog(false),
    },
    {
      variant: 'contained',
      label: '離開',
      onClick: notifyQuitApp,
    },
  ];

  return (
    <>
      <NavigationBar
        leftButtonHandler={handleDismiss}
        noBackgroundColor={true}></NavigationBar>

      <Modal
        title="登出OP加密資產存摺"
        enabled={showDialog}
        buttons={confirmModalButtons}
        buttonDirection="row">
        <Typography
          fontWeight={400}
          fontSize={16}
          textAlign="center"
          color="text.secondary">
          請確認是否要離開OP加密資產存摺?
        </Typography>
      </Modal>
    </>
  );
}
