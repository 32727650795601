import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const GradientPaper = styled(Paper)({
  width: '100%',
  height: '236px',
  backgroundColor: '#3211A0',
  position: 'fixed',
  top: 0,
  overflow: 'hidden',
  padding: '64px 0px 28px 0px',
  borderRadius: '0px 0px 50px 50px',
  aspectRatio: '1.603',
  zIndex: 900,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-220px',
    left: '-230px',
    width: '480px',
    height: '480px',
    borderRadius: '50%',
    background: 'radial-gradient(circle, #2A93D5, transparent 95%)',
    filter: 'blur(60px)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-250px',
    right: '-240px',
    width: '480px',
    height: '480px',
    borderRadius: '50%',
    background: 'radial-gradient(circle, #9E5A7D, transparent 95%)',
    filter: 'blur(60px)',
  },
});

// 要使用 content wrapper 主要是為了，讓 children 內容可以在 z-index 的基礎上往上排序，不會吃到 filter
const ContentWrapper = styled('div')({
  position: 'relative',
  zIndex: 1,
  height: '100%',
});

export default function EveTopPaper({ children }) {
  return (
    <GradientPaper elevation={3}>
      <ContentWrapper>{children}</ContentWrapper>
    </GradientPaper>
  );
}
