import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const ItemBox = styled(Paper)(({ backgroundchosencolor }) => ({
  textAlign: 'start',
  color: 'white',
  height: 42,
  paddingLeft: '10px',
  alignContent: 'center',
  borderRadius: '6px',
  boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
  backgroundColor: backgroundchosencolor ? backgroundchosencolor : '#150F32',
}));

export default function CheckboxGroup({
  title,
  options,
  name,
  selectedValue,
  showError,
  onChange,
}) {
  selectedValue = Array.isArray(selectedValue) ? selectedValue : [];

  const isChecked = (value) => selectedValue.some((opt) => opt.value === value);
  const getRemark = (value) =>
    selectedValue.find((opt) => opt.value === value)?.remark || '';

  const handleChange = (event) => {
    const { value, checked } = event.target;
    let updateSelectedValues = [...selectedValue];
    if (checked) {
      updateSelectedValues.push({ value, remark: '' });
    } else {
      updateSelectedValues = updateSelectedValues.filter(
        (option) => option.value !== value,
      );
    }
    onChange(updateSelectedValues);
  };

  const handleRemarkChange = (value, remark) => {
    const updateSelectedValues = selectedValue.map((option) =>
      option.value === value ? { ...option, remark } : option,
    );
    onChange(updateSelectedValues);
  };

  return (
    <FormControl error={showError}>
      <FormGroup>
        <Stack spacing="8px">
          {options.map((item) => (
            <Stack key={item.value}>
              <ItemBox
                backgroundchosencolor={isChecked(item.value) && '#3E1EE6'}>
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'white',
                        },
                      }}
                      checked={isChecked(item.value)}
                      onChange={handleChange}
                      name={name}
                      value={item.value}
                    />
                  }
                  label={item.label}
                />
              </ItemBox>
              {selectedValue.some(
                (opt) => opt.value === item.value && item.textbox,
              ) && (
                <Box
                  sx={{
                    backgroundColor: '#150F32',
                    paddingLeft: '10px',
                    height: 42,
                    alignContent: 'center',
                    mt: '8px',
                  }}>
                  <TextField
                    sx={{
                      '& .MuiInputBase-input': {
                        color: '#FFFFFF',
                        fontSize: 14,
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'rgba(255, 255, 255, 0.5)',
                        fontSize: 14,
                      },
                    }}
                    variant="standard"
                    placeholder={item.annotation}
                    value={getRemark(item.value)}
                    onChange={(e) =>
                      handleRemarkChange(item.value, e.target.value)
                    }
                  />
                </Box>
              )}
            </Stack>
          ))}
        </Stack>
      </FormGroup>
      <FormHelperText id={`${name}-error-text`}>
        {showError && `請填寫: ${title}`}
      </FormHelperText>
    </FormControl>
  );
}
