import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import userTerms from './userTerms';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

export default function UserTermsPage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const response = userTerms;

  const handleBack = () => {
    if (state) {
      if (state.previousPage === '/signUp')
        navigate('/signUp', { state: { previousPage: '/terms' } });
    } else navigate(-1);
  };

  const navigation = () => {
    return <NavigationBar title="使用者條款" leftButtonHandler={handleBack} />;
  };

  const TermsExternalPage = () => {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          backgroundColor: 'white',
          padding: '16px',
          paddingRight: '0px',
        }}>
        <iframe
          src="https://staging.opcryptoex.com/terms_user.html"
          title="WebView"
          width="100%"
          height="100%"
          style={{
            border: 'none',
          }}
        />
      </Box>
    );
  };

  return (
    <Box sx={sxContainer}>
      {navigation() /* navigation bar */}
      <TermsExternalPage />
    </Box>
  );
}
