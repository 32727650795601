import { useEffect, useState, useCallback } from 'react';
import { InvoiceAPI } from '../apis/InvoiceAPI';

const default_invoice_npo = [
  { npoBan: '876', npoName: '財團法人心路社會福利基金會' },
];

const carrierTypes = [
  { code: 'A', carrierType: '會員載具' },
  { code: 'B', carrierType: '手機條碼載具' },
  { code: 'C', carrierType: '自然人憑證條碼' },
  { code: 'P', carrierType: '個人紙本發票' },
];

/*
reference: https://www.post.gov.tw/post/download/103.12.25-%E8%87%BA%E7%81%A3%E5%9C%B0%E5%8D%80%E9%83%B5%E9%81%9E%E5%8D%80%E8%99%9F%E5%89%8D3%E7%A2%BC%E4%B8%80%E8%A6%BD%E8%A1%A8.txt
update date: 2014-12-25
*/

const cities = [
  '臺北市',
  '基隆市',
  '新北市',
  '宜蘭縣',
  '桃園市',
  '新竹市',
  '新竹縣',
  '苗栗縣',
  '臺中市',
  '彰化縣',
  '南投縣',
  '嘉義市',
  '嘉義縣',
  '雲林縣',
  '臺南市',
  '高雄市',
  '澎湖縣',
  '金門縣',
  '屏東縣',
  '臺東縣',
  '花蓮縣',
  '連江縣',
];

const districts = {
  臺北市: [
    { code: '100', district: '中正區' },
    { code: '103', district: '大同區' },
    { code: '104', district: '中山區' },
    { code: '105', district: '松山區' },
    { code: '106', district: '大安區' },
    { code: '108', district: '萬華區' },
    { code: '110', district: '信義區' },
    { code: '111', district: '士林區' },
    { code: '112', district: '北投區' },
    { code: '114', district: '內湖區' },
    { code: '115', district: '南港區' },
    { code: '116', district: '文山區' },
  ],
  基隆市: [
    { code: '200', district: '仁愛區' },
    { code: '201', district: '信義區' },
    { code: '202', district: '中正區' },
    { code: '203', district: '中山區' },
    { code: '204', district: '安樂區' },
    { code: '205', district: '暖暖區' },
    { code: '206', district: '七堵區' },
  ],
  新北市: [
    { code: '207', district: '萬里區' },
    { code: '208', district: '金山區' },
    { code: '220', district: '板橋區' },
    { code: '221', district: '汐止區' },
    { code: '222', district: '深坑區' },
    { code: '223', district: '石碇區' },
    { code: '224', district: '瑞芳區' },
    { code: '226', district: '平溪區' },
    { code: '227', district: '雙溪區' },
    { code: '228', district: '貢寮區' },
    { code: '231', district: '新店區' },
    { code: '232', district: '坪林區' },
    { code: '233', district: '烏來區' },
    { code: '234', district: '永和區' },
    { code: '235', district: '中和區' },
    { code: '236', district: '土城區' },
    { code: '237', district: '三峽區' },
    { code: '238', district: '樹林區' },
    { code: '239', district: '鶯歌區' },
    { code: '241', district: '三重區' },
    { code: '242', district: '新莊區' },
    { code: '243', district: '泰山區' },
    { code: '244', district: '林口區' },
    { code: '247', district: '蘆洲區' },
    { code: '248', district: '五股區' },
    { code: '249', district: '八里區' },
    { code: '251', district: '淡水區' },
    { code: '252', district: '三芝區' },
    { code: '253', district: '石門區' },
  ],
  宜蘭縣: [
    { code: '260', district: '宜蘭市' },
    { code: '261', district: '頭城鎮' },
    { code: '262', district: '礁溪鄉' },
    { code: '263', district: '壯圍鄉' },
    { code: '264', district: '員山鄉' },
    { code: '265', district: '羅東鎮' },
    { code: '266', district: '三星鄉' },
    { code: '267', district: '大同鄉' },
    { code: '268', district: '五結鄉' },
    { code: '269', district: '冬山鄉' },
    { code: '270', district: '蘇澳鎮' },
    { code: '272', district: '南澳鄉' },
    { code: '290', district: '釣魚臺列嶼' },
  ],
  桃園市: [
    { code: '320', district: '中壢區' },
    { code: '324', district: '平鎮區' },
    { code: '325', district: '龍潭區' },
    { code: '326', district: '楊梅區' },
    { code: '327', district: '新屋區' },
    { code: '328', district: '觀音區' },
    { code: '330', district: '桃園區' },
    { code: '333', district: '龜山區' },
    { code: '334', district: '八德區' },
    { code: '335', district: '大溪區' },
    { code: '336', district: '復興區' },
    { code: '337', district: '大園區' },
    { code: '338', district: '蘆竹區' },
  ],
  新竹市: [
    { code: '300', district: '東區' },
    { code: '300', district: '北區' },
    { code: '300', district: '香山區' },
  ],
  新竹縣: [
    { code: '302', district: '竹北市' },
    { code: '303', district: '湖口鄉' },
    { code: '304', district: '新豐鄉' },
    { code: '305', district: '新埔鎮' },
    { code: '306', district: '關西鎮' },
    { code: '307', district: '芎林鄉' },
    { code: '308', district: '寶山鄉' },
    { code: '310', district: '竹東鎮' },
    { code: '311', district: '五峰鄉' },
    { code: '312', district: '橫山鄉' },
    { code: '313', district: '尖石鄉' },
    { code: '314', district: '北埔鄉' },
    { code: '315', district: '峨眉鄉' },
  ],
  苗栗縣: [
    { code: '350', district: '竹南鎮' },
    { code: '351', district: '頭份市' },
    { code: '352', district: '三灣鄉' },
    { code: '353', district: '南庄鄉' },
    { code: '354', district: '獅潭鄉' },
    { code: '356', district: '後龍鎮' },
    { code: '357', district: '通霄鎮' },
    { code: '358', district: '苑裡鎮' },
    { code: '360', district: '苗栗市' },
    { code: '361', district: '造橋鄉' },
    { code: '362', district: '頭屋鄉' },
    { code: '363', district: '公館鄉' },
    { code: '364', district: '大湖鄉' },
    { code: '365', district: '泰安鄉' },
    { code: '366', district: '銅鑼鄉' },
    { code: '367', district: '三義鄉' },
    { code: '368', district: '西湖鄉' },
    { code: '369', district: '卓蘭鎮' },
  ],
  臺中市: [
    { code: '400', district: '中區' },
    { code: '401', district: '東區' },
    { code: '402', district: '南區' },
    { code: '403', district: '西區' },
    { code: '404', district: '北區' },
    { code: '406', district: '北屯區' },
    { code: '407', district: '西屯區' },
    { code: '408', district: '南屯區' },
    { code: '411', district: '太平區' },
    { code: '412', district: '大里區' },
    { code: '413', district: '霧峰區' },
    { code: '414', district: '烏日區' },
    { code: '420', district: '豐原區' },
    { code: '421', district: '后里區' },
    { code: '422', district: '石岡區' },
    { code: '423', district: '東勢區' },
    { code: '424', district: '和平區' },
    { code: '426', district: '新社區' },
    { code: '427', district: '潭子區' },
    { code: '428', district: '大雅區' },
    { code: '429', district: '神岡區' },
    { code: '432', district: '大肚區' },
    { code: '433', district: '沙鹿區' },
    { code: '434', district: '龍井區' },
    { code: '435', district: '梧棲區' },
    { code: '436', district: '清水區' },
    { code: '437', district: '大甲區' },
    { code: '438', district: '外埔區' },
    { code: '439', district: '大安區' },
  ],
  彰化縣: [
    { code: '500', district: '彰化市' },
    { code: '502', district: '芬園鄉' },
    { code: '503', district: '花壇鄉' },
    { code: '504', district: '秀水鄉' },
    { code: '505', district: '鹿港鎮' },
    { code: '506', district: '福興鄉' },
    { code: '507', district: '線西鄉' },
    { code: '508', district: '和美鎮' },
    { code: '509', district: '伸港鄉' },
    { code: '510', district: '員林市' },
    { code: '511', district: '社頭鄉' },
    { code: '512', district: '永靖鄉' },
    { code: '513', district: '埔心鄉' },
    { code: '514', district: '溪湖鎮' },
    { code: '515', district: '大村鄉' },
    { code: '516', district: '埔鹽鄉' },
    { code: '520', district: '田中鎮' },
    { code: '521', district: '北斗鎮' },
    { code: '522', district: '田尾鄉' },
    { code: '523', district: '埤頭鄉' },
    { code: '524', district: '溪州鄉' },
    { code: '525', district: '竹塘鄉' },
    { code: '526', district: '二林鎮' },
    { code: '527', district: '大城鄉' },
    { code: '528', district: '芳苑鄉' },
    { code: '530', district: '二水鄉' },
  ],
  南投縣: [
    { code: '540', district: '南投市' },
    { code: '541', district: '中寮鄉' },
    { code: '542', district: '草屯鎮' },
    { code: '544', district: '國姓鄉' },
    { code: '545', district: '埔里鎮' },
    { code: '546', district: '仁愛鄉' },
    { code: '551', district: '名間鄉' },
    { code: '552', district: '集集鎮' },
    { code: '553', district: '水里鄉' },
    { code: '555', district: '魚池鄉' },
    { code: '556', district: '信義鄉' },
    { code: '557', district: '竹山鎮' },
    { code: '558', district: '鹿谷鄉' },
  ],
  嘉義市: [
    { code: '600', district: '東區' },
    { code: '600', district: '西區' },
  ],
  嘉義縣: [
    { code: '602', district: '番路鄉' },
    { code: '603', district: '梅山鄉' },
    { code: '604', district: '竹崎鄉' },
    { code: '605', district: '阿里山' },
    { code: '606', district: '中埔鄉' },
    { code: '607', district: '大埔鄉' },
    { code: '608', district: '水上鄉' },
    { code: '611', district: '鹿草鄉' },
    { code: '612', district: '太保市' },
    { code: '613', district: '朴子市' },
    { code: '614', district: '東石鄉' },
    { code: '615', district: '六腳鄉' },
    { code: '616', district: '新港鄉' },
    { code: '621', district: '民雄鄉' },
    { code: '622', district: '大林鎮' },
    { code: '623', district: '溪口鄉' },
    { code: '624', district: '義竹鄉' },
    { code: '625', district: '布袋鎮' },
  ],
  雲林縣: [
    { code: '630', district: '斗南鎮' },
    { code: '631', district: '大埤鄉' },
    { code: '632', district: '虎尾鎮' },
    { code: '633', district: '土庫鎮' },
    { code: '634', district: '褒忠鄉' },
    { code: '635', district: '東勢鄉' },
    { code: '636', district: '臺西鄉' },
    { code: '637', district: '崙背鄉' },
    { code: '638', district: '麥寮鄉' },
    { code: '640', district: '斗六市' },
    { code: '643', district: '林內鄉' },
    { code: '646', district: '古坑鄉' },
    { code: '647', district: '莿桐鄉' },
    { code: '648', district: '西螺鎮' },
    { code: '649', district: '二崙鄉' },
    { code: '651', district: '北港鎮' },
    { code: '652', district: '水林鄉' },
    { code: '653', district: '口湖鄉' },
    { code: '654', district: '四湖鄉' },
    { code: '655', district: '元長鄉' },
  ],
  臺南市: [
    { code: '700', district: '中西區' },
    { code: '701', district: '東區' },
    { code: '702', district: '南區' },
    { code: '704', district: '北區' },
    { code: '708', district: '安平區' },
    { code: '709', district: '安南區' },
    { code: '710', district: '永康區' },
    { code: '711', district: '歸仁區' },
    { code: '712', district: '新化區' },
    { code: '713', district: '左鎮區' },
    { code: '714', district: '玉井區' },
    { code: '715', district: '楠西區' },
    { code: '716', district: '南化區' },
    { code: '717', district: '仁德區' },
    { code: '718', district: '關廟區' },
    { code: '719', district: '龍崎區' },
    { code: '720', district: '官田區' },
    { code: '721', district: '麻豆區' },
    { code: '722', district: '佳里區' },
    { code: '723', district: '西港區' },
    { code: '724', district: '七股區' },
    { code: '725', district: '將軍區' },
    { code: '726', district: '學甲區' },
    { code: '727', district: '北門區' },
    { code: '730', district: '新營區' },
    { code: '731', district: '後壁區' },
    { code: '732', district: '白河區' },
    { code: '733', district: '東山區' },
    { code: '734', district: '六甲區' },
    { code: '735', district: '下營區' },
    { code: '736', district: '柳營區' },
    { code: '737', district: '鹽水區' },
    { code: '741', district: '善化區' },
    { code: '742', district: '大內區' },
    { code: '743', district: '山上區' },
    { code: '744', district: '新市區' },
    { code: '745', district: '安定區' },
  ],
  高雄市: [
    { code: '800', district: '新興區' },
    { code: '801', district: '前金區' },
    { code: '802', district: '苓雅區' },
    { code: '803', district: '鹽埕區' },
    { code: '804', district: '鼓山區' },
    { code: '805', district: '旗津區' },
    { code: '806', district: '前鎮區' },
    { code: '807', district: '三民區' },
    { code: '811', district: '楠梓區' },
    { code: '812', district: '小港區' },
    { code: '813', district: '左營區' },
    { code: '814', district: '仁武區' },
    { code: '815', district: '大社區' },
    { code: '817', district: '東沙群島' },
    { code: '819', district: '南沙群島' },
    { code: '820', district: '岡山區' },
    { code: '821', district: '路竹區' },
    { code: '822', district: '阿蓮區' },
    { code: '823', district: '田寮區' },
    { code: '824', district: '燕巢區' },
    { code: '825', district: '橋頭區' },
    { code: '826', district: '梓官區' },
    { code: '827', district: '彌陀區' },
    { code: '828', district: '永安區' },
    { code: '829', district: '湖內區' },
    { code: '830', district: '鳳山區' },
    { code: '831', district: '大寮區' },
    { code: '832', district: '林園區' },
    { code: '833', district: '鳥松區' },
    { code: '840', district: '大樹區' },
    { code: '842', district: '旗山區' },
    { code: '843', district: '美濃區' },
    { code: '844', district: '六龜區' },
    { code: '845', district: '內門區' },
    { code: '846', district: '杉林區' },
    { code: '847', district: '甲仙區' },
    { code: '848', district: '桃源區' },
    { code: '849', district: '那瑪夏區' },
    { code: '851', district: '茂林區' },
    { code: '852', district: '茄萣區' },
  ],
  澎湖縣: [
    { code: '880', district: '馬公市' },
    { code: '881', district: '西嶼鄉' },
    { code: '882', district: '望安鄉' },
    { code: '883', district: '七美鄉' },
    { code: '884', district: '白沙鄉' },
    { code: '885', district: '湖西鄉' },
  ],
  金門縣: [
    { code: '890', district: '金沙鎮' },
    { code: '891', district: '金湖鎮' },
    { code: '892', district: '金寧鄉' },
    { code: '893', district: '金城鎮' },
    { code: '894', district: '烈嶼鄉' },
    { code: '896', district: '烏坵鄉' },
  ],
  屏東縣: [
    { code: '900', district: '屏東市' },
    { code: '901', district: '三地門鄉' },
    { code: '902', district: '霧臺鄉' },
    { code: '903', district: '瑪家鄉' },
    { code: '904', district: '九如鄉' },
    { code: '905', district: '里港鄉' },
    { code: '906', district: '高樹鄉' },
    { code: '907', district: '鹽埔鄉' },
    { code: '908', district: '長治鄉' },
    { code: '909', district: '麟洛鄉' },
    { code: '911', district: '竹田鄉' },
    { code: '912', district: '內埔鄉' },
    { code: '913', district: '萬丹鄉' },
    { code: '920', district: '潮州鎮' },
    { code: '921', district: '泰武鄉' },
    { code: '922', district: '來義鄉' },
    { code: '923', district: '萬巒鄉' },
    { code: '924', district: '崁頂鄉' },
    { code: '925', district: '新埤鄉' },
    { code: '926', district: '南州鄉' },
    { code: '927', district: '林邊鄉' },
    { code: '928', district: '東港鎮' },
    { code: '929', district: '琉球鄉' },
    { code: '931', district: '佳冬鄉' },
    { code: '932', district: '新園鄉' },
    { code: '940', district: '枋寮鄉' },
    { code: '941', district: '枋山鄉' },
    { code: '942', district: '春日鄉' },
    { code: '943', district: '獅子鄉' },
    { code: '944', district: '車城鄉' },
    { code: '945', district: '牡丹鄉' },
    { code: '946', district: '恆春鎮' },
    { code: '947', district: '滿州鄉' },
  ],
  臺東縣: [
    { code: '950', district: '臺東市' },
    { code: '951', district: '綠島鄉' },
    { code: '952', district: '蘭嶼鄉' },
    { code: '953', district: '延平鄉' },
    { code: '954', district: '卑南鄉' },
    { code: '955', district: '鹿野鄉' },
    { code: '956', district: '關山鎮' },
    { code: '957', district: '海端鄉' },
    { code: '958', district: '池上鄉' },
    { code: '959', district: '東河鄉' },
    { code: '961', district: '成功鎮' },
    { code: '962', district: '長濱鄉' },
    { code: '963', district: '太麻里' },
    { code: '964', district: '金峰鄉' },
    { code: '965', district: '大武鄉' },
    { code: '966', district: '達仁鄉' },
  ],
  花蓮縣: [
    { code: '970', district: '花蓮市' },
    { code: '971', district: '新城鄉' },
    { code: '972', district: '秀林鄉' },
    { code: '973', district: '吉安鄉' },
    { code: '974', district: '壽豐鄉' },
    { code: '975', district: '鳳林鎮' },
    { code: '976', district: '光復鄉' },
    { code: '977', district: '豐濱鄉' },
    { code: '978', district: '瑞穗鄉' },
    { code: '979', district: '萬榮鄉' },
    { code: '981', district: '玉里鎮' },
    { code: '982', district: '卓溪鄉' },
    { code: '983', district: '富里鄉' },
  ],
  連江縣: [
    { code: '209', district: '南竿鄉' },
    { code: '210', district: '北竿鄉' },
    { code: '211', district: '莒光鄉' },
    { code: '212', district: '東引鄉' },
  ],
};

function useInvoice() {
  const [invoiceNpo, setInvoiceNpo] = useState(default_invoice_npo);

  const getDistricts = (city) => {
    let dists = [];
    if (city) {
      districts[city]?.forEach((item) => {
        dists.push(item.district);
      });
    }
    return dists;
  };

  const getDistrictCode = (city, district) => {
    let code = null;
    if (city) {
      districts[city]?.forEach((item) => {
        if (item.district === district) {
          code = item.code;
        }
      });
    }
    return code;
  };

  const getCarrierTypes = () => {
    let carriers = [];
    carrierTypes.forEach((item) => {
      carriers.push(item.carrierType);
    });
    return carriers;
  };

  const getCarrierCode = (carrierType) => {
    let code = null;
    carrierTypes.forEach((item) => {
      if (item.carrierType === carrierType) {
        code = item.code;
      }
    });
    return code;
  };

  const getCarrierType = (code) => {
    let carrierType = null;
    carrierTypes.forEach((item) => {
      if (item.code === code) {
        carrierType = item.carrierType;
      }
    });
    return carrierType;
  };

  const getDonees = useCallback(() => {
    let doneeList = [];
    invoiceNpo.forEach((item) => {
      doneeList.push(item.npoName);
    });
    return doneeList;
  }, [invoiceNpo]);

  const getDonee = useCallback(
    (npoBan) => {
      let donee = null;
      invoiceNpo.forEach((item) => {
        if (item.npoBan === npoBan) {
          donee = item.npoName;
        }
      });
      return donee;
    },
    [invoiceNpo],
  );

  const getDoneeCode = useCallback(
    (npoName) => {
      let code = null;
      invoiceNpo.forEach((item) => {
        if (item.npoName === npoName) {
          code = item.npoBan;
        }
      });
      return code;
    },
    [invoiceNpo],
  );

  useEffect(() => {
    async function getInvoiceNpo() {
      const response = await InvoiceAPI.getInvoiceNpo();
      if (response.status === 200) {
        const data = response.data;
        setInvoiceNpo(data);
      }
    }

    getInvoiceNpo();
  }, []);

  return {
    cities,
    getDistricts,
    getDonee,
    getDonees,
    getDoneeCode,
    getCarrierType,
    getCarrierTypes,
    getCarrierCode,
    getDistrictCode,
  };
}

export default useInvoice;
