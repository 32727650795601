import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Stack, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const ItemBox = styled(Paper)(({ backgroundchosencolor }) => ({
  textAlign: 'start',
  fontSize: '14px',
  color: 'white',
  height: '46px',
  paddingLeft: '10px',
  alignContent: 'center',
  borderRadius: '6px',
  boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
  backgroundColor: backgroundchosencolor ? backgroundchosencolor : '#150F32',
}));

export default function RadioButtonsGroup({
  id,
  title,
  options,
  name,
  selectedValue = { value: '', remark: '' },
  showError,
  onChange,
}) {
  const handleChange = (e) => {
    const newValue = e.target.value;
    const newRemark = options.find((option) => option.value === newValue)
      ?.textbox
      ? selectedValue.remark
      : '';
    onChange({ value: newValue, remark: newRemark });
  };

  const handleRemarkChange = (e) => {
    const updatedRemark = e.target.value;
    onChange({ value: selectedValue.value, remark: updatedRemark });
  };

  const currentOption = options.find(
    (option) => option.value === selectedValue.value,
  );

  return (
    <FormControl error={showError}>
      <RadioGroup
        aria-labelledby={id}
        value={selectedValue.value || ''}
        name={name}
        onChange={handleChange}>
        <Stack spacing="8px">
          {options.map((item) => {
            return (
              <ItemBox
                key={item.value}
                backgroundchosencolor={
                  item.value === selectedValue.value && '#3E1EE6'
                }>
                <FormControlLabel
                  value={item.value}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: 'white',
                        },
                      }}
                    />
                  }
                  label={item.label}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
              </ItemBox>
            );
          })}
          {currentOption?.textbox && (
            <Box
              sx={{
                backgroundColor: '#150F32',
                paddingLeft: '10px',
                height: 42,
                alignContent: 'center',
              }}>
              <TextField
                sx={{
                  '& .MuiInputBase-input': {
                    color: '#FFFFFF',
                    fontSize: 14,
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: 'rgba(255, 255, 255, 0.5)',
                    fontSize: 14,
                  },
                }}
                variant="standard"
                placeholder={currentOption.annotation}
                value={selectedValue.remark || ''}
                onChange={handleRemarkChange}
              />
            </Box>
          )}
        </Stack>
      </RadioGroup>
      <FormHelperText id={`${name}-error-text`}>
        {showError && `請填寫: ${title}`}
      </FormHelperText>
    </FormControl>
  );
}
