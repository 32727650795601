import { React, useRef, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Box, TextField } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import BottomPanel from '../../components/BottomPanel';
import validator from './validator';
import Dialog from '../../components/Dialog';
import { UserContext } from '../../UserContext';
import { UserAPI } from '../../apis/UserAPI';
import StyledButton from '../../components/StyledButton';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

const DescriptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '15px',
  fontWeight: '400',
}));

export default function ChangeEmailPage() {
  const navigate = useNavigate();
  const { setLoading } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const inputUserEmail = useRef();
  const [errors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const alertTitle = useRef('');
  const alertMessage = useRef('');
  const token = useRef(searchParams.get('token'));

  const checkEmail = () => {
    let alert = null;
    if (!validator.isEmail(inputUserEmail.current.value, errors)) {
      alert = errors.email;
    } else if (inputUserEmail.current.value === '') {
      alert = '請填寫Email';
    }

    if (alert) {
      alertTitle.current = '輸入錯誤';
      alertMessage.current = alert;
      setShowAlert(true);
      return false;
    } else {
      return true;
    }
  };

  const handleResendVerifyCode = async () => {
    if (!checkEmail()) {
      return;
    }

    setLoading(true);

    const response = await UserAPI.getEipEmailCode({
      email: inputUserEmail.current.value,
    }).catch((error) => {
      setShowAlert(true);
      alertTitle.current = '請輸入正確電子信箱';
      alertMessage.current = error.response.data.error.message;
    });

    setLoading(false);

    if (response) {
      navigate('/eipEmailVerify', {
        state: {
          token: token.current,
          email: inputUserEmail.current.value,
        },
      });
    }
  };

  const navigation = () => {
    return <NavigationBar title={'請輸入會員電子信箱'} hideLeftButton={true} />;
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleResendVerifyCode}>
          確認並發送驗證信
        </StyledButton>
      </BottomPanel>
    );
  };

  const description = () => {
    return (
      <Box marginTop="10px" marginBottom="37px">
        <DescriptionLabel>
          請輸入會員電子信箱，我們會將驗證碼寄送至新的電子信箱，請輸入信件中的驗證碼以完成載具綁定程序，謝謝。
        </DescriptionLabel>
      </Box>
    );
  };

  const emailField = () => {
    return (
      <Box marginTop="10px" marginBottom="20px">
        <TextField
          autoComplete="off"
          fullWidth
          id="userid"
          placeholder="請輸入會員 Email"
          sx={{ pt: '10px' }}
          inputRef={inputUserEmail}
        />
      </Box>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box sx={{ backgroundColor: '#FFFFFFF' }} padding="16px">
          {description()}
          {emailField()}
        </Box>
        {bottomPanel() /* 驗證按鈕 */}
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowAlert(false)}
      />
    </>
  );
}
