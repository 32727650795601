import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const GradientPaper = styled(Paper)({
  backgroundColor: '#3211A0',
  overflow: 'hidden',
  width: '100vw', // Fill full viewport width
  height: '100vh', // Fill full viewport height
  position: 'fixed', // Ensure it covers the full screen
  top: 0,
  left: 0,
  zIndex: -1000, // Push it behind other content
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '420px',
    left: '-230px',
    width: '480px',
    height: '480px',
    borderRadius: '50%',
    background: 'radial-gradient(circle,rgb(55, 252, 255), transparent 90%)',
    filter: 'blur(80px)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '200px',
    right: '-240px',
    width: '380px',
    height: '380px',
    borderRadius: '50%',
    background: 'radial-gradient(circle,rgb(255, 196, 19), transparent 90%)',
    filter: 'blur(90px)',
  },
});
const ExtraCircle = styled('div')({
  zIndex: -100,
  content: '""',
  position: 'absolute',
  top: '-180px',
  left: '-180px',
  width: '380px',
  height: '380px',
  borderRadius: '50%',
  background: 'radial-gradient(circle,rgb(255, 255, 255), transparent 90%)',
  filter: 'blur(100px)',
});

export default function EveBackground() {
  return (
    <>
      <ExtraCircle />
      <GradientPaper
        elevation={3}
        sx={{
          justifyContent: 'center',
          alignContent: 'center',
        }}
      />
    </>
  );
}
