import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Stack,
  Typography,
  Button,
  styled,
  Snackbar,
} from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { useCountdown } from '../../hooks/useCountdown';
import { OrderAPI } from '../../apis/OrderAPI';
import Panel from '../../components/Panel';
import { UserContext } from '../../UserContext';
import { UserAPI } from '../../apis/UserAPI';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ThousandSeparator } from '../../utils/CoinNumberFormat';

const sxContainer = {
  p: `${navigationBarHeight}px 16px 0`,
};

const Navigation = ({ backNavigation }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (backNavigation) {
      navigate(backNavigation);
    } else {
      navigate(-1);
    }
  };

  return (
    <NavigationBar title="付款" leftButtonHandler={handleBack}>
      <Button
        onClick={() => {
          navigate('/bankLimited');
        }}
        sx={{ color: '#fff', fontSize: '17px', fontWeight: '600' }}>
        銀行限額
      </Button>
    </NavigationBar>
  );
};

const PaymentCountdown = ({ settledExpireTime, id }) => {
  const { duration } = useCountdown(settledExpireTime);
  const navigate = useNavigate();

  async function updateOrder() {
    const order = await OrderAPI.getOrder(id);
    if (order.status !== 'open') {
      navigate('/tradeDone', {
        state: {
          order,
          title: '交易失敗...',
          message: '交易已取消',
        },
      });
    }
  }
  useEffect(() => {
    updateOrder();
  }, []);

  useEffect(() => {
    const interval = setInterval(updateOrder, 10000);
    return () => clearInterval(interval);
  }, [id, navigate]);

  return (
    <>
      {String(duration.minutes()).padStart(2, '0')}:
      {String(duration.seconds()).padStart(2, '0')}
    </>
  );
};

function Caution({ user }) {
  return (
    <Stack spacing={1} alignItems="center" marginBottom="16px">
      <Typography
        variant="h5"
        component="h5"
        sx={{
          fontWeight: 600,
          textAlign: 'center',
          lineHeight: 1.5,
          fontSize: '20px',
        }}>
        僅可使用
        <br />
        {user?.bankAccount?.simpleBankName} **
        {user?.bankAccount?.accountNumber.slice(-5)} ({user?.chineseName})
        <br />
        轉帳存入金額
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 400,
          textAlign: 'center',
          color: '#FFA8A8',
          fontSize: '14px',
        }}>
        請注意該帳戶非約定轉帳的限額
      </Typography>
    </Stack>
  );
}

const CancelTradeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 'medium',
  backgroundColor: '#3E1EE6',
  width: '91px',
  borderRadius: '24px',

  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  '&:active': {
    backgroundColor: theme.palette.background.default,
  },
}));

function BankDepositInfo({ user, order, setOpenCopyMessage }) {
  const { size, productId, totalPrice, fund, id, settledExpireTime } = order;
  const navigate = useNavigate();
  const handleAccountIdCopy = async () => {
    await navigator.clipboard.writeText(user?.virtualAccountNumber);
    setOpenCopyMessage(true);
  };
  const handleCancelOrder = async () => {
    const order = await OrderAPI.updateOrder(id);

    if (order) {
      navigate('/tradeDone', {
        state: {
          order,
          nextNavigation: '/assetHistory/ALL',
          title: '交易失敗...',
          message: '交易已取消',
        },
      });
    }
  };

  return (
    <>
      <Panel backgroundColor={'#FFFFFF'}>
        <Stack spacing={0.5}>
          <Typography
            fontSize="16px"
            color="text.orange"
            fontWeight={500}
            align="center">
            訂單成功後無法退款或取消，
            <br />
            請務必確認下方帳號由您的帳號產出
          </Typography>
          <Typography
            fontSize="14px"
            color="text.black"
            fontWeight={500}
            align="center">
            虛擬貨幣將存入 {user?.phoneNumber} 帳戶
          </Typography>
        </Stack>
        <Box display="flex" sx={{ flexDirection: 'column' }} gap="11px">
          <Stack spacing={2} alignItems="center" direction="row" marginTop={2}>
            <Typography fontSize="14px" color="text.neutral">
              期限倒數
            </Typography>
            <Typography
              fontSize="18px"
              color="#3E1EE6"
              fontWeight={600}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}>
              <PaymentCountdown settledExpireTime={settledExpireTime} id={id} />
            </Typography>
          </Stack>
          <Stack spacing={2} alignItems="center" direction="row">
            <Typography fontSize="14px" color="text.neutral">
              專屬帳號
            </Typography>
            <Typography
              fontSize="18px"
              color="text.black"
              fontWeight={600}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}>
              <span>{user?.virtualAccountNumber}</span>
              <ContentCopyIcon
                fontSize="small"
                sx={{ cursor: 'pointer', color: '#a4a4a4' }}
                onClick={handleAccountIdCopy}
              />
            </Typography>
          </Stack>
          <Stack spacing={2} alignItems="center" direction="row">
            <Typography fontSize="14px" color="text.neutral">
              銀行代碼
            </Typography>
            <Typography fontSize="18px" color="text.black" fontWeight={600}>
              012 富邦銀行
            </Typography>
          </Stack>
          <Stack spacing={2} alignItems="center" direction="row">
            <Typography fontSize="14px" color="text.neutral">
              轉入金額
            </Typography>
            <Typography fontSize="18px" color="text.black" fontWeight={600}>
              {ThousandSeparator(fund)} TWD
            </Typography>
          </Stack>
          <Stack spacing={2} direction="row">
            <Typography fontSize="14px" color="text.neutral">
              購買數量
            </Typography>
            <Box>
              <Typography fontSize="14px" color="text.black" fontWeight={400}>
                {size}&nbsp;
                {productId.slice(0, 3)}
              </Typography>
              <Typography fontSize="14px" color="#AEAEAE" fontWeight={400}>
                1 {productId.slice(0, 3)} ≈ {ThousandSeparator(totalPrice)} TWD
              </Typography>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="center" marginTop="26px">
            <CancelTradeButton onClick={() => handleCancelOrder()}>
              取消交易
            </CancelTradeButton>
          </Box>
        </Box>
      </Panel>
    </>
  );
}

function PaymentWording() {
  return (
    <Typography sx={{ marginTop: '12px', fontSize: '14px' }}>
      ※ 除了實體
      ATM/網路銀行外，不接受其他轉帳方式，包含臨櫃匯款、ATM現金存款、電子支付帳戶轉帳等。
      <br />※
      訂單付款完成後，若交易未成功或匯款金額大於訂單金額，款項將會匯入餘額帳戶。
    </Typography>
  );
}

export default function OrderPaymentPage() {
  const { user } = useContext(UserContext);
  const { state } = useLocation();
  const { order, backNavigation } = state;
  const [openCopyMessage, setOpenCopyMessage] = useState();

  return (
    <Box sx={sxContainer}>
      <Navigation backNavigation={backNavigation} />
      <Caution user={user} />
      <BankDepositInfo
        user={user}
        order={order}
        setOpenCopyMessage={setOpenCopyMessage}
      />
      <PaymentWording />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openCopyMessage}
        autoHideDuration={5000}
        message="匯款帳號已複製"
        key={'bottomCenter'}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: 'center',
          },
        }}
      />
    </Box>
  );
}
