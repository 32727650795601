import { styled } from '@mui/material/styles';

const Icon = styled('img', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height',
})(({ theme, width, height }) => ({
  maxWidth: width ? width : 40,
  maxHeight: height ? height : 40,
}));

export default Icon;

const StyledImageIcon = styled('img', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height',
})(({ theme, width, height }) => ({
  width: width ? `${width}px` : '40px',
  height: height ? `${height}px` : '40px',
  objectFit: 'contain',
}));

export function ImageIcon({ src, alt, width, height, ...props }) {
  return (
    <StyledImageIcon
      src={src}
      alt={alt || ''}
      width={width}
      height={height}
      {...props}
    />
  );
}

const StyledSvgIcon = styled(SvgIcon)(({ theme, width, height }) => ({
  width: width || 'inherit',
  height: height || 'inherit',
  fontSize: 'inherit', // 移除 MUI 的默認字體大小限制
  '& svg': {
    width: '100%',
    height: '100%',
  },
}));

export function SvgIcon({
  children,
  component,
  src,
  alt,
  width,
  height,
  color,
  ...props
}) {
  if (src) {
    return (
      <img
        src={src}
        alt={alt || ''}
        style={{
          width: width || 'inherit',
          height: height || 'inherit',
        }}
        {...props}
      />
    );
  }

  return (
    <StyledSvgIcon
      component={component}
      width={width}
      height={height}
      color={color}
      viewBox="0 0 18 18" // 保持和原始 SVG 一致
      {...props}>
      {children}
    </StyledSvgIcon>
  );
}
