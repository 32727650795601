import NavigationBar from '../../components/NavigationBar';
import Box from '@mui/material/Box';
import { ImageIcon } from '../../components/Icon';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import EveButton from '../../components/EveButton';
import ProviderInformation from '../../components/ProviderInformation';
import { useNavigate } from 'react-router-dom';

export default function MobileDeviceVerificationPage() {
  const navigate = useNavigate();
  return (
    <>
      <NavigationBar />
      {/* page container */}
      <Box
        padding="60px 16px 30px 16px"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
        {/* page content */}
        <Stack
          marginTop="40px"
          spacing={0}
          direction="column"
          alignItems="center"
          sx={{ flex: 1, position: 'relative' }}>
          <Stack alignItems="center">
            {/* logo */}
            <ImageIcon
              src={'/images/img_accountlink.png'}
              width={176}
              height={176}
            />
            {/* description */}
            <Typography
              fontWeight={400}
              fontSize={26}
              marginTop={5}
              textAlign="center">
              您的手機號碼
              <br />
              與上一次登入不同
            </Typography>
            {/* provider information */}
            <Box marginTop={3}>
              <ProviderInformation />
            </Box>
          </Stack>
          <Stack
            spacing={3}
            width="100%"
            alignItems="center"
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              padding: '0 16px',
            }}>
            <EveButton
              sx={{ height: '48px', width: '100%' }}
              onClick={() => navigate('/landing')}>
              重新登入
            </EveButton>
            <Box>
              <EveButton
                variant="text"
                onClick={() => navigate('/changePhoneNumber')}>
                變更手機號碼
              </EveButton>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
