import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { UserContext } from '../../UserContext';
import { useEffect } from 'react';
import { OrderAPI } from '../../apis/OrderAPI';
import moment from 'moment';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import ButtonContainer from '../../components/ButtonContainer';
import Icon from '../../components/Icon';

const sxContainer = {
  position: 'relative',
  pl: '16px',
  pr: '16px',
};

const TitleLabel = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
  fontWeight: '400',
  opacity: '60%',
}));

const ValueLabel = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '32px',
  fontWeight: '400',
  display: 'flex',
  alignItems: 'center',
}));

const SubValueLabel = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
  fontWeight: '400',
}));

export default function TradeDonePage() {
  const { setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { order, message } = state;

  const [orderResult, setOrderResult] = useState(order);
  const {
    id,
    productId,
    side,
    size,
    fund,
    payment,
    status,
    doneReason,
    createdTime,
    totalPrice,
    rejectReason,
  } = orderResult;

  const formatUnixTimestamp = (timestamp) => {
    if (timestamp) {
      return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
    }
    return moment().format('YYYY-MM-DD HH:mm:ss');
  };

  function HeadingTitle({ side, status }) {
    function handleTitle() {
      switch (status) {
        case 'pending':
          if (side === 'buy') {
            return '申請買幣中';
          } else {
            return '申請賣幣中';
          }

        case 'success':
          if (side === 'buy') {
            return '買幣成功！';
          } else {
            return '賣幣成功！';
          }
        case 'cancel':
          return '交易失敗...';
        default:
          return '交易失敗...';
      }
    }

    return (
      <Box
        sx={{
          pt: '30px',
          pb: '50px',
        }}>
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 30,
              marginTop: '40px',
            }}>
            {handleTitle()}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 14,
            }}>
            {formatUnixTimestamp(createdTime)}
          </Typography>
        </Stack>
      </Box>
    );
  }

  function TransactionDetails({
    side,
    fund,
    productId,
    totalPrice,
    id,
    payment,
  }) {
    const coinType = {
      'BTC-TWD': '/icons/BTC.svg',
      'ETC-TWD': '/icons/ETC.svg',
    };
    const paymentType = { balance: '帳戶餘額', bank: '網路銀行轉帳' };
    return (
      <>
        <Stack flexDirection="column" gap="16px">
          <Stack>
            <TitleLabel>金額 (TWD)</TitleLabel>
            <ValueLabel>
              {side === 'buy' ? '-' : '+'}
              <CoinNumberFormat value={fund} prefix="$" />
            </ValueLabel>
          </Stack>
          <Stack>
            <TitleLabel>交易數量</TitleLabel>
            <ValueLabel>
              {side === 'buy' ? '+' : '-'}
              {size}&nbsp;
              <Icon src={coinType[productId]} />
            </ValueLabel>
            <SubValueLabel>
              1 {productId?.slice(0, 3)} ≈&nbsp;
              <CoinNumberFormat value={totalPrice} />
              &nbsp; TWD
            </SubValueLabel>
          </Stack>
          <Stack>
            <TitleLabel>交易序號</TitleLabel>
            <SubValueLabel>{id}</SubValueLabel>
          </Stack>
          <Stack>
            <TitleLabel>付款方式</TitleLabel>
            <SubValueLabel>{paymentType[payment]}</SubValueLabel>
          </Stack>
        </Stack>

        <BottomButton error={false} />
      </>
    );
  }

  function TransactionError({ doneReason, rejectReason, message }) {
    function getOrderStatus() {
      switch (doneReason) {
        case 'cancel':
          return '主動取消';
        case 'rejected':
          if (rejectReason === 'unpaid') {
            return '逾期付款';
          } else if (rejectReason === 'coinbase') {
            return '幣價波動';
          }
          break;
        default:
          return '交易失敗';
      }
    }
    return (
      <>
        <Box marginTop="30px">
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 16,
            }}>
            {message || getOrderStatus()}
          </Typography>
        </Box>
        <BottomButton error={true} />
      </>
    );
  }

  const BottomButton = ({ error }) => {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '8px',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            letterSpacing: '-0.4px',
            justifyContent: 'flex-start',
            padding: '8px 16px',
            gap: '10px',
            backgroundColor: '#231a54',
          }}>
          {error ? (
            <ButtonContainer
              direction="column"
              buttons={[
                {
                  label: '重新發動交易',
                  variant: 'contained',
                  onClick: () => {
                    navigate('/');
                  },
                },
              ]}
            />
          ) : (
            <ButtonContainer
              direction="row"
              buttons={[
                {
                  label: '交易紀錄',
                  variant: 'outlined',
                  onClick: () => {
                    navigate('/assetHistory/order?q=all', {
                      state: {
                        recordType: 0,
                      },
                    });
                  },
                },
                {
                  label: '完成',
                  variant: 'contained',
                  onClick: () => {
                    navigate('/');
                  },
                },
              ]}
            />
          )}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    setLoading(false);
    window.scrollTo(0, 0);
    const updateOrderStatus = setTimeout(async () => {
      const response = await OrderAPI.getOrder(orderResult?.id);
      if (response) {
        const invoiceDetail = orderResult?.invoiceDetail;
        const updatedOrder = { ...response, invoiceDetail: invoiceDetail };
        setOrderResult(updatedOrder);
      }
    }, 10000);

    return () => clearTimeout(updateOrderStatus);
  }, []);

  function titleImg() {
    switch (status) {
      case 'pending':
        return '/icons/result_unidentified.svg';
      case 'success':
        return '/icons/trade_success.svg';
      case 'cancel':
        return '/icons/result_error.svg';

      default:
        return '/icons/result_error.svg';
    }
  }

  return (
    <Box sx={sxContainer}>
      <Box
        component="img"
        src={titleImg()}
        alt="Confirmation Image"
        sx={{
          position: 'absolute',
          right: -10,
          top: `18px`,
          width: '200px',
          height: '150px',
          zIndex: 1,
        }}
      />

      <HeadingTitle status={status} side={side} />
      {status === 'cancel' || message ? (
        <TransactionError
          doneReason={doneReason}
          rejectReason={rejectReason}
          message={message}
        />
      ) : (
        <TransactionDetails
          side={side}
          fund={fund}
          productId={productId}
          totalPrice={totalPrice}
          id={id}
          payment={payment}
        />
      )}
    </Box>
  );
}
