import { useLocation } from 'react-router-dom';

import RecordList from '../../components/asset/RecordList';
import { useTransactions } from '../../hooks/useTransactions';
import AccountAssetLayout from './AccountAssetLayout';
import { useContext } from 'react';
import { UserContext } from '../../UserContext';
import { useRecordNavigation } from '../../hooks/useRecordNavigation';
import { useNavigate } from 'react-router-dom';

const TWD_ACCT_DATA_LIST = ['deposits', 'withdraws'];
const CRYPTO_ACCT_DATA_LIST = ['orders'];

export default function AccountAssetPage() {
  const { state } = useLocation();
  const { currencyId, chineseName, twdValue, availableBalance, accounts } =
    state;

  const { user, setLoading } = useContext(UserContext);
  const originFrom = state?.from || state?.originFrom || '';
  const { handleRecordClick } = useRecordNavigation(originFrom);
  const navigate = useNavigate();

  const dataList =
    currencyId === 'TWD' ? TWD_ACCT_DATA_LIST : CRYPTO_ACCT_DATA_LIST;
  const { deposits, withdraws, orders } = useTransactions(
    user,
    setLoading,
    dataList,
  );

  // TODO: 這邊後端 API 給的不太有人性，我個人建議在這頁要麻給新的 API（推薦），要麻在舊的 API 加上 能夠區別 withdraw 跟 deposit 的 參數。目前 withdraw 有一個 type 可以判斷，但是 deposit 沒有。這在渲染上會很麻煩。因為這一個頁面會同時渲染 withdraw 跟 deposit 的資料，可是這兩種 row 又是分別導到不同的明細頁。而且在資料列顯示上有稍嫌不同，但又沒有很不同。這導致共用元件很難設計，導致前端處理過多邏輯。建議還是給新的 API，並做好時間排序。另外，也希望在 /me/orders/ 可以加上filter 不要資料過來了才在做filter，這樣佔用 bandwidth，效能也不好
  let records = [];
  if (currencyId === 'TWD') {
    records = deposits.concat(withdraws).sort((a, b) => {
      return new Date(b.createdTime) - new Date(a.createdTime);
    });
  } else {
    records = orders.filter(
      (record) => record.productId.split('-')[0] === currencyId,
    );
  }

  const cellType = currencyId === 'TWD' ? 'deposit' : 'order';
  const actionNameOne = currencyId === 'TWD' ? '提領' : '買入';
  const actionNameTwo = currencyId === 'TWD' ? '儲值' : '賣出';
  const navigateToWithdrawPage = () =>
    navigate('/withdraw/bank', { state: { accounts } });
  const navigateToDepositPage = () => navigate('/deposit/bank');
  const navigateToBuyPage = () => navigate(`/buy/${currencyId}-TWD`);
  const navigateToSellPage = () => navigate(`/sell/${currencyId}-TWD`);
  const actionOne =
    currencyId === 'TWD' ? navigateToWithdrawPage : navigateToBuyPage;
  const actionTwo =
    currencyId === 'TWD' ? navigateToDepositPage : navigateToSellPage;
  const handleDisabled =
    currencyId !== 'TWD' &&
    accounts?.find((size) => size.currencyId === currencyId)?.twdValue === 0;

  return (
    <>
      <AccountAssetLayout
        currencyId={currencyId}
        chineseName={chineseName}
        availableBalance={availableBalance}
        twdValue={twdValue}
        actionNameOne={actionNameOne}
        actionNameTwo={actionNameTwo}
        actionOne={actionOne}
        actionTwo={actionTwo}
        isDisabled={handleDisabled}>
        <RecordList
          type={cellType}
          records={records}
          onItemClick={(record) => handleRecordClick(record, cellType)}
        />
      </AccountAssetLayout>
    </>
  );
}
