import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Box, Drawer, IconButton, Button } from '@mui/material';
import { TitleLabel, SubTitleLabel } from './Label';
import CloseIcon from '@mui/icons-material/Close';

const sxBottomDrawer = {
  bottom: 0,
  left: 0,
  right: 0,
  padding: '16px',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  bgcolor: 'background.default',
};

const PrimaryActionButton = styled(Button)(({ theme }) => ({
  color: 'white',
  fontSize: '17px',
  fontWeight: 'bold',
  height: '48px',
  borderRadius: '9px',
  backgroundColor: '#3E1EE6',
}));

const SecondaryActionButton = styled(Button)(({ theme }) => ({
  color: '#CCC3F8',
  backgroundColor: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  height: '48px',
  borderRadius: '9px',
  border: '1px solid #CCC3F8',
  '&:focus': {
    color: '#000',
    backgroundColor: '#FFF',
  },
}));

export default function Dialog({
  showDialog,
  setShowDialog,
  title,
  message,
  actionLabel,
  actionHandler,
  secondaryActionLabel,
  secondaryActionHandler,
  children,
  closeHandler,
}) {
  const handleClose = () => {
    setShowDialog(false);
    if (closeHandler) closeHandler();
  };

  return (
    <Drawer
      open={showDialog}
      onClose={handleClose}
      anchor="bottom"
      PaperProps={{
        elevation: 0,
        style: { backgroundColor: 'transparent' },
      }}>
      <Paper sx={sxBottomDrawer} elevation={3}>
        <Box display="flex" alignItems="center" marginBottom="12px">
          <Box sx={{ flex: 1 }} marginLeft="10vw">
            <TitleLabel fontSize="17px" fontWeight="bold" textAlign="center">
              {title}
            </TitleLabel>
          </Box>

          <IconButton onClick={handleClose}>
            <CloseIcon
              style={{ fontSize: 24 }}
              sx={{
                color: (theme) => theme.palette.common.white,
              }}
            />
          </IconButton>
        </Box>

        {message ? (
          <SubTitleLabel fontSize="15px">{message}</SubTitleLabel>
        ) : (
          children
        )}

        <Box display="flex" marginTop="32px">
          {secondaryActionLabel && (
            <>
              <SecondaryActionButton
                variant="contained"
                fullWidth
                disableElevation
                onClick={secondaryActionHandler}>
                {secondaryActionLabel}
              </SecondaryActionButton>

              <Box width="20px"></Box>
            </>
          )}

          {actionLabel && (
            <PrimaryActionButton
              variant="contained"
              fullWidth
              disableElevation
              onClick={actionHandler}>
              {actionLabel}
            </PrimaryActionButton>
          )}
        </Box>
      </Paper>
    </Drawer>
  );
}
