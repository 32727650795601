import React, { useState, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import Box from '@mui/material/Box';
import { Label } from '../../components/Label';
import { PriceNumberFormat } from '../../utils/CoinNumberFormat';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';

const CurrentPrice = ({ product, navigation }) => {
  const [buyPrice, setBuyPrice] = useState(0);
  const [fluctuation, setFluctuation] = useState(0);

  // 初始化：使用 product 內的即時價格資料（若有）
  useEffect(() => {
    if (product && product.realtimePrice?.balance) {
      const priceData = product.realtimePrice.balance;
      setBuyPrice(priceData.buy_price || priceData.buyPrice);
      setFluctuation(priceData.fluctuation);
    }
  }, [product]);

  // 使用 WebSocket 更新即時價格
  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => false,
    queryParams: {
      token: getWebsocketToken(),
    },
  });

  useEffect(() => {
    if (!product) return;
    if (lastMessage) {
      try {
        const data = JSON.parse(lastMessage.data);
        console.log('WebSocket received:', data);
        // 若收到資料中包含 id 與 status 則略過
        if (data.id && data.status) return;
        // 確認 WS 回傳資料中有對應 product.id 的數據
        if (data[product.id]) {
          const priceData = data[product.id].balance;
          setBuyPrice(priceData.buy_price);
          setFluctuation(priceData.fluctuation);
        }
      } catch (error) {
        console.error('Error parsing WebSocket data:', error);
      }
    }
  }, [lastMessage, product]);

  const fluctuationColor = (num) => {
    if (num === 0) return '#fff';
    return num > 0 ? '#FBA28B' : '#22DDB9';
  };

  if (navigation) {
    return (
      <Box marginRight="6px">
        <Label fontSize="14px" sx={{ color: fluctuationColor(fluctuation) }}>
          <PriceNumberFormat value={buyPrice} />
        </Label>
        <Box display="flex" flexDirection="column">
          <Label
            sx={{ color: fluctuationColor(fluctuation) }}
            fontSize="10px"
            textAlign="right">
            {fluctuation}%
          </Label>
        </Box>
      </Box>
    );
  }
  return (
    <Box marginBottom="16px">
      <Label
        fontWeight="medium"
        fontSize="30px"
        sx={{ color: fluctuationColor(fluctuation) }}>
        <PriceNumberFormat value={buyPrice} />
      </Label>
      <Box display="flex" flexDirection="column">
        <Label sx={{ color: fluctuationColor(fluctuation) }} fontSize="12px">
          {fluctuation}%
        </Label>
      </Box>
    </Box>
  );
};

export default React.memo(CurrentPrice);
