import React, { useState, useEffect, useRef } from 'react';
import { Box, Fab } from '@mui/material';
import Icon from './Icon';
import { Label } from './Label';

const sxButtonSelected = {
  width: '77px',
  height: '31px',
  fontSize: '12px',
  fontWeight: '400',
  backgroundColor: (theme) => theme.palette.background.secondary,
  color: (theme) => theme.palette.text.primary,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)',
  borderRadius: '6px',

  '&:hover': {
    backgroundColor: (theme) => theme.palette.background.secondary,
  },
};

const sxButtonUnselected = {
  width: '77px',
  fontSize: '12px',
  height: '31px',
  fontWeight: '400',
  backgroundColor: (theme) => theme.palette.primary.main,
  color: (theme) => theme.palette.text.primary,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0)',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: (theme) => theme.palette.background.secondary,
  },
};

export default function GroupButtons({
  selection,
  items,
  selectionHandler,
  sx = {},
  selected,
}) {
  const handleClick = (index) => {
    selectionHandler(index);
  };

  return (
    <Box display="flex" justifyContent="space-between" sx={{ my: '16px' }}>
      {items.map((item, index) => (
        <Fab
          sx={{
            ...(index === selection
              ? { ...sxButtonSelected, ...sx.selected }
              : { ...sxButtonUnselected, ...sx.unselected }),
          }}
          key={index}
          variant="extended"
          size="small"
          onClick={() => handleClick(index)}>
          <Box display="flex" alignItem="center" gap="1px">
            {item === '全部' && <Icon src={`/icons/time.svg`} />}
            <Label fontSize="12px">{item}</Label>
          </Box>
        </Fab>
      ))}
    </Box>
  );
}
