import { Label } from '../../../components/Label';
import { Box, Stack } from '@mui/material';

const SectionList = ({ items, orderBy }) => {
  const DetailItem = ({ detail }) => {
    return (
      <>
        {detail.detailItems.map((text, index) => (
          <Label key={index} py="4px">
            {text}
          </Label>
        ))}
      </>
    );
  };

  return (
    <>
      {orderBy === 'ordinalNumber' &&
        items.map((item, index) => (
          <Box key={`prefix-${index}`} sx={{ pb: '8px' }}>
            <Stack direction="row" spacing={1}>
              <Label>{`${index + 1}. `}</Label>
              <Box>
                <Label>{item.item}</Label>
                <Box>{item.detail && <DetailItem detail={item.detail} />}</Box>
              </Box>
            </Stack>
          </Box>
        ))}
      {orderBy === 'alphabetically' &&
        items.map((item, index) => (
          <Stack
            key={`prefix-${index}`}
            direction="row"
            spacing={1}
            sx={{ pb: '8px' }}>
            <Label>{`(${String.fromCharCode(97 + index)}) `}</Label>
            <Label>
              {item.item}
              {item.detail && <DetailItem detail={item.detail} />}
            </Label>
          </Stack>
        ))}
    </>
  );
};

export default SectionList;
