import { Box, styled } from '@mui/material';
import Slider from 'react-slick';

//Banner SectionSection
const Image = styled('img')(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '12px',
}));

function Banner({ image, height }) {
  return (
    <Box
      sx={{
        height,
      }}>
      <Image src={`/images/${image}`} />
    </Box>
  );
}

const PromotionBannerSection = ({ images, height, autoplay = true }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay,
    speed: 800,
    autoplaySpeed: 5000,
    dotsClass: 'custom_dot',
  };

  //   const images = ['banner1.jpg', 'banner2.jpg', 'banner3.jpg', 'banner4.jpg'];

  return (
    <Box
      sx={{
        marginBottom: '16px',
        overflow: 'hidden',
      }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <Banner key={index} image={image} height={height} />
        ))}
      </Slider>
    </Box>
  );
};

export default PromotionBannerSection;
