import EveButton from '../../components/EveButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import PromotionBannerSection from '../../components/PromotionBannerSection';
import ProviderInformation from '../../components/ProviderInformation';

export default function Indroduction() {
  const navigate = useNavigate();
  const images = ['banner1.jpg', 'banner2.jpg', 'banner3.jpg', 'banner4.jpg'];

  return (
    <>
      <NavigationBar
        title=""
        leftButtonHandler={() => navigate('/')}
        noBackgroundColor
      />
      <Box padding="72px 16px">
        <PromotionBannerSection
          images={images}
          height="calc(100vh - 300px)"
          // height="486px"
          autoplay={false}
        />
        <Stack
          width="100%"
          spacing={2}
          alignItems="center"
          sx={{
            position: 'fixed',
            bottom: 40,
            left: 0,
            right: 0,
            padding: '0 20px',
          }}>
          <ProviderInformation />
          <EveButton
            variant="contained"
            sx={{ height: '48px', width: '100%' }}
            onClick={() => navigate('/landing')}>
            馬上成為會員
          </EveButton>
          <Box>
            <EveButton
              variant="text"
              onClick={() => navigate('/', { replace: true })}>
              再看看
            </EveButton>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
