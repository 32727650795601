import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.querySelector('.MuiBox-root');
    if (element) {
      element.scrollTo(0, 0);
    } else {
      // Fallback to window scroll if MuiBox-root isn't found
      window.scrollTo(0, 0);
    }
  }, [pathname]);
};

export default ScrollToTop;
