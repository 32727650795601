import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Container,
  Link,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ProviderInformation from '../../components/ProviderInformation';
import NavigationBar from '../../components/NavigationBar';
import { useNavigate } from 'react-router-dom';

// 自定義樣式
const StyledContainer = styled(Container)(({ theme }) => ({
  background: '#231A54',
  color: '#fff',
  padding: theme.spacing(5),
  minWidth: 320,
}));

const StyledTable = styled(Table)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiTableCell-root': {
    color: '#fff',
    borderColor: 'rgba(255,255,255,.3)',
    textAlign: 'center',
    border: '1px solid rgba(255,255,255,.3)',
    fontSize: '1rem',
    padding: '12px', // Changed from 16px to 8px to reduce spacing
  },
  '& .MuiTableBody-root': {
    border: '1px solid rgba(255,255,255,.3)',
  },
}));
const StyledLink = styled(Link)({
  color: '#FFD66E',
  borderBottom: '1px solid',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});

const Footer = styled(Box)({
  marginTop: 60,
  fontSize: 14,
  color: 'rgba(255,255,255,.5)',
  textAlign: 'center',
});

export default function BankLimitTable() {
  const navigate = useNavigate();
  return (
    <>
      <NavigationBar leftButtonHandler={() => navigate(-1)} />
      <Box padding="45px 16px">
        <StyledContainer>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight={350}
            marginBottom={3}>
            銀行非約定帳戶轉帳限額
          </Typography>

          <StyledTable>
            <TableBody>
              <TableRow>
                <TableCell component="th" style={{ fontWeight: 'bold' }}>
                  銀行帳戶類型
                </TableCell>
                <TableCell>銀行帳戶</TableCell>
                <TableCell>數位帳戶</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" style={{ fontWeight: 'bold' }}>
                  單筆限額
                </TableCell>
                <TableCell>5萬</TableCell>
                <TableCell>1萬</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" style={{ fontWeight: 'bold' }}>
                  單日限額
                </TableCell>
                <TableCell>10萬</TableCell>
                <TableCell>3萬</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" style={{ fontWeight: 'bold' }}>
                  單月限額
                </TableCell>
                <TableCell>20萬</TableCell>
                <TableCell>5萬</TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>

          <Typography variant="body2" gutterBottom>
            ※ 各銀行規定皆不同，實際以各銀行官網公告為主。
          </Typography>

          <Typography variant="body2" gutterBottom>
            ※
            如有大額交易需求，建議您銀行帳戶綁定成功後，可將專屬的虛擬帳號約定至您已綁定的銀行帳戶，詳細相關說明請參考
            {/* <StyledLink href=""> */}
            <StyledLink>如何將「虛擬帳號」設定為約定帳號轉帳？</StyledLink>。
          </Typography>

          <Footer variant="body2">
            <ProviderInformation />
          </Footer>
        </StyledContainer>
      </Box>
    </>
  );
}
