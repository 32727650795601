import { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Box, Tabs, Tab, List, ListItem } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { UserContext } from '../../UserContext';
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerWrapper from '../../components/DrawerWrapper';
import { ImageIcon, SvgIcon } from '../../components/Icon';
import CheckIcon from '@mui/icons-material/Check';
import { Label } from '../../components/Label';
import Stack from '@mui/material/Stack';
import { useAssetHistory } from '../../hooks/useAssetHistory';
import RecordList from '../../components/asset/RecordList';

function a11yProps(index) {
  return {
    id: `editor-tab-${index}`,
    'aria-controls': `editor-tabpanel-${index}`,
  };
}

export default function AssetHistoryPage() {
  const { user, setLoading, setTabIndex, setNavTitle } =
    useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const [recordType, setRecordType] = useState(getInitialRecordType(params));
  const [filter, setFilter] = useState(getInitialFilter(params));
  const [showCurrencyMenu, setShowCurrencyMenu] = useState(false);

  const { orders, deposits, withdraws, coinInfo } = useAssetHistory(
    user,
    setLoading,
  );

  const originFrom = state?.from || state?.originFrom || '';

  const handleBack = () => {
    if (originFrom.indexOf('/market') >= 0) {
      navigate(originFrom);
    } else {
      setTabIndex(1);
      setNavTitle('我的帳戶');
      navigate('/asset');
    }
  };

  const handleRecordClick = (record, type) => {
    switch (type) {
      case 'order':
        navigateToOrder(record, navigate, originFrom);
        break;
      case 'deposit':
        navigateToDeposit(record, navigate, originFrom);
        break;
      case 'withdraw':
        navigateToWithdraw(record, navigate, originFrom);
        break;
      default:
        break;
    }
  };

  const filteredOrders = orders.filter(
    (order) =>
      filter === 'all' ||
      getCurrencySymbolFromProductId(order.productId) === filter,
  );

  return (
    <>
      <NavigationBar title="帳戶紀錄" leftButtonHandler={handleBack} />
      <Box padding="60px 16px">
        <Menu
          recordType={recordType}
          onChangeType={setRecordType}
          filter={filter}
          coinInfo={coinInfo}
          showCurrencyMenu={showCurrencyMenu}
          setShowCurrencyMenu={setShowCurrencyMenu}
          onFilterChange={setFilter}
        />

        <RecordList
          type={['order', 'deposit', 'withdraw'][recordType]}
          records={
            recordType === 0
              ? filteredOrders
              : recordType === 1
              ? deposits
              : withdraws
          }
          onItemClick={(record) =>
            handleRecordClick(
              record,
              ['order', 'deposit', 'withdraw'][recordType],
            )
          }
        />
      </Box>
    </>
  );
}

function Menu({
  recordType,
  onChangeType,
  filter,
  coinInfo,
  showCurrencyMenu,
  setShowCurrencyMenu,
  onFilterChange,
}) {
  const navigate = useNavigate();
  const menuItems = ['訂單', '儲值', '提領'];

  const handleTabChange = (event, newValue) => {
    onChangeType(newValue);
    const path = `/assetHistory/${['order', 'deposit', 'withdraw'][newValue]}`;

    if (newValue === 0) {
      navigate(`${path}?q=${filter}`, { replace: true });
    } else {
      navigate(path, { replace: true });
    }
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'common.borderColor',
        }}>
        <Tabs
          value={recordType}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            bgcolor: 'background.default',
          }}
          TabIndicatorProps={{
            sx: { bgcolor: 'common.darkBlue' },
          }}>
          {menuItems.map((menuItem, index) => (
            <Tab
              label={menuItem}
              key={index}
              {...a11yProps({ index })}
              sx={{
                color: 'text.primary',
                '&.Mui-selected': {
                  color: 'text.primary',
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {recordType === 0 && (
        <DrawerWrapper
          title="選擇幣種"
          anchor="bottom"
          drawerContent={
            <CoinTypeList
              coinInfo={coinInfo}
              setFilter={onFilterChange}
              currentFilter={filter}
            />
          }
          open={showCurrencyMenu}
          setOpen={setShowCurrencyMenu}>
          <Button
            fullWidth
            endIcon={<ExpandMoreIcon />}
            sx={{
              height: '47px',
              textTransform: 'none',
              fontWeight: '400',
              color: 'text.primary',
              borderBottom: 1,
              borderColor: 'common.borderColor',
            }}
            onClick={() => setShowCurrencyMenu(true)}>
            {filter === 'all' ? '所有幣種' : filter}
          </Button>
        </DrawerWrapper>
      )}
    </>
  );
}

function CoinTypeList({ coinInfo, setFilter, currentFilter }) {
  const navigate = useNavigate();
  const handleFilterClick = (filter) => {
    const filterValue = filter === 'all' ? 'all' : filter;
    setFilter(filterValue);
    navigate(`/assetHistory/order?q=${filterValue}`, { replace: true });
  };

  return (
    <List>
      <ListItem
        onClick={() => handleFilterClick('all')}
        sx={{ marginBottom: '10px', cursor: 'pointer' }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ImageIcon width={40} height={40} src={'/icons/all_currency.svg'} />
          <Label>所有幣種</Label>
        </Stack>
        {currentFilter === 'all' && (
          <CheckIcon
            sx={{
              maxHeight: 40,
              maxWidth: 40,
              marginLeft: 'auto',
            }}
          />
        )}
      </ListItem>
      {coinInfo?.map((option, index) => (
        <ListItem
          key={index}
          onClick={() => handleFilterClick(option.baseCurrency.id)}
          sx={{ marginBottom: '10px', cursor: 'pointer' }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <SvgIcon
              width={40}
              height={40}
              src={`../icons/${option.baseCurrency.id}.svg`}
            />
            <Label>{option.baseCurrency.id}</Label>
          </Stack>
          {currentFilter === option.baseCurrency.id && (
            <CheckIcon
              sx={{
                maxHeight: 40,
                maxWidth: 40,
                marginLeft: 'auto',
              }}
            />
          )}
        </ListItem>
      ))}
    </List>
  );
}

// Utility functions
const getInitialRecordType = (params) => {
  switch (params.defaultFilter) {
    case 'order':
      return 0;
    case 'deposit':
      return 1;
    case 'withdraw':
      return 2;
    default:
      return 0;
  }
};

const getInitialFilter = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const currencyFilter = searchParams.get('q');
  return currencyFilter || 'all';
};

const getCurrencySymbolFromProductId = (productId) => {
  const symbols = productId.split('-');
  return symbols[0];
};

const navigateToOrder = (order, navigate, originFrom) => {
  // 如果是實體付款或銀行轉帳，則導向訂單付款頁
  if (
    (order.payment === 'store' || order.payment === 'bank') &&
    order.status === 'open'
  ) {
    navigate('/orderPayment', {
      state: { order, cancelOrder: true, originFrom },
    });
  } else {
    // 其餘，則導向訂單資訊頁
    navigate('/orderInfo', { state: { order, originFrom } });
  }
};

const navigateToDeposit = (deposit, navigate, originFrom) => {
  navigate(`/deposit/done`, {
    state: {
      depositRecord: deposit,
      originFrom,
    },
  });
};

const navigateToWithdraw = (withdraw, navigate, originFrom) => {
  navigate('/withdraw/bank/info', {
    state: {
      title: '提領詳情',
      bankRecord: withdraw,
      originFrom,
    },
  });
};
