import { useState, useEffect } from 'react';
import { UserAPI } from '../apis/UserAPI';
import { OrderAPI } from '../apis/OrderAPI';

export function useTransactions(user, setLoading, types = ['all']) {
  const [deposits, setDeposits] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function fetchTransactions() {
      if (!user) return;

      setLoading(true);
      try {
        if (types.includes('all')) {
          const [depositsResult, withdrawsResult, ordersResult] =
            await Promise.all([
              UserAPI.getDepositRecords(),
              UserAPI.getWithdrawRecords(),
              OrderAPI.getOrders(),
            ]);
          setDeposits(depositsResult);
          setWithdraws(withdrawsResult);
          setOrders(ordersResult);
        } else {
          const promises = [];
          if (types.includes('deposits')) {
            promises.push(UserAPI.getDepositRecords());
          }
          if (types.includes('withdraws')) {
            promises.push(UserAPI.getWithdrawRecords());
          }
          if (types.includes('orders')) {
            promises.push(OrderAPI.getOrders());
          }

          const results = await Promise.all(promises);
          let index = 0;
          if (types.includes('deposits')) {
            setDeposits(results[index++]);
          }
          if (types.includes('withdraws')) {
            setWithdraws(results[index++]);
          }
          if (types.includes('orders')) {
            setOrders(results[index]);
          }
        }
      } finally {
        setLoading(false);
      }
    }
    fetchTransactions();
  }, [user, setLoading, types]);

  return { deposits, withdraws, orders };
}
