import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { Tooltip, Typography } from '@mui/material';
import Icon from '../../../components/Icon';

const SelectBox = styled(Select)(() => ({
  fontSize: 14,
  textAlign: 'start',
  color: 'white',
  height: 42,
  paddingRight: 16,
  borderRadius: '8px',
  boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
  backgroundColor: '#150F32',
  '& .MuiSelect-select:empty': {
    color: 'rgba(255, 255, 255, 0.5)', // Placeholder color
  },
}));

const CustomIcon = (props) => (
  <Icon
    src="/icons/ic_drop_dowm_sm.svg"
    alt=""
    color="white"
    sx={{
      width: '18px',
      alignSelf: 'center',
      transform: 'rotate(90deg)',
    }}
  />
);

export default function Dropdown({
  name,
  options,
  value,
  onChange,
  defaultLabel,
  isDisabled,
}) {
  return (
    <FormControl sx={{ minWidth: 120 }}>
      <SelectBox
        IconComponent={CustomIcon}
        displayEmpty
        name={name}
        inputProps={{ 'aria-label': 'Without label' }}
        value={value}
        onChange={onChange}
        placeholder={defaultLabel}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <em
                style={{ fontStyle: 'normal', color: 'rgba(255,255,255,0.5)' }}>
                {defaultLabel}
              </em>
            );
          }

          const item = options.find(({ value: v }) => v === selected);
          return item.label;
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}>
        {options.map((option) => {
          return (
            <MenuItem
              key={option?.value}
              value={option?.value}
              sx={{ whiteSpace: 'normal', color: 'black' }}>
              {option?.label}
            </MenuItem>
          );
        })}
      </SelectBox>
    </FormControl>
  );
}
