import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(MuiButton)(({ theme, variant }) => ({
  borderRadius: '8px',
  padding: '12px 58px',
  width: '100%',
  fontFamily: 'PingFang TC, sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  letterSpacing: '-0.4px',
  whiteSpace: 'nowrap',
  ...(variant === 'outlined' && {
    border: '1px solid rgba(204, 195, 248, 1)',
    color: theme.palette.common.lightPurple,
    '&:focus': {
      outline: '2px solid #4A90E2',
      outlineOffset: '2px',
    },
  }),
  ...(variant === 'contained' && {
    backgroundColor: 'rgba(62, 30, 230, 1)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgba(62, 30, 230, 0.8)',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 3px rgba(62, 30, 230, 0.5)',
    },
    '&:disabled': {
      color: 'rgb(255, 255, 255, 0.5)',
    },
  }),
  ...(variant === 'disable' && {
    backgroundColor: theme.palette.common.purple,
    color: theme.palette.common.white,
    ':disabled': {
      color: theme.palette.common.white,
    },
  }),
  ...(variant === 'empty' && {
    color: theme.palette.common.lightPurple,
  }),
}));

const CustomButton = ({
  children,
  variant = 'outlined',
  onClick,
  ...props
}) => {
  return (
    <StyledButton variant={variant} {...props} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default CustomButton;
