import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { Box, List, ListItem, ListItemText, TextField } from '@mui/material';
import BottomPanel from '../../components/BottomPanel';
import Panel from '../../components/Panel';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import useInvoice from '../../hooks/useInvoice';
import StyledButton from '../../components/StyledButton';

export default function ChangeDoneeCodePage() {
  const { state } = useLocation();
  const billInfo = state?.billInfo;
  const [disableButton, setDisableButton] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showDoneeDialog, setShowDoneeDialog] = useState(false);
  const doneeRef = useRef(null);
  const doneeCodeRef = useRef(null);
  const navigate = useNavigate();
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const alertActionLabel = useRef(null);
  const { getDonees, getDonee, getDoneeCode } = useInvoice();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    doneeCodeRef.current = billInfo.doneeCode;
    doneeRef.current = billInfo.doneeName;
  }, [getDonee, billInfo]);

  const sxContainer = {
    pt: `${navigationBarHeight}px`,
  };

  const txtInputProps = {
    disableUnderline: true,
    style: {
      fontSize: '15px',
    },
  };

  const sxTextField = {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'black',
    },
  };

  const InputBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#FAFAFA',
    width: '100%',
    height: '56px',
    borderRadius: '9px',
  }));

  async function handleUpdateDoneeCode() {
    let payload = {};
    let alert = null;
    if (doneeCodeRef.current !== billInfo.doneeCode) {
      payload['donee_code'] = doneeCodeRef.current;
    } else {
      navigate(-1);
      return;
    }
    if (alert) {
      alertTitle.current = '愛心碼更新失敗';
      alertMessage.current = alert;
      alertActionLabel.current = '確定';
      setShowAlert(true);
    } else {
      const userData = await UserAPI.updateBillInfo(payload);
      if (userData !== null) {
        navigate(-1);
      } else {
        alertTitle.current = '愛心碼更新失敗';
        alertMessage.current = '請確認愛心碼是否正確';
        alertActionLabel.current = '確定';
        setShowAlert(true);
      }
    }
  }

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleUpdateDoneeCode}
          disabled={disableButton}>
          儲存
        </StyledButton>
      </BottomPanel>
    );
  };

  const navigation = () => {
    return (
      <NavigationBar
        title="發票捐贈愛心碼設定"
        leftButtonHandler={handleBack}
      />
    );
  };

  const handleDoneeClick = (item) => {
    if (doneeRef.current !== item) {
      setDisableButton(false);
      doneeRef.current = item;
      let doneeCode = getDoneeCode(item);
      doneeCodeRef.current = doneeCode;
      setShowDoneeDialog(false);
    }
  };

  const invoiceDialog = (
    title,
    dataList,
    showDialog,
    setShowDialog,
    ref,
    handle,
  ) => {
    return (
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title={title}>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
          }}>
          {dataList.map((item, index, data) => {
            if (index + 1 === data.length) {
              return (
                <React.Fragment key={index}>
                  <Box
                    display="flex"
                    alignItems="center"
                    marginRight="15px"
                    key={index}>
                    <ListItem
                      sx={{ height: '56px' }}
                      onClick={() => handle(item)}>
                      <ListItemText
                        primary={
                          <SubTitleLabel
                            color="#000000"
                            fontSize="15px"
                            fontWeight="bold">
                            {item}
                          </SubTitleLabel>
                        }
                      />
                    </ListItem>
                    {ref.current === item && <CheckIcon />}
                  </Box>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <Box display="flex" alignItems="center" marginRight="15px">
                    <ListItem
                      sx={{
                        height: '56px',
                      }}
                      onClick={() => handle(item)}>
                      <ListItemText
                        primary={
                          <SubTitleLabel
                            color="#000000"
                            fontSize="15px"
                            fontWeight="bold">
                            {item}
                          </SubTitleLabel>
                        }
                      />
                    </ListItem>
                    {ref.current === item && <CheckIcon />}
                  </Box>
                  <Divider variant="middle" component="li" />
                </React.Fragment>
              );
            }
          })}
        </List>
      </Dialog>
    );
  };

  const handleAlertAction = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        <Box margin="16px">
          <Panel>
            <Box margin="16px">
              <TitleLabel fontSize="18px" fontWeight="bold">
                優先捐贈單位
              </TitleLabel>
              <InputBox
                display="flex"
                alignItems="center"
                sx={{
                  padding: '16px',
                  border: 1,
                  borderColor: '#C1C1C1',
                  backgroundColor: '#FFFFFF',
                }}
                onClick={() => setShowDoneeDialog(true)}>
                <TextField
                  variant="standard"
                  InputProps={txtInputProps}
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  inputProps={{
                    min: 0,
                    style: {
                      textAlign: 'left',
                    },
                  }}
                  placeholder="請選擇捐贈單位"
                  sx={sxTextField}
                  value={doneeRef.current}
                  disabled
                />
                <ArrowDropDownIcon />
              </InputBox>
            </Box>
          </Panel>
        </Box>
        {bottomPanel()}
      </Box>
      {invoiceDialog(
        '請選擇捐贈單位',
        getDonees(),
        showDoneeDialog,
        setShowDoneeDialog,
        doneeRef,
        handleDoneeClick,
      )}
      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel={alertActionLabel.current}
        actionHandler={handleAlertAction}
      />
    </>
  );
}
